import { gql } from '@apollo/client';

export const GET_B2B_SHIPMENT_SERVICES_QUERY = gql`
  query GetB2BShipmentServices($limit: Int, $offset: Int) {
    getB2BShipmentServices(limit: $limit, offset: $offset) {
      rows {
        costInUSD
        description
        id
        title
      }
      count
    }
  }
`;
