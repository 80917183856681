import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import FilterSelectLimitItems from '../../../components/FilterComponents/FilterSelectLimitItems/FilterSelectLimitItems';
import { IFilters } from '../../../utils/types';
import {
  B2B_SHIPMENT_SERVICES_TABLE_CELLS_ARRAY,
  LOCAL_STORAGE_KEYS,
} from '../../../utils/constants';
import { LIMIT_ITEM_PAGE } from '../../../utils/helpers';
import TableBodyRequestHandler from '../../../components/TableBodyRequestHandler/TableRequestHandler';
import {
  B2BServices,
  useDeleteB2BServiceMutation,
  useGetB2BShipmentServicesLazyQuery,
} from '../../../generated/graphql';
import { useSnackbar } from 'notistack';
import PaginationComponent from '../../../components/PaginationComponent/PaginationComponent';
import B2bShipmentServicesTableRowComponent from '../../../components/B2bShipmentsComponents/B2bShipmentServicesTableRowComponent';
import B2bShipmentsServiceCreateModal from '../../../components/Modals/B2bShipmentsModals/B2bShipmentsServiceCreateModal';
import { GET_B2B_SHIPMENT_SERVICES_QUERY } from '../../../GraphQL/queries/getB2BShipmentServices';
import DeleteItemModal from '../../../components/Modals/DeleteItemModal';

const itemsLimit = localStorage.getItem(LOCAL_STORAGE_KEYS.ITEMS_LIMIT);

const B2bShipmentServices = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenCreateOrEditModal, setIsOpenCreateOrEditModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState<number | null>(null);

  const [itemToEdit, setItemToEdit] = useState<B2BServices>({
    title: '',
    description: '',
    costInUSD: 0,
    id: null,
  });

  const [filter, setFilter] = useState<IFilters>({
    page: 1,
    itemsLimit: itemsLimit ? +itemsLimit : LIMIT_ITEM_PAGE,
  });

  const [getB2BShipmentServicesLazyQuery, { data, loading }] =
    useGetB2BShipmentServicesLazyQuery({
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    getB2BShipmentServicesLazyQuery({
      variables: {
        offset: (filter.page - 1) * filter.itemsLimit!,
        limit: filter.itemsLimit,
      },
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
    });
    // eslint-disable-next-line
  }, [filter]);

  const [
    deleteB2BServiceMutation,
    { loading: deleteB2BServiceMutationLoading },
  ] = useDeleteB2BServiceMutation();

  const handleDeleteCloseModal = () => {
    setIsOpenDeleteModal(false);
    setItemIdToDelete(null);
  };

  const handleDelete = () => {
    deleteB2BServiceMutation({
      variables: { deleteB2BServiceId: itemIdToDelete },
      onCompleted: (data) => {
        if (data) {
          enqueueSnackbar('app.b2bShipmentsPointSuccessfullyDeleted', {
            variant: 'success',
          });
          handleDeleteCloseModal();
        }
      },
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
      refetchQueries: [GET_B2B_SHIPMENT_SERVICES_QUERY],
    });
  };

  const handleOpemModalToCreate = () => {
    setIsOpenCreateOrEditModal(true);
  };

  return (
    <>
      <Typography variant='h2' align='center' className={classes.mainTitle}>
        {t('app.shipmentServices')}
      </Typography>
      <div className={classes.buttonsForSelected}>
        <FilterSelectLimitItems
          value={filter.itemsLimit as number}
          setFilter={setFilter}
          localStorageItemsLimitKey={LOCAL_STORAGE_KEYS.ITEMS_LIMIT}
        />
        <Button variant='contained' onClick={handleOpemModalToCreate}>
          {t('app.add')}
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              {B2B_SHIPMENT_SERVICES_TABLE_CELLS_ARRAY.map((text) => (
                <TableCell key={text} align='center' rowSpan={2}>
                  {t(text)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableBodyRequestHandler
              dataLength={data?.getB2BShipmentServices?.rows?.length as number}
              colSpan={B2B_SHIPMENT_SERVICES_TABLE_CELLS_ARRAY.length}
              loading={loading}
            >
              {data?.getB2BShipmentServices?.rows?.length
                ? data?.getB2BShipmentServices?.rows?.map((item) => (
                    <B2bShipmentServicesTableRowComponent
                      key={item?.id}
                      item={item as B2BServices}
                      setItemToEdit={setItemToEdit}
                      setIsOpenCreateOrEditModal={setIsOpenCreateOrEditModal}
                      setItemIdToDelete={setItemIdToDelete}
                      setIsOpenDeleteModal={setIsOpenDeleteModal}
                    />
                  ))
                : null}
            </TableBodyRequestHandler>
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationComponent
        count={data?.getB2BShipmentServices?.count}
        limit={filter?.itemsLimit as number}
        currentPage={filter.page}
        setFilter={setFilter}
      />

      <B2bShipmentsServiceCreateModal
        isOpenCreateOrEditModal={isOpenCreateOrEditModal}
        setIsOpenCreateOrEditModal={setIsOpenCreateOrEditModal}
        item={itemToEdit}
        setItemToEdit={setItemToEdit}
      />

      <DeleteItemModal
        isOpenModal={isOpenDeleteModal}
        setIsOpenModal={setIsOpenDeleteModal}
        handleCloseModal={handleDeleteCloseModal}
        loading={deleteB2BServiceMutationLoading}
        handleDelete={handleDelete}
        title={t('app.confirmDeleteService')}
      />
    </>
  );
};

export default B2bShipmentServices;
