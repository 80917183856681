import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export enum Availability_Operation {
  Increment = 'INCREMENT',
  Decrement = 'DECREMENT'
}

export type AddUndeclaredShipmentItemInput = {
  undeclaredShipmentId: Scalars['Int'];
  productId: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type AdminConsolidationRateResponse = {
  __typename?: 'AdminConsolidationRateResponse';
  weight?: Maybe<Scalars['Float']>;
  volume_weight?: Maybe<Scalars['Float']>;
  weight_to_pay?: Maybe<Scalars['Float']>;
  variants?: Maybe<Array<Maybe<RateUserAccount>>>;
};

export type ArrivalResponceObject = {
  __typename?: 'ArrivalResponceObject';
  status?: Maybe<Scalars['String']>;
  entity?: Maybe<Entity>;
  b2bOrder?: Maybe<B2BOrder>;
  shipment?: Maybe<WarehouseShipment>;
  order?: Maybe<WarehouseShipmentOrder>;
  parcel?: Maybe<Parcel>;
  product?: Maybe<Product>;
  piece?: Maybe<WarehouseShipmentPiece>;
  warehouseZoneCell?: Maybe<WarehouseZoneCell>;
  expectedQuantityItem?: Maybe<Scalars['Int']>;
  acceptedQuantityItem?: Maybe<Scalars['Int']>;
};

export type AvailableWarehouseProduct = {
  __typename?: 'AvailableWarehouseProduct';
  inTransitQuantity?: Maybe<Scalars['Int']>;
  reservedQuantity?: Maybe<Scalars['Int']>;
  availableQuantity?: Maybe<Scalars['Int']>;
};

export type AvailableWarehouseProductsItem = {
  __typename?: 'AvailableWarehouseProductsItem';
  product?: Maybe<Product>;
  inTransitQuantity?: Maybe<Scalars['Int']>;
  reservedQuantity?: Maybe<Scalars['Int']>;
  availableQuantity?: Maybe<Scalars['Int']>;
};

export type B2BCargoPlaceProduct = {
  __typename?: 'B2BCargoPlaceProduct';
  id?: Maybe<Scalars['Int']>;
  cargoId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type B2BCargoPlaceProductInput = {
  cargoId?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type B2BDriverInfo = {
  __typename?: 'B2BDriverInfo';
  fullName?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  carModel?: Maybe<Scalars['String']>;
  carNumber?: Maybe<Scalars['String']>;
};

export type B2BDriverInfoInput = {
  fullName?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  carModel?: Maybe<Scalars['String']>;
  carNumber?: Maybe<Scalars['String']>;
};

export type B2BOrder = {
  __typename?: 'B2BOrder';
  id?: Maybe<Scalars['Int']>;
  client?: Maybe<UserClient>;
  wrapType?: Maybe<B2BWrapTypeEnum>;
  wrapWay?: Maybe<B2BWrapWayEnum>;
  shipmentInfo?: Maybe<B2BShipmentInfo>;
  barcodeId?: Maybe<Scalars['String']>;
  externalOrdeId?: Maybe<Scalars['String']>;
  shipmentPointTitle?: Maybe<Scalars['String']>;
  warehouseName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  technicalTaskFileUrl?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<B2BOrderProduct>>>;
  cargoPlaces?: Maybe<Array<Maybe<B2BOrderCargoPlace>>>;
  servicesWithCost?: Maybe<Array<Maybe<B2BServices>>>;
  totalCost?: Maybe<Scalars['Float']>;
  warehouseStatus?: Maybe<WarehouseB2BStatus>;
  createdAt?: Maybe<Scalars['String']>;
};

export type B2BOrderCargoPlace = {
  __typename?: 'B2BOrderCargoPlace';
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<B2BWrapWayEnum>;
  externalBarcodeUrl?: Maybe<Scalars['String']>;
  uniqSkuCount?: Maybe<Scalars['Int']>;
  productQty?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  productsItems?: Maybe<Array<Maybe<B2BCargoPlaceProduct>>>;
};

export type B2BOrderCargoPlaceInput = {
  id?: Maybe<Scalars['Int']>;
  uniqSkuCount?: Maybe<Scalars['Int']>;
  productQty?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  type?: Maybe<B2BWrapWayEnum>;
};

export type B2BOrderForManager = {
  __typename?: 'B2BOrderForManager';
  id?: Maybe<Scalars['Int']>;
  client?: Maybe<UserClient>;
  barcodeId?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  technicalTaskFileUrl?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<B2BOrderProductForManager>>>;
  warehouseStatus?: Maybe<WarehouseB2BStatus>;
  createdAt?: Maybe<Scalars['String']>;
};

export type B2BOrderList = {
  __typename?: 'B2BOrderList';
  rows?: Maybe<Array<Maybe<B2BOrderListItem>>>;
  count?: Maybe<Scalars['Int']>;
};

export type B2BOrderListFilter = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  dateFilter?: Maybe<Scalars['Int']>;
  deliveryStatusId?: Maybe<Scalars['Int']>;
  warehouseStatus?: Maybe<WarehouseB2BStatus>;
  warehouseId?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type B2BOrderListForManager = {
  __typename?: 'B2BOrderListForManager';
  rows?: Maybe<Array<Maybe<B2BOrderListItemForManger>>>;
  count?: Maybe<Scalars['Int']>;
};

export type B2BOrderListForManagerFilter = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  warehouseStatus?: Maybe<WarehouseB2BStatus>;
  search?: Maybe<Scalars['String']>;
};

export type B2BOrderListItem = {
  __typename?: 'B2BOrderListItem';
  id?: Maybe<Scalars['Int']>;
  client?: Maybe<UserClient>;
  barcodeId?: Maybe<Scalars['String']>;
  externalOrdeId?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<StatusPayment>;
  shipmentPointTitle?: Maybe<Scalars['String']>;
  productsQty?: Maybe<Scalars['Int']>;
  warehouseName?: Maybe<Scalars['String']>;
  totalCost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['String']>;
  shipmentDate?: Maybe<Scalars['String']>;
  warehouseStatus?: Maybe<WarehouseB2BStatus>;
};

export type B2BOrderListItemForManger = {
  __typename?: 'B2BOrderListItemForManger';
  id?: Maybe<Scalars['Int']>;
  client?: Maybe<UserClient>;
  barcodeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  warehouseStatus?: Maybe<WarehouseB2BStatus>;
};

export type B2BOrderProduct = {
  __typename?: 'B2BOrderProduct';
  productId?: Maybe<Scalars['Int']>;
  relatedProduct?: Maybe<B2BRelatedProduct>;
  acceptedQuantity?: Maybe<Scalars['Int']>;
  expectedQuantity?: Maybe<Scalars['Int']>;
};

export type B2BOrderProductForManager = {
  __typename?: 'B2BOrderProductForManager';
  productId?: Maybe<Scalars['Int']>;
  relatedProduct?: Maybe<B2BRelatedProduct>;
  expectedQuantity?: Maybe<Scalars['String']>;
  acceptedQuantity?: Maybe<Scalars['String']>;
};

export type B2BOrderProductInput = {
  productId?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type B2BOrderServiceInput = {
  title?: Maybe<Scalars['String']>;
  costInUSD?: Maybe<Scalars['Float']>;
  qty?: Maybe<Scalars['Int']>;
};

export type B2BRelatedProduct = {
  __typename?: 'B2BRelatedProduct';
  name?: Maybe<Scalars['String']>;
  barcodeId?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
};

export type B2BServices = {
  __typename?: 'B2BServices';
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  costInUSD?: Maybe<Scalars['Float']>;
  qty?: Maybe<Scalars['Int']>;
};

export type B2BShipmentInfo = {
  __typename?: 'B2BShipmentInfo';
  typeMethod?: Maybe<B2BShipmentMethodTypeEnum>;
  scheduledDate?: Maybe<Scalars['String']>;
  scheduledTime?: Maybe<Scalars['String']>;
  shipmentLabelFileUrl?: Maybe<Scalars['String']>;
  driverInfo?: Maybe<B2BDriverInfo>;
};

export type B2BShipmentInfoInput = {
  typeMethod?: Maybe<B2BShipmentMethodTypeEnum>;
  scheduledDate?: Maybe<Scalars['String']>;
  scheduledTime?: Maybe<Scalars['String']>;
  shipmentLabelFile?: Maybe<Scalars['Upload']>;
  driverInfo?: Maybe<B2BDriverInfoInput>;
};

export enum B2BShipmentMethodTypeEnum {
  PicknPack = 'PicknPack',
  Custom = 'Custom'
}

export type B2BShipmentPoint = {
  __typename?: 'B2BShipmentPoint';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export enum B2BWrapTypeEnum {
  Boxes = 'Boxes',
  Pallet = 'Pallet'
}

export enum B2BWrapWayEnum {
  MonoboxOrMonopallet = 'MonoboxOrMonopallet',
  MixBoxOrMixPallet = 'MixBoxOrMixPallet'
}

export type Balance = {
  __typename?: 'Balance';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  income?: Maybe<Scalars['Float']>;
  paymentParcel?: Maybe<Scalars['Float']>;
  discrepancyInWeight?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  indebtedness?: Maybe<Scalars['Float']>;
};

export type BalanceResponse = {
  __typename?: 'BalanceResponse';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Balance>>>;
  totalIncome?: Maybe<Scalars['Float']>;
  totalPaymentParcel?: Maybe<Scalars['Float']>;
  totalDiscrepancyInWeight?: Maybe<Scalars['Float']>;
  totalIndebtedness?: Maybe<Scalars['Float']>;
  totalBalance?: Maybe<Scalars['Float']>;
};


export type CdekBarcode = {
  __typename?: 'CDEKBarcode';
  entity?: Maybe<CdekEntityBarcode>;
  requests?: Maybe<Array<Maybe<CdekRequest>>>;
};

export type CdekCalculationByTariffCodeInput = {
  from_location: Scalars['String'];
  packages?: Maybe<Array<CargoPlaceInput>>;
};

export type CdekCalculationByTariffCodeResponse = {
  __typename?: 'CDEKCalculationByTariffCodeResponse';
  delivery_sum?: Maybe<Scalars['Float']>;
  period_min?: Maybe<Scalars['Int']>;
  period_max?: Maybe<Scalars['Int']>;
  weight_calc?: Maybe<Scalars['Int']>;
  total_sum?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<CdekError>>>;
  tariff?: Maybe<CdekTariffInfo>;
};

export type CdekCitiesListFilterInput = {
  country_codes?: Maybe<Array<Maybe<Scalars['String']>>>;
  region_code?: Maybe<Scalars['Int']>;
  kladr_region_code?: Maybe<Scalars['String']>;
  fias_region_code?: Maybe<Scalars['String']>;
  kladr_code?: Maybe<Scalars['String']>;
  fias_guid?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
  payment_limit?: Maybe<Scalars['Float']>;
};

export type CdekCitiesListResponse = {
  __typename?: 'CDEKCitiesListResponse';
  code?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  fias_guid?: Maybe<Scalars['String']>;
  kladr_code?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  contry?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  region_code?: Maybe<Scalars['Int']>;
  fias_region_guid?: Maybe<Scalars['String']>;
  kladr_region_code?: Maybe<Scalars['String']>;
  sub_region?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Array<Maybe<Scalars['String']>>>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  time_zone?: Maybe<Scalars['String']>;
  payment_limit?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<Maybe<CdekError>>>;
};

export type CdekCourierCreateRequest = {
  intake_date: Scalars['String'];
  intake_time_from: Scalars['String'];
  intake_time_to: Scalars['String'];
  name: Scalars['String'];
};

export type CdekCourierEntity = {
  __typename?: 'CDEKCourierEntity';
  uuid?: Maybe<Scalars['String']>;
  intake_date?: Maybe<Scalars['String']>;
  intake_time_from?: Maybe<Scalars['String']>;
  intake_time_to?: Maybe<Scalars['String']>;
  lunch_time_from?: Maybe<Scalars['String']>;
  lunch_time_to?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  sender?: Maybe<CdekSender>;
  from_location?: Maybe<CdekLocation>;
  need_call?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<Array<Maybe<CdekCourierStatuses>>>;
};

export type CdekCourierInfo = {
  __typename?: 'CDEKCourierInfo';
  entity?: Maybe<CdekCourierEntity>;
  requests?: Maybe<Array<Maybe<CdekRequest>>>;
};

export type CdekCourierPickUpRequestInput = {
  __typename?: 'CDEKCourierPickUpRequestInput';
  intake_date?: Maybe<Scalars['String']>;
  intake_time_from?: Maybe<Scalars['String']>;
  intake_time_to?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type CdekCourierRequestInput = {
  intake_date?: Maybe<Scalars['String']>;
  intake_time_from?: Maybe<Scalars['String']>;
  intake_time_to?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CdekCourierStatuses = {
  __typename?: 'CDEKCourierStatuses';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  date_time?: Maybe<Scalars['String']>;
};

export type CdekDeliveryDetail = {
  __typename?: 'CDEKDeliveryDetail';
  date?: Maybe<Scalars['String']>;
  recepient_name?: Maybe<Scalars['String']>;
  total_sum?: Maybe<Scalars['Float']>;
};

export type CdekDeliveryRecipientCost = {
  __typename?: 'CDEKDeliveryRecipientCost';
  value?: Maybe<Scalars['Float']>;
  vat_sum?: Maybe<Scalars['Float']>;
  vat_rate?: Maybe<Scalars['Int']>;
};

export type CdekDeliveryRecipientCostAdv = {
  __typename?: 'CDEKDeliveryRecipientCostAdv';
  threshold?: Maybe<Scalars['Int']>;
  sum?: Maybe<Scalars['Float']>;
  vat_sum?: Maybe<Scalars['Float']>;
  vat_rate?: Maybe<Scalars['Int']>;
};

export type CdekDimension = {
  __typename?: 'CDEKDimension';
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
};

export type CdekEntity = {
  __typename?: 'CDEKEntity';
  uuid?: Maybe<Scalars['String']>;
};

export type CdekEntityBarcode = {
  __typename?: 'CDEKEntityBarcode';
  uuid?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type CdekEntityResponse = {
  __typename?: 'CDEKEntityResponse';
  entity?: Maybe<CdekEntity>;
  requests?: Maybe<Array<Maybe<CdekRequest>>>;
  courierInfo?: Maybe<CdekCourierInfo>;
};

export type CdekError = {
  __typename?: 'CDEKError';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type CdekImageList = {
  __typename?: 'CDEKImageList';
  url?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
};

export type CdekInfoAbountOrderDeliveryDetail = {
  __typename?: 'CDEKInfoAbountOrderDeliveryDetail';
  date?: Maybe<Scalars['String']>;
  recipient_name?: Maybe<Scalars['String']>;
  payment_sum?: Maybe<Scalars['Float']>;
};

export type CdekInfoAboutOrderEntity = {
  __typename?: 'CDEKInfoAboutOrderEntity';
  uuid?: Maybe<Scalars['String']>;
  is_return?: Maybe<Scalars['Boolean']>;
  is_reverse?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['Int']>;
  cdek_number?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  tariff_code?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  shipment_point?: Maybe<Scalars['String']>;
  delivery_point?: Maybe<Scalars['String']>;
  date_invoice?: Maybe<Scalars['String']>;
  shipper_name?: Maybe<Scalars['String']>;
  shipper_address?: Maybe<Scalars['String']>;
  delivery_recipient_cost?: Maybe<CdekDeliveryRecipientCost>;
  delivery_recipient_cost_adv?: Maybe<CdekDeliveryRecipientCostAdv>;
  seller?: Maybe<CdekSeller>;
  recipient?: Maybe<CdekRecipient>;
  statuses?: Maybe<Array<Maybe<CdekCourierStatuses>>>;
  from_location?: Maybe<CdekLocation>;
  to_location?: Maybe<CdekLocation>;
  packages?: Maybe<Array<Maybe<CdekInfoAboutOrderPackage>>>;
  delivery_detail?: Maybe<CdekDeliveryDetail>;
  sender?: Maybe<CdekSender>;
};

export type CdekInfoAboutOrderPackage = {
  __typename?: 'CDEKInfoAboutOrderPackage';
  package_id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  weight_volume?: Maybe<Scalars['Int']>;
  weight_calc?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<CdekRegistrationOrderItemForPackage>>>;
};

export type CdekInfoAboutOrderResponse = {
  __typename?: 'CDEKInfoAboutOrderResponse';
  entity?: Maybe<CdekInfoAboutOrderEntity>;
  requests?: Maybe<Array<Maybe<CdekRequest>>>;
  otherInfo?: Maybe<CdekParcel>;
  courierInfo?: Maybe<CdekCourierEntity>;
};

export type CdekInputFilter = {
  city: Scalars['String'];
  address: Scalars['String'];
  postal_code: Scalars['String'];
};

export type CdekLocation = {
  __typename?: 'CDEKLocation';
  code?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  region_code?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
  city_code?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  address?: Maybe<Scalars['String']>;
  address_full?: Maybe<Scalars['String']>;
};

export type CdekLocationFilters = {
  region?: Maybe<CdekRegionListFilterInput>;
  office?: Maybe<CdekOfiiceListFilterInput>;
  city?: Maybe<CdekCitiesListFilterInput>;
};

export type CdekLocationList = {
  __typename?: 'CDEKLocationList';
  regions?: Maybe<Array<Maybe<CdekRegionListResponse>>>;
  offices?: Maybe<Array<Maybe<CdekOfficeListResponse>>>;
  cities?: Maybe<Array<Maybe<CdekCitiesListResponse>>>;
};

export type CdekOfficeListResponse = {
  __typename?: 'CDEKOfficeListResponse';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  location?: Maybe<CdekLocation>;
  address_comment?: Maybe<Scalars['String']>;
  nearest_station?: Maybe<Scalars['String']>;
  nearest_metro_station?: Maybe<Scalars['String']>;
  work_time?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<Maybe<CdekPhone>>>;
  email?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  owner_code?: Maybe<Scalars['String']>;
  take_only?: Maybe<Scalars['Boolean']>;
  is_handout?: Maybe<Scalars['Boolean']>;
  is_dressing_room?: Maybe<Scalars['Boolean']>;
  have_cashless?: Maybe<Scalars['Boolean']>;
  have_cash?: Maybe<Scalars['Boolean']>;
  allowed_code?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Scalars['String']>;
  office_image_list?: Maybe<Array<Maybe<CdekImageList>>>;
  work_time_list?: Maybe<Array<Maybe<CdekWorkTime>>>;
  work_time_exceptions?: Maybe<Array<Maybe<CdekWorkTimeException>>>;
  weight_min?: Maybe<Scalars['Float']>;
  weight_max?: Maybe<Scalars['Float']>;
  fulfillment?: Maybe<Scalars['Boolean']>;
  dimensions?: Maybe<CdekDimension>;
  errors?: Maybe<Array<Maybe<CdekError>>>;
};

export type CdekOfiiceListFilterInput = {
  postal_code?: Maybe<Scalars['Int']>;
  city_code?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  region_code?: Maybe<Scalars['Int']>;
  have_cash?: Maybe<Scalars['Boolean']>;
  allowed_cod?: Maybe<Scalars['Boolean']>;
  is_dressing_room?: Maybe<Scalars['Boolean']>;
  weight_max?: Maybe<Scalars['Int']>;
  weight_min?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
  take_only?: Maybe<Scalars['Boolean']>;
  is_handout?: Maybe<Scalars['Boolean']>;
};

export type CdekOrderListInfo = {
  __typename?: 'CDEKOrderListInfo';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<CdekParcel>>>;
};

export type CdekOrderLocation = {
  address?: Maybe<Scalars['String']>;
  code: Scalars['Int'];
  city?: Maybe<Scalars['String']>;
};

export type CdekParcel = {
  __typename?: 'CDEKParcel';
  id?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['Int']>;
  entityUuid?: Maybe<Scalars['String']>;
  requestUuid?: Maybe<Scalars['String']>;
  dateTime?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  parcelIds?: Maybe<Scalars['String']>;
  parcelBarcodes?: Maybe<Scalars['String']>;
  courierUuid?: Maybe<Scalars['String']>;
  paymentStatusId?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['String']>;
  trackNumber?: Maybe<Scalars['String']>;
  parcelErrorsReason?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  courierStatus?: Maybe<Scalars['String']>;
  courierErrorsReason?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  status?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  city_code?: Maybe<Scalars['Int']>;
  office_code?: Maybe<Scalars['String']>;
  apartmentNumber?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  tariffCode?: Maybe<Scalars['Int']>;
  houseNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  pdf?: Maybe<Scalars['String']>;
  packages?: Maybe<Array<Maybe<CargoPlace>>>;
};

export type CdekPayment = {
  __typename?: 'CDEKPayment';
  value?: Maybe<Scalars['Float']>;
};

export type CdekPhone = {
  __typename?: 'CDEKPhone';
  number?: Maybe<Scalars['String']>;
  additional?: Maybe<Scalars['String']>;
};

export type CdekRecipient = {
  __typename?: 'CDEKRecipient';
  name: Scalars['String'];
  email: Scalars['String'];
  phones: Array<Maybe<CdekPhone>>;
};

export type CdekRegionListFilterInput = {
  country_codes?: Maybe<Array<Maybe<Scalars['String']>>>;
  region_code?: Maybe<Scalars['Int']>;
  kladr_region_code?: Maybe<Scalars['String']>;
  flash_region_guid?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
};

export type CdekRegionListResponse = {
  __typename?: 'CDEKRegionListResponse';
  country_code?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  region_code?: Maybe<Scalars['Int']>;
  kladr_region_guid?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<CdekError>>>;
};

export type CdekRegistrationOrderInpur = {
  preliminaryCost: Scalars['Float'];
  from: Scalars['String'];
  tariff_code: Scalars['Int'];
  parcel_ids: Array<Maybe<Scalars['Int']>>;
  packages?: Maybe<Array<Maybe<CargoPlaceInput>>>;
  shipment_point?: Maybe<Scalars['String']>;
  from_location?: Maybe<CdekOrderLocation>;
  to_location?: Maybe<CdekOrderLocation>;
  comment?: Maybe<Scalars['String']>;
  sender?: Maybe<CdekRegistrationOrderSender>;
  recipient?: Maybe<CdekRegistrationOrderRecipient>;
  delivery_point?: Maybe<Scalars['String']>;
};

export type CdekRegistrationOrderInput = {
  preliminaryCost?: Maybe<Scalars['Float']>;
  from?: Maybe<Scalars['String']>;
  tariff_code?: Maybe<Scalars['Int']>;
  shipment_point?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  delivery_point?: Maybe<Scalars['String']>;
};

export type CdekRegistrationOrderItemForPackage = {
  __typename?: 'CDEKRegistrationOrderItemForPackage';
  ware_key?: Maybe<Scalars['String']>;
  payment?: Maybe<CdekPayment>;
  cost?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
};

export type CdekRegistrationOrderPackage = {
  __typename?: 'CDEKRegistrationOrderPackage';
  number?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<CdekRegistrationOrderItemForPackage>>>;
};

export type CdekRegistrationOrderRecipient = {
  company: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type CdekRegistrationOrderSender = {
  fullName: Scalars['String'];
  phone: Scalars['String'];
  street: Scalars['String'];
  houseNumber: Scalars['String'];
  apartmentNumber: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
};

export type CdekRequest = {
  __typename?: 'CDEKRequest';
  request_uuid?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  date_time?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<CdekError>>>;
  warnings?: Maybe<Array<Maybe<CdekError>>>;
};

export type CdekSeller = {
  __typename?: 'CDEKSeller';
  name?: Maybe<Scalars['String']>;
  inn?: Maybe<Scalars['String']>;
  phone?: Maybe<CdekPhone>;
  ownership_form?: Maybe<Scalars['Int']>;
  address?: Maybe<Scalars['String']>;
};

export type CdekSender = {
  __typename?: 'CDEKSender';
  company?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pasport_requirements_statisfied?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<Maybe<CdekPhone>>>;
  tin?: Maybe<Scalars['String']>;
};

export type CdekTariffInfo = {
  __typename?: 'CDEKTariffInfo';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  mode?: Maybe<Scalars['String']>;
  weight_restrictions?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type CdekWorkTime = {
  __typename?: 'CDEKWorkTime';
  day?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['String']>;
};

export type CdekWorkTimeException = {
  __typename?: 'CDEKWorkTimeException';
  data?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  is_work?: Maybe<Scalars['Boolean']>;
};

export enum Change_Product_Availability_Operation {
  Refill = 'REFILL',
  WriteOff = 'WRITE_OFF'
}

export enum Currency {
  Usd = 'USD',
  Eur = 'EUR',
  Gbp = 'GBP',
  Rub = 'RUB'
}

export type CalculationInput = {
  mode?: Maybe<Mode_Types>;
  warehouseId?: Maybe<Scalars['ID']>;
  sender?: Maybe<ParcelContactInput>;
  receiver: ParcelContactInput;
  address_validation: Scalars['Boolean'];
  cargo_price?: Maybe<Scalars['Float']>;
  currency_code: Scalars['String'];
  iso: Scalars['String'];
  positions: Array<Maybe<CalculatorPositions>>;
  userId?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  readyTime?: Maybe<Scalars['String']>;
  additionalInsurance?: Maybe<Scalars['Boolean']>;
  insuranceAmount?: Maybe<Scalars['Float']>;
  signature?: Maybe<Scalars['Boolean']>;
};

export type CalculatorParcelUnits = {
  quantity: Scalars['Int'];
  price: Scalars['Float'];
  code?: Maybe<Scalars['BigInt']>;
  countryId?: Maybe<Scalars['Int']>;
  nameEN?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  sku?: Maybe<Scalars['String']>;
};

export type CalculatorPositions = {
  weight: Scalars['Float'];
  length: Scalars['Float'];
  height: Scalars['Float'];
  width: Scalars['Float'];
  count: Scalars['Int'];
  units?: Maybe<Array<Maybe<CalculatorParcelUnits>>>;
};

export type CalculatorResponse = {
  __typename?: 'CalculatorResponse';
  weight?: Maybe<Scalars['Float']>;
  volume_weight?: Maybe<Scalars['Float']>;
  weight_to_pay?: Maybe<Scalars['Float']>;
  special_tax?: Maybe<Scalars['Float']>;
  insurance?: Maybe<Scalars['Float']>;
  data?: Maybe<Array<Maybe<CalculatorResponseData>>>;
  userAccount?: Maybe<QuoteResponseForCalculator>;
};

export type CalculatorResponseData = {
  __typename?: 'CalculatorResponseData';
  name?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  days_to_delivery?: Maybe<Scalars['Int']>;
  summary_amount?: Maybe<Scalars['Float']>;
  remoteArea?: Maybe<Scalars['Float']>;
  typeDelivery?: Maybe<Scalars['Int']>;
  fuelSurcharge?: Maybe<Scalars['Float']>;
  dollarRate?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  rateId?: Maybe<Scalars['ID']>;
  shipmentId?: Maybe<Scalars['ID']>;
  info?: Maybe<VariantInfo>;
  priceCurrency?: Maybe<Scalars['String']>;
};

export type CalculatorResponseObject = {
  __typename?: 'CalculatorResponseObject';
  standard?: Maybe<Array<Maybe<CalculatorResponseData>>>;
  doorToDoor?: Maybe<Array<Maybe<CalculatorResponseData>>>;
};

export type CargoPlace = {
  __typename?: 'CargoPlace';
  id?: Maybe<Scalars['Int']>;
  warehouseShipmentId?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
};

export type CargoPlaceInput = {
  weight?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
};

export type ChangeProductAvailabilityInput = {
  productId: Scalars['Int'];
  warehouseId?: Maybe<Scalars['Int']>;
  quantity: Scalars['Int'];
  operation?: Maybe<Change_Product_Availability_Operation>;
  comment?: Maybe<Scalars['String']>;
  cellId: Scalars['ID'];
};

export type CheckParcels = {
  __typename?: 'CheckParcels';
  parcels?: Maybe<Array<Maybe<Parcel>>>;
  numberOfFount?: Maybe<Scalars['Int']>;
  numberOfNotFount?: Maybe<Scalars['Int']>;
  foundList?: Maybe<Array<Maybe<Scalars['String']>>>;
  notFoundList?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CityInfo = {
  __typename?: 'CityInfo';
  cityId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  altName?: Maybe<Scalars['String']>;
  /** Country ISO 3166-1 alpha-2 */
  country?: Maybe<Scalars['String']>;
  featureCode?: Maybe<Scalars['String']>;
  adminCode?: Maybe<Scalars['String']>;
  population?: Maybe<Scalars['Int']>;
};

export type CompleteUndeclaredShipmentInput = {
  id: Scalars['Int'];
  carrierCode?: Maybe<Scalars['String']>;
  trackNumber?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  files: Array<Maybe<Scalars['Upload']>>;
};

export type Config = {
  __typename?: 'Config';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['JSON']>;
};

export type ConfigInput = {
  key: Scalars['String'];
  value: Scalars['JSON'];
};

export type ConfigList = {
  __typename?: 'ConfigList';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Config>>>;
};

export type Consolidation = {
  __typename?: 'Consolidation';
  id?: Maybe<Scalars['ID']>;
  serialNumber?: Maybe<Scalars['String']>;
  trackNumber?: Maybe<Scalars['String']>;
  deliveryStatus?: Maybe<StatusDelivery>;
  paymentStatus?: Maybe<StatusPayment>;
  packages?: Maybe<Array<Maybe<CargoPlace>>>;
  parcels?: Maybe<Array<Maybe<Parcel>>>;
  sender?: Maybe<ParcelContact>;
  receiver?: Maybe<ParcelContact>;
  insurance?: Maybe<Scalars['Boolean']>;
  shipmentServiceName?: Maybe<Scalars['String']>;
  waybillPath?: Maybe<Scalars['String']>;
  cargoPlaces?: Maybe<Scalars['Int']>;
  totalGrossWeight?: Maybe<Scalars['Float']>;
  totalNetWeight?: Maybe<Scalars['Float']>;
  totalVolumetricWeight?: Maybe<Scalars['Float']>;
  totalCostOfEnteties?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
  senderAccount?: Maybe<Scalars['String']>;
  fileTokenInvoiceXlsx?: Maybe<Scalars['String']>;
  barcode?: Maybe<Scalars['String']>;
};

export type ConsolidationList = {
  __typename?: 'ConsolidationList';
  consolidations?: Maybe<Array<Maybe<Consolidation>>>;
  count?: Maybe<Scalars['Int']>;
};

export type Contact = {
  __typename?: 'Contact';
  id: Scalars['ID'];
  name: Scalars['String'];
  company: Scalars['String'];
  country?: Maybe<DeliveryCountry>;
  address: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  eoriCode?: Maybe<Scalars['String']>;
  innKppCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  contactName?: Maybe<Scalars['String']>;
  type?: Maybe<ContactType>;
};

export type ContactFilter = {
  contactType?: Maybe<ContactType>;
  contactName?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ContactInput = {
  name: Scalars['String'];
  company: Scalars['String'];
  countryId: Scalars['Int'];
  address: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  phone: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  eoriCode?: Maybe<Scalars['String']>;
  innKppCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  /** Available variants: SENDER, RECIPIENT(default) */
  type?: Maybe<ContactType>;
};

export type ContactList = {
  __typename?: 'ContactList';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Contact>>>;
};

export enum ContactType {
  Sender = 'SENDER',
  Recipient = 'RECIPIENT'
}

export type CourierList = {
  __typename?: 'CourierList';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<CourierResponse>>>;
};

export type CourierRequestInput = {
  /** Date of pickup */
  pickupDate: Scalars['String'];
  /** Time pickup must be ready by */
  readyByTime: Scalars['String'];
  /** Closing time of pickup location */
  closeTime: Scalars['String'];
  /** The contactName element contains the name of the consignee for the pickup. It is mandatory element in the ConsigneeDetails Contact element. It is of type String with maximum length 35 */
  contactName?: Maybe<Scalars['String']>;
  /** The contactPhone element contains the phone number of the consignee for the pickup. It is mandatory element in the ConsigneeDetails Contact Element */
  contactPhone?: Maybe<Scalars['String']>;
  /** Unless location type is residence, company name is required */
  company?: Maybe<Scalars['String']>;
  /** The PackageLocation element contains the information about the location of the package at the pick address. e.g. Front Desk. It must be declared in the Place element */
  packageLocation: Scalars['String'];
  /** The Pieces Element contains the information about the pieces. */
  pieces: Array<Maybe<PieceInput>>;
  /** Service identifier through which the package is sent */
  senderAccountId: Scalars['Int'];
  /** Sender contact id */
  contactId?: Maybe<Scalars['Int']>;
};

export type CourierResponse = {
  __typename?: 'CourierResponse';
  id?: Maybe<Scalars['Int']>;
  pickupDate?: Maybe<Scalars['String']>;
  readyByTime?: Maybe<Scalars['String']>;
  closeTime?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  packageLocation?: Maybe<Scalars['String']>;
  pieces?: Maybe<Array<Maybe<Piece>>>;
  confirmationNumber?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CreateB2BOrderInput = {
  warehouseId?: Maybe<Scalars['Int']>;
  products?: Maybe<Array<Maybe<B2BOrderProductInput>>>;
  wrapType?: Maybe<B2BWrapTypeEnum>;
  wrapWay?: Maybe<B2BWrapWayEnum>;
  comment?: Maybe<Scalars['String']>;
  technicalTaskFile?: Maybe<Scalars['Upload']>;
  shipmentPointId?: Maybe<Scalars['Int']>;
  shipmentInfo?: Maybe<B2BShipmentInfoInput>;
};

export type CreateWarehouseShipmentOrderInput = {
  warehouseId?: Maybe<Scalars['Int']>;
  products?: Maybe<Array<Maybe<ShipmentProductInput>>>;
  receiver: ParcelContactInput;
  carrierCode?: Maybe<Scalars['String']>;
  carrierService?: Maybe<Scalars['String']>;
  typeDeliveryId?: Maybe<Scalars['Int']>;
  wrap?: Maybe<Scalars['String']>;
  giftWrap?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  rateId: Scalars['ID'];
  shipmentId: Scalars['ID'];
  lastMileCost: Scalars['String'];
  additionalInsurance?: Maybe<Scalars['Boolean']>;
  insuranceAmount?: Maybe<Scalars['Float']>;
  signature?: Maybe<Scalars['Boolean']>;
  senderMark?: Maybe<Scalars['String']>;
  vatKey?: Maybe<Vatkey>;
  vatValue?: Maybe<Scalars['String']>;
  totalGrossWeight?: Maybe<Scalars['Float']>;
  totalNetWeight?: Maybe<Scalars['Float']>;
  orderCostCurrency?: Maybe<Scalars['String']>;
  preliminaryCost?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  handlingFee?: Maybe<Scalars['Float']>;
  marketplaceInvoiceToken?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  marketPlaceOrderId?: Maybe<Scalars['Int']>;
};

export type CreateWarehouseShipmentOrderWithoutBuyLabelInput = {
  warehouseId?: Maybe<Scalars['Int']>;
  products?: Maybe<Array<Maybe<ShipmentProductInput>>>;
  carrierCode?: Maybe<Scalars['String']>;
  carrierService?: Maybe<Scalars['String']>;
  wrap?: Maybe<Scalars['String']>;
  giftWrap?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  additionalInsurance?: Maybe<Scalars['Boolean']>;
  insuranceAmount?: Maybe<Scalars['Float']>;
  signature?: Maybe<Scalars['Boolean']>;
  senderMark?: Maybe<Scalars['String']>;
  vatKey?: Maybe<Vatkey>;
  vatValue?: Maybe<Scalars['String']>;
  totalGrossWeight?: Maybe<Scalars['Float']>;
  totalNetWeight?: Maybe<Scalars['Float']>;
  orderCostCurrency?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  handlingFee?: Maybe<Scalars['Float']>;
  marketplaceInvoiceToken?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  marketPlaceOrderId?: Maybe<Scalars['Int']>;
  trackNumber?: Maybe<Scalars['String']>;
};

export type CreateWarehouseZoneCell = {
  name: Scalars['String'];
  warehouseZoneId: Scalars['Int'];
};

export type DashboardStatistic = {
  __typename?: 'DashboardStatistic';
  warehouseShipmentsCount?: Maybe<Scalars['Int']>;
  returnsCount?: Maybe<Scalars['Int']>;
  totalWarehouseShipmentsCost?: Maybe<Scalars['Float']>;
  ordersPerDayList?: Maybe<Array<Maybe<OrderPerDey>>>;
  orderStatuses?: Maybe<Array<Maybe<OrderStatusesStatictic>>>;
  totalProductQuantity?: Maybe<Scalars['Int']>;
  totalUniqueSKUCount?: Maybe<Scalars['Int']>;
  totalStorageCost?: Maybe<Scalars['Float']>;
  totalShippedProductQuantity?: Maybe<Scalars['Int']>;
  totalInboundAcceptedProductQuantity?: Maybe<Scalars['Int']>;
  totalInboundInTransitProductQuantity?: Maybe<Scalars['Int']>;
};

export type DeliveryCountry = {
  __typename?: 'DeliveryCountry';
  id?: Maybe<Scalars['Int']>;
  order: Scalars['Int'];
  name: Scalars['String'];
  daysToDelivery: Scalars['Int'];
  zoneId: Scalars['Int'];
  nameEng: Scalars['String'];
  iso?: Maybe<Scalars['String']>;
  phoneCode?: Maybe<Scalars['String']>;
  phoneMin?: Maybe<Scalars['Int']>;
  phoneMax?: Maybe<Scalars['Int']>;
  customsInfo?: Maybe<Scalars['String']>;
};

export type DeliveryDateInfo = {
  __typename?: 'DeliveryDateInfo';
  /** The DeliveryType element defines the type of Delivery. */
  DeliveryType?: Maybe<Scalars['String']>;
  /** The DlvyDateTime element is the delivery date and time. */
  DlvyDateTime?: Maybe<Scalars['String']>;
  /** The DeliveryDate element is the date the shipment is delivered. */
  DeliveryDateTimeOffset?: Maybe<Scalars['String']>;
};

export type DeliveryOptionsResponse = {
  __typename?: 'DeliveryOptionsResponse';
  name?: Maybe<Scalars['String']>;
  days_to_delivery?: Maybe<Scalars['Int']>;
  summary_amount?: Maybe<Scalars['Float']>;
  lastMileCost?: Maybe<Scalars['String']>;
  typeDelivery?: Maybe<Scalars['Int']>;
  info?: Maybe<VariantInfoWarehouseShipmentOrder>;
  rateId?: Maybe<Scalars['ID']>;
  shipmentId?: Maybe<Scalars['ID']>;
  monthly_pay_amount?: Maybe<Scalars['Float']>;
  priceCurrency?: Maybe<Scalars['String']>;
};

export type DeliveryOptionsResponseObject = {
  __typename?: 'DeliveryOptionsResponseObject';
  variants?: Maybe<Array<Maybe<DeliveryOptionsResponse>>>;
  monthly_pay_amount?: Maybe<Scalars['Float']>;
};

export enum DeliveryService {
  AmazonLogistics = 'AMAZON_LOGISTICS',
  Ups = 'UPS',
  Dhl = 'DHL',
  Usps = 'USPS',
  Fedex = 'FEDEX',
  Tnt = 'TNT',
  PostRussia = 'POST_RUSSIA',
  Dpd = 'DPD',
  Apc = 'APC',
  Boxberry = 'BOXBERRY',
  Other = 'OTHER'
}

export type Discount = {
  __typename?: 'Discount';
  id?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  creditLimit?: Maybe<Scalars['Float']>;
  orderHandlingDiscountPercent?: Maybe<Scalars['Float']>;
  warehouseStorageDiscountPercent?: Maybe<Scalars['Float']>;
  forwardingDiscountPercent?: Maybe<Scalars['Float']>;
  returnHandlingDiscountPercent?: Maybe<Scalars['Float']>;
  inboundDiscountPercent?: Maybe<Scalars['Float']>;
  undeclaredInboundDiscountPercent?: Maybe<Scalars['Float']>;
};

export type DiscountInput = {
  creditLimit?: Maybe<Scalars['Float']>;
  orderHandlingDiscountPercent?: Maybe<Scalars['Float']>;
  warehouseStorageDiscountPercent?: Maybe<Scalars['Float']>;
  forwardingDiscountPercent?: Maybe<Scalars['Float']>;
  returnHandlingDiscountPercent?: Maybe<Scalars['Float']>;
  inboundDiscountPercent?: Maybe<Scalars['Float']>;
  undeclaredInboundDiscountPercent?: Maybe<Scalars['Float']>;
};

export type DiscountList = {
  __typename?: 'DiscountList';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Discount>>>;
};

export enum Entity {
  B2Border = 'B2BORDER',
  Product = 'PRODUCT',
  Parcel = 'PARCEL',
  Warehouseshipment = 'WAREHOUSESHIPMENT',
  Warehouseshipmentorder = 'WAREHOUSESHIPMENTORDER',
  Warehousezonecell = 'WAREHOUSEZONECELL',
  Warehouseshipmentpiece = 'WAREHOUSESHIPMENTPIECE',
  NotDetermined = 'NOT_DETERMINED'
}

export type EditB2BOrderCargoPlacesInput = {
  wrapType?: Maybe<B2BWrapTypeEnum>;
  wrapWay?: Maybe<B2BWrapWayEnum>;
  cargoPlaces?: Maybe<Array<Maybe<B2BOrderCargoPlaceInput>>>;
};

export type EntityLegalData = {
  __typename?: 'EntityLegalData';
  id: Scalars['Int'];
  company: Scalars['String'];
  inn: Scalars['String'];
  kpp?: Maybe<Scalars['String']>;
  MSRN: Scalars['String'];
  generalManager: Scalars['String'];
  legalAddress: Scalars['String'];
  nameOfTheBank: Scalars['String'];
  BIK: Scalars['String'];
  paymentAccount: Scalars['String'];
  correspondentAccount: Scalars['String'];
  fileName: Scalars['String'];
  userId: Scalars['Int'];
  isDataVerified: Scalars['Boolean'];
};

export type EntityLegalDataInput = {
  company: Scalars['String'];
  inn: Scalars['String'];
  /** used regex: /^([0-9]{10}$)|([0-9]{12}$)/gm */
  kpp?: Maybe<Scalars['String']>;
  /**
   * Main state registration number
   * used regex: /^([0-9]{13}$)|([0-9]{15}$)/gm
   */
  MSRN: Scalars['String'];
  /** Full name */
  generalManager: Scalars['String'];
  /** The address must be entered in the following order: Country, Zip Code, State, City, Street, Building, Apt. */
  legalAddress: Scalars['String'];
  nameOfTheBank: Scalars['String'];
  /** Bank identification code */
  BIK: Scalars['String'];
  paymentAccount: Scalars['String'];
  /** Correspondent account */
  correspondentAccount: Scalars['String'];
  dataUpToDate?: Scalars['Boolean'];
};

export type EntityLegalUpdateDataInput = {
  company?: Maybe<Scalars['String']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  MSRN?: Maybe<Scalars['String']>;
  generalManager?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  nameOfTheBank?: Maybe<Scalars['String']>;
  BIK?: Maybe<Scalars['String']>;
  paymentAccount?: Maybe<Scalars['String']>;
  correspondentAccount?: Maybe<Scalars['String']>;
  dataUpToDate?: Scalars['Boolean'];
};

export type ExchangeRate = {
  __typename?: 'ExchangeRate';
  code: Scalars['String'];
  nominal: Scalars['String'];
  value: Scalars['String'];
};

export type ExchangeRateResponse = {
  __typename?: 'ExchangeRateResponse';
  exchangeRate?: Maybe<Array<Maybe<ExchangeRate>>>;
  lastUpdate?: Maybe<Scalars['String']>;
};

export type File = {
  __typename?: 'File';
  filename: Scalars['String'];
  mimetype: Scalars['String'];
  encoding: Scalars['String'];
};

export type GetWarehouseZoneCellResidues = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  warehouseZoneCellId?: Maybe<Scalars['Int']>;
};

export enum IdentifiersType {
  /** track number`s parcel */
  TrackNumber = 'TRACK_NUMBER',
  /** identifier from marketplace */
  MarketplaceNumber = 'MARKETPLACE_NUMBER',
  /** inner identifier from DB */
  InnerNumber = 'INNER_NUMBER'
}



export type LabelDataEasyPost = {
  shipmentId?: Maybe<Scalars['String']>;
  rateId?: Maybe<Scalars['String']>;
};

export type ListB2BShipmentPoints = {
  __typename?: 'ListB2BShipmentPoints';
  rows?: Maybe<Array<Maybe<B2BShipmentPoint>>>;
  count?: Maybe<Scalars['Int']>;
};

export type ListB2BShipmentServices = {
  __typename?: 'ListB2BShipmentServices';
  rows?: Maybe<Array<Maybe<B2BServices>>>;
  count?: Maybe<Scalars['Int']>;
};

export type ListOfSenderAccounts = {
  __typename?: 'ListOfSenderAccounts';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<SenderAccount>>>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  token?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum Mode_Types {
  Full = 'FULL',
  ForwardingOnly = 'FORWARDING_ONLY'
}

export type Manager = {
  __typename?: 'Manager';
  id?: Maybe<Scalars['Int']>;
  warehouse?: Maybe<Warehouse>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  role?: Maybe<ManagerRoles>;
};

export type ManagerInput = {
  warehouseId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<ManagerRoles>;
};

export type ManagerList = {
  __typename?: 'ManagerList';
  rows?: Maybe<Array<Maybe<Manager>>>;
  count?: Maybe<Scalars['Int']>;
};

export enum ManagerRoles {
  Manager = 'MANAGER',
  Admin = 'ADMIN'
}

export type Mutation = {
  __typename?: 'Mutation';
  userDiscountManagement?: Maybe<Discount>;
  sendNotification?: Maybe<Scalars['Boolean']>;
  readNotifications?: Maybe<Scalars['Boolean']>;
  createB2BOrder?: Maybe<Scalars['Boolean']>;
  setInProcessB2BOrder?: Maybe<Scalars['Boolean']>;
  pickB2BOrderProduct?: Maybe<ArrivalResponceObject>;
  setB2BOrderCargoPlaces?: Maybe<Scalars['Boolean']>;
  editB2BOrderCargoPlaces?: Maybe<Scalars['Boolean']>;
  setB2BOrderServicesAndShipment?: Maybe<Scalars['Boolean']>;
  setB2BOrderExternalId?: Maybe<Scalars['Boolean']>;
  editB2BOrderShipmentDriverInfo?: Maybe<Scalars['Boolean']>;
  uploadB2BOrderCargoPlaceBarcode?: Maybe<Scalars['Boolean']>;
  confirmAllB2BOrderBarodesUploaded?: Maybe<Scalars['Boolean']>;
  completeB2BOrder?: Maybe<Scalars['Boolean']>;
  cancelB2BOrder?: Maybe<Scalars['Boolean']>;
  deleteB2BService?: Maybe<Scalars['Boolean']>;
  createOrEditB2BService?: Maybe<Scalars['Boolean']>;
  createOrEditB2BShipmentPoint?: Maybe<Scalars['Boolean']>;
  deleteB2BShipmentPoint?: Maybe<Scalars['Boolean']>;
  cancelWarehouseShipment?: Maybe<WarehouseShipment>;
  changePassword?: Maybe<Scalars['Boolean']>;
  uploadMarketplaceInvoice?: Maybe<Scalars['String']>;
  pickingWithoutScanning?: Maybe<WarehouseShipmentInfoForManager>;
  pickingWithScanning?: Maybe<WarehouseShipmentInfoForManager>;
  createReportByShipments?: Maybe<ReportFilename>;
  createReportByProducts?: Maybe<ReportFilename>;
  createReportByTransactions?: Maybe<ReportFilename>;
  createReportByFulfillment?: Maybe<ReportFilename>;
  arrivalOfTheParcelForAdmin?: Maybe<WarehouseShipment>;
  setProductActualDimensions?: Maybe<Product>;
  createQRPayment?: Maybe<Scalars['String']>;
  addEventToWarehouseShipment?: Maybe<WarehouseShipment>;
  addTrackNumber?: Maybe<WarehouseShipment>;
  removeTrackNumber?: Maybe<WarehouseShipment>;
  removeWarehouseShipment?: Maybe<WarehouseShipment>;
  setArrivalWarehouseShipment?: Maybe<ArrivalResponceObject>;
  setArrivalWarehouseShipmentItem?: Maybe<ArrivalResponceObject>;
  changeProductAvailability?: Maybe<Scalars['Boolean']>;
  returnOfTheParcel?: Maybe<Scalars['String']>;
  createWarehouseShipmentUseProducts?: Maybe<WarehouseShipment>;
  createWarehouseShipmentUseParcels?: Maybe<WarehouseShipment>;
  createWarehouseShipmentOrder?: Maybe<WarehouseShipmentOrder>;
  createWarehouseShipmentOrderWithoutBuyLabel?: Maybe<WarehouseShipmentOrder>;
  sendWarehouseShipmentOrder?: Maybe<WarehouseShipmentOrder>;
  sendWarehouseShipmentOrderWihtoutBuyLabel?: Maybe<WarehouseShipmentOrder>;
  cancelWarehouseShipmentOrder?: Maybe<WarehouseShipmentOrder>;
  createWarehouse?: Maybe<Warehouse>;
  updateWarehouse?: Maybe<Warehouse>;
  removeWarehouse?: Maybe<Warehouse>;
  createManager?: Maybe<Manager>;
  signInManager?: Maybe<Token>;
  updateManager?: Maybe<Manager>;
  removeManager?: Maybe<Manager>;
  addEntityLegalData?: Maybe<EntityLegalData>;
  updateEntityLegalData?: Maybe<EntityLegalData>;
  verifyLegalData?: Maybe<EntityLegalData>;
  updateCreditLimitForUser?: Maybe<User>;
  addPassportData?: Maybe<UserPasport>;
  updatePassportData?: Maybe<UserPasport>;
  verifyPassportData?: Maybe<UserPasport>;
  addEtsyTrackNumber?: Maybe<Scalars['JSON']>;
  addPackageToOrder?: Maybe<Order>;
  removeStore?: Maybe<Store>;
  updateStore?: Maybe<Store>;
  addStore?: Maybe<Store>;
  switchStoreStatus?: Maybe<Store>;
  createShipmentRequest?: Maybe<ShipmentResponse>;
  createRequestToCallCourier?: Maybe<CourierResponse>;
  createSenderAccount?: Maybe<SenderAccount>;
  updateSenderAccount?: Maybe<SenderAccount>;
  removeSenderAccount?: Maybe<Scalars['Boolean']>;
  addTariff?: Maybe<Array<Maybe<Tariff>>>;
  updateTariff?: Maybe<Array<Maybe<Tariff>>>;
  removeTariff?: Maybe<Scalars['Int']>;
  addConfiguration?: Maybe<Config>;
  updateConfiguration?: Maybe<Scalars['JSON']>;
  createPackagingTemplate?: Maybe<PackagingTemplate>;
  updatePackagingTemplate?: Maybe<PackagingTemplate>;
  removePackagingTemplate?: Maybe<Scalars['Boolean']>;
  importProductListFromFile?: Maybe<ProductListUpload>;
  updateProduct?: Maybe<Product>;
  createProduct?: Maybe<Product>;
  removeProduct?: Maybe<Scalars['Boolean']>;
  removeWeight?: Maybe<Parcel>;
  debitingAndCreditingFunds?: Maybe<Transaction>;
  importOfActualWeight?: Maybe<Scalars['Boolean']>;
  updateEntrySelfExport?: Maybe<CdekParcel>;
  registrationOrder?: Maybe<CdekEntityResponse>;
  cancelOrder?: Maybe<CdekEntityResponse>;
  removeOrder?: Maybe<CdekEntityResponse>;
  cancelEntrySelfExport?: Maybe<Scalars['String']>;
  signIn?: Maybe<LoginResponse>;
  signInAdmin?: Maybe<LoginResponse>;
  signUp?: Maybe<LoginResponse>;
  addEmail: User;
  authFacebook?: Maybe<LoginResponse>;
  authGoogle?: Maybe<LoginResponse>;
  authVkontakte?: Maybe<LoginResponse>;
  createUser?: Maybe<User>;
  editCurrentUser?: Maybe<User>;
  saveNewPassword: Status;
  createContact?: Maybe<Contact>;
  updateContact?: Maybe<Contact>;
  removeContact: Status;
  createParcel?: Maybe<Parcel>;
  cancelParcel: Parcel;
  editActualWeightParcel?: Maybe<Parcel>;
  editTrackNumberParcel?: Maybe<Parcel>;
  createPayment?: Maybe<Scalars['String']>;
  removeTracking?: Maybe<Parcel>;
  saveOrSendConsolidation?: Maybe<Consolidation>;
  updateTrackNumberConsolidation?: Maybe<Consolidation>;
  cancelConsolidation?: Maybe<Scalars['Boolean']>;
  createWarehouseForwarding?: Maybe<WarehouseShipment>;
  createWarehouseZone?: Maybe<WarehouseZone>;
  updateWarehouseZone?: Maybe<WarehouseZone>;
  deleteWarehouseZone?: Maybe<Scalars['Boolean']>;
  createWarehouseZoneCell?: Maybe<WarehouseZoneCell>;
  updateWarehouseZoneCell?: Maybe<WarehouseZoneCell>;
  deleteWarehouseZoneCell?: Maybe<Scalars['Boolean']>;
  uploadWarehouseZoneCells?: Maybe<UploadWarehouseZoneCellsResult>;
  swapWarehouseCellProducts?: Maybe<Scalars['Boolean']>;
  registerReturnByShipmentOrder?: Maybe<ReturnShipment>;
  setReturnShipmentItemArrival?: Maybe<ArrivalResponceObject>;
  setProccessingReturnShipment?: Maybe<Scalars['Boolean']>;
  applyReturnShipmentAttachments?: Maybe<Scalars['Boolean']>;
  createUndeclaredShipment?: Maybe<UndeclaredShipment>;
  setProcessingStatusUndeclaredShipment?: Maybe<Scalars['Boolean']>;
  addUndeclaredShipmentItem?: Maybe<UndeclaredShipmentItem>;
  completeUndeclaredShipment?: Maybe<UndeclaredShipment>;
  cancelUndeclaredShipment?: Maybe<UndeclaredShipment>;
};


export type MutationUserDiscountManagementArgs = {
  userId: Scalars['Int'];
  discount?: Maybe<DiscountInput>;
};


export type MutationSendNotificationArgs = {
  topic?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};


export type MutationReadNotificationsArgs = {
  notificationId?: Maybe<Scalars['Int']>;
};


export type MutationCreateB2BOrderArgs = {
  input?: Maybe<CreateB2BOrderInput>;
};


export type MutationSetInProcessB2BOrderArgs = {
  orderId?: Maybe<Scalars['Int']>;
};


export type MutationPickB2BOrderProductArgs = {
  orderId: Scalars['Int'];
  productId: Scalars['Int'];
  quantity: Scalars['Int'];
  cellIdFrom: Scalars['Int'];
};


export type MutationSetB2BOrderCargoPlacesArgs = {
  orderId: Scalars['Int'];
  cargosCount: Scalars['Int'];
};


export type MutationEditB2BOrderCargoPlacesArgs = {
  orderId: Scalars['Int'];
  cargoData: EditB2BOrderCargoPlacesInput;
  cargoItems: Array<Maybe<B2BCargoPlaceProductInput>>;
};


export type MutationSetB2BOrderServicesAndShipmentArgs = {
  orderId: Scalars['Int'];
  data: SetB2BOrderServicesAndShipmentInput;
};


export type MutationSetB2BOrderExternalIdArgs = {
  orderId: Scalars['Int'];
  externalId: Scalars['String'];
};


export type MutationEditB2BOrderShipmentDriverInfoArgs = {
  orderOd: Scalars['Int'];
  driverInfo: B2BDriverInfoInput;
};


export type MutationUploadB2BOrderCargoPlaceBarcodeArgs = {
  orderId: Scalars['Int'];
  cargoPlaceId: Scalars['Int'];
  barcode: Scalars['Upload'];
};


export type MutationConfirmAllB2BOrderBarodesUploadedArgs = {
  orderId: Scalars['Int'];
};


export type MutationCompleteB2BOrderArgs = {
  orderId: Scalars['Int'];
};


export type MutationCancelB2BOrderArgs = {
  orderId: Scalars['Int'];
};


export type MutationDeleteB2BServiceArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type MutationCreateOrEditB2BServiceArgs = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  costInUSD?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
};


export type MutationCreateOrEditB2BShipmentPointArgs = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};


export type MutationDeleteB2BShipmentPointArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type MutationCancelWarehouseShipmentArgs = {
  id: Scalars['Int'];
};


export type MutationChangePasswordArgs = {
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationUploadMarketplaceInvoiceArgs = {
  file: Scalars['Upload'];
};


export type MutationPickingWithoutScanningArgs = {
  shipmentBarcodeId: Scalars['String'];
};


export type MutationPickingWithScanningArgs = {
  shipmentBarcodeId: Scalars['String'];
  productBarcodeId: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
  cellId?: Maybe<Scalars['ID']>;
};


export type MutationCreateReportByShipmentsArgs = {
  input?: Maybe<ReportRangeInput>;
};


export type MutationCreateReportByProductsArgs = {
  input?: Maybe<ReportProductInput>;
};


export type MutationCreateReportByTransactionsArgs = {
  input?: Maybe<ReportRangeInput>;
};


export type MutationCreateReportByFulfillmentArgs = {
  input?: Maybe<ReportFulfillment>;
};


export type MutationArrivalOfTheParcelForAdminArgs = {
  id: Scalars['Int'];
};


export type MutationSetProductActualDimensionsArgs = {
  id: Scalars['Int'];
  weight: Scalars['Float'];
  width: Scalars['Float'];
  length: Scalars['Float'];
  height: Scalars['Float'];
};


export type MutationCreateQrPaymentArgs = {
  value: Scalars['Float'];
};


export type MutationAddEventToWarehouseShipmentArgs = {
  warehouseShipmentId: Scalars['Int'];
  operation?: Maybe<WarehouseOperationForUser>;
};


export type MutationAddTrackNumberArgs = {
  shipmentId: Scalars['Int'];
  trackNumber: Scalars['String'];
  carrierCode: Scalars['String'];
};


export type MutationRemoveTrackNumberArgs = {
  shipmentId: Scalars['Int'];
};


export type MutationRemoveWarehouseShipmentArgs = {
  id: Scalars['Int'];
};


export type MutationSetArrivalWarehouseShipmentArgs = {
  identifier?: Maybe<Scalars['String']>;
  acceptWithDiscrepancy?: Maybe<Scalars['Boolean']>;
};


export type MutationSetArrivalWarehouseShipmentItemArgs = {
  identifier?: Maybe<Scalars['String']>;
  shipmentId: Scalars['ID'];
  quantity: Scalars['Int'];
  overExpected?: Maybe<Scalars['Boolean']>;
  way?: Maybe<Scan_Way>;
};


export type MutationChangeProductAvailabilityArgs = {
  input: ChangeProductAvailabilityInput;
};


export type MutationReturnOfTheParcelArgs = {
  identifier?: Maybe<Scalars['String']>;
};


export type MutationCreateWarehouseShipmentUseProductsArgs = {
  input?: Maybe<WarehouseShipmentUseProductInput>;
};


export type MutationCreateWarehouseShipmentUseParcelsArgs = {
  input?: Maybe<WarehouseShipmentUseParcelInput>;
};


export type MutationCreateWarehouseShipmentOrderArgs = {
  data: CreateWarehouseShipmentOrderInput;
};


export type MutationCreateWarehouseShipmentOrderWithoutBuyLabelArgs = {
  data: CreateWarehouseShipmentOrderWithoutBuyLabelInput;
  fileLabel: Scalars['Upload'];
};


export type MutationSendWarehouseShipmentOrderArgs = {
  data: SendWarehouseShipmentOrderInput;
};


export type MutationSendWarehouseShipmentOrderWihtoutBuyLabelArgs = {
  data: SendWarehouseShipmentOrderWithoutBuyLabelInput;
};


export type MutationCancelWarehouseShipmentOrderArgs = {
  id: Scalars['ID'];
};


export type MutationCreateWarehouseArgs = {
  input?: Maybe<WarehouseInput>;
};


export type MutationUpdateWarehouseArgs = {
  id: Scalars['Int'];
  input?: Maybe<WarehouseInput>;
};


export type MutationRemoveWarehouseArgs = {
  id: Scalars['Int'];
};


export type MutationCreateManagerArgs = {
  input?: Maybe<ManagerInput>;
};


export type MutationSignInManagerArgs = {
  password: Scalars['String'];
  email: Scalars['String'];
};


export type MutationUpdateManagerArgs = {
  input?: Maybe<ManagerInput>;
  id: Scalars['Int'];
};


export type MutationRemoveManagerArgs = {
  id: Scalars['Int'];
};


export type MutationAddEntityLegalDataArgs = {
  input?: Maybe<EntityLegalDataInput>;
  file?: Maybe<Scalars['Upload']>;
};


export type MutationUpdateEntityLegalDataArgs = {
  input?: Maybe<EntityLegalUpdateDataInput>;
  file?: Maybe<Scalars['Upload']>;
};


export type MutationVerifyLegalDataArgs = {
  entityLegalDataId: Scalars['Int'];
};


export type MutationUpdateCreditLimitForUserArgs = {
  userId: Scalars['Int'];
  value: Scalars['Int'];
};


export type MutationAddPassportDataArgs = {
  input?: Maybe<UserPassportDataInput>;
  file?: Maybe<Scalars['Upload']>;
};


export type MutationUpdatePassportDataArgs = {
  input?: Maybe<UserPassportUpdateDataInput>;
  file?: Maybe<Scalars['Upload']>;
};


export type MutationVerifyPassportDataArgs = {
  passportId: Scalars['Int'];
};


export type MutationAddEtsyTrackNumberArgs = {
  storeId: Scalars['Int'];
  carrier_name: Scalars['String'];
  receipt_id: Scalars['String'];
  send_bcc?: Maybe<Scalars['Boolean']>;
  tracking_code: Scalars['String'];
};


export type MutationAddPackageToOrderArgs = {
  packageId?: Maybe<Scalars['Int']>;
  orderId: Scalars['Int'];
  weight?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};


export type MutationRemoveStoreArgs = {
  marketplaceId: Scalars['Int'];
};


export type MutationUpdateStoreArgs = {
  input?: Maybe<StoreInput>;
};


export type MutationAddStoreArgs = {
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  storeId?: Maybe<Scalars['Int']>;
  marketplace?: Maybe<Scalars['String']>;
};


export type MutationSwitchStoreStatusArgs = {
  storeId: Scalars['ID'];
};


export type MutationCreateShipmentRequestArgs = {
  input?: Maybe<ShipmentInput>;
};


export type MutationCreateRequestToCallCourierArgs = {
  input?: Maybe<CourierRequestInput>;
};


export type MutationCreateSenderAccountArgs = {
  input?: Maybe<SenderAccountInput>;
};


export type MutationUpdateSenderAccountArgs = {
  input?: Maybe<SenderAccountInput>;
};


export type MutationRemoveSenderAccountArgs = {
  id: Scalars['Int'];
};


export type MutationAddTariffArgs = {
  input: TariffInput;
};


export type MutationUpdateTariffArgs = {
  input: TariffInput;
};


export type MutationRemoveTariffArgs = {
  weight: Scalars['Float'];
};


export type MutationAddConfigurationArgs = {
  key: Scalars['String'];
  value: Scalars['JSON'];
};


export type MutationUpdateConfigurationArgs = {
  input: Array<Maybe<ConfigInput>>;
};


export type MutationCreatePackagingTemplateArgs = {
  input?: Maybe<PackagingTemplateInput>;
};


export type MutationUpdatePackagingTemplateArgs = {
  input?: Maybe<PackagingTemplateInput>;
};


export type MutationRemovePackagingTemplateArgs = {
  id: Scalars['Int'];
};


export type MutationImportProductListFromFileArgs = {
  file?: Maybe<Scalars['Upload']>;
};


export type MutationUpdateProductArgs = {
  product?: Maybe<ProductInput>;
};


export type MutationCreateProductArgs = {
  product?: Maybe<ProductInput>;
};


export type MutationRemoveProductArgs = {
  productId: Scalars['Int'];
};


export type MutationRemoveWeightArgs = {
  parcelId: Scalars['Int'];
};


export type MutationDebitingAndCreditingFundsArgs = {
  userId: Scalars['Int'];
  amount: Scalars['Float'];
  reason: Scalars['String'];
  isDebiting?: Maybe<Scalars['Boolean']>;
  type: TransactionTypes;
};


export type MutationImportOfActualWeightArgs = {
  file: Scalars['Upload'];
};


export type MutationUpdateEntrySelfExportArgs = {
  parcelId: Scalars['Int'];
  parcelIds: Array<Maybe<Scalars['Int']>>;
};


export type MutationRegistrationOrderArgs = {
  registrationOrder: CdekRegistrationOrderInpur;
  registrationCourier?: Maybe<CdekCourierCreateRequest>;
  uuidToUpdateTheRecord?: Maybe<Scalars['String']>;
};


export type MutationCancelOrderArgs = {
  uuid: Scalars['String'];
};


export type MutationRemoveOrderArgs = {
  uuid: Scalars['String'];
};


export type MutationCancelEntrySelfExportArgs = {
  parcelId: Scalars['Int'];
};


export type MutationSignInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  rememberMe?: Maybe<Scalars['Boolean']>;
};


export type MutationSignInAdminArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  rememberMe?: Maybe<Scalars['Boolean']>;
};


export type MutationSignUpArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
};


export type MutationAddEmailArgs = {
  email: Scalars['String'];
};


export type MutationAuthFacebookArgs = {
  accessToken: Scalars['String'];
};


export type MutationAuthGoogleArgs = {
  accessToken: Scalars['String'];
};


export type MutationAuthVkontakteArgs = {
  accessToken: Scalars['String'];
};


export type MutationCreateUserArgs = {
  name: Scalars['String'];
  email: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  countryId: Scalars['Int'];
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  eoriCode?: Maybe<Scalars['String']>;
};


export type MutationEditCurrentUserArgs = {
  name: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  countryId: Scalars['Int'];
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  eoriCode?: Maybe<Scalars['String']>;
};


export type MutationSaveNewPasswordArgs = {
  token: Scalars['String'];
  password: Scalars['String'];
};


export type MutationCreateContactArgs = {
  input: ContactInput;
};


export type MutationUpdateContactArgs = {
  id: Scalars['ID'];
  input: ContactInput;
};


export type MutationRemoveContactArgs = {
  id: Scalars['ID'];
};


export type MutationCreateParcelArgs = {
  userId?: Maybe<Scalars['Int']>;
  input: ParcelInput;
  registrationCourier?: Maybe<CdekCourierRequestInput>;
  registrationOrder?: Maybe<CdekRegistrationOrderInput>;
};


export type MutationCancelParcelArgs = {
  id: Scalars['ID'];
};


export type MutationEditActualWeightParcelArgs = {
  id: Scalars['ID'];
  actualWeight: Scalars['Float'];
};


export type MutationEditTrackNumberParcelArgs = {
  id: Scalars['ID'];
  trackNumber: Scalars['String'];
  carrierCode: Scalars['String'];
};


export type MutationCreatePaymentArgs = {
  value?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  parcelId?: Maybe<Scalars['Int']>;
};


export type MutationRemoveTrackingArgs = {
  carrierCode: Scalars['String'];
  barCode: Scalars['String'];
};


export type MutationSaveOrSendConsolidationArgs = {
  parcelsIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  consolidationId?: Maybe<Scalars['ID']>;
  accountId?: Maybe<Scalars['ID']>;
  shipmentData: ShipmentDataSend;
  doSend?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateTrackNumberConsolidationArgs = {
  id?: Maybe<Scalars['ID']>;
  trackNumber?: Maybe<Scalars['String']>;
  carrierCode?: Maybe<Scalars['String']>;
};


export type MutationCancelConsolidationArgs = {
  id: Scalars['ID'];
};


export type MutationCreateWarehouseForwardingArgs = {
  input?: Maybe<WarehouseForwarding>;
};


export type MutationCreateWarehouseZoneArgs = {
  name: Scalars['String'];
};


export type MutationUpdateWarehouseZoneArgs = {
  id: Scalars['ID'];
  data: UpdateWarehouseZone;
};


export type MutationDeleteWarehouseZoneArgs = {
  id: Scalars['ID'];
};


export type MutationCreateWarehouseZoneCellArgs = {
  data: CreateWarehouseZoneCell;
};


export type MutationUpdateWarehouseZoneCellArgs = {
  id: Scalars['ID'];
  data: UpdateWarehouseZoneCell;
};


export type MutationDeleteWarehouseZoneCellArgs = {
  id: Scalars['ID'];
};


export type MutationUploadWarehouseZoneCellsArgs = {
  file: Scalars['Upload'];
};


export type MutationSwapWarehouseCellProductsArgs = {
  input: SwapWarehouseCellProductsInput;
};


export type MutationRegisterReturnByShipmentOrderArgs = {
  warehouseShipmentId: Scalars['Int'];
  type: ReturnShipmentType;
};


export type MutationSetReturnShipmentItemArrivalArgs = {
  productId: Scalars['Int'];
  returnId: Scalars['Int'];
  quantity: Scalars['Int'];
};


export type MutationSetProccessingReturnShipmentArgs = {
  id: Scalars['Int'];
};


export type MutationApplyReturnShipmentAttachmentsArgs = {
  id: Scalars['Int'];
  files: Array<Maybe<Scalars['Upload']>>;
};


export type MutationCreateUndeclaredShipmentArgs = {
  productId: Scalars['Int'];
};


export type MutationSetProcessingStatusUndeclaredShipmentArgs = {
  undeclaredShipmentId: Scalars['Int'];
};


export type MutationAddUndeclaredShipmentItemArgs = {
  input: AddUndeclaredShipmentItemInput;
};


export type MutationCompleteUndeclaredShipmentArgs = {
  input: CompleteUndeclaredShipmentInput;
};


export type MutationCancelUndeclaredShipmentArgs = {
  id: Scalars['Int'];
};

export type Notification = {
  __typename?: 'Notification';
  id?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  sendAt?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  data?: Maybe<Scalars['JSON']>;
};

export type NotificationRowsWithCount = {
  __typename?: 'NotificationRowsWithCount';
  rows?: Maybe<Array<Maybe<Notification>>>;
  count?: Maybe<Scalars['Int']>;
};

export type OnlyPackage = {
  weightKg: Scalars['Float'];
  lengthCm: Scalars['Float'];
  widthCm: Scalars['Float'];
  heightCm: Scalars['Float'];
};

export type Order = {
  __typename?: 'Order';
  id?: Maybe<Scalars['Int']>;
  marketplace?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  addressFirstLine?: Maybe<Scalars['String']>;
  addressSecondLine?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  deliveryType?: Maybe<Scalars['String']>;
  deliveryPrice?: Maybe<Scalars['Float']>;
  shippingMethod?: Maybe<Scalars['String']>;
  isShipped?: Maybe<Scalars['Boolean']>;
  orderProducts?: Maybe<Array<Maybe<OrderProduct>>>;
  package?: Maybe<PackagingTemplate>;
  itemId?: Maybe<Scalars['String']>;
  receiverName?: Maybe<Scalars['String']>;
  storeId?: Maybe<Scalars['String']>;
  /** Recipient phone number */
  phone?: Maybe<Scalars['String']>;
};

export type OrderList = {
  __typename?: 'OrderList';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Order>>>;
};

export type OrderPerDey = {
  __typename?: 'OrderPerDey';
  date?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type OrderProduct = {
  __typename?: 'OrderProduct';
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  priceAmount?: Maybe<Scalars['Float']>;
  priceCurrency?: Maybe<Scalars['String']>;
  shippingCostAmount?: Maybe<Scalars['Float']>;
  shippingCostCurrency?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
};

export type OrderStatusesStatictic = {
  __typename?: 'OrderStatusesStatictic';
  name?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type OriginServiceAreaInfo = {
  __typename?: 'OriginServiceAreaInfo';
  /** The FacilityCode element is the origin facility code of the shipment. */
  FacilityCode?: Maybe<Scalars['String']>;
  /** The ServiceAreaCode element is the origin service area code of the shipment. */
  ServiceAreaCode?: Maybe<Scalars['String']>;
};

export type Package = {
  __typename?: 'Package';
  id: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
  weightKg?: Maybe<Scalars['Float']>;
  lengthCm?: Maybe<Scalars['Float']>;
  widthCm?: Maybe<Scalars['Float']>;
  heightCm?: Maybe<Scalars['Float']>;
  units?: Maybe<Array<Maybe<ParcelUnit>>>;
};

export type PackageInput = {
  weightKg: Scalars['Float'];
  lengthCm: Scalars['Float'];
  widthCm: Scalars['Float'];
  heightCm: Scalars['Float'];
  units: Array<Maybe<ParcelUnitInput>>;
};

export type PackagingTemplate = {
  __typename?: 'PackagingTemplate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Int']>;
};

export type PackagingTemplateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  weight: Scalars['Float'];
  length: Scalars['Float'];
  width: Scalars['Float'];
  height: Scalars['Float'];
};

export type PackagingTemplateList = {
  __typename?: 'PackagingTemplateList';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<PackagingTemplate>>>;
};

export type Parcel = {
  __typename?: 'Parcel';
  id: Scalars['ID'];
  sender: ParcelContact;
  receiver: ParcelContact;
  packages: Array<Maybe<Package>>;
  deliveryStatus?: Maybe<StatusDelivery>;
  paymentStatus?: Maybe<StatusPayment>;
  fileTokenInvoiceXlsx?: Maybe<Scalars['String']>;
  senderNotes?: Maybe<Scalars['String']>;
  additionalInsurance: Scalars['Boolean'];
  insuranceAmount?: Maybe<Scalars['Float']>;
  signature?: Maybe<Scalars['Boolean']>;
  declaredWeight?: Maybe<Scalars['Float']>;
  actualWeight?: Maybe<Scalars['Float']>;
  declaredAmount?: Maybe<Scalars['Float']>;
  actualAmount?: Maybe<Scalars['Float']>;
  trackNumber?: Maybe<Scalars['String']>;
  carrierCode?: Maybe<Scalars['String']>;
  paymentStatusId?: Maybe<Scalars['Int']>;
  deliveryStatusId?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
  currency?: Maybe<Scalars['String']>;
  senderMark?: Maybe<Scalars['String']>;
  sentToWarehouseId?: Maybe<Scalars['Int']>;
  isDiscountUsed?: Maybe<Scalars['Boolean']>;
  typeDelivery?: Maybe<TypeDelivery>;
  exportReason?: Maybe<Scalars['String']>;
  paymentOfTaxes?: Maybe<Scalars['String']>;
  vatID?: Maybe<Scalars['String']>;
  /** Tax ID, VAT, IOSS, EMPTY */
  vatKey?: Maybe<Vatkey>;
  pathNameFile?: Maybe<Scalars['String']>;
  /** fuel surcharge at the time of order creation */
  fuelSurcharge?: Maybe<Scalars['Float']>;
  /** dollar rate at the moment of order creation */
  dollarRate?: Maybe<Scalars['Float']>;
  consolidationTrackNumber?: Maybe<ConsolidationTrackNumberObject>;
  barcode?: Maybe<Scalars['String']>;
  isConsolidationPiece?: Maybe<Scalars['Boolean']>;
  warehouseShipmentId?: Maybe<Scalars['Int']>;
  accepted?: Maybe<Scalars['Boolean']>;
  orderCostCurrency?: Maybe<Scalars['String']>;
  handlingFee?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['Int']>;
  warehouse?: Maybe<Warehouse>;
};

export type ParcelContact = {
  __typename?: 'ParcelContact';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country?: Maybe<DeliveryCountry>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  innKppCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  eoriCode?: Maybe<Scalars['String']>;
};

export type ParcelContactInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  countryId: Scalars['Int'];
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  innKppCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  eoriCode?: Maybe<Scalars['String']>;
};

export type ParcelInput = {
  sender: ParcelContactInput;
  receiver: ParcelContactInput;
  packages: Array<Maybe<PackageInput>>;
  typeDeliveryId?: Maybe<Scalars['Int']>;
  labelDataEasyPost?: Maybe<LabelDataEasyPost>;
  declaredWeight?: Maybe<Scalars['Float']>;
  declaredAmount?: Maybe<Scalars['Float']>;
  senderNotes?: Maybe<Scalars['String']>;
  additionalInsurance: Scalars['Boolean'];
  insuranceAmount?: Maybe<Scalars['Float']>;
  signature?: Maybe<Scalars['Boolean']>;
  carrierCode?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  currency: Currency;
  senderMark?: Maybe<Scalars['String']>;
  /** Format: YY-MM-DD */
  intake_date?: Maybe<Scalars['String']>;
  /** Format: HH:MM */
  intake_time_from?: Maybe<Scalars['String']>;
  /** Sender street */
  street?: Maybe<Scalars['String']>;
  /** Format: HH:MM */
  intake_time_to?: Maybe<Scalars['String']>;
  /** Description of cargo */
  name?: Maybe<Scalars['String']>;
  /** Sender house number  */
  houseNumber?: Maybe<Scalars['String']>;
  apartmentNumber?: Maybe<Scalars['String']>;
  /** Marketplace order ID */
  orderId?: Maybe<Scalars['Int']>;
  /** Available variants: COMMERCIAL(default), GIFT, SAMPLE, RETURN, REPAIR */
  exportReason?: Maybe<Scalars['String']>;
  /** Available varians: CONSIGNEE(default), SENDER */
  paymentOfTaxes?: Maybe<Scalars['String']>;
  /** Tax ID, VAT, IOSS, EMPTY */
  vatKey?: Maybe<Vatkey>;
  vatValue?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['Int']>;
  globalProductCode?: Maybe<Scalars['String']>;
  /** fuel surcharge at the time of order creation */
  fuelSurcharge?: Maybe<Scalars['Float']>;
  /** dollar rate at the moment of order creation */
  dollarRate?: Maybe<Scalars['Float']>;
  orderCostCurrency?: Maybe<Scalars['String']>;
  handlingFee?: Maybe<Scalars['Float']>;
};

export type ParcelPaginationResponse = {
  __typename?: 'ParcelPaginationResponse';
  count: Scalars['Int'];
  rows: Array<Maybe<Parcel>>;
};

export enum ParcelType {
  /** For use is like FROM MY WAREHOUSE */
  Shipment = 'SHIPMENT',
  Forwarding = 'FORWARDING',
  Custom = 'CUSTOM'
}

export type ParcelUnit = {
  __typename?: 'ParcelUnit';
  id: Scalars['ID'];
  nameRU?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['BigInt']>;
  quantity?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  country?: Maybe<DeliveryCountry>;
  netWeight?: Maybe<Scalars['Float']>;
  parcel?: Maybe<Parcel>;
  nameEN?: Maybe<Scalars['String']>;
  tradeMark?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type ParcelUnitInput = {
  nameRU: Scalars['String'];
  code?: Maybe<Scalars['BigInt']>;
  quantity: Scalars['Int'];
  price: Scalars['Float'];
  countryId: Scalars['Int'];
  netWeight: Scalars['Float'];
  nameEN?: Maybe<Scalars['String']>;
  tradeMark?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type PaymentAmount = {
  __typename?: 'PaymentAmount';
  value?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
};

export type PaymentConfirmation = {
  __typename?: 'PaymentConfirmation';
  type?: Maybe<Scalars['String']>;
  return_url?: Maybe<Scalars['String']>;
  confirmation_url?: Maybe<Scalars['String']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  saved?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};

export type Piece = {
  __typename?: 'Piece';
  weight?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
};

export type PieceInput = {
  weight: Scalars['Float'];
  width: Scalars['Float'];
  height: Scalars['Float'];
  length: Scalars['Float'];
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  detailsDescriptionRU?: Maybe<Scalars['String']>;
  detailsDescriptionEN?: Maybe<Scalars['String']>;
  state?: Maybe<ProdyctState>;
  trademark?: Maybe<Scalars['String']>;
  declaredValue?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  country?: Maybe<DeliveryCountry>;
  netWeight?: Maybe<Scalars['Float']>;
  code?: Maybe<Scalars['BigInt']>;
  userId?: Maybe<Scalars['Int']>;
  owner?: Maybe<User>;
  fnsku?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  actualDimensions?: Maybe<CargoPlace>;
  declaredDimensions?: Maybe<CargoPlace>;
  barcodeId?: Maybe<Scalars['String']>;
  isCustomBarcodeId?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  productAvailabilityQuantity?: Maybe<AvailableWarehouseProduct>;
  monthlyWarehouseStorageFee?: Maybe<Scalars['Float']>;
};

export type ProductIdAndQuantity = {
  id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type ProductInParcel = {
  __typename?: 'ProductInParcel';
  id?: Maybe<Scalars['Int']>;
  warehouseShipmentId?: Maybe<Scalars['Int']>;
  product?: Maybe<Product>;
  quantity?: Maybe<Scalars['Int']>;
  arrivalQuantity?: Maybe<Scalars['Int']>;
};

export type ProductInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  sku?: Maybe<Scalars['String']>;
  detailsDescriptionRU: Scalars['String'];
  detailsDescriptionEN: Scalars['String'];
  state?: Maybe<ProdyctState>;
  trademark?: Maybe<Scalars['String']>;
  declaredValue: Scalars['Float'];
  currency: Scalars['String'];
  countryId: Scalars['Int'];
  netWeight: Scalars['Float'];
  code?: Maybe<Scalars['BigInt']>;
  fnsku?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  customBarcodeId?: Maybe<Scalars['String']>;
  package?: Maybe<PieceInput>;
};

export type ProductList = {
  __typename?: 'ProductList';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Product>>>;
};

export type ProductListUpload = {
  __typename?: 'ProductListUpload';
  products?: Maybe<ProductList>;
  messages?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ProductSort = {
  way?: Maybe<SortType>;
  type?: Maybe<ProdyctSortType>;
};

export enum ProdyctSortType {
  Transit = 'TRANSIT',
  Availability = 'AVAILABILITY'
}

export enum ProdyctState {
  New = 'NEW',
  Used = 'USED',
  Unknown = 'UNKNOWN'
}

export type QtdSInAdCurInfo = {
  __typename?: 'QtdSInAdCurInfo';
  /** The CurrencyCode element indicates the currency code of the service charge value. */
  CurrencyCode?: Maybe<Scalars['String']>;
  /**
   * The CurrencyRoleTypeCode element is the roles in which the currency used in extra charge
   * calculation.
   */
  CurrencyRoleTypeCode?: Maybe<Scalars['String']>;
  /** The WeightChargeTaxDet element contains the weight charge tax breakdown pricing details. */
  WeightCharge?: Maybe<Scalars['Float']>;
  /** The Total Amount */
  TotalAmount?: Maybe<Scalars['Float']>;
  /** The TotalTaxAmount element is the total tax for weight and all applicable charges. */
  TotalTaxAmount?: Maybe<Scalars['Float']>;
  /** The WeightChargeTax element contains the tax type amount for the weight charge. */
  WeightChargeTax?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  getUserDiscount?: Maybe<Discount>;
  getWarehouseShipmentOrderAdditionalOptions?: Maybe<WarehouseShipmentOrderAdditionalOptions>;
  getMyLatesUnreadNotificationsList?: Maybe<NotificationRowsWithCount>;
  getMyNotificationList?: Maybe<NotificationRowsWithCount>;
  getNotificationList?: Maybe<NotificationRowsWithCount>;
  getDashboardStatistic?: Maybe<DashboardStatistic>;
  getB2BOrderById?: Maybe<B2BOrder>;
  getB2BOrderList?: Maybe<B2BOrderList>;
  getB2BOrderListForManager?: Maybe<B2BOrderListForManager>;
  getB2BOrderByIdForManager?: Maybe<B2BOrderForManager>;
  getB2BShipmentServices?: Maybe<ListB2BShipmentServices>;
  getB2BShipmentPoints?: Maybe<ListB2BShipmentPoints>;
  checkWarehouseShipmentByIdentifiers?: Maybe<CheckParcels>;
  getWarehouseShipments?: Maybe<WarehouseShipmentListForManager>;
  getWarehouseShipmentOrderInfo?: Maybe<WarehouseShipmentInfoForManager>;
  getScanInfo?: Maybe<ArrivalResponceObject>;
  currentManager?: Maybe<Manager>;
  getManagers?: Maybe<ManagerList>;
  getManager?: Maybe<Manager>;
  getParcelsByType?: Maybe<ShipmentOrderList>;
  getReports?: Maybe<ReportList>;
  getWarehouseShipmentInfo?: Maybe<WarehouseShipment>;
  getListOfReturnedShipments?: Maybe<WarehouseShipmentsList>;
  getWarehouseShipmentsList?: Maybe<WarehouseShipmentsList>;
  getShipmentBarcodeList?: Maybe<Scalars['String']>;
  getAnalyticData?: Maybe<AnalyticData>;
  converCurrency?: Maybe<Scalars['Float']>;
  getWarehousesList?: Maybe<WarehousesList>;
  getWarehouse?: Maybe<Warehouse>;
  getAvailableWarehouseProductById?: Maybe<AvailableWarehouseProduct>;
  getAvailableWarehouseProducts?: Maybe<Array<Maybe<AvailableWarehouseProductsItem>>>;
  getWarehouseShipmentOrderDeliveryOptionsList?: Maybe<DeliveryOptionsResponseObject>;
  getWarehouseShipmentOrderById?: Maybe<WarehouseShipmentOrder>;
  getWarehouseShipmentOrders?: Maybe<WarehouseShipmentOrdersList>;
  getUser?: Maybe<User>;
  getCountriesByISO?: Maybe<Array<Maybe<DeliveryCountry>>>;
  getListOfTypesDelivery?: Maybe<TypeDelivery>;
  getOrders?: Maybe<OrderList>;
  getOrderById?: Maybe<Order>;
  getCDEKTariffs?: Maybe<Scalars['JSON']>;
  getMyStores?: Maybe<Array<Maybe<Store>>>;
  generateUrlForAuthorizationInEtsy?: Maybe<Scalars['String']>;
  generateUrlForAuthorizationInEbay?: Maybe<Scalars['String']>;
  generateUrlForAuthorizationInAmazon?: Maybe<Scalars['String']>;
  getRegionsCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  getListOfDeliveryOptions?: Maybe<CalculatorResponse>;
  validationAddress?: Maybe<ValidationAddressResponse>;
  getQuote?: Maybe<QuoteResponse>;
  getMyProductList?: Maybe<ProductList>;
  getMyProductById?: Maybe<Product>;
  getProductByBarcodeId?: Maybe<Product>;
  getTheExchangeRate?: Maybe<ExchangeRateResponse>;
  currentUser?: Maybe<User>;
  users: UserPaginationResponse;
  contacts?: Maybe<Array<Maybe<Contact>>>;
  contact?: Maybe<Contact>;
  parcel: Parcel;
  parcels: ParcelPaginationResponse;
  repeatConfirmLetter: Status;
  recoveryPassword: Status;
  trackParcel?: Maybe<TrackParcelResponse>;
  getStatusesList?: Maybe<Statuses>;
  getBalance?: Maybe<BalanceResponse>;
  getPaymentHistoryListByUserId?: Maybe<TransactionList>;
  deliveryCountries?: Maybe<Array<Maybe<DeliveryCountry>>>;
  getLocationList?: Maybe<CdekLocationList>;
  getTariffsByParcel?: Maybe<Array<Maybe<CdekCalculationByTariffCodeResponse>>>;
  getOrderInformation?: Maybe<CdekInfoAboutOrderResponse>;
  getBarcodeByParcelUuid?: Maybe<Scalars['String']>;
  weigtToPayValidator?: Maybe<Scalars['Boolean']>;
  getRegionList?: Maybe<Array<Maybe<CdekRegionListResponse>>>;
  getOfficeList?: Maybe<Array<Maybe<CdekOfficeListResponse>>>;
  getCityList?: Maybe<Array<Maybe<CdekCitiesListResponse>>>;
  getOrderList?: Maybe<CdekOrderListInfo>;
  getInformationOrderByUuid?: Maybe<CdekInfoAboutOrderResponse>;
  getPackagingTemplatesList?: Maybe<PackagingTemplateList>;
  getPackagingTemplateById?: Maybe<PackagingTemplate>;
  getTariffsList?: Maybe<TariffList>;
  getConfigurationList?: Maybe<Scalars['JSON']>;
  getPublicConfigurations?: Maybe<Scalars['JSON']>;
  getSenderAccountList?: Maybe<ListOfSenderAccounts>;
  getSenderAccountById?: Maybe<SenderAccount>;
  getCourierList?: Maybe<CourierList>;
  getCourierById?: Maybe<CourierResponse>;
  getDHLShipmentList?: Maybe<ShipmentResponseList>;
  getDHLShipmentById?: Maybe<ShipmentDataResponse>;
  getCitiesByCountryIso?: Maybe<Array<Maybe<CityInfo>>>;
  getCityByName?: Maybe<Array<Maybe<CityInfo>>>;
  getConsolidationsDeliveryVariants?: Maybe<AdminConsolidationRateResponse>;
  getConsolidationsList?: Maybe<ConsolidationList>;
  getConsolidationById?: Maybe<Consolidation>;
  getTransactionTypesList?: Maybe<Array<Maybe<TransactionTypeItem>>>;
  getWarehouseZones?: Maybe<Array<Maybe<WarehouseZone>>>;
  getWarehouseZoneById?: Maybe<WarehouseZone>;
  getWarehouseZoneCellsResidues?: Maybe<WarehouseCellProductList>;
  getWarehouseZoneCells?: Maybe<WarehouseZoneCellsResult>;
  getWarehouseZoneCellById?: Maybe<WarehouseZoneCell>;
  getRelatedWarehouseZoneCellsByProductId?: Maybe<Array<Maybe<WarehouseZoneCellByProduct>>>;
  suggesWarehousCellByProductQuantity?: Maybe<WarehouseZoneCellByProduct>;
  getDefaultRecevingWarehouseZoneCell?: Maybe<WarehouseZoneCell>;
  getWarehouseZoneCellProduct?: Maybe<WarehouseCellProduct>;
  getWarehouseProductLog?: Maybe<WarehouseProductLogList>;
  getWarehouseProductLogByProductId?: Maybe<WarehouseProductLogList>;
  getReturnShipmentInfo?: Maybe<ReturnShipment>;
  getReturnShipmentsList?: Maybe<ReturnShipmentsList>;
  getUndeclaredShipmentInfo?: Maybe<UndeclaredShipment>;
  getUndeclaredShipmentsList?: Maybe<UndeclaredShipmentsList>;
};


export type QueryGetUserDiscountArgs = {
  userId?: Maybe<Scalars['Int']>;
};


export type QueryGetMyLatesUnreadNotificationsListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryGetMyNotificationListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryGetNotificationListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryGetDashboardStatisticArgs = {
  dateFilter: Scalars['Int'];
};


export type QueryGetB2BOrderByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetB2BOrderListArgs = {
  filter: B2BOrderListFilter;
};


export type QueryGetB2BOrderListForManagerArgs = {
  filter?: Maybe<B2BOrderListForManagerFilter>;
};


export type QueryGetB2BOrderByIdForManagerArgs = {
  id: Scalars['Int'];
};


export type QueryGetB2BShipmentServicesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryGetB2BShipmentPointsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryCheckWarehouseShipmentByIdentifiersArgs = {
  identifiers?: Maybe<Array<Maybe<Scalars['String']>>>;
  identifiersType?: Maybe<IdentifiersType>;
};


export type QueryGetWarehouseShipmentsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  type?: Maybe<Warehouse_Shipment_Order_Type>;
  status?: Maybe<ShipmentWarehouseStatus>;
  sort?: Maybe<SortType>;
};


export type QueryGetWarehouseShipmentOrderInfoArgs = {
  barcodeId: Scalars['String'];
};


export type QueryGetScanInfoArgs = {
  barcode?: Maybe<Scalars['String']>;
  trackNumber?: Maybe<Scalars['String']>;
  way?: Maybe<Scan_Way>;
};


export type QueryGetManagersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryGetManagerArgs = {
  id: Scalars['Int'];
};


export type QueryGetParcelsByTypeArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Int']>;
  type?: Maybe<ParcelType>;
  deliveryStatusId?: Maybe<Scalars['Int']>;
  paymentStatusId?: Maybe<Scalars['Int']>;
};


export type QueryGetReportsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryGetWarehouseShipmentInfoArgs = {
  id: Scalars['Int'];
};


export type QueryGetListOfReturnedShipmentsArgs = {
  filter?: Maybe<WarehouseShipmentFilter>;
};


export type QueryGetWarehouseShipmentsListArgs = {
  filter?: Maybe<WarehouseShipmentFilter>;
  search?: Maybe<Scalars['String']>;
};


export type QueryGetShipmentBarcodeListArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryConverCurrencyArgs = {
  from: Currency;
  nominal: Scalars['Float'];
  to: Currency;
};


export type QueryGetWarehousesListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  operation?: Maybe<Warehouse_Operations>;
};


export type QueryGetWarehouseArgs = {
  id: Scalars['Int'];
};


export type QueryGetAvailableWarehouseProductByIdArgs = {
  warehouseId: Scalars['ID'];
  productId: Scalars['ID'];
};


export type QueryGetAvailableWarehouseProductsArgs = {
  warehouseId?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryGetWarehouseShipmentOrderDeliveryOptionsListArgs = {
  data?: Maybe<WarehouseShipmentOrderDeliveryOptionsInput>;
};


export type QueryGetWarehouseShipmentOrderByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryGetWarehouseShipmentOrdersArgs = {
  data?: Maybe<WarehouseShipmentOrdersOptions>;
};


export type QueryGetUserArgs = {
  id: Scalars['Int'];
};


export type QueryGetCountriesByIsoArgs = {
  ISOList: Array<Maybe<Scalars['String']>>;
};


export type QueryGetOrdersArgs = {
  marketplace?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['Int']>;
  paymentStatus?: Maybe<Scalars['Boolean']>;
  shippedStatus?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryGetOrderByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetCdekTariffsArgs = {
  fromLocation: CdekInputFilter;
  toLocation: CdekInputFilter;
  listOfPackage: Array<OnlyPackage>;
};


export type QueryGenerateUrlForAuthorizationInEtsyArgs = {
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  storeId: Scalars['Int'];
};


export type QueryGenerateUrlForAuthorizationInEbayArgs = {
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  storeId?: Maybe<Scalars['Int']>;
};


export type QueryGenerateUrlForAuthorizationInAmazonArgs = {
  name: Scalars['String'];
  merchantId: Scalars['String'];
  region: Scalars['String'];
};


export type QueryGetListOfDeliveryOptionsArgs = {
  input?: Maybe<CalculationInput>;
};


export type QueryValidationAddressArgs = {
  input?: Maybe<ValidationAddress>;
};


export type QueryGetQuoteArgs = {
  input?: Maybe<QuoteInput>;
};


export type QueryGetMyProductListArgs = {
  warehouseId?: Maybe<Scalars['ID']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<ProductSort>;
};


export type QueryGetMyProductByIdArgs = {
  productId: Scalars['Int'];
};


export type QueryGetProductByBarcodeIdArgs = {
  barcodeId: Scalars['String'];
  userId?: Maybe<Scalars['Int']>;
};


export type QueryUsersArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  order?: Maybe<UserOrder>;
};


export type QueryContactsArgs = {
  filter?: Maybe<ContactFilter>;
};


export type QueryContactArgs = {
  id: Scalars['ID'];
};


export type QueryParcelArgs = {
  id: Scalars['ID'];
};


export type QueryParcelsArgs = {
  convert_to_usd?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Int']>;
  paymentStatusId?: Maybe<Scalars['Int']>;
  deliveryStatusId?: Maybe<Scalars['Int']>;
  isUserAccount?: Maybe<Scalars['Boolean']>;
};


export type QueryRecoveryPasswordArgs = {
  email: Scalars['String'];
};


export type QueryTrackParcelArgs = {
  carrier_code?: Maybe<Scalars['String']>;
  barcode?: Maybe<Scalars['String']>;
};


export type QueryGetBalanceArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  period?: Maybe<Scalars['Int']>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
};


export type QueryGetPaymentHistoryListByUserIdArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};


export type QueryGetLocationListArgs = {
  filters?: Maybe<CdekLocationFilters>;
};


export type QueryGetTariffsByParcelArgs = {
  calculationInput?: Maybe<CdekCalculationByTariffCodeInput>;
};


export type QueryGetOrderInformationArgs = {
  id: Scalars['Int'];
};


export type QueryGetBarcodeByParcelUuidArgs = {
  uuid: Scalars['String'];
};


export type QueryWeigtToPayValidatorArgs = {
  parcelIds?: Maybe<Array<Scalars['Int']>>;
  packages?: Maybe<Array<CargoPlaceInput>>;
};


export type QueryGetRegionListArgs = {
  filter?: Maybe<CdekRegionListFilterInput>;
};


export type QueryGetOfficeListArgs = {
  filter?: Maybe<CdekOfiiceListFilterInput>;
};


export type QueryGetCityListArgs = {
  filter?: Maybe<CdekCitiesListFilterInput>;
};


export type QueryGetOrderListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};


export type QueryGetInformationOrderByUuidArgs = {
  uuid: Scalars['String'];
};


export type QueryGetPackagingTemplatesListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};


export type QueryGetPackagingTemplateByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetTariffsListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryGetSenderAccountListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryGetSenderAccountByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetCourierListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryGetCourierByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetDhlShipmentListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryGetDhlShipmentByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetCitiesByCountryIsoArgs = {
  iso: Scalars['String'];
};


export type QueryGetCityByNameArgs = {
  name: Scalars['String'];
};


export type QueryGetConsolidationsDeliveryVariantsArgs = {
  data: ShipmentDataRate;
};


export type QueryGetConsolidationsListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryGetConsolidationByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetTransactionTypesListArgs = {
  operation?: Maybe<Availability_Operation>;
};


export type QueryGetWarehouseZoneByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetWarehouseZoneCellsResiduesArgs = {
  options: GetWarehouseZoneCellResidues;
};


export type QueryGetWarehouseZoneCellsArgs = {
  search?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryGetWarehouseZoneCellByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetRelatedWarehouseZoneCellsByProductIdArgs = {
  productId: Scalars['ID'];
  fromCellId?: Maybe<Scalars['ID']>;
};


export type QuerySuggesWarehousCellByProductQuantityArgs = {
  options: SuggesWarehousCellByProductQuantity;
};


export type QueryGetWarehouseZoneCellProductArgs = {
  options: GetWarehouseZoneCellProduct;
};


export type QueryGetWarehouseProductLogArgs = {
  barcodeId?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};


export type QueryGetWarehouseProductLogByProductIdArgs = {
  warehouseId: Scalars['ID'];
  productId: Scalars['ID'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};


export type QueryGetReturnShipmentInfoArgs = {
  id: Scalars['Int'];
};


export type QueryGetReturnShipmentsListArgs = {
  filter?: Maybe<ReturnShipmentFilter>;
  search?: Maybe<Scalars['String']>;
};


export type QueryGetUndeclaredShipmentInfoArgs = {
  id: Scalars['Int'];
};


export type QueryGetUndeclaredShipmentsListArgs = {
  filter?: Maybe<UndeclaredShipmentFilter>;
  search?: Maybe<Scalars['String']>;
};

export type Quote = {
  __typename?: 'Quote';
  /**
   * The OriginServiceArea element is a complex type of element which contains the child
   * elements for origin service area and facility code details.
   */
  OriginServiceArea?: Maybe<OriginServiceAreaInfo>;
  /**
   * The DestinationServiceArea element is a complex type of element which contains the
   * child elements for destination service area and facility code details.
   */
  DestinationServiceArea?: Maybe<OriginServiceAreaInfo>;
  /**
   * The GlobalProductCode element is the global product code of the shipment. Please refer to the
   * Reference Data and use appropriate Global codes enabled for the country/region.
   */
  GlobalProductCode?: Maybe<Scalars['String']>;
  /** The LocalProductCode element is the local product code of the shipment. */
  LocalProductCode?: Maybe<Scalars['String']>;
  /** The ProductShortName element is the global product name of the shipment. */
  ProductShortName?: Maybe<Scalars['String']>;
  /** The LocalProductName element is the local product name of the shipment. */
  LocalProductName?: Maybe<Scalars['String']>;
  /** The NetworkTypeCode element is the network type of the product in the shipment. */
  NetworkTypeCode?: Maybe<Scalars['String']>;
  /**
   * The POfferedCustAgreement element indicates that the product or service or solution only can be
   * offered to a customer agreement that are not default to a billing entity.
   */
  POfferedCustAgreement?: Maybe<Scalars['String']>;
  /**
   * The TransInd element indicates the web user interface indicator. If value of Y, it is show on the
   * Web user interface.
   */
  TransInd?: Maybe<Scalars['String']>;
  /** The PickupDate element is the pick up date. */
  PickupDate?: Maybe<Scalars['String']>;
  /** The PickupCutoffTime element is the pick up cut off time. */
  PickupCutoffTime?: Maybe<Scalars['String']>;
  /** The BookingTime element is the booking cut off time. */
  BookingTime?: Maybe<Scalars['String']>;
  /** The ExchangeRate element is the exchange rate between EUR and charge currency code. */
  ExchangeRate?: Maybe<Scalars['Float']>;
  /** The WeightCharge element is the weight price including tax. */
  WeightCharge?: Maybe<Scalars['Float']>;
  /** The WeightChargeTax element is the tax for weight quote. */
  WeightChargeTax?: Maybe<Scalars['Float']>;
  /** The TotalTransitDays element is the number of transit days. */
  TotalTransitDays?: Maybe<Scalars['Int']>;
  /**
   * The PickupPostalLocAddDays element is the in-forwarding days. This is additional transit delays
   * (in days) for shipment picked up from the mentioned city or postal area to arrival at the service area.
   */
  PickupPostalLocAddDays?: Maybe<Scalars['Int']>;
  /**
   * The DeliveryPostalLocAddDays element is the on-forwarding days. This is additional transit delays
   * (in days) for shipment delivered to the mentioned city or postal area following arrival at the service
   * area.
   */
  DeliveryPostalLocAddDays?: Maybe<Scalars['Int']>;
  /** The DeliveryDate element is the date the shipment is delivered. */
  DeliveryDate?: Maybe<DeliveryDateInfo>;
  /**
   * The DeliveryTime element is the commited delivery by time (actual delivery can occur before this
   * time).
   */
  DeliveryTime?: Maybe<Scalars['String']>;
  /** The DimensionalWeight element is the volumetric weight of the shipment. */
  DimensionalWeight?: Maybe<Scalars['Float']>;
  /** The WeightUnit element is the volumetric weight unit of measurement of the shipment. */
  WeightUnit?: Maybe<Scalars['String']>;
  /** The PickupDayOfWeekNum element is the pickup day in week number. */
  PickupDayOfWeekNum?: Maybe<Scalars['Int']>;
  /** The DestinationDayOfWeekNum element is the destination day in week number. */
  DestinationDayOfWeekNum?: Maybe<Scalars['Int']>;
  /** The QuotedWeight element is the quoted weight of the shipment. */
  QuotedWeight?: Maybe<Scalars['Float']>;
  /** The QuotedWeightUOM element is the quoted weight unit of measurement of the shipment. */
  QuotedWeightUOM?: Maybe<Scalars['String']>;
  /** The PricingDate element is the date of the price. */
  PricingDate?: Maybe<Scalars['String']>;
  /** The ShippingCharge element is the total quote with tax for weight and all applicable charges. */
  ShippingCharge?: Maybe<Scalars['Float']>;
  /** The TotalTaxAmount element is the total tax for weight and all applicable charges. */
  TotalTaxAmount?: Maybe<Scalars['Float']>;
  /** The QtdSInAdCur element contains the optional services information. */
  QtdSInAdCur?: Maybe<Array<Maybe<QtdSInAdCurInfo>>>;
  /** The PickupWindowEarliestTime element contains the pickup window earliest time. */
  PickupWindowEarliestTime?: Maybe<Scalars['String']>;
  /** The PickupWindowLatestTime element contains the pickup window latest time. */
  PickupWindowLatestTime?: Maybe<Scalars['String']>;
  /** The BookingCutoffOffset element contains the booking cutoff offset. */
  BookingCutoffOffset?: Maybe<Scalars['String']>;
};

export type QuoteDetailsInput = {
  /**
   * The Date element contains the pickup date of the shipment. If the pu date is holiday, or weekend
   * and in TTS is not set pu capability, DCT can't offer pickup on that date. PU date is shifted just in
   * case the ready by time is after cut off time.
   */
  date?: Maybe<Scalars['String']>;
  /**
   * The Date element contains the pickup date of the shipment. If the pu date is holiday, or weekend
   * and in TTS is not set pu capability, DCT can't offer pickup on that date. PU date is shifted just in
   * case the ready by time is after cut off time.
   */
  readyTime?: Maybe<Scalars['String']>;
};

export type QuoteForCalculator = {
  __typename?: 'QuoteForCalculator';
  TypeDeliveryName?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  TypeDeliveryId?: Maybe<Scalars['Int']>;
  day_to_delivery?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
  error?: Maybe<Scalars['String']>;
  priceCurrency?: Maybe<Scalars['String']>;
  rateId?: Maybe<Scalars['String']>;
  shipmentId?: Maybe<Scalars['String']>;
};

export type QuoteFromToInput = {
  /** The CountryCode element contains the country/region code of the origin address. */
  countryCode?: Maybe<Scalars['String']>;
  /**
   * The Postalcode element contains the postal code of the origin address. It is required for countries
   * with postcodes and in correct format. Please refer to the Reference Data (DHL Country/Region) –
   * Postcode format.
   */
  postalCode?: Maybe<Scalars['String']>;
};

export type QuoteInput = {
  date: Scalars['String'];
  readyTime: Scalars['String'];
  contactId?: Maybe<Scalars['Int']>;
  parcelId: Scalars['Int'];
  senderAccountId: Scalars['Int'];
};

export type QuotePiecesInput = {
  id?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

export type QuoteResponse = {
  __typename?: 'QuoteResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  rows?: Maybe<Array<Maybe<Quote>>>;
};

export type QuoteResponseForCalculator = {
  __typename?: 'QuoteResponseForCalculator';
  rows?: Maybe<Array<Maybe<QuoteForCalculator>>>;
};

export type RateUserAccount = {
  __typename?: 'RateUserAccount';
  name?: Maybe<Scalars['String']>;
  summary_amount?: Maybe<Scalars['Float']>;
  typeDelivery?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
};

export type Report = {
  __typename?: 'Report';
  id: Scalars['ID'];
  userId: Scalars['Int'];
  type: ReportType;
  from: Scalars['String'];
  to: Scalars['String'];
  fileToken: Scalars['String'];
  productBarcode?: Maybe<Scalars['String']>;
  warehouseCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
};

export type ReportFilename = {
  __typename?: 'ReportFilename';
  filename: Scalars['String'];
};

export type ReportFulfillment = {
  from: Scalars['String'];
  to: Scalars['String'];
  warehouseId: Scalars['Int'];
};

export type ReportList = {
  __typename?: 'ReportList';
  rows: Array<Maybe<Report>>;
  count: Scalars['Int'];
};

export type ReportProductInput = {
  from: Scalars['String'];
  to: Scalars['String'];
  productId: Scalars['String'];
  warehouseId: Scalars['Int'];
};

export type ReportRangeInput = {
  from: Scalars['String'];
  to: Scalars['String'];
};

export enum ReportType {
  Shipments = 'SHIPMENTS',
  Transactions = 'TRANSACTIONS',
  Product = 'PRODUCT',
  Fulfillment = 'FULFILLMENT'
}

export type ReturnShipment = {
  __typename?: 'ReturnShipment';
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<ReturnShipmentType>;
  createdAt?: Maybe<Scalars['String']>;
  arrivalDate?: Maybe<Scalars['String']>;
  warehouse?: Maybe<Scalars['String']>;
  trackNumber?: Maybe<Scalars['String']>;
  carrierCode?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  barcodeId?: Maybe<Scalars['String']>;
  warehouseStatus?: Maybe<WarehouseShipmentStatus>;
  user?: Maybe<User>;
  shipmentOrderBarcodeId?: Maybe<Scalars['String']>;
  shipmentOrderId?: Maybe<Scalars['Int']>;
  attachments?: Maybe<Array<Maybe<ReturnShipmentAttachment>>>;
  products?: Maybe<Array<Maybe<ReturnShipmentItem>>>;
};

export type ReturnShipmentAttachment = {
  __typename?: 'ReturnShipmentAttachment';
  id?: Maybe<Scalars['Int']>;
  filePath?: Maybe<Scalars['String']>;
};

export type ReturnShipmentFilter = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  dateFilter?: Maybe<Scalars['Int']>;
  deliveryStatusId?: Maybe<Scalars['Int']>;
  warehouseStatus?: Maybe<WarehouseShipmentStatus>;
  type?: Maybe<ReturnShipmentType>;
};

export type ReturnShipmentItem = {
  __typename?: 'ReturnShipmentItem';
  productId?: Maybe<Scalars['Int']>;
  productBarcode?: Maybe<Scalars['String']>;
  productSku?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  acceptedQuantity?: Maybe<Scalars['Int']>;
  expectedQuantity?: Maybe<Scalars['Int']>;
};

export type ReturnShipmentListItem = {
  __typename?: 'ReturnShipmentListItem';
  id?: Maybe<Scalars['Int']>;
  barcodeId?: Maybe<Scalars['String']>;
  shipmentOrderBarcodeId?: Maybe<Scalars['String']>;
  shipmentOrderId?: Maybe<Scalars['Int']>;
  warehouse?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  trackNumber?: Maybe<Scalars['String']>;
  carrierCode?: Maybe<Scalars['String']>;
  warehouseStatus?: Maybe<WarehouseShipmentStatus>;
  acceptedQuantityItem?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
};

export enum ReturnShipmentType {
  Rts = 'RTS',
  Rma = 'RMA'
}

export type ReturnShipmentsList = {
  __typename?: 'ReturnShipmentsList';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<ReturnShipmentListItem>>>;
};

export enum Scan_Way {
  Product = 'PRODUCT',
  WarehouseshipmentForwarding = 'WAREHOUSESHIPMENT_FORWARDING',
  WarehouseshipmentInbound = 'WAREHOUSESHIPMENT_INBOUND',
  Warehouseshipmentorder = 'WAREHOUSESHIPMENTORDER',
  Warehousezonecell = 'WAREHOUSEZONECELL',
  Warehouseshipmentpiece = 'WAREHOUSESHIPMENTPIECE',
  B2Border = 'B2BORDER'
}

export type SendWarehouseShipmentOrderInput = {
  products?: Maybe<Array<Maybe<ShipmentProductInput>>>;
  warehouseShipmentOrderId: Scalars['ID'];
  typeDeliveryId: Scalars['Int'];
  additionalInsurance?: Maybe<Scalars['Boolean']>;
  carrierService?: Maybe<Scalars['String']>;
  giftWrap?: Maybe<Scalars['Boolean']>;
  insuranceAmount?: Maybe<Scalars['Float']>;
  signature?: Maybe<Scalars['Boolean']>;
  wrap?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  rateId: Scalars['ID'];
  shipmentId: Scalars['ID'];
  dimensions: CargoPlaceInput;
  vatKey?: Maybe<Vatkey>;
  vatValue?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['Int']>;
  handlingFee?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
};

export type SendWarehouseShipmentOrderWithoutBuyLabelInput = {
  products?: Maybe<Array<Maybe<ShipmentProductInput>>>;
  warehouseShipmentOrderId: Scalars['ID'];
  additionalInsurance?: Maybe<Scalars['Boolean']>;
  carrierService?: Maybe<Scalars['String']>;
  carrierCode?: Maybe<Scalars['String']>;
  giftWrap?: Maybe<Scalars['Boolean']>;
  insuranceAmount?: Maybe<Scalars['Float']>;
  signature?: Maybe<Scalars['Boolean']>;
  wrap?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  dimensions: CargoPlaceInput;
  vatKey?: Maybe<Vatkey>;
  vatValue?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['Int']>;
  handlingFee?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
};

export type SenderAccount = {
  __typename?: 'SenderAccount';
  id?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  accountNumber?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  TaxIdentificationNumber?: Maybe<Scalars['String']>;
  attentionName?: Maybe<Scalars['String']>;
  clientIdFEDEX?: Maybe<Scalars['String']>;
  clientSecretFEDEX?: Maybe<Scalars['String']>;
  userNameUPS?: Maybe<Scalars['String']>;
  userPasswordUPS?: Maybe<Scalars['String']>;
  accessLicenseNumberUPS?: Maybe<Scalars['String']>;
  requestorName?: Maybe<Scalars['String']>;
  requestorPhone?: Maybe<Scalars['String']>;
  requestorAddress?: Maybe<Scalars['String']>;
  requestorCity?: Maybe<Scalars['String']>;
  requestorCountryCode?: Maybe<Scalars['String']>;
  requestorPostalCode?: Maybe<Scalars['String']>;
  serviceName?: Maybe<Scalars['String']>;
  isTestMode?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
  easypostTestApiKey?: Maybe<Scalars['String']>;
  easypostProdApiKey?: Maybe<Scalars['String']>;
};

/**
 * For admin required all field
 *
 * For user required only:
 *
 * -accountName
 *
 * -accountNumber
 *
 * -isTestMode(optional and default false)
 */
export type SenderAccountInput = {
  id?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  accountNumber?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  requestorName?: Maybe<Scalars['String']>;
  requestorPhone?: Maybe<Scalars['String']>;
  requestorAddress?: Maybe<Scalars['String']>;
  requestorCity?: Maybe<Scalars['String']>;
  requestorCountryCode?: Maybe<Scalars['String']>;
  requestorPostalCode?: Maybe<Scalars['String']>;
  TaxIdentificationNumber?: Maybe<Scalars['String']>;
  attentionName?: Maybe<Scalars['String']>;
  clientIdFEDEX?: Maybe<Scalars['String']>;
  clientSecretFEDEX?: Maybe<Scalars['String']>;
  userNameUPS?: Maybe<Scalars['String']>;
  userPasswordUPS?: Maybe<Scalars['String']>;
  accessLicenseNumberUPS?: Maybe<Scalars['String']>;
  /** Available variants: DHL, UPS, FEDEX */
  serviceName?: Maybe<ServiceNameAvailableVariants>;
  isTestMode?: Maybe<Scalars['Boolean']>;
  easypostTestApiKey?: Maybe<Scalars['String']>;
  easypostProdApiKey?: Maybe<Scalars['String']>;
};

export enum ServiceNameAvailableVariants {
  Dhl = 'DHL',
  Ups = 'UPS',
  Fedex = 'FEDEX',
  Easypost = 'EASYPOST'
}

export type ShipmentDataResponse = {
  __typename?: 'ShipmentDataResponse';
  id?: Maybe<Scalars['Int']>;
  /**
   * The AirwayBillNumber element contains the DHL defines 10-digit waybill number. It is a mandatory
   * field in the shipment validation response. If shipment validation request is successful, this is the
   * actual waybill number assigned to the shipment.
   */
  airwayBillNumber?: Maybe<Scalars['String']>;
  /** Сurrent shipment status */
  status?: Maybe<Scalars['String']>;
  /** The account ID from which the sending was made */
  senderAccountId?: Maybe<Scalars['Int']>;
  /** Id pick&pack parcels */
  parcelId?: Maybe<Scalars['Int']>;
  /** Total price */
  price?: Maybe<Scalars['Float']>;
  senderCode: Scalars['String'];
  /** waybill filename for rout: /api/get-receipt/:filename */
  waybill?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type ShipmentDateInput = {
  scheduledDate?: Maybe<Scalars['String']>;
  scheduledTime?: Maybe<Scalars['String']>;
};

export type ShipmentInput = {
  /** The DHLInvoiceType element identifies the type of invoice */
  DHLInvoiceType?: Maybe<Scalars['String']>;
  /** Reason code for export */
  exportReason?: Maybe<Scalars['String']>;
  /** The account ID from which the sending was made */
  senderAccountId: Scalars['Int'];
  /** The TermsOfTrade element contains the codes for terms of trade of the shipment. */
  termsOfTrade?: Maybe<Scalars['String']>;
  /** Sd pick&pack parcels */
  parcelId: Scalars['Int'];
  /** Sender contact id */
  contactId?: Maybe<Scalars['Int']>;
  /** Departure information */
  shipmentDate?: Maybe<Scalars['String']>;
  globalProductCode: Scalars['String'];
  senderCode: Scalars['String'];
  customDescription?: Maybe<Array<Maybe<UnitDescription>>>;
};

export type ShipmentOrderList = {
  __typename?: 'ShipmentOrderList';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Parcel>>>;
};

export type ShipmentProductInput = {
  id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  code?: Maybe<Scalars['BigInt']>;
  countryId?: Maybe<Scalars['Int']>;
  nameEN?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  sku?: Maybe<Scalars['String']>;
};

export type ShipmentResponse = {
  __typename?: 'ShipmentResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  data?: Maybe<ShipmentDataResponse>;
};

export type ShipmentResponseList = {
  __typename?: 'ShipmentResponseList';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<ShipmentDataResponse>>>;
};

export type ShipmentWarehouseProduct = {
  __typename?: 'ShipmentWarehouseProduct';
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  relatedProduct?: Maybe<Product>;
};

export enum ShipmentWarehouseStatus {
  Picked = 'PICKED',
  Cancelled = 'CANCELLED',
  Shipped = 'SHIPPED',
  Unshipped = 'UNSHIPPED'
}

export enum SortType {
  Desc = 'DESC',
  Asc = 'ASC'
}

export type Status = {
  __typename?: 'Status';
  status?: Maybe<Scalars['Boolean']>;
};

export type StatusDelivery = {
  __typename?: 'StatusDelivery';
  id: Scalars['Int'];
  name: Scalars['String'];
  explain: Scalars['String'];
};

export type StatusPayment = {
  __typename?: 'StatusPayment';
  id: Scalars['Int'];
  name: Scalars['String'];
  explain: Scalars['String'];
};

export type Statuses = {
  __typename?: 'Statuses';
  DeliveryStatusList?: Maybe<Array<Maybe<StatusDelivery>>>;
  PaymentStatusList?: Maybe<Array<Maybe<StatusPayment>>>;
};

export type Store = {
  __typename?: 'Store';
  id?: Maybe<Scalars['Int']>;
  /** Connection name */
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  userId: Scalars['Int'];
  /** Shop ID from etsy website */
  storeId?: Maybe<Scalars['Int']>;
  /** Enabled/Disabled */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Available variants: etsy, ebay, amazon, shopify, woo_commerce, big_commerce, paypal, wildberries, ozon */
  marketplace: Scalars['String'];
};

export type StoreInput = {
  name?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['String']>;
  /** marketplace id from DB */
  marketplaceId: Scalars['Int'];
  /** available value: etsy, ebay, amazon, shopify, woo_commerce, big_commerce, paypal, wildberries, ozon */
  marketplace?: Maybe<StoreMarketplace>;
};

export enum StoreMarketplace {
  Etsy = 'etsy',
  Ebay = 'ebay',
  Amazon = 'amazon',
  Shopify = 'shopify',
  WooCommerce = 'woo_commerce',
  BigCommerce = 'big_commerce',
  Paypal = 'paypal',
  Wildberries = 'wildberries',
  Ozon = 'ozon'
}

export type Subscription = {
  __typename?: 'Subscription';
  hello?: Maybe<Scalars['String']>;
  newNotification?: Maybe<Scalars['Boolean']>;
};

export type SuggesWarehousCellByProductQuantity = {
  productId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type SwapWarehouseCellProduct = {
  productId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type SwapWarehouseCellProductsInput = {
  cellFromId: Scalars['ID'];
  cellToId: Scalars['ID'];
  swapProducts?: Maybe<Array<Maybe<SwapWarehouseCellProduct>>>;
};

export type Tariff = {
  __typename?: 'Tariff';
  id?: Maybe<Scalars['Int']>;
  zoneId?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  tariffVariant?: Maybe<Scalars['String']>;
};

export type TariffInput = {
  weight?: Maybe<Scalars['Float']>;
  rows?: Maybe<Array<Maybe<Tariff_>>>;
};

export type TariffList = {
  __typename?: 'TariffList';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Tariff>>>;
};

export type Tariff_ = {
  zoneId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
};

export type Token = {
  __typename?: 'Token';
  token?: Maybe<Scalars['String']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  id: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  transactionId?: Maybe<Scalars['String']>;
  parcelId?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  confirmationUrl?: Maybe<Scalars['String']>;
  /** Available variants: REFILL, ORDER_PAYMENT, WEIGHT_ADJUSTMENT, CANCEL_ADJUSTMENT, REFUND, AWAITING_PAYMENT, CDEK, WAREHOUSE_STORAGE */
  paymentType: Scalars['String'];
  /** Available variants: pending, succeeded, canceled */
  status: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  cancellationReason?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  parcel?: Maybe<Parcel>;
  returnShipment?: Maybe<ReturnShipment>;
  warehouseShipmentOrder?: Maybe<WarehouseShipmentOrder>;
  warehouseShipment?: Maybe<WarehouseShipment>;
  undeclaredShipment?: Maybe<UndeclaredShipment>;
  b2bOrder?: Maybe<B2BOrder>;
  cdekParcelId?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Float']>;
  paymentStatus?: Maybe<Scalars['String']>;
};

export type TransactionList = {
  __typename?: 'TransactionList';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Transaction>>>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<DeliveryCountry>;
  zipCode?: Maybe<Scalars['String']>;
};

export type TransactionTypeItem = {
  __typename?: 'TransactionTypeItem';
  id?: Maybe<Scalars['Int']>;
  paymentType?: Maybe<TransactionTypes>;
};

export enum TransactionTypes {
  Refund = 'REFUND',
  AdminOperation = 'ADMIN_OPERATION',
  CancelAdjustment = 'CANCEL_ADJUSTMENT',
  WeightAdjustment = 'WEIGHT_ADJUSTMENT',
  OrderPayment = 'ORDER_PAYMENT',
  AwaitingPayment = 'AWAITING_PAYMENT',
  WarehouseStorage = 'WAREHOUSE_STORAGE'
}

export type TypeDelivery = {
  __typename?: 'TypeDelivery';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  serviceName?: Maybe<Scalars['String']>;
  isUserAccount?: Maybe<Scalars['Boolean']>;
};

export type UndeclaredShipment = {
  __typename?: 'UndeclaredShipment';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  warehouse?: Maybe<Scalars['String']>;
  trackNumber?: Maybe<Scalars['String']>;
  carrierCode?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  warehouseStatus?: Maybe<WarehouseShipmentStatus>;
  user?: Maybe<User>;
  attachments?: Maybe<Array<Maybe<UndeclaredShipmentAttachment>>>;
  items?: Maybe<Array<Maybe<UndeclaredShipmentItem>>>;
};

export type UndeclaredShipmentAttachment = {
  __typename?: 'UndeclaredShipmentAttachment';
  id?: Maybe<Scalars['Int']>;
  filePath?: Maybe<Scalars['String']>;
};

export type UndeclaredShipmentFilter = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  dateFilter?: Maybe<Scalars['Int']>;
  warehouseStatus?: Maybe<WarehouseShipmentStatus>;
};

export type UndeclaredShipmentItem = {
  __typename?: 'UndeclaredShipmentItem';
  productId?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  product?: Maybe<Product>;
};

export type UndeclaredShipmentListItem = {
  __typename?: 'UndeclaredShipmentListItem';
  id?: Maybe<Scalars['Int']>;
  warehouse?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  user?: Maybe<User>;
  trackNumber?: Maybe<Scalars['String']>;
  carrierCode?: Maybe<Scalars['String']>;
  warehouseStatus?: Maybe<WarehouseShipmentStatus>;
  quantityItems?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

export type UndeclaredShipmentsList = {
  __typename?: 'UndeclaredShipmentsList';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<UndeclaredShipmentListItem>>>;
};

export type UnitDescription = {
  id: Scalars['Int'];
  description: Scalars['String'];
};

export type UpdateWarehouseZone = {
  name?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['Int']>;
};

export type UpdateWarehouseZoneCell = {
  name?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['Int']>;
  warehouseZoneId?: Maybe<Scalars['Int']>;
};


export type UploadWarehouseZoneCellsResult = {
  __typename?: 'UploadWarehouseZoneCellsResult';
  status?: Maybe<Scalars['String']>;
  all?: Maybe<Scalars['Int']>;
  loaded?: Maybe<Scalars['Int']>;
  messages?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  country?: Maybe<DeliveryCountry>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  eoriCode?: Maybe<Scalars['String']>;
  /** Available variants: ACTIVE, NOT_ACTIVE */
  status: Scalars['Int'];
  transactions?: Maybe<Array<Maybe<Transaction>>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  balance?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  whFeePersonalDiscount?: Maybe<Scalars['Float']>;
  passportData?: Maybe<UserPasport>;
  entityLegalData?: Maybe<EntityLegalData>;
  role?: Maybe<UserRoles>;
  senderAccount?: Maybe<Array<Maybe<Scalars['String']>>>;
  creditLimit?: Maybe<Scalars['Int']>;
  warehouse?: Maybe<Warehouse>;
  fullAccess?: Maybe<Scalars['Boolean']>;
};

export type UserClient = {
  __typename?: 'UserClient';
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type UserInput = {
  name: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  countryId: Scalars['Int'];
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  eoriCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type UserOrder = {
  rule?: Maybe<UserOrderRules>;
  way?: Maybe<SortType>;
};

export enum UserOrderRules {
  Balance = 'balance',
  LastTransaction = 'lastTransaction',
  Verification = 'verification'
}

export type UserPaginationResponse = {
  __typename?: 'UserPaginationResponse';
  count: Scalars['Int'];
  rows: Array<Maybe<User>>;
};

export type UserPasport = {
  __typename?: 'UserPasport';
  id: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName: Scalars['String'];
  birthDay: Scalars['String'];
  seriesAndNumberOfPassport: Scalars['String'];
  dateOfIssueOfTheDocument: Scalars['String'];
  inn: Scalars['String'];
  scanPath: Scalars['String'];
  userId: Scalars['Int'];
  isDataVerified: Scalars['Boolean'];
};

export type UserPassportDataInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName: Scalars['String'];
  birthDay: Scalars['String'];
  seriesAndNumberOfPassport: Scalars['String'];
  dateOfIssueOfTheDocument: Scalars['String'];
  inn: Scalars['String'];
  dataUpToDate?: Scalars['Boolean'];
};

export type UserPassportUpdateDataInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  birthDay?: Maybe<Scalars['String']>;
  seriesAndNumberOfPassport?: Maybe<Scalars['String']>;
  dateOfIssueOfTheDocument?: Maybe<Scalars['String']>;
  inn?: Maybe<Scalars['String']>;
  dataUpToDate?: Scalars['Boolean'];
};

export enum UserRoles {
  Manager = 'MANAGER',
  SuperAdmin = 'SUPER_ADMIN',
  Admin = 'ADMIN',
  Customer = 'CUSTOMER'
}

export enum Vatkey {
  Vat = 'VAT',
  Ioss = 'IOSS',
  Ein = 'EIN',
  Empty = 'EMPTY'
}

export type ValidationAddress = {
  address: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  countryName: Scalars['String'];
  countryIso: Scalars['String'];
};

export type ValidationAddressResponse = {
  __typename?: 'ValidationAddressResponse';
  address?: Maybe<ValidationField>;
  city?: Maybe<ValidationField>;
  state?: Maybe<ValidationField>;
  postalCode?: Maybe<ValidationField>;
};

export type ValidationField = {
  __typename?: 'ValidationField';
  inputValue?: Maybe<Scalars['String']>;
  returnValue?: Maybe<Scalars['String']>;
};

export type VariantInfo = {
  __typename?: 'VariantInfo';
  warehouse_delivery_cost?: Maybe<Scalars['Float']>;
  warehouse_proccesing_cost?: Maybe<Scalars['Float']>;
  last_mile_label_cost?: Maybe<Scalars['Float']>;
};

export type VariantInfoWarehouseShipmentOrder = {
  __typename?: 'VariantInfoWarehouseShipmentOrder';
  warehouse_delivery_cost?: Maybe<Scalars['Float']>;
  warehouse_proccesing_cost?: Maybe<Scalars['Float']>;
  last_mile_label_cost?: Maybe<Scalars['Float']>;
  wrap_cost?: Maybe<Scalars['Int']>;
  message_cost?: Maybe<Scalars['Int']>;
};

export enum Warehouse_Operations {
  ArrivalOrders = 'ARRIVAL_ORDERS',
  Forwarding = 'FORWARDING',
  Fulfillment = 'FULFILLMENT',
  Returns = 'RETURNS'
}

export enum Warehouse_Shipment_Order_Type {
  WithBuyLabel = 'WITH_BUY_LABEL',
  WithoutBuyLabel = 'WITHOUT_BUY_LABEL'
}

export enum Warehouse_Shipment_Type {
  Forwarding = 'FORWARDING',
  Inbound = 'INBOUND'
}

export type Warehouse = {
  __typename?: 'Warehouse';
  id?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  country?: Maybe<DeliveryCountry>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  managerName?: Maybe<Scalars['String']>;
  availableOperation?: Maybe<Array<Maybe<WarehouseOperation>>>;
};

export enum WarehouseB2BStatus {
  Created = 'CREATED',
  Processing = 'PROCESSING',
  Picked = 'PICKED',
  BarcodeRequried = 'BARCODE_REQURIED',
  ShipmentReady = 'SHIPMENT_READY',
  Complete = 'COMPLETE',
  Canceled = 'CANCELED'
}

export type WarehouseCellProduct = {
  __typename?: 'WarehouseCellProduct';
  product?: Maybe<Product>;
  cellName?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type WarehouseCellProductList = {
  __typename?: 'WarehouseCellProductList';
  rows?: Maybe<Array<Maybe<WarehouseCellProduct>>>;
  count?: Maybe<Scalars['Int']>;
};

export type WarehouseForwarding = {
  from: ParcelContactInput;
  warehouseId?: Maybe<Scalars['Int']>;
  packages?: Maybe<Array<Maybe<CargoPlaceInput>>>;
  trackNumber?: Maybe<Scalars['String']>;
  carrierCode?: Maybe<Scalars['String']>;
  trackNumberItems?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type WarehouseInput = {
  name?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  managerName?: Maybe<Scalars['String']>;
  operations?: Maybe<Array<Maybe<Warehouse_Operations>>>;
};

export type WarehouseOperation = {
  __typename?: 'WarehouseOperation';
  operation?: Maybe<Warehouse_Operations>;
  warehouseId?: Maybe<Scalars['Int']>;
};

export enum WarehouseOperationForManager {
  TheParcelHasArrived = 'THE_PARCEL_HAS_ARRIVED',
  ReturnOfTheParcel = 'RETURN_OF_THE_PARCEL'
}

export enum WarehouseOperationForUser {
  Resending = 'RESENDING',
  Destroy = 'DESTROY'
}

export type WarehouseProductForManager = {
  __typename?: 'WarehouseProductForManager';
  id?: Maybe<Scalars['Int']>;
  barcodeId?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type WarehouseProductLog = {
  __typename?: 'WarehouseProductLog';
  id?: Maybe<Scalars['ID']>;
  productId?: Maybe<Scalars['ID']>;
  productBarcodeId?: Maybe<Scalars['String']>;
  fromCellId?: Maybe<Scalars['ID']>;
  fromCellName?: Maybe<Scalars['String']>;
  toCellId?: Maybe<Scalars['ID']>;
  toCellName?: Maybe<Scalars['String']>;
  operation?: Maybe<WarehouseProductLogOperation>;
  quantity?: Maybe<Scalars['Int']>;
  shipmentId?: Maybe<Scalars['ID']>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
};

export type WarehouseProductLogList = {
  __typename?: 'WarehouseProductLogList';
  rows?: Maybe<Array<Maybe<WarehouseProductLog>>>;
  count?: Maybe<Scalars['Int']>;
};

export enum WarehouseProductLogOperation {
  Inbound = 'INBOUND',
  InternalSwap = 'INTERNAL_SWAP',
  Sending = 'SENDING',
  Refill = 'REFILL',
  WriteOff = 'WRITE_OFF',
  OrderPicking = 'ORDER_PICKING',
  OrderCancel = 'ORDER_CANCEL',
  Return = 'RETURN',
  UndeclaredInbound = 'UNDECLARED_INBOUND'
}

export type WarehouseShipment = {
  __typename?: 'WarehouseShipment';
  id?: Maybe<Scalars['Int']>;
  from?: Maybe<ParcelContact>;
  events?: Maybe<Array<Maybe<WarehouseShipmentEvent>>>;
  createdAt?: Maybe<Scalars['String']>;
  warehouse?: Maybe<Warehouse>;
  trackNumber?: Maybe<Scalars['String']>;
  carrierCode?: Maybe<Scalars['String']>;
  deliveryStatus?: Maybe<StatusDelivery>;
  places?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  parcels?: Maybe<Array<Maybe<Parcel>>>;
  expectedQuantity?: Maybe<Scalars['Int']>;
  acceptedQuantity?: Maybe<Scalars['Int']>;
  barcode?: Maybe<Scalars['String']>;
  packages?: Maybe<Array<Maybe<CargoPlace>>>;
  products?: Maybe<Array<Maybe<ProductInParcel>>>;
  pieces?: Maybe<Array<Maybe<WarehouseShipmentPiece>>>;
  warehouseStatus?: Maybe<WarehouseShipmentStatus>;
  note?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
};

export type WarehouseShipmentEvent = {
  __typename?: 'WarehouseShipmentEvent';
  id?: Maybe<Scalars['Int']>;
  warehouseShipment?: Maybe<WarehouseShipment>;
  warehouse?: Maybe<Warehouse>;
  manager?: Maybe<Manager>;
  user?: Maybe<User>;
  operation?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
};

export type WarehouseShipmentFilter = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  dateFilter?: Maybe<Scalars['Int']>;
  deliveryStatusId?: Maybe<Scalars['Int']>;
  warehouseStatus?: Maybe<WarehouseShipmentStatus>;
  type?: Maybe<Warehouse_Shipment_Type>;
};

export type WarehouseShipmentInfoForManager = {
  __typename?: 'WarehouseShipmentInfoForManager';
  barcodeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  warehouseStatus?: Maybe<ShipmentWarehouseStatus>;
  User?: Maybe<WarehouseShipmentUserInfo>;
  shipmentProduct?: Maybe<Array<Maybe<WarehouseShipmentProductInfo>>>;
  wrap?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  giftWrap?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
};

export type WarehouseShipmentInput = {
  from: ParcelContactInput;
  parcelIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  products?: Maybe<Array<Maybe<ProductIdAndQuantity>>>;
  warehouseId?: Maybe<Scalars['Int']>;
  packages?: Maybe<Array<Maybe<CargoPlaceInput>>>;
  trackNumber?: Maybe<Scalars['String']>;
  carrierCode?: Maybe<Scalars['String']>;
};

export type WarehouseShipmentListForManager = {
  __typename?: 'WarehouseShipmentListForManager';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<WarehouseShipmentInfoForManager>>>;
};

export type WarehouseShipmentOrder = {
  __typename?: 'WarehouseShipmentOrder';
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<Warehouse_Shipment_Order_Type>;
  clientUser?: Maybe<User>;
  receiver?: Maybe<ParcelContact>;
  sender?: Maybe<Warehouse>;
  trackNumber?: Maybe<Scalars['String']>;
  carrierCode?: Maybe<Scalars['String']>;
  carrierService?: Maybe<Scalars['String']>;
  declaredAmount?: Maybe<Scalars['Float']>;
  actualAmount?: Maybe<Scalars['Float']>;
  additionalInsurance?: Maybe<Scalars['Boolean']>;
  wrap?: Maybe<Scalars['String']>;
  giftWrap?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  insuranceAmount?: Maybe<Scalars['Float']>;
  signature?: Maybe<Scalars['Boolean']>;
  declaredDimensions?: Maybe<CargoPlace>;
  dimensions?: Maybe<CargoPlace>;
  deliveryStatus?: Maybe<StatusDelivery>;
  paymentStatus?: Maybe<StatusPayment>;
  adminDeliveryStatus?: Maybe<Scalars['String']>;
  typeDelivery?: Maybe<TypeDelivery>;
  pathNameFile?: Maybe<Scalars['String']>;
  senderMark?: Maybe<Scalars['String']>;
  vatKey?: Maybe<Vatkey>;
  vatValue?: Maybe<Scalars['String']>;
  barcodeId?: Maybe<Scalars['String']>;
  easyPostShipmentId?: Maybe<Scalars['ID']>;
  easyPostRateId?: Maybe<Scalars['ID']>;
  lastMileCost?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<ShipmentWarehouseProduct>>>;
  orderCostCurrency?: Maybe<Scalars['String']>;
  preliminaryCost?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  handlingFee?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  warehouseStatus?: Maybe<ShipmentWarehouseStatus>;
  marketplaceInvoiceToken?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export type WarehouseShipmentOrderAdditionalOptions = {
  __typename?: 'WarehouseShipmentOrderAdditionalOptions';
  wrappers?: Maybe<Scalars['JSON']>;
  giftMessageCost?: Maybe<Scalars['Float']>;
  giftWrapperCost?: Maybe<Scalars['Float']>;
};

export type WarehouseShipmentOrderDeliveryOptionsInput = {
  warehouseId?: Maybe<Scalars['Int']>;
  warehouseShipmentOrderId?: Maybe<Scalars['Int']>;
  products?: Maybe<Array<Maybe<ShipmentProductInput>>>;
  receiver: ParcelContactInput;
  additionalInsurance?: Maybe<Scalars['Boolean']>;
  wrap: Scalars['String'];
  giftWrap?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  insuranceAmount?: Maybe<Scalars['Float']>;
  signature?: Maybe<Scalars['Boolean']>;
  dimensions?: Maybe<CargoPlaceInput>;
  only_calculation?: Maybe<Scalars['Boolean']>;
  address_validation?: Maybe<Scalars['Boolean']>;
  vatKey?: Maybe<Vatkey>;
  vatValue?: Maybe<Scalars['String']>;
};

export type WarehouseShipmentOrdersList = {
  __typename?: 'WarehouseShipmentOrdersList';
  rows?: Maybe<Array<Maybe<WarehouseShipmentOrder>>>;
  count?: Maybe<Scalars['Int']>;
};

export type WarehouseShipmentOrdersOptions = {
  warehouseId?: Maybe<Scalars['ID']>;
  dateFilter?: Maybe<Scalars['Int']>;
  paymentStatusId?: Maybe<Scalars['ID']>;
  deliveryStatusId?: Maybe<Scalars['ID']>;
  deiveryService?: Maybe<DeliveryService>;
  search?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  type?: Maybe<Warehouse_Shipment_Order_Type>;
};

export type WarehouseShipmentPiece = {
  __typename?: 'WarehouseShipmentPiece';
  id?: Maybe<Scalars['Int']>;
  warehouseShipmentId?: Maybe<Scalars['Int']>;
  customIdentifier?: Maybe<Scalars['String']>;
  accepted?: Maybe<Scalars['Boolean']>;
};

export type WarehouseShipmentProductInfo = {
  __typename?: 'WarehouseShipmentProductInfo';
  quantity?: Maybe<Scalars['Int']>;
  accepted?: Maybe<Scalars['Int']>;
  product?: Maybe<WarehouseProductForManager>;
};

export enum WarehouseShipmentStatus {
  Created = 'CREATED',
  Processing = 'PROCESSING',
  Accepted = 'ACCEPTED',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED'
}

export type WarehouseShipmentUseParcelInput = {
  from: ParcelContactInput;
  warehouseId?: Maybe<Scalars['Int']>;
  packages?: Maybe<Array<Maybe<CargoPlaceInput>>>;
  trackNumber?: Maybe<Scalars['String']>;
  carrierCode?: Maybe<Scalars['String']>;
  identifiers?: Maybe<Array<Maybe<Scalars['String']>>>;
  identifiersType?: Maybe<IdentifiersType>;
};

export type WarehouseShipmentUseProductInput = {
  from: ParcelContactInput;
  products?: Maybe<Array<Maybe<ProductIdAndQuantity>>>;
  warehouseId?: Maybe<Scalars['Int']>;
  packages?: Maybe<Array<Maybe<CargoPlaceInput>>>;
  trackNumber?: Maybe<Scalars['String']>;
  carrierCode?: Maybe<Scalars['String']>;
};

export type WarehouseShipmentUserInfo = {
  __typename?: 'WarehouseShipmentUserInfo';
  name?: Maybe<Scalars['String']>;
};

export type WarehouseShipmentsList = {
  __typename?: 'WarehouseShipmentsList';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<WarehouseShipment>>>;
};

export type WarehouseZone = {
  __typename?: 'WarehouseZone';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  totalCellsCount?: Maybe<Scalars['Int']>;
  freeCellsCount?: Maybe<Scalars['Int']>;
  occupiedCellsCount?: Maybe<Scalars['Int']>;
  orderNumber?: Maybe<Scalars['Int']>;
};

export type WarehouseZoneCell = {
  __typename?: 'WarehouseZoneCell';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  orderNumber?: Maybe<Scalars['Int']>;
  totalProductsCount?: Maybe<Scalars['Int']>;
  warehouseZoneId?: Maybe<Scalars['Int']>;
  barcode?: Maybe<Scalars['String']>;
};

export type WarehouseZoneCellByProduct = {
  __typename?: 'WarehouseZoneCellByProduct';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  targetProductsCount?: Maybe<Scalars['Int']>;
  targetFromCell?: Maybe<Scalars['Boolean']>;
};

export type WarehouseZoneCellsResult = {
  __typename?: 'WarehouseZoneCellsResult';
  rows?: Maybe<Array<Maybe<WarehouseZoneCell>>>;
  count?: Maybe<Scalars['Int']>;
};

export type WarehousesList = {
  __typename?: 'WarehousesList';
  rows?: Maybe<Array<Maybe<Warehouse>>>;
  count?: Maybe<Scalars['Int']>;
};

export type WriteEtsyKey = {
  code: Scalars['String'];
  state?: Maybe<Scalars['String']>;
};

export type AnalyticData = {
  __typename?: 'analyticData';
  volume?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type ConsolidationTrackNumberObject = {
  __typename?: 'consolidationTrackNumberObject';
  service?: Maybe<Scalars['String']>;
  consolidationTrackNumber?: Maybe<Scalars['String']>;
};

export type GetWarehouseZoneCellProduct = {
  productId: Scalars['ID'];
  cellId: Scalars['ID'];
};

export type GetWarehouseZoneCellProducts = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  productId: Scalars['ID'];
};

export type SetB2BOrderServicesAndShipmentInput = {
  services?: Maybe<Array<Maybe<B2BOrderServiceInput>>>;
  shipmentDriverInfo?: Maybe<B2BDriverInfoInput>;
  shipmentDate?: Maybe<ShipmentDateInput>;
};

export type ShipmentDataRate = {
  parcelsIds: Array<Maybe<Scalars['Int']>>;
  sender: ParcelContactInput;
  receiver: ParcelContactInput;
  readyTime: Scalars['String'];
  packages: Array<Maybe<CargoPlaceInput>>;
  currency_code: Scalars['String'];
  exportReason?: Maybe<Scalars['String']>;
  paymentOfTaxes: Scalars['String'];
  vatValue?: Maybe<Scalars['String']>;
  iso: Scalars['String'];
};

export type ShipmentDataSend = {
  sender: ParcelContactInput;
  receiver: ParcelContactInput;
  packages: Array<Maybe<CargoPlaceInput>>;
  declaredWeight: Scalars['Float'];
  declaredAmount: Scalars['Float'];
  senderNotes?: Maybe<Scalars['String']>;
  additionalInsurance: Scalars['Boolean'];
  userId?: Maybe<Scalars['Int']>;
  currency_code: Scalars['String'];
  senderMark?: Maybe<Scalars['String']>;
  exportReason?: Maybe<Scalars['String']>;
  paymentOfTaxes: Scalars['String'];
  vatValue?: Maybe<Scalars['String']>;
  cargoPlaces: Scalars['Int'];
  totalGrossWeight?: Maybe<Scalars['Float']>;
  totalNetWeight: Scalars['Float'];
  totalVolumetricWeight?: Maybe<Scalars['Float']>;
  totalCostOfEnteties: Scalars['Float'];
  totalCost: Scalars['Float'];
};

export type TrackParcelHistory = {
  __typename?: 'trackParcelHistory';
  eventName: Scalars['String'];
  timestamp: Scalars['Int'];
};

export type TrackParcelResponse = {
  __typename?: 'trackParcelResponse';
  error?: Maybe<Scalars['String']>;
  /** Return back the carrier received from request */
  carrier_code?: Maybe<Scalars['String']>;
  /** Return back the track number received from request */
  track_number?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  statusDescription?: Maybe<Scalars['String']>;
  /** Which city is the parcel now */
  city?: Maybe<Scalars['String']>;
  /** Which coutry (ISO code) is the parcel now */
  countryISO?: Maybe<Scalars['String']>;
  history?: Maybe<Array<Maybe<TrackParcelHistory>>>;
  /**
   * Current parcel step. Can be one of 4:
   * 1 - Shipment information sent to FedEx (Обрабатывается)
   * 2 - pick up (Груз отгружен)
   * 3 - in transit (В пути)
   * 4 - delivered (Доставлен)
   */
  currentStep?: Maybe<Scalars['Int']>;
  senderCity?: Maybe<Scalars['String']>;
  senderStateOrProvinceCode?: Maybe<Scalars['String']>;
  senderCountryISO?: Maybe<Scalars['String']>;
  receiverCity?: Maybe<Scalars['String']>;
  receiverStateOrProvinceCode?: Maybe<Scalars['String']>;
  receiverCountryISO?: Maybe<Scalars['String']>;
};

export type ConsolidationFragmentFragment = (
  { __typename?: 'Consolidation' }
  & Pick<Consolidation, 'id' | 'serialNumber' | 'trackNumber' | 'insurance' | 'shipmentServiceName' | 'waybillPath' | 'cargoPlaces' | 'totalGrossWeight' | 'totalNetWeight' | 'totalVolumetricWeight' | 'totalCostOfEnteties' | 'totalCost' | 'date' | 'senderAccount' | 'fileTokenInvoiceXlsx' | 'barcode'>
  & { paymentStatus?: Maybe<(
    { __typename?: 'StatusPayment' }
    & Pick<StatusPayment, 'id' | 'name' | 'explain'>
  )>, deliveryStatus?: Maybe<(
    { __typename?: 'StatusDelivery' }
    & Pick<StatusDelivery, 'id' | 'name' | 'explain'>
  )>, parcels?: Maybe<Array<Maybe<(
    { __typename?: 'Parcel' }
    & Pick<Parcel, 'id' | 'barcode' | 'trackNumber' | 'carrierCode' | 'accepted'>
    & { packages: Array<Maybe<(
      { __typename?: 'Package' }
      & { units?: Maybe<Array<Maybe<(
        { __typename?: 'ParcelUnit' }
        & Pick<ParcelUnit, 'id' | 'nameRU' | 'code' | 'quantity' | 'price' | 'netWeight' | 'nameEN' | 'tradeMark' | 'state'>
        & { country?: Maybe<(
          { __typename?: 'DeliveryCountry' }
          & Pick<DeliveryCountry, 'id'>
        )> }
      )>>> }
    )>> }
  )>>>, sender?: Maybe<(
    { __typename?: 'ParcelContact' }
    & ParcelContactFragmentFragment
  )>, receiver?: Maybe<(
    { __typename?: 'ParcelContact' }
    & ParcelContactFragmentFragment
  )>, packages?: Maybe<Array<Maybe<(
    { __typename?: 'CargoPlace' }
    & Pick<CargoPlace, 'id' | 'width' | 'weight' | 'length' | 'height'>
  )>>> }
);

export type FragmentDeliveryCountryFragment = (
  { __typename?: 'DeliveryCountry' }
  & Pick<DeliveryCountry, 'id' | 'order' | 'name' | 'daysToDelivery' | 'zoneId' | 'nameEng' | 'iso' | 'phoneCode' | 'phoneMin' | 'phoneMax' | 'customsInfo'>
);

export type ManagerFragmentFragment = (
  { __typename?: 'Manager' }
  & Pick<Manager, 'id' | 'name' | 'email' | 'role'>
);

export type ParcelContactFragmentFragment = (
  { __typename?: 'ParcelContact' }
  & Pick<ParcelContact, 'id' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'email' | 'innKppCode' | 'remark' | 'eoriCode'>
  & { country?: Maybe<(
    { __typename?: 'DeliveryCountry' }
    & FragmentDeliveryCountryFragment
  )> }
);

export type FragmentUserPasportFragment = (
  { __typename?: 'UserPasport' }
  & Pick<UserPasport, 'id' | 'firstName' | 'lastName' | 'middleName' | 'birthDay' | 'seriesAndNumberOfPassport' | 'dateOfIssueOfTheDocument' | 'inn' | 'scanPath' | 'userId' | 'isDataVerified'>
);

export type AddEventToWarehouseShipmentMutationVariables = Exact<{
  warehouseShipmentId: Scalars['Int'];
  operation?: Maybe<WarehouseOperationForUser>;
}>;


export type AddEventToWarehouseShipmentMutation = (
  { __typename?: 'Mutation' }
  & { addEventToWarehouseShipment?: Maybe<(
    { __typename?: 'WarehouseShipment' }
    & Pick<WarehouseShipment, 'id'>
  )> }
);

export type AddTariffMutationVariables = Exact<{
  input: TariffInput;
}>;


export type AddTariffMutation = (
  { __typename?: 'Mutation' }
  & { addTariff?: Maybe<Array<Maybe<(
    { __typename?: 'Tariff' }
    & Pick<Tariff, 'id' | 'zoneId' | 'weight' | 'price'>
  )>>> }
);

export type ArrivalOfTheParcelForAdminMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ArrivalOfTheParcelForAdminMutation = (
  { __typename?: 'Mutation' }
  & { arrivalOfTheParcelForAdmin?: Maybe<(
    { __typename?: 'WarehouseShipment' }
    & Pick<WarehouseShipment, 'id'>
  )> }
);

export type CancelB2BOrderMutationVariables = Exact<{
  orderId: Scalars['Int'];
}>;


export type CancelB2BOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelB2BOrder'>
);

export type CancelConsolidationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CancelConsolidationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelConsolidation'>
);

export type CancelUndeclaredShipmentMutationVariables = Exact<{
  cancelUndeclaredShipmentId: Scalars['Int'];
}>;


export type CancelUndeclaredShipmentMutation = (
  { __typename?: 'Mutation' }
  & { cancelUndeclaredShipment?: Maybe<(
    { __typename?: 'UndeclaredShipment' }
    & Pick<UndeclaredShipment, 'id'>
  )> }
);

export type CancelWarehouseShipmentMutationVariables = Exact<{
  cancelWarehouseShipmentId: Scalars['Int'];
}>;


export type CancelWarehouseShipmentMutation = (
  { __typename?: 'Mutation' }
  & { cancelWarehouseShipment?: Maybe<(
    { __typename?: 'WarehouseShipment' }
    & Pick<WarehouseShipment, 'id'>
  )> }
);

export type CancelWarehouseShipmentOrderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CancelWarehouseShipmentOrderMutation = (
  { __typename?: 'Mutation' }
  & { cancelWarehouseShipmentOrder?: Maybe<(
    { __typename?: 'WarehouseShipmentOrder' }
    & Pick<WarehouseShipmentOrder, 'id'>
  )> }
);

export type ChangeProductAvailabilityMutationVariables = Exact<{
  input: ChangeProductAvailabilityInput;
}>;


export type ChangeProductAvailabilityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changeProductAvailability'>
);

export type CompleteB2BOrderMutationVariables = Exact<{
  orderId: Scalars['Int'];
}>;


export type CompleteB2BOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'completeB2BOrder'>
);

export type CreateContactMutationVariables = Exact<{
  input: ContactInput;
}>;


export type CreateContactMutation = (
  { __typename?: 'Mutation' }
  & { createContact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'email' | 'remark' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'eoriCode' | 'contactName' | 'innKppCode'>
    & { country?: Maybe<(
      { __typename?: 'DeliveryCountry' }
      & FragmentDeliveryCountryFragment
    )> }
  )> }
);

export type CreateManagerMutationVariables = Exact<{
  input?: Maybe<ManagerInput>;
}>;


export type CreateManagerMutation = (
  { __typename?: 'Mutation' }
  & { createManager?: Maybe<(
    { __typename?: 'Manager' }
    & ManagerFragmentFragment
  )> }
);

export type CreateOrEditB2BServiceMutationVariables = Exact<{
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  costInUsd?: Maybe<Scalars['Float']>;
  createOrEditB2BServiceId?: Maybe<Scalars['Int']>;
}>;


export type CreateOrEditB2BServiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createOrEditB2BService'>
);

export type CreateOrEditB2BShipmentPointMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createOrEditB2BShipmentPointId?: Maybe<Scalars['Int']>;
}>;


export type CreateOrEditB2BShipmentPointMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createOrEditB2BShipmentPoint'>
);

export type CreateReportByFulfillmentMutationVariables = Exact<{
  input?: Maybe<ReportFulfillment>;
}>;


export type CreateReportByFulfillmentMutation = (
  { __typename?: 'Mutation' }
  & { createReportByFulfillment?: Maybe<(
    { __typename?: 'ReportFilename' }
    & Pick<ReportFilename, 'filename'>
  )> }
);

export type CreateReportByProductsMutationVariables = Exact<{
  input?: Maybe<ReportProductInput>;
}>;


export type CreateReportByProductsMutation = (
  { __typename?: 'Mutation' }
  & { createReportByProducts?: Maybe<(
    { __typename?: 'ReportFilename' }
    & Pick<ReportFilename, 'filename'>
  )> }
);

export type CreateReportByTransactionsMutationVariables = Exact<{
  input?: Maybe<ReportRangeInput>;
}>;


export type CreateReportByTransactionsMutation = (
  { __typename?: 'Mutation' }
  & { createReportByTransactions?: Maybe<(
    { __typename?: 'ReportFilename' }
    & Pick<ReportFilename, 'filename'>
  )> }
);

export type CreateRequestToCallCourierMutationVariables = Exact<{
  input?: Maybe<CourierRequestInput>;
}>;


export type CreateRequestToCallCourierMutation = (
  { __typename?: 'Mutation' }
  & { createRequestToCallCourier?: Maybe<(
    { __typename?: 'CourierResponse' }
    & Pick<CourierResponse, 'id' | 'pickupDate' | 'readyByTime' | 'closeTime' | 'contactName' | 'contactPhone' | 'packageLocation' | 'confirmationNumber' | 'errors'>
    & { pieces?: Maybe<Array<Maybe<(
      { __typename?: 'Piece' }
      & Pick<Piece, 'weight' | 'width' | 'height' | 'length'>
    )>>> }
  )> }
);

export type CreateSenderAccountMutationVariables = Exact<{
  input?: Maybe<SenderAccountInput>;
}>;


export type CreateSenderAccountMutation = (
  { __typename?: 'Mutation' }
  & { createSenderAccount?: Maybe<(
    { __typename?: 'SenderAccount' }
    & Pick<SenderAccount, 'id' | 'accountName' | 'status' | 'accountNumber' | 'password' | 'siteId'>
  )> }
);

export type CreateShipmentRequestMutationVariables = Exact<{
  input?: Maybe<ShipmentInput>;
}>;


export type CreateShipmentRequestMutation = (
  { __typename?: 'Mutation' }
  & { createShipmentRequest?: Maybe<(
    { __typename?: 'ShipmentResponse' }
    & Pick<ShipmentResponse, 'errors'>
    & { data?: Maybe<(
      { __typename?: 'ShipmentDataResponse' }
      & Pick<ShipmentDataResponse, 'id' | 'airwayBillNumber' | 'status' | 'senderAccountId' | 'parcelId' | 'price' | 'createdAt' | 'updatedAt'>
    )> }
  )> }
);

export type CreateWarehouseMutationVariables = Exact<{
  input?: Maybe<WarehouseInput>;
}>;


export type CreateWarehouseMutation = (
  { __typename?: 'Mutation' }
  & { createWarehouse?: Maybe<(
    { __typename?: 'Warehouse' }
    & Pick<Warehouse, 'id' | 'code'>
  )> }
);

export type CreateWarehouseZoneMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateWarehouseZoneMutation = (
  { __typename?: 'Mutation' }
  & { createWarehouseZone?: Maybe<(
    { __typename?: 'WarehouseZone' }
    & Pick<WarehouseZone, 'id' | 'name'>
  )> }
);

export type CreateWarehouseZoneCellMutationVariables = Exact<{
  data: CreateWarehouseZoneCell;
}>;


export type CreateWarehouseZoneCellMutation = (
  { __typename?: 'Mutation' }
  & { createWarehouseZoneCell?: Maybe<(
    { __typename?: 'WarehouseZoneCell' }
    & Pick<WarehouseZoneCell, 'id' | 'name'>
  )> }
);

export type DeleteB2BServiceMutationVariables = Exact<{
  deleteB2BServiceId?: Maybe<Scalars['Int']>;
}>;


export type DeleteB2BServiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteB2BService'>
);

export type DeleteB2BShipmentPointMutationVariables = Exact<{
  deleteB2BShipmentPointId?: Maybe<Scalars['Int']>;
}>;


export type DeleteB2BShipmentPointMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteB2BShipmentPoint'>
);

export type DeleteWarehouseZoneMutationVariables = Exact<{
  deleteWarehouseZoneId: Scalars['ID'];
}>;


export type DeleteWarehouseZoneMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteWarehouseZone'>
);

export type DeleteWarehouseZoneCellMutationVariables = Exact<{
  deleteWarehouseZoneCellId: Scalars['ID'];
}>;


export type DeleteWarehouseZoneCellMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteWarehouseZoneCell'>
);

export type EditB2BOrderCargoPlacesMutationVariables = Exact<{
  orderId: Scalars['Int'];
  cargoData: EditB2BOrderCargoPlacesInput;
  cargoItems: Array<Maybe<B2BCargoPlaceProductInput>> | Maybe<B2BCargoPlaceProductInput>;
}>;


export type EditB2BOrderCargoPlacesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'editB2BOrderCargoPlaces'>
);

export type RemoveContactMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveContactMutation = (
  { __typename?: 'Mutation' }
  & { removeContact: (
    { __typename?: 'Status' }
    & Pick<Status, 'status'>
  ) }
);

export type RemoveManagerMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveManagerMutation = (
  { __typename?: 'Mutation' }
  & { removeManager?: Maybe<(
    { __typename?: 'Manager' }
    & ManagerFragmentFragment
  )> }
);

export type RemoveSenderAccountMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveSenderAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeSenderAccount'>
);

export type RemoveTariffMutationVariables = Exact<{
  weight: Scalars['Float'];
}>;


export type RemoveTariffMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeTariff'>
);

export type RemoveWarehouseMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveWarehouseMutation = (
  { __typename?: 'Mutation' }
  & { removeWarehouse?: Maybe<(
    { __typename?: 'Warehouse' }
    & Pick<Warehouse, 'id' | 'code'>
  )> }
);

export type SaveOrSendConsolidationMutationVariables = Exact<{
  parcelsIds?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  consolidationId?: Maybe<Scalars['ID']>;
  accountId?: Maybe<Scalars['ID']>;
  shipmentData: ShipmentDataSend;
  doSend?: Maybe<Scalars['Boolean']>;
}>;


export type SaveOrSendConsolidationMutation = (
  { __typename?: 'Mutation' }
  & { saveOrSendConsolidation?: Maybe<(
    { __typename?: 'Consolidation' }
    & Pick<Consolidation, 'id'>
  )> }
);

export type SendNotificationMutationVariables = Exact<{
  topic?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
}>;


export type SendNotificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendNotification'>
);

export type SendWarehouseShipmentOrderMutationVariables = Exact<{
  data: SendWarehouseShipmentOrderInput;
}>;


export type SendWarehouseShipmentOrderMutation = (
  { __typename?: 'Mutation' }
  & { sendWarehouseShipmentOrder?: Maybe<(
    { __typename?: 'WarehouseShipmentOrder' }
    & Pick<WarehouseShipmentOrder, 'id' | 'trackNumber' | 'carrierCode' | 'carrierService' | 'declaredAmount' | 'actualAmount' | 'additionalInsurance' | 'insuranceAmount' | 'signature' | 'pathNameFile' | 'senderMark' | 'vatKey' | 'vatValue'>
    & { clientUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, receiver?: Maybe<(
      { __typename?: 'ParcelContact' }
      & Pick<ParcelContact, 'id'>
    )>, sender?: Maybe<(
      { __typename?: 'Warehouse' }
      & Pick<Warehouse, 'id'>
    )>, dimensions?: Maybe<(
      { __typename?: 'CargoPlace' }
      & Pick<CargoPlace, 'id' | 'warehouseShipmentId' | 'length' | 'width' | 'height' | 'weight'>
    )>, deliveryStatus?: Maybe<(
      { __typename?: 'StatusDelivery' }
      & Pick<StatusDelivery, 'id' | 'name' | 'explain'>
    )>, products?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentWarehouseProduct' }
      & Pick<ShipmentWarehouseProduct, 'price' | 'quantity'>
    )>>> }
  )> }
);

export type SendWarehouseShipmentOrderWihtoutBuyLabelMutationVariables = Exact<{
  data: SendWarehouseShipmentOrderWithoutBuyLabelInput;
}>;


export type SendWarehouseShipmentOrderWihtoutBuyLabelMutation = (
  { __typename?: 'Mutation' }
  & { sendWarehouseShipmentOrderWihtoutBuyLabel?: Maybe<(
    { __typename?: 'WarehouseShipmentOrder' }
    & Pick<WarehouseShipmentOrder, 'id' | 'trackNumber' | 'carrierCode' | 'carrierService' | 'declaredAmount' | 'actualAmount' | 'additionalInsurance' | 'insuranceAmount' | 'signature' | 'pathNameFile' | 'senderMark' | 'vatKey' | 'vatValue'>
    & { clientUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, receiver?: Maybe<(
      { __typename?: 'ParcelContact' }
      & Pick<ParcelContact, 'id'>
    )>, sender?: Maybe<(
      { __typename?: 'Warehouse' }
      & Pick<Warehouse, 'id'>
    )>, dimensions?: Maybe<(
      { __typename?: 'CargoPlace' }
      & Pick<CargoPlace, 'id' | 'warehouseShipmentId' | 'length' | 'width' | 'height' | 'weight'>
    )>, deliveryStatus?: Maybe<(
      { __typename?: 'StatusDelivery' }
      & Pick<StatusDelivery, 'id' | 'name' | 'explain'>
    )>, products?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentWarehouseProduct' }
      & Pick<ShipmentWarehouseProduct, 'price' | 'quantity'>
    )>>> }
  )> }
);

export type SetB2BOrderCargoPlacesMutationVariables = Exact<{
  orderId: Scalars['Int'];
  cargosCount: Scalars['Int'];
}>;


export type SetB2BOrderCargoPlacesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setB2BOrderCargoPlaces'>
);

export type SetB2BOrderServicesAndShipmentMutationVariables = Exact<{
  orderId: Scalars['Int'];
  data: SetB2BOrderServicesAndShipmentInput;
}>;


export type SetB2BOrderServicesAndShipmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setB2BOrderServicesAndShipment'>
);

export type SetProductActualDimensionsMutationVariables = Exact<{
  id: Scalars['Int'];
  weight: Scalars['Float'];
  width: Scalars['Float'];
  length: Scalars['Float'];
  height: Scalars['Float'];
}>;


export type SetProductActualDimensionsMutation = (
  { __typename?: 'Mutation' }
  & { setProductActualDimensions?: Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'sku'>
  )> }
);

export type UserDiscountManagementMutationVariables = Exact<{
  userId: Scalars['Int'];
  discount?: Maybe<DiscountInput>;
}>;


export type UserDiscountManagementMutation = (
  { __typename?: 'Mutation' }
  & { userDiscountManagement?: Maybe<(
    { __typename?: 'Discount' }
    & Pick<Discount, 'id' | 'userId' | 'creditLimit' | 'orderHandlingDiscountPercent' | 'warehouseStorageDiscountPercent' | 'forwardingDiscountPercent' | 'returnHandlingDiscountPercent' | 'inboundDiscountPercent' | 'undeclaredInboundDiscountPercent'>
  )> }
);

export type UpdateConfigurationMutationVariables = Exact<{
  input: Array<Maybe<ConfigInput>> | Maybe<ConfigInput>;
}>;


export type UpdateConfigurationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateConfiguration'>
);

export type UpdateContactMutationVariables = Exact<{
  id: Scalars['ID'];
  input: ContactInput;
}>;


export type UpdateContactMutation = (
  { __typename?: 'Mutation' }
  & { updateContact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'email' | 'remark' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'eoriCode' | 'contactName' | 'innKppCode'>
    & { country?: Maybe<(
      { __typename?: 'DeliveryCountry' }
      & FragmentDeliveryCountryFragment
    )> }
  )> }
);

export type UpdateCreditLimitForUserMutationVariables = Exact<{
  userId: Scalars['Int'];
  value: Scalars['Int'];
}>;


export type UpdateCreditLimitForUserMutation = (
  { __typename?: 'Mutation' }
  & { updateCreditLimitForUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type UpdateManagerMutationVariables = Exact<{
  input?: Maybe<ManagerInput>;
  id: Scalars['Int'];
}>;


export type UpdateManagerMutation = (
  { __typename?: 'Mutation' }
  & { updateManager?: Maybe<(
    { __typename?: 'Manager' }
    & { warehouse?: Maybe<(
      { __typename?: 'Warehouse' }
      & Pick<Warehouse, 'id' | 'code'>
    )> }
    & ManagerFragmentFragment
  )> }
);

export type UpdatePassportDataMutationVariables = Exact<{
  input: UserPassportUpdateDataInput;
  file?: Maybe<Scalars['Upload']>;
}>;


export type UpdatePassportDataMutation = (
  { __typename?: 'Mutation' }
  & { updatePassportData?: Maybe<(
    { __typename?: 'UserPasport' }
    & FragmentUserPasportFragment
  )> }
);

export type UpdateSenderAccountMutationVariables = Exact<{
  input?: Maybe<SenderAccountInput>;
}>;


export type UpdateSenderAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateSenderAccount?: Maybe<(
    { __typename?: 'SenderAccount' }
    & Pick<SenderAccount, 'id' | 'accountName' | 'status' | 'accountNumber' | 'password' | 'siteId'>
  )> }
);

export type UpdateTariffMutationVariables = Exact<{
  input: TariffInput;
}>;


export type UpdateTariffMutation = (
  { __typename?: 'Mutation' }
  & { updateTariff?: Maybe<Array<Maybe<(
    { __typename?: 'Tariff' }
    & Pick<Tariff, 'id' | 'zoneId' | 'weight' | 'price'>
  )>>> }
);

export type UpdateTrackNumberConsolidationMutationMutationVariables = Exact<{
  id: Scalars['ID'];
  trackNumber: Scalars['String'];
  carrierCode?: Maybe<Scalars['String']>;
}>;


export type UpdateTrackNumberConsolidationMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateTrackNumberConsolidation?: Maybe<(
    { __typename?: 'Consolidation' }
    & Pick<Consolidation, 'id' | 'trackNumber' | 'serialNumber' | 'insurance' | 'date' | 'senderAccount' | 'totalCost' | 'shipmentServiceName' | 'waybillPath' | 'cargoPlaces' | 'totalGrossWeight' | 'totalNetWeight' | 'totalVolumetricWeight' | 'totalCostOfEnteties' | 'fileTokenInvoiceXlsx' | 'barcode'>
    & { sender?: Maybe<(
      { __typename?: 'ParcelContact' }
      & Pick<ParcelContact, 'id' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'email' | 'innKppCode' | 'remark' | 'eoriCode'>
      & { country?: Maybe<(
        { __typename?: 'DeliveryCountry' }
        & FragmentDeliveryCountryFragment
      )> }
    )>, receiver?: Maybe<(
      { __typename?: 'ParcelContact' }
      & Pick<ParcelContact, 'id' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'email' | 'innKppCode' | 'remark' | 'eoriCode'>
      & { country?: Maybe<(
        { __typename?: 'DeliveryCountry' }
        & FragmentDeliveryCountryFragment
      )> }
    )>, parcels?: Maybe<Array<Maybe<(
      { __typename?: 'Parcel' }
      & Pick<Parcel, 'id' | 'fileTokenInvoiceXlsx' | 'senderNotes' | 'senderMark' | 'additionalInsurance' | 'declaredAmount' | 'declaredWeight' | 'actualAmount' | 'actualWeight' | 'trackNumber' | 'carrierCode' | 'deliveryStatusId' | 'paymentStatusId' | 'currency' | 'sentToWarehouseId' | 'exportReason' | 'paymentOfTaxes' | 'vatKey' | 'vatID' | 'isConsolidationPiece'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )>, sender: (
        { __typename?: 'ParcelContact' }
        & Pick<ParcelContact, 'id' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'email' | 'innKppCode' | 'remark'>
        & { country?: Maybe<(
          { __typename?: 'DeliveryCountry' }
          & FragmentDeliveryCountryFragment
        )> }
      ), receiver: (
        { __typename?: 'ParcelContact' }
        & Pick<ParcelContact, 'id' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'email' | 'innKppCode' | 'remark' | 'eoriCode'>
        & { country?: Maybe<(
          { __typename?: 'DeliveryCountry' }
          & FragmentDeliveryCountryFragment
        )> }
      ), packages: Array<Maybe<(
        { __typename?: 'Package' }
        & Pick<Package, 'id' | 'weightKg' | 'lengthCm' | 'widthCm' | 'heightCm'>
        & { units?: Maybe<Array<Maybe<(
          { __typename?: 'ParcelUnit' }
          & Pick<ParcelUnit, 'id' | 'nameRU' | 'nameEN' | 'tradeMark' | 'code' | 'quantity' | 'price' | 'netWeight' | 'state'>
          & { country?: Maybe<(
            { __typename?: 'DeliveryCountry' }
            & FragmentDeliveryCountryFragment
          )> }
        )>>> }
      )>>, typeDelivery?: Maybe<(
        { __typename?: 'TypeDelivery' }
        & Pick<TypeDelivery, 'id' | 'name' | 'serviceName'>
      )>, consolidationTrackNumber?: Maybe<(
        { __typename?: 'consolidationTrackNumberObject' }
        & Pick<ConsolidationTrackNumberObject, 'service' | 'consolidationTrackNumber'>
      )> }
    )>>>, deliveryStatus?: Maybe<(
      { __typename?: 'StatusDelivery' }
      & Pick<StatusDelivery, 'id' | 'name' | 'explain'>
    )>, paymentStatus?: Maybe<(
      { __typename?: 'StatusPayment' }
      & Pick<StatusPayment, 'id' | 'name' | 'explain'>
    )> }
  )> }
);

export type UpdateWarehouseMutationVariables = Exact<{
  id: Scalars['Int'];
  input?: Maybe<WarehouseInput>;
}>;


export type UpdateWarehouseMutation = (
  { __typename?: 'Mutation' }
  & { updateWarehouse?: Maybe<(
    { __typename?: 'Warehouse' }
    & Pick<Warehouse, 'id' | 'code' | 'address' | 'company' | 'phone'>
  )> }
);

export type UpdateWarehouseZoneMutationVariables = Exact<{
  updateWarehouseZoneId: Scalars['ID'];
  data: UpdateWarehouseZone;
}>;


export type UpdateWarehouseZoneMutation = (
  { __typename?: 'Mutation' }
  & { updateWarehouseZone?: Maybe<(
    { __typename?: 'WarehouseZone' }
    & Pick<WarehouseZone, 'id' | 'name'>
  )> }
);

export type UpdateWarehouseZoneCellMutationVariables = Exact<{
  updateWarehouseZoneCellId: Scalars['ID'];
  data: UpdateWarehouseZoneCell;
}>;


export type UpdateWarehouseZoneCellMutation = (
  { __typename?: 'Mutation' }
  & { updateWarehouseZoneCell?: Maybe<(
    { __typename?: 'WarehouseZoneCell' }
    & Pick<WarehouseZoneCell, 'id' | 'name'>
  )> }
);

export type UploadWarehouseZoneCellsMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadWarehouseZoneCellsMutation = (
  { __typename?: 'Mutation' }
  & { uploadWarehouseZoneCells?: Maybe<(
    { __typename?: 'UploadWarehouseZoneCellsResult' }
    & Pick<UploadWarehouseZoneCellsResult, 'all' | 'loaded' | 'messages' | 'status'>
  )> }
);

export type VerifyLegalDataMutationVariables = Exact<{
  entityLegalDataId: Scalars['Int'];
}>;


export type VerifyLegalDataMutation = (
  { __typename?: 'Mutation' }
  & { verifyLegalData?: Maybe<(
    { __typename?: 'EntityLegalData' }
    & Pick<EntityLegalData, 'id'>
  )> }
);

export type VerifyPassportDataMutationVariables = Exact<{
  passportId: Scalars['Int'];
}>;


export type VerifyPassportDataMutation = (
  { __typename?: 'Mutation' }
  & { verifyPassportData?: Maybe<(
    { __typename?: 'UserPasport' }
    & FragmentUserPasportFragment
  )> }
);

export type GetAvailableWarehouseProductByIdQueryVariables = Exact<{
  warehouseId: Scalars['ID'];
  productId: Scalars['ID'];
}>;


export type GetAvailableWarehouseProductByIdQuery = (
  { __typename?: 'Query' }
  & { getAvailableWarehouseProductById?: Maybe<(
    { __typename?: 'AvailableWarehouseProduct' }
    & Pick<AvailableWarehouseProduct, 'inTransitQuantity' | 'reservedQuantity' | 'availableQuantity'>
  )> }
);

export type GetAvailableWarehouseProductsQueryVariables = Exact<{
  warehouseId?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
}>;


export type GetAvailableWarehouseProductsQuery = (
  { __typename?: 'Query' }
  & { getAvailableWarehouseProducts?: Maybe<Array<Maybe<(
    { __typename?: 'AvailableWarehouseProductsItem' }
    & Pick<AvailableWarehouseProductsItem, 'inTransitQuantity' | 'reservedQuantity' | 'availableQuantity'>
    & { product?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'sku' | 'detailsDescriptionRU' | 'detailsDescriptionEN' | 'state' | 'trademark' | 'declaredValue' | 'netWeight' | 'code' | 'userId' | 'fnsku' | 'upc'>
      & { country?: Maybe<(
        { __typename?: 'DeliveryCountry' }
        & FragmentDeliveryCountryFragment
      )>, actualDimensions?: Maybe<(
        { __typename?: 'CargoPlace' }
        & Pick<CargoPlace, 'id' | 'warehouseShipmentId' | 'weight' | 'length' | 'width' | 'height'>
      )>, declaredDimensions?: Maybe<(
        { __typename?: 'CargoPlace' }
        & Pick<CargoPlace, 'id' | 'warehouseShipmentId' | 'weight' | 'length' | 'width' | 'height'>
      )> }
    )> }
  )>>> }
);

export type GetB2BOrderByIdQueryVariables = Exact<{
  getB2BOrderByIdId: Scalars['Int'];
}>;


export type GetB2BOrderByIdQuery = (
  { __typename?: 'Query' }
  & { getB2BOrderById?: Maybe<(
    { __typename?: 'B2BOrder' }
    & Pick<B2BOrder, 'id' | 'wrapType' | 'wrapWay' | 'barcodeId' | 'externalOrdeId' | 'shipmentPointTitle' | 'warehouseName' | 'comment' | 'technicalTaskFileUrl' | 'totalCost' | 'warehouseStatus' | 'createdAt'>
    & { client?: Maybe<(
      { __typename?: 'UserClient' }
      & Pick<UserClient, 'id' | 'name'>
    )>, shipmentInfo?: Maybe<(
      { __typename?: 'B2BShipmentInfo' }
      & Pick<B2BShipmentInfo, 'scheduledDate' | 'scheduledTime' | 'shipmentLabelFileUrl' | 'typeMethod'>
      & { driverInfo?: Maybe<(
        { __typename?: 'B2BDriverInfo' }
        & Pick<B2BDriverInfo, 'carModel' | 'carNumber' | 'fullName' | 'mobileNumber'>
      )> }
    )>, products?: Maybe<Array<Maybe<(
      { __typename?: 'B2BOrderProduct' }
      & Pick<B2BOrderProduct, 'productId' | 'expectedQuantity' | 'acceptedQuantity'>
      & { relatedProduct?: Maybe<(
        { __typename?: 'B2BRelatedProduct' }
        & Pick<B2BRelatedProduct, 'barcodeId' | 'name' | 'sku'>
      )> }
    )>>>, cargoPlaces?: Maybe<Array<Maybe<(
      { __typename?: 'B2BOrderCargoPlace' }
      & Pick<B2BOrderCargoPlace, 'id' | 'type' | 'externalBarcodeUrl' | 'uniqSkuCount' | 'productQty' | 'weight' | 'length' | 'width' | 'height'>
      & { productsItems?: Maybe<Array<Maybe<(
        { __typename?: 'B2BCargoPlaceProduct' }
        & Pick<B2BCargoPlaceProduct, 'id' | 'cargoId' | 'productId' | 'quantity'>
      )>>> }
    )>>>, servicesWithCost?: Maybe<Array<Maybe<(
      { __typename?: 'B2BServices' }
      & Pick<B2BServices, 'costInUSD' | 'description' | 'id' | 'title' | 'qty'>
    )>>> }
  )> }
);

export type GetB2BOrderListQueryVariables = Exact<{
  filter: B2BOrderListFilter;
}>;


export type GetB2BOrderListQuery = (
  { __typename?: 'Query' }
  & { getB2BOrderList?: Maybe<(
    { __typename?: 'B2BOrderList' }
    & Pick<B2BOrderList, 'count'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'B2BOrderListItem' }
      & Pick<B2BOrderListItem, 'id' | 'barcodeId' | 'externalOrdeId' | 'shipmentPointTitle' | 'productsQty' | 'warehouseName' | 'totalCost' | 'createdAt' | 'shipmentDate' | 'warehouseStatus'>
      & { client?: Maybe<(
        { __typename?: 'UserClient' }
        & Pick<UserClient, 'name' | 'id'>
      )>, paymentStatus?: Maybe<(
        { __typename?: 'StatusPayment' }
        & Pick<StatusPayment, 'explain' | 'id' | 'name'>
      )> }
    )>>> }
  )> }
);

export type GetB2BShipmentPointsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type GetB2BShipmentPointsQuery = (
  { __typename?: 'Query' }
  & { getB2BShipmentPoints?: Maybe<(
    { __typename?: 'ListB2BShipmentPoints' }
    & Pick<ListB2BShipmentPoints, 'count'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'B2BShipmentPoint' }
      & Pick<B2BShipmentPoint, 'description' | 'id' | 'name'>
    )>>> }
  )> }
);

export type GetB2BShipmentServicesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type GetB2BShipmentServicesQuery = (
  { __typename?: 'Query' }
  & { getB2BShipmentServices?: Maybe<(
    { __typename?: 'ListB2BShipmentServices' }
    & Pick<ListB2BShipmentServices, 'count'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'B2BServices' }
      & Pick<B2BServices, 'costInUSD' | 'description' | 'id' | 'title'>
    )>>> }
  )> }
);

export type GetCitiesByCountryIsoQueryVariables = Exact<{
  iso: Scalars['String'];
}>;


export type GetCitiesByCountryIsoQuery = (
  { __typename?: 'Query' }
  & { getCitiesByCountryIso?: Maybe<Array<Maybe<(
    { __typename?: 'CityInfo' }
    & Pick<CityInfo, 'name'>
  )>>> }
);

export type GetConfigurationListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConfigurationListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getConfigurationList'>
);

export type GetConsolidationByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetConsolidationByIdQuery = (
  { __typename?: 'Query' }
  & { getConsolidationById?: Maybe<(
    { __typename?: 'Consolidation' }
    & ConsolidationFragmentFragment
  )> }
);

export type GetConsolidationsDeliveryVariantsQueryVariables = Exact<{
  data: ShipmentDataRate;
}>;


export type GetConsolidationsDeliveryVariantsQuery = (
  { __typename?: 'Query' }
  & { getConsolidationsDeliveryVariants?: Maybe<(
    { __typename?: 'AdminConsolidationRateResponse' }
    & Pick<AdminConsolidationRateResponse, 'weight' | 'volume_weight' | 'weight_to_pay'>
    & { variants?: Maybe<Array<Maybe<(
      { __typename?: 'RateUserAccount' }
      & Pick<RateUserAccount, 'name' | 'summary_amount' | 'typeDelivery' | 'error' | 'accountId'>
    )>>> }
  )> }
);

export type GetConsolidationsListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConsolidationsListQuery = (
  { __typename?: 'Query' }
  & { getConsolidationsList?: Maybe<(
    { __typename?: 'ConsolidationList' }
    & Pick<ConsolidationList, 'count'>
    & { consolidations?: Maybe<Array<Maybe<(
      { __typename?: 'Consolidation' }
      & ConsolidationFragmentFragment
    )>>> }
  )> }
);

export type GetContactQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetContactQuery = (
  { __typename?: 'Query' }
  & { contact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'email' | 'eoriCode' | 'contactName' | 'innKppCode' | 'remark' | 'type'>
    & { country?: Maybe<(
      { __typename?: 'DeliveryCountry' }
      & FragmentDeliveryCountryFragment
    )> }
  )> }
);

export type GetContactsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContactsQuery = (
  { __typename?: 'Query' }
  & { contacts?: Maybe<Array<Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'email' | 'innKppCode' | 'remark' | 'eoriCode' | 'contactName' | 'type'>
    & { country?: Maybe<(
      { __typename?: 'DeliveryCountry' }
      & FragmentDeliveryCountryFragment
    )> }
  )>>> }
);

export type GetCourierListQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type GetCourierListQuery = (
  { __typename?: 'Query' }
  & { getCourierList?: Maybe<(
    { __typename?: 'CourierList' }
    & Pick<CourierList, 'count'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'CourierResponse' }
      & Pick<CourierResponse, 'id' | 'pickupDate' | 'readyByTime' | 'closeTime' | 'contactName' | 'contactPhone' | 'packageLocation' | 'confirmationNumber' | 'errors'>
      & { pieces?: Maybe<Array<Maybe<(
        { __typename?: 'Piece' }
        & Pick<Piece, 'weight' | 'width' | 'height' | 'length'>
      )>>> }
    )>>> }
  )> }
);

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'status' | 'createdAt' | 'updatedAt' | 'role' | 'whFeePersonalDiscount'>
    & { country?: Maybe<(
      { __typename?: 'DeliveryCountry' }
      & FragmentDeliveryCountryFragment
    )>, passportData?: Maybe<(
      { __typename?: 'UserPasport' }
      & FragmentUserPasportFragment
    )>, entityLegalData?: Maybe<(
      { __typename?: 'EntityLegalData' }
      & Pick<EntityLegalData, 'id' | 'company' | 'inn' | 'kpp' | 'MSRN' | 'generalManager' | 'legalAddress' | 'nameOfTheBank' | 'BIK' | 'paymentAccount' | 'correspondentAccount' | 'fileName' | 'userId' | 'isDataVerified'>
    )>, warehouse?: Maybe<(
      { __typename?: 'Warehouse' }
      & Pick<Warehouse, 'id' | 'code'>
    )> }
  )> }
);

export type GetDhlShipmentListQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type GetDhlShipmentListQuery = (
  { __typename?: 'Query' }
  & { getDHLShipmentList?: Maybe<(
    { __typename?: 'ShipmentResponseList' }
    & Pick<ShipmentResponseList, 'count'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentDataResponse' }
      & Pick<ShipmentDataResponse, 'id' | 'airwayBillNumber' | 'status' | 'senderAccountId' | 'parcelId' | 'price' | 'createdAt' | 'updatedAt' | 'waybill'>
    )>>> }
  )> }
);

export type GetDeliveryCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDeliveryCountriesQuery = (
  { __typename?: 'Query' }
  & { deliveryCountries?: Maybe<Array<Maybe<(
    { __typename?: 'DeliveryCountry' }
    & Pick<DeliveryCountry, 'id' | 'order' | 'name' | 'daysToDelivery' | 'zoneId' | 'nameEng' | 'iso' | 'customsInfo'>
  )>>> }
);

export type GetListOfDeliveryOptionsQueryVariables = Exact<{
  input?: Maybe<CalculationInput>;
}>;


export type GetListOfDeliveryOptionsQuery = (
  { __typename?: 'Query' }
  & { getListOfDeliveryOptions?: Maybe<(
    { __typename?: 'CalculatorResponse' }
    & Pick<CalculatorResponse, 'weight' | 'volume_weight' | 'weight_to_pay' | 'special_tax' | 'insurance'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'CalculatorResponseData' }
      & Pick<CalculatorResponseData, 'name' | 'success' | 'days_to_delivery' | 'summary_amount' | 'remoteArea' | 'typeDelivery' | 'fuelSurcharge' | 'dollarRate' | 'errors' | 'rateId' | 'shipmentId' | 'priceCurrency'>
    )>>> }
  )> }
);

export type GetListOfReturnedShipmentsQueryVariables = Exact<{
  filter?: Maybe<WarehouseShipmentFilter>;
}>;


export type GetListOfReturnedShipmentsQuery = (
  { __typename?: 'Query' }
  & { getListOfReturnedShipments?: Maybe<(
    { __typename?: 'WarehouseShipmentsList' }
    & Pick<WarehouseShipmentsList, 'count'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'WarehouseShipment' }
      & Pick<WarehouseShipment, 'id' | 'createdAt' | 'trackNumber' | 'carrierCode'>
      & { warehouse?: Maybe<(
        { __typename?: 'Warehouse' }
        & Pick<Warehouse, 'id' | 'address' | 'code'>
      )>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name' | 'address' | 'city'>
        & { country?: Maybe<(
          { __typename?: 'DeliveryCountry' }
          & Pick<DeliveryCountry, 'name'>
        )> }
      )>, deliveryStatus?: Maybe<(
        { __typename?: 'StatusDelivery' }
        & Pick<StatusDelivery, 'id' | 'name' | 'explain'>
      )>, events?: Maybe<Array<Maybe<(
        { __typename?: 'WarehouseShipmentEvent' }
        & Pick<WarehouseShipmentEvent, 'operation'>
        & { manager?: Maybe<(
          { __typename?: 'Manager' }
          & Pick<Manager, 'name'>
        )> }
      )>>> }
    )>>> }
  )> }
);

export type GetManagersQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type GetManagersQuery = (
  { __typename?: 'Query' }
  & { getManagers?: Maybe<(
    { __typename?: 'ManagerList' }
    & Pick<ManagerList, 'count'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'Manager' }
      & { warehouse?: Maybe<(
        { __typename?: 'Warehouse' }
        & Pick<Warehouse, 'id' | 'code'>
      )> }
      & ManagerFragmentFragment
    )>>> }
  )> }
);

export type GetMyProductByIdQueryVariables = Exact<{
  productId: Scalars['Int'];
}>;


export type GetMyProductByIdQuery = (
  { __typename?: 'Query' }
  & { getMyProductById?: Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'sku' | 'detailsDescriptionRU' | 'detailsDescriptionEN' | 'state' | 'trademark' | 'declaredValue' | 'netWeight' | 'code' | 'userId' | 'fnsku' | 'upc' | 'createdAt' | 'barcodeId' | 'monthlyWarehouseStorageFee' | 'isCustomBarcodeId' | 'currency'>
    & { country?: Maybe<(
      { __typename?: 'DeliveryCountry' }
      & Pick<DeliveryCountry, 'id' | 'nameEng'>
    )>, actualDimensions?: Maybe<(
      { __typename?: 'CargoPlace' }
      & Pick<CargoPlace, 'id' | 'warehouseShipmentId' | 'weight' | 'length' | 'width' | 'height'>
    )>, declaredDimensions?: Maybe<(
      { __typename?: 'CargoPlace' }
      & Pick<CargoPlace, 'id' | 'warehouseShipmentId' | 'weight' | 'length' | 'width' | 'height'>
    )>, productAvailabilityQuantity?: Maybe<(
      { __typename?: 'AvailableWarehouseProduct' }
      & Pick<AvailableWarehouseProduct, 'inTransitQuantity' | 'reservedQuantity' | 'availableQuantity'>
    )> }
  )> }
);

export type GetMyProductListQueryVariables = Exact<{
  warehouseId?: Maybe<Scalars['ID']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<ProductSort>;
}>;


export type GetMyProductListQuery = (
  { __typename?: 'Query' }
  & { getMyProductList?: Maybe<(
    { __typename?: 'ProductList' }
    & Pick<ProductList, 'count'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'sku' | 'detailsDescriptionRU' | 'detailsDescriptionEN' | 'state' | 'trademark' | 'declaredValue' | 'netWeight' | 'code' | 'userId' | 'fnsku' | 'upc' | 'barcodeId' | 'createdAt' | 'isCustomBarcodeId' | 'currency'>
      & { country?: Maybe<(
        { __typename?: 'DeliveryCountry' }
        & FragmentDeliveryCountryFragment
      )>, actualDimensions?: Maybe<(
        { __typename?: 'CargoPlace' }
        & Pick<CargoPlace, 'id' | 'warehouseShipmentId' | 'weight' | 'length' | 'width' | 'height'>
      )>, declaredDimensions?: Maybe<(
        { __typename?: 'CargoPlace' }
        & Pick<CargoPlace, 'id' | 'warehouseShipmentId' | 'weight' | 'length' | 'width' | 'height'>
      )>, productAvailabilityQuantity?: Maybe<(
        { __typename?: 'AvailableWarehouseProduct' }
        & Pick<AvailableWarehouseProduct, 'inTransitQuantity' | 'reservedQuantity' | 'availableQuantity'>
      )> }
    )>>> }
  )> }
);

export type GetNotificationListQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type GetNotificationListQuery = (
  { __typename?: 'Query' }
  & { getNotificationList?: Maybe<(
    { __typename?: 'NotificationRowsWithCount' }
    & Pick<NotificationRowsWithCount, 'count'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'Notification' }
      & Pick<Notification, 'data' | 'isRead' | 'text' | 'sendAt' | 'topic'>
    )>>> }
  )> }
);

export type ParcelQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ParcelQuery = (
  { __typename?: 'Query' }
  & { parcel: (
    { __typename?: 'Parcel' }
    & Pick<Parcel, 'id' | 'fileTokenInvoiceXlsx' | 'senderNotes' | 'senderMark' | 'additionalInsurance' | 'declaredAmount' | 'declaredWeight' | 'actualAmount' | 'actualWeight' | 'trackNumber' | 'carrierCode' | 'deliveryStatusId' | 'paymentStatusId' | 'currency' | 'sentToWarehouseId' | 'exportReason' | 'paymentOfTaxes' | 'vatKey' | 'vatID' | 'isConsolidationPiece' | 'barcode' | 'pathNameFile' | 'orderCostCurrency' | 'createdAt' | 'warehouseId'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )>, sender: (
      { __typename?: 'ParcelContact' }
      & Pick<ParcelContact, 'id' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'email' | 'innKppCode' | 'remark'>
      & { country?: Maybe<(
        { __typename?: 'DeliveryCountry' }
        & FragmentDeliveryCountryFragment
      )> }
    ), receiver: (
      { __typename?: 'ParcelContact' }
      & Pick<ParcelContact, 'id' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'email' | 'innKppCode' | 'remark' | 'eoriCode'>
      & { country?: Maybe<(
        { __typename?: 'DeliveryCountry' }
        & FragmentDeliveryCountryFragment
      )> }
    ), packages: Array<Maybe<(
      { __typename?: 'Package' }
      & Pick<Package, 'id' | 'weightKg' | 'lengthCm' | 'widthCm' | 'heightCm'>
      & { units?: Maybe<Array<Maybe<(
        { __typename?: 'ParcelUnit' }
        & Pick<ParcelUnit, 'id' | 'nameRU' | 'nameEN' | 'tradeMark' | 'code' | 'quantity' | 'price' | 'netWeight' | 'state'>
        & { country?: Maybe<(
          { __typename?: 'DeliveryCountry' }
          & FragmentDeliveryCountryFragment
        )> }
      )>>> }
    )>>, typeDelivery?: Maybe<(
      { __typename?: 'TypeDelivery' }
      & Pick<TypeDelivery, 'id' | 'name' | 'serviceName'>
    )>, consolidationTrackNumber?: Maybe<(
      { __typename?: 'consolidationTrackNumberObject' }
      & Pick<ConsolidationTrackNumberObject, 'service' | 'consolidationTrackNumber'>
    )>, warehouse?: Maybe<(
      { __typename?: 'Warehouse' }
      & Pick<Warehouse, 'id' | 'code' | 'address' | 'address2' | 'company' | 'phone' | 'managerName' | 'city' | 'zipCode' | 'state' | 'email'>
      & { country?: Maybe<(
        { __typename?: 'DeliveryCountry' }
        & FragmentDeliveryCountryFragment
      )> }
    )> }
  ) }
);

export type ParcelsQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Int']>;
  paymentStatusId?: Maybe<Scalars['Int']>;
  deliveryStatusId?: Maybe<Scalars['Int']>;
  isUserAccount?: Maybe<Scalars['Boolean']>;
  convert_to_usd?: Maybe<Scalars['Boolean']>;
}>;


export type ParcelsQuery = (
  { __typename?: 'Query' }
  & { parcels: (
    { __typename?: 'ParcelPaginationResponse' }
    & Pick<ParcelPaginationResponse, 'count'>
    & { rows: Array<Maybe<(
      { __typename?: 'Parcel' }
      & Pick<Parcel, 'id' | 'currency' | 'sentToWarehouseId' | 'fileTokenInvoiceXlsx' | 'senderNotes' | 'senderMark' | 'exportReason' | 'paymentOfTaxes' | 'vatID' | 'additionalInsurance' | 'declaredWeight' | 'actualWeight' | 'declaredAmount' | 'actualAmount' | 'trackNumber' | 'carrierCode' | 'deliveryStatusId' | 'paymentStatusId' | 'pathNameFile' | 'isConsolidationPiece' | 'barcode' | 'orderCostCurrency' | 'createdAt'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'senderAccount'>
      )>, sender: (
        { __typename?: 'ParcelContact' }
        & Pick<ParcelContact, 'id' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'email' | 'innKppCode' | 'remark' | 'eoriCode'>
        & { country?: Maybe<(
          { __typename?: 'DeliveryCountry' }
          & FragmentDeliveryCountryFragment
        )> }
      ), receiver: (
        { __typename?: 'ParcelContact' }
        & Pick<ParcelContact, 'id' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'email' | 'innKppCode' | 'remark' | 'eoriCode'>
        & { country?: Maybe<(
          { __typename?: 'DeliveryCountry' }
          & FragmentDeliveryCountryFragment
        )> }
      ), packages: Array<Maybe<(
        { __typename?: 'Package' }
        & Pick<Package, 'id' | 'weightKg' | 'lengthCm' | 'widthCm' | 'heightCm'>
        & { units?: Maybe<Array<Maybe<(
          { __typename?: 'ParcelUnit' }
          & Pick<ParcelUnit, 'id' | 'nameRU' | 'nameEN' | 'tradeMark' | 'code' | 'quantity' | 'price' | 'netWeight'>
          & { country?: Maybe<(
            { __typename?: 'DeliveryCountry' }
            & FragmentDeliveryCountryFragment
          )> }
        )>>> }
      )>>, typeDelivery?: Maybe<(
        { __typename?: 'TypeDelivery' }
        & Pick<TypeDelivery, 'id' | 'name' | 'serviceName'>
      )> }
    )>> }
  ) }
);

export type GetParcelsByTypeQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Int']>;
  type?: Maybe<ParcelType>;
  deliveryStatusId?: Maybe<Scalars['Int']>;
  paymentStatusId?: Maybe<Scalars['Int']>;
}>;


export type GetParcelsByTypeQuery = (
  { __typename?: 'Query' }
  & { getParcelsByType?: Maybe<(
    { __typename?: 'ShipmentOrderList' }
    & Pick<ShipmentOrderList, 'count'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'Parcel' }
      & Pick<Parcel, 'id' | 'fileTokenInvoiceXlsx' | 'senderNotes' | 'senderMark' | 'additionalInsurance' | 'declaredAmount' | 'declaredWeight' | 'actualAmount' | 'actualWeight' | 'trackNumber' | 'carrierCode' | 'deliveryStatusId' | 'paymentStatusId' | 'pathNameFile' | 'isConsolidationPiece' | 'barcode' | 'orderCostCurrency' | 'createdAt' | 'warehouseId'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'senderAccount'>
      )>, sender: (
        { __typename?: 'ParcelContact' }
        & Pick<ParcelContact, 'id' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'email' | 'innKppCode' | 'remark' | 'eoriCode'>
        & { country?: Maybe<(
          { __typename?: 'DeliveryCountry' }
          & FragmentDeliveryCountryFragment
        )> }
      ), receiver: (
        { __typename?: 'ParcelContact' }
        & Pick<ParcelContact, 'id' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'email' | 'innKppCode' | 'remark' | 'eoriCode'>
        & { country?: Maybe<(
          { __typename?: 'DeliveryCountry' }
          & FragmentDeliveryCountryFragment
        )> }
      ), packages: Array<Maybe<(
        { __typename?: 'Package' }
        & Pick<Package, 'id' | 'weightKg' | 'lengthCm' | 'widthCm' | 'heightCm'>
        & { units?: Maybe<Array<Maybe<(
          { __typename?: 'ParcelUnit' }
          & Pick<ParcelUnit, 'id' | 'nameRU' | 'nameEN' | 'tradeMark' | 'code' | 'quantity' | 'price' | 'netWeight' | 'state'>
          & { country?: Maybe<(
            { __typename?: 'DeliveryCountry' }
            & FragmentDeliveryCountryFragment
          )> }
        )>>> }
      )>>, typeDelivery?: Maybe<(
        { __typename?: 'TypeDelivery' }
        & Pick<TypeDelivery, 'id' | 'name' | 'serviceName'>
      )>, warehouse?: Maybe<(
        { __typename?: 'Warehouse' }
        & Pick<Warehouse, 'id' | 'code' | 'address' | 'address2' | 'company' | 'phone' | 'managerName' | 'city' | 'zipCode' | 'state' | 'email'>
        & { country?: Maybe<(
          { __typename?: 'DeliveryCountry' }
          & FragmentDeliveryCountryFragment
        )> }
      )> }
    )>>> }
  )> }
);

export type GetPaymentHistoryListByUserIdQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
}>;


export type GetPaymentHistoryListByUserIdQuery = (
  { __typename?: 'Query' }
  & { getPaymentHistoryListByUserId?: Maybe<(
    { __typename?: 'TransactionList' }
    & Pick<TransactionList, 'count' | 'name' | 'email' | 'address' | 'address2' | 'address3' | 'phone' | 'balance' | 'state' | 'zipCode'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id' | 'userId' | 'transactionId' | 'parcelId' | 'amount' | 'currency' | 'confirmationUrl' | 'paymentType' | 'status' | 'createdAt' | 'updatedAt' | 'cancellationReason' | 'cdekParcelId' | 'reason' | 'balance' | 'paymentStatus'>
      & { parcel?: Maybe<(
        { __typename?: 'Parcel' }
        & Pick<Parcel, 'id'>
      )>, warehouseShipment?: Maybe<(
        { __typename?: 'WarehouseShipment' }
        & Pick<WarehouseShipment, 'id' | 'barcode'>
      )>, warehouseShipmentOrder?: Maybe<(
        { __typename?: 'WarehouseShipmentOrder' }
        & Pick<WarehouseShipmentOrder, 'id' | 'barcodeId'>
      )>, returnShipment?: Maybe<(
        { __typename?: 'ReturnShipment' }
        & Pick<ReturnShipment, 'barcodeId' | 'id'>
      )>, undeclaredShipment?: Maybe<(
        { __typename?: 'UndeclaredShipment' }
        & Pick<UndeclaredShipment, 'id'>
      )>, b2bOrder?: Maybe<(
        { __typename?: 'B2BOrder' }
        & Pick<B2BOrder, 'barcodeId' | 'id'>
      )> }
    )>>>, country?: Maybe<(
      { __typename?: 'DeliveryCountry' }
      & FragmentDeliveryCountryFragment
    )> }
  )> }
);

export type GetQuoteQueryVariables = Exact<{
  input?: Maybe<QuoteInput>;
}>;


export type GetQuoteQuery = (
  { __typename?: 'Query' }
  & { getQuote?: Maybe<(
    { __typename?: 'QuoteResponse' }
    & Pick<QuoteResponse, 'errors'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'GlobalProductCode' | 'LocalProductCode' | 'ProductShortName' | 'LocalProductName' | 'NetworkTypeCode' | 'POfferedCustAgreement' | 'TransInd' | 'PickupDate' | 'PickupCutoffTime' | 'BookingTime' | 'ExchangeRate' | 'WeightCharge' | 'WeightChargeTax' | 'TotalTransitDays' | 'PickupPostalLocAddDays' | 'DeliveryPostalLocAddDays' | 'DeliveryTime' | 'DimensionalWeight' | 'WeightUnit' | 'PickupDayOfWeekNum' | 'DestinationDayOfWeekNum' | 'QuotedWeight' | 'QuotedWeightUOM' | 'PricingDate' | 'ShippingCharge' | 'TotalTaxAmount' | 'PickupWindowEarliestTime' | 'PickupWindowLatestTime' | 'BookingCutoffOffset'>
      & { OriginServiceArea?: Maybe<(
        { __typename?: 'OriginServiceAreaInfo' }
        & Pick<OriginServiceAreaInfo, 'FacilityCode' | 'ServiceAreaCode'>
      )>, DestinationServiceArea?: Maybe<(
        { __typename?: 'OriginServiceAreaInfo' }
        & Pick<OriginServiceAreaInfo, 'FacilityCode' | 'ServiceAreaCode'>
      )>, DeliveryDate?: Maybe<(
        { __typename?: 'DeliveryDateInfo' }
        & Pick<DeliveryDateInfo, 'DeliveryType' | 'DlvyDateTime' | 'DeliveryDateTimeOffset'>
      )>, QtdSInAdCur?: Maybe<Array<Maybe<(
        { __typename?: 'QtdSInAdCurInfo' }
        & Pick<QtdSInAdCurInfo, 'CurrencyCode' | 'CurrencyRoleTypeCode' | 'WeightCharge' | 'TotalAmount' | 'TotalTaxAmount' | 'WeightChargeTax'>
      )>>> }
    )>>> }
  )> }
);

export type GetReportsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type GetReportsQuery = (
  { __typename?: 'Query' }
  & { getReports?: Maybe<(
    { __typename?: 'ReportList' }
    & Pick<ReportList, 'count'>
    & { rows: Array<Maybe<(
      { __typename?: 'Report' }
      & Pick<Report, 'id' | 'userId' | 'type' | 'from' | 'to' | 'createdAt' | 'fileToken' | 'productBarcode' | 'warehouseCode'>
    )>> }
  )> }
);

export type GetReturnShipmentInfoQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetReturnShipmentInfoQuery = (
  { __typename?: 'Query' }
  & { getReturnShipmentInfo?: Maybe<(
    { __typename?: 'ReturnShipment' }
    & Pick<ReturnShipment, 'id' | 'barcodeId' | 'warehouse' | 'cost' | 'trackNumber' | 'carrierCode' | 'warehouseStatus' | 'createdAt' | 'type' | 'arrivalDate' | 'shipmentOrderBarcodeId' | 'shipmentOrderId'>
    & { attachments?: Maybe<Array<Maybe<(
      { __typename?: 'ReturnShipmentAttachment' }
      & Pick<ReturnShipmentAttachment, 'id' | 'filePath'>
    )>>>, products?: Maybe<Array<Maybe<(
      { __typename?: 'ReturnShipmentItem' }
      & Pick<ReturnShipmentItem, 'productId' | 'productBarcode' | 'productSku' | 'productName' | 'acceptedQuantity' | 'expectedQuantity'>
    )>>>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )> }
  )> }
);

export type GetReturnShipmentsListQueryVariables = Exact<{
  filter?: Maybe<ReturnShipmentFilter>;
  search?: Maybe<Scalars['String']>;
}>;


export type GetReturnShipmentsListQuery = (
  { __typename?: 'Query' }
  & { getReturnShipmentsList?: Maybe<(
    { __typename?: 'ReturnShipmentsList' }
    & Pick<ReturnShipmentsList, 'count'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'ReturnShipmentListItem' }
      & Pick<ReturnShipmentListItem, 'id' | 'barcodeId' | 'shipmentOrderId' | 'shipmentOrderBarcodeId' | 'warehouse' | 'cost' | 'trackNumber' | 'carrierCode' | 'warehouseStatus' | 'acceptedQuantityItem' | 'createdAt'>
    )>>> }
  )> }
);

export type GetSenderAccountByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetSenderAccountByIdQuery = (
  { __typename?: 'Query' }
  & { getSenderAccountById?: Maybe<(
    { __typename?: 'SenderAccount' }
    & Pick<SenderAccount, 'id' | 'accountName' | 'status' | 'accountNumber' | 'password' | 'siteId' | 'TaxIdentificationNumber' | 'attentionName' | 'clientIdFEDEX' | 'clientSecretFEDEX' | 'userNameUPS' | 'userPasswordUPS' | 'accessLicenseNumberUPS' | 'requestorName' | 'requestorPhone' | 'requestorAddress' | 'requestorCity' | 'requestorCountryCode' | 'requestorPostalCode' | 'serviceName' | 'isTestMode' | 'easypostTestApiKey' | 'easypostProdApiKey'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'eoriCode' | 'status' | 'createdAt' | 'updatedAt' | 'balance' | 'discount'>
      & { country?: Maybe<(
        { __typename?: 'DeliveryCountry' }
        & FragmentDeliveryCountryFragment
      )> }
    )> }
  )> }
);

export type GetSenderAccountListQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type GetSenderAccountListQuery = (
  { __typename?: 'Query' }
  & { getSenderAccountList?: Maybe<(
    { __typename?: 'ListOfSenderAccounts' }
    & Pick<ListOfSenderAccounts, 'count'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'SenderAccount' }
      & Pick<SenderAccount, 'id' | 'accountName' | 'status' | 'accountNumber' | 'password' | 'siteId' | 'TaxIdentificationNumber' | 'attentionName' | 'clientIdFEDEX' | 'clientSecretFEDEX' | 'userNameUPS' | 'userPasswordUPS' | 'accessLicenseNumberUPS' | 'requestorName' | 'requestorPhone' | 'requestorAddress' | 'requestorCity' | 'requestorCountryCode' | 'requestorPostalCode' | 'serviceName' | 'isTestMode'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'eoriCode' | 'status' | 'createdAt' | 'updatedAt' | 'balance' | 'discount'>
        & { country?: Maybe<(
          { __typename?: 'DeliveryCountry' }
          & FragmentDeliveryCountryFragment
        )> }
      )> }
    )>>> }
  )> }
);

export type GetShipmentBarcodeListQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetShipmentBarcodeListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getShipmentBarcodeList'>
);

export type GetStatusesListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStatusesListQuery = (
  { __typename?: 'Query' }
  & { getStatusesList?: Maybe<(
    { __typename?: 'Statuses' }
    & { DeliveryStatusList?: Maybe<Array<Maybe<(
      { __typename?: 'StatusDelivery' }
      & Pick<StatusDelivery, 'id' | 'name' | 'explain'>
    )>>>, PaymentStatusList?: Maybe<Array<Maybe<(
      { __typename?: 'StatusPayment' }
      & Pick<StatusPayment, 'id' | 'name' | 'explain'>
    )>>> }
  )> }
);

export type GetTariffsListQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type GetTariffsListQuery = (
  { __typename?: 'Query' }
  & { getTariffsList?: Maybe<(
    { __typename?: 'TariffList' }
    & Pick<TariffList, 'count'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'Tariff' }
      & Pick<Tariff, 'id' | 'zoneId' | 'weight' | 'price'>
    )>>> }
  )> }
);

export type GetTheExchangeRateQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTheExchangeRateQuery = (
  { __typename?: 'Query' }
  & { getTheExchangeRate?: Maybe<(
    { __typename?: 'ExchangeRateResponse' }
    & Pick<ExchangeRateResponse, 'lastUpdate'>
    & { exchangeRate?: Maybe<Array<Maybe<(
      { __typename?: 'ExchangeRate' }
      & Pick<ExchangeRate, 'code' | 'nominal' | 'value'>
    )>>> }
  )> }
);

export type GetTransactionTypesListQueryVariables = Exact<{
  operation?: Maybe<Availability_Operation>;
}>;


export type GetTransactionTypesListQuery = (
  { __typename?: 'Query' }
  & { getTransactionTypesList?: Maybe<Array<Maybe<(
    { __typename?: 'TransactionTypeItem' }
    & Pick<TransactionTypeItem, 'id' | 'paymentType'>
  )>>> }
);

export type GetUndeclaredShipmentInfoQueryVariables = Exact<{
  getUndeclaredShipmentInfoId: Scalars['Int'];
}>;


export type GetUndeclaredShipmentInfoQuery = (
  { __typename?: 'Query' }
  & { getUndeclaredShipmentInfo?: Maybe<(
    { __typename?: 'UndeclaredShipment' }
    & Pick<UndeclaredShipment, 'id' | 'createdAt' | 'updatedAt' | 'warehouse' | 'trackNumber' | 'carrierCode' | 'cost' | 'note' | 'warehouseStatus'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )>, attachments?: Maybe<Array<Maybe<(
      { __typename?: 'UndeclaredShipmentAttachment' }
      & Pick<UndeclaredShipmentAttachment, 'filePath' | 'id'>
    )>>>, items?: Maybe<Array<Maybe<(
      { __typename?: 'UndeclaredShipmentItem' }
      & Pick<UndeclaredShipmentItem, 'productId' | 'quantity'>
      & { product?: Maybe<(
        { __typename?: 'Product' }
        & Pick<Product, 'barcodeId' | 'sku' | 'name'>
      )> }
    )>>> }
  )> }
);

export type GetUndeclaredShipmentsListQueryVariables = Exact<{
  filter?: Maybe<UndeclaredShipmentFilter>;
  search?: Maybe<Scalars['String']>;
}>;


export type GetUndeclaredShipmentsListQuery = (
  { __typename?: 'Query' }
  & { getUndeclaredShipmentsList?: Maybe<(
    { __typename?: 'UndeclaredShipmentsList' }
    & Pick<UndeclaredShipmentsList, 'count'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'UndeclaredShipmentListItem' }
      & Pick<UndeclaredShipmentListItem, 'carrierCode' | 'cost' | 'createdAt' | 'id' | 'note' | 'quantityItems' | 'trackNumber' | 'warehouse' | 'warehouseStatus'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name'>
      )> }
    )>>> }
  )> }
);

export type GetUserQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'eoriCode' | 'status' | 'createdAt' | 'updatedAt' | 'discount' | 'balance' | 'creditLimit' | 'whFeePersonalDiscount'>
    & { country?: Maybe<(
      { __typename?: 'DeliveryCountry' }
      & FragmentDeliveryCountryFragment
    )>, passportData?: Maybe<(
      { __typename?: 'UserPasport' }
      & FragmentUserPasportFragment
    )>, entityLegalData?: Maybe<(
      { __typename?: 'EntityLegalData' }
      & Pick<EntityLegalData, 'id' | 'company' | 'inn' | 'kpp' | 'MSRN' | 'generalManager' | 'legalAddress' | 'nameOfTheBank' | 'BIK' | 'paymentAccount' | 'correspondentAccount' | 'fileName' | 'userId' | 'isDataVerified'>
    )> }
  )> }
);

export type GetUserDiscountQueryVariables = Exact<{
  userId?: Maybe<Scalars['Int']>;
}>;


export type GetUserDiscountQuery = (
  { __typename?: 'Query' }
  & { getUserDiscount?: Maybe<(
    { __typename?: 'Discount' }
    & Pick<Discount, 'id' | 'userId' | 'creditLimit' | 'orderHandlingDiscountPercent' | 'warehouseStorageDiscountPercent' | 'forwardingDiscountPercent' | 'returnHandlingDiscountPercent' | 'inboundDiscountPercent' | 'undeclaredInboundDiscountPercent'>
  )> }
);

export type UsersQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  order?: Maybe<UserOrder>;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'UserPaginationResponse' }
    & Pick<UserPaginationResponse, 'count'>
    & { rows: Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'eoriCode' | 'status' | 'createdAt' | 'updatedAt' | 'discount' | 'balance' | 'creditLimit' | 'whFeePersonalDiscount'>
      & { country?: Maybe<(
        { __typename?: 'DeliveryCountry' }
        & FragmentDeliveryCountryFragment
      )>, passportData?: Maybe<(
        { __typename?: 'UserPasport' }
        & FragmentUserPasportFragment
      )>, entityLegalData?: Maybe<(
        { __typename?: 'EntityLegalData' }
        & Pick<EntityLegalData, 'isDataVerified' | 'company' | 'id'>
      )> }
    )>> }
  ) }
);

export type GetWarehouseProductLogQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  barcodeId?: Maybe<Scalars['String']>;
}>;


export type GetWarehouseProductLogQuery = (
  { __typename?: 'Query' }
  & { getWarehouseProductLog?: Maybe<(
    { __typename?: 'WarehouseProductLogList' }
    & Pick<WarehouseProductLogList, 'count'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'WarehouseProductLog' }
      & Pick<WarehouseProductLog, 'id' | 'productId' | 'productBarcodeId' | 'fromCellId' | 'fromCellName' | 'toCellId' | 'toCellName' | 'operation' | 'quantity' | 'shipmentId' | 'comment' | 'createdAt'>
    )>>> }
  )> }
);

export type GetWarehouseProductLogByProductIdQueryVariables = Exact<{
  warehouseId: Scalars['ID'];
  productId: Scalars['ID'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetWarehouseProductLogByProductIdQuery = (
  { __typename?: 'Query' }
  & { getWarehouseProductLogByProductId?: Maybe<(
    { __typename?: 'WarehouseProductLogList' }
    & Pick<WarehouseProductLogList, 'count'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'WarehouseProductLog' }
      & Pick<WarehouseProductLog, 'id' | 'productId' | 'productBarcodeId' | 'operation' | 'quantity' | 'shipmentId' | 'comment' | 'createdAt'>
    )>>> }
  )> }
);

export type GetWarehouseShipmentInfoQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetWarehouseShipmentInfoQuery = (
  { __typename?: 'Query' }
  & { getWarehouseShipmentInfo?: Maybe<(
    { __typename?: 'WarehouseShipment' }
    & Pick<WarehouseShipment, 'id' | 'createdAt' | 'trackNumber' | 'carrierCode' | 'places' | 'expectedQuantity' | 'acceptedQuantity' | 'barcode' | 'warehouseStatus' | 'note' | 'cost'>
    & { events?: Maybe<Array<Maybe<(
      { __typename?: 'WarehouseShipmentEvent' }
      & Pick<WarehouseShipmentEvent, 'id' | 'operation' | 'date'>
      & { warehouse?: Maybe<(
        { __typename?: 'Warehouse' }
        & Pick<Warehouse, 'code'>
      )> }
    )>>>, from?: Maybe<(
      { __typename?: 'ParcelContact' }
      & Pick<ParcelContact, 'id' | 'email' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone'>
      & { country?: Maybe<(
        { __typename?: 'DeliveryCountry' }
        & FragmentDeliveryCountryFragment
      )> }
    )>, warehouse?: Maybe<(
      { __typename?: 'Warehouse' }
      & Pick<Warehouse, 'id' | 'code' | 'address' | 'address2' | 'company' | 'phone' | 'managerName' | 'city' | 'zipCode' | 'state' | 'email'>
      & { country?: Maybe<(
        { __typename?: 'DeliveryCountry' }
        & FragmentDeliveryCountryFragment
      )> }
    )>, deliveryStatus?: Maybe<(
      { __typename?: 'StatusDelivery' }
      & Pick<StatusDelivery, 'id' | 'name' | 'explain'>
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'eoriCode' | 'status' | 'createdAt' | 'updatedAt'>
      & { country?: Maybe<(
        { __typename?: 'DeliveryCountry' }
        & Pick<DeliveryCountry, 'id'>
      )> }
    )>, packages?: Maybe<Array<Maybe<(
      { __typename?: 'CargoPlace' }
      & Pick<CargoPlace, 'id' | 'warehouseShipmentId' | 'weight' | 'length' | 'width' | 'height'>
    )>>>, products?: Maybe<Array<Maybe<(
      { __typename?: 'ProductInParcel' }
      & Pick<ProductInParcel, 'id' | 'quantity' | 'arrivalQuantity'>
      & { product?: Maybe<(
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'sku' | 'netWeight' | 'declaredValue' | 'barcodeId'>
      )> }
    )>>>, parcels?: Maybe<Array<Maybe<(
      { __typename?: 'Parcel' }
      & Pick<Parcel, 'id' | 'barcode' | 'accepted'>
    )>>>, pieces?: Maybe<Array<Maybe<(
      { __typename?: 'WarehouseShipmentPiece' }
      & Pick<WarehouseShipmentPiece, 'accepted' | 'customIdentifier' | 'id' | 'warehouseShipmentId'>
    )>>> }
  )> }
);

export type GetWarehouseShipmentsListQueryVariables = Exact<{
  filter?: Maybe<WarehouseShipmentFilter>;
  search?: Maybe<Scalars['String']>;
}>;


export type GetWarehouseShipmentsListQuery = (
  { __typename?: 'Query' }
  & { getWarehouseShipmentsList?: Maybe<(
    { __typename?: 'WarehouseShipmentsList' }
    & Pick<WarehouseShipmentsList, 'count'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'WarehouseShipment' }
      & Pick<WarehouseShipment, 'id' | 'createdAt' | 'trackNumber' | 'carrierCode' | 'places' | 'expectedQuantity' | 'acceptedQuantity' | 'barcode' | 'warehouseStatus' | 'note'>
      & { events?: Maybe<Array<Maybe<(
        { __typename?: 'WarehouseShipmentEvent' }
        & Pick<WarehouseShipmentEvent, 'id' | 'date' | 'operation'>
      )>>>, warehouse?: Maybe<(
        { __typename?: 'Warehouse' }
        & Pick<Warehouse, 'id' | 'code' | 'address' | 'company' | 'phone'>
      )>, deliveryStatus?: Maybe<(
        { __typename?: 'StatusDelivery' }
        & Pick<StatusDelivery, 'id' | 'name' | 'explain'>
      )>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name'>
      )>, packages?: Maybe<Array<Maybe<(
        { __typename?: 'CargoPlace' }
        & Pick<CargoPlace, 'id'>
      )>>> }
    )>>> }
  )> }
);

export type GetWarehouseShipmentOrderAdditionalOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWarehouseShipmentOrderAdditionalOptionsQuery = (
  { __typename?: 'Query' }
  & { getWarehouseShipmentOrderAdditionalOptions?: Maybe<(
    { __typename?: 'WarehouseShipmentOrderAdditionalOptions' }
    & Pick<WarehouseShipmentOrderAdditionalOptions, 'wrappers' | 'giftMessageCost' | 'giftWrapperCost'>
  )> }
);

export type GetWarehouseShipmentOrderByIdQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type GetWarehouseShipmentOrderByIdQuery = (
  { __typename?: 'Query' }
  & { getWarehouseShipmentOrderById?: Maybe<(
    { __typename?: 'WarehouseShipmentOrder' }
    & Pick<WarehouseShipmentOrder, 'id' | 'trackNumber' | 'carrierCode' | 'carrierService' | 'declaredAmount' | 'actualAmount' | 'additionalInsurance' | 'insuranceAmount' | 'signature' | 'wrap' | 'message' | 'senderMark' | 'vatKey' | 'vatValue' | 'barcodeId' | 'orderCostCurrency' | 'createdAt' | 'currency' | 'giftWrap' | 'type' | 'pathNameFile' | 'easyPostShipmentId' | 'easyPostRateId' | 'preliminaryCost' | 'warehouseStatus' | 'comment'>
    & { receiver?: Maybe<(
      { __typename?: 'ParcelContact' }
      & Pick<ParcelContact, 'id' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'email' | 'innKppCode' | 'remark' | 'eoriCode'>
      & { country?: Maybe<(
        { __typename?: 'DeliveryCountry' }
        & FragmentDeliveryCountryFragment
      )> }
    )>, sender?: Maybe<(
      { __typename?: 'Warehouse' }
      & Pick<Warehouse, 'id' | 'name' | 'company' | 'address' | 'address2' | 'zipCode' | 'city' | 'state' | 'phone' | 'email' | 'code' | 'managerName'>
      & { country?: Maybe<(
        { __typename?: 'DeliveryCountry' }
        & FragmentDeliveryCountryFragment
      )> }
    )>, clientUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'email' | 'eoriCode'>
      & { country?: Maybe<(
        { __typename?: 'DeliveryCountry' }
        & FragmentDeliveryCountryFragment
      )> }
    )>, dimensions?: Maybe<(
      { __typename?: 'CargoPlace' }
      & Pick<CargoPlace, 'id' | 'warehouseShipmentId' | 'weight' | 'length' | 'width' | 'height'>
    )>, deliveryStatus?: Maybe<(
      { __typename?: 'StatusDelivery' }
      & Pick<StatusDelivery, 'id' | 'name' | 'explain'>
    )>, paymentStatus?: Maybe<(
      { __typename?: 'StatusPayment' }
      & Pick<StatusPayment, 'id' | 'name' | 'explain'>
    )>, products?: Maybe<Array<Maybe<(
      { __typename?: 'ShipmentWarehouseProduct' }
      & Pick<ShipmentWarehouseProduct, 'price' | 'quantity'>
      & { relatedProduct?: Maybe<(
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'barcodeId' | 'sku' | 'detailsDescriptionEN' | 'code' | 'netWeight'>
        & { country?: Maybe<(
          { __typename?: 'DeliveryCountry' }
          & Pick<DeliveryCountry, 'id'>
        )>, productAvailabilityQuantity?: Maybe<(
          { __typename?: 'AvailableWarehouseProduct' }
          & Pick<AvailableWarehouseProduct, 'availableQuantity' | 'inTransitQuantity' | 'reservedQuantity'>
        )> }
      )> }
    )>>>, typeDelivery?: Maybe<(
      { __typename?: 'TypeDelivery' }
      & Pick<TypeDelivery, 'id'>
    )>, declaredDimensions?: Maybe<(
      { __typename?: 'CargoPlace' }
      & Pick<CargoPlace, 'id' | 'weight' | 'length' | 'width' | 'height'>
    )> }
  )> }
);

export type GetWarehouseShipmentOrderDeliveryOptionsListQueryVariables = Exact<{
  data?: Maybe<WarehouseShipmentOrderDeliveryOptionsInput>;
}>;


export type GetWarehouseShipmentOrderDeliveryOptionsListQuery = (
  { __typename?: 'Query' }
  & { getWarehouseShipmentOrderDeliveryOptionsList?: Maybe<(
    { __typename?: 'DeliveryOptionsResponseObject' }
    & Pick<DeliveryOptionsResponseObject, 'monthly_pay_amount'>
    & { variants?: Maybe<Array<Maybe<(
      { __typename?: 'DeliveryOptionsResponse' }
      & Pick<DeliveryOptionsResponse, 'name' | 'days_to_delivery' | 'summary_amount' | 'typeDelivery' | 'rateId' | 'shipmentId' | 'lastMileCost' | 'priceCurrency'>
      & { info?: Maybe<(
        { __typename?: 'VariantInfoWarehouseShipmentOrder' }
        & Pick<VariantInfoWarehouseShipmentOrder, 'warehouse_delivery_cost' | 'warehouse_proccesing_cost' | 'last_mile_label_cost'>
      )> }
    )>>> }
  )> }
);

export type GetWarehouseShipmentOrdersQueryVariables = Exact<{
  data?: Maybe<WarehouseShipmentOrdersOptions>;
}>;


export type GetWarehouseShipmentOrdersQuery = (
  { __typename?: 'Query' }
  & { getWarehouseShipmentOrders?: Maybe<(
    { __typename?: 'WarehouseShipmentOrdersList' }
    & Pick<WarehouseShipmentOrdersList, 'count'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'WarehouseShipmentOrder' }
      & Pick<WarehouseShipmentOrder, 'id' | 'trackNumber' | 'carrierCode' | 'carrierService' | 'declaredAmount' | 'actualAmount' | 'additionalInsurance' | 'insuranceAmount' | 'signature' | 'wrap' | 'message' | 'senderMark' | 'pathNameFile' | 'vatKey' | 'vatValue' | 'warehouseStatus' | 'barcodeId' | 'orderCostCurrency' | 'createdAt' | 'comment' | 'giftWrap'>
      & { receiver?: Maybe<(
        { __typename?: 'ParcelContact' }
        & Pick<ParcelContact, 'id' | 'name' | 'city'>
        & { country?: Maybe<(
          { __typename?: 'DeliveryCountry' }
          & FragmentDeliveryCountryFragment
        )> }
      )>, deliveryStatus?: Maybe<(
        { __typename?: 'StatusDelivery' }
        & Pick<StatusDelivery, 'id' | 'name' | 'explain'>
      )>, paymentStatus?: Maybe<(
        { __typename?: 'StatusPayment' }
        & Pick<StatusPayment, 'id' | 'name' | 'explain'>
      )>, products?: Maybe<Array<Maybe<(
        { __typename?: 'ShipmentWarehouseProduct' }
        & Pick<ShipmentWarehouseProduct, 'price' | 'quantity'>
        & { relatedProduct?: Maybe<(
          { __typename?: 'Product' }
          & Pick<Product, 'id' | 'name' | 'barcodeId' | 'sku'>
        )> }
      )>>>, clientUser?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name'>
      )> }
    )>>> }
  )> }
);

export type GetWarehouseZoneByIdQueryVariables = Exact<{
  getWarehouseZoneByIdId: Scalars['ID'];
}>;


export type GetWarehouseZoneByIdQuery = (
  { __typename?: 'Query' }
  & { getWarehouseZoneById?: Maybe<(
    { __typename?: 'WarehouseZone' }
    & Pick<WarehouseZone, 'freeCellsCount' | 'id' | 'isDefault' | 'name' | 'occupiedCellsCount' | 'orderNumber' | 'totalCellsCount'>
  )> }
);

export type GetWarehouseZoneCellByIdQueryVariables = Exact<{
  getWarehouseZoneCellByIdId: Scalars['ID'];
}>;


export type GetWarehouseZoneCellByIdQuery = (
  { __typename?: 'Query' }
  & { getWarehouseZoneCellById?: Maybe<(
    { __typename?: 'WarehouseZoneCell' }
    & Pick<WarehouseZoneCell, 'id' | 'isDefault' | 'name' | 'orderNumber' | 'totalProductsCount' | 'warehouseZoneId'>
  )> }
);

export type GetWarehouseZoneCellProductQueryVariables = Exact<{
  options: GetWarehouseZoneCellProduct;
}>;


export type GetWarehouseZoneCellProductQuery = (
  { __typename?: 'Query' }
  & { getWarehouseZoneCellProduct?: Maybe<(
    { __typename?: 'WarehouseCellProduct' }
    & Pick<WarehouseCellProduct, 'cellName' | 'quantity'>
    & { product?: Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'sku'>
      & { productAvailabilityQuantity?: Maybe<(
        { __typename?: 'AvailableWarehouseProduct' }
        & Pick<AvailableWarehouseProduct, 'availableQuantity' | 'inTransitQuantity' | 'reservedQuantity'>
      )> }
    )> }
  )> }
);

export type GetWarehouseZoneCellsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type GetWarehouseZoneCellsQuery = (
  { __typename?: 'Query' }
  & { getWarehouseZoneCells?: Maybe<(
    { __typename?: 'WarehouseZoneCellsResult' }
    & Pick<WarehouseZoneCellsResult, 'count'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'WarehouseZoneCell' }
      & Pick<WarehouseZoneCell, 'barcode' | 'id' | 'isDefault' | 'name' | 'orderNumber' | 'totalProductsCount' | 'warehouseZoneId'>
    )>>> }
  )> }
);

export type GetWarehouseZoneCellsResiduesQueryVariables = Exact<{
  options: GetWarehouseZoneCellResidues;
}>;


export type GetWarehouseZoneCellsResiduesQuery = (
  { __typename?: 'Query' }
  & { getWarehouseZoneCellsResidues?: Maybe<(
    { __typename?: 'WarehouseCellProductList' }
    & Pick<WarehouseCellProductList, 'count'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'WarehouseCellProduct' }
      & Pick<WarehouseCellProduct, 'cellName' | 'quantity'>
      & { product?: Maybe<(
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'sku' | 'createdAt' | 'barcodeId'>
      )> }
    )>>> }
  )> }
);

export type GetWarehouseZonesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWarehouseZonesQuery = (
  { __typename?: 'Query' }
  & { getWarehouseZones?: Maybe<Array<Maybe<(
    { __typename?: 'WarehouseZone' }
    & Pick<WarehouseZone, 'freeCellsCount' | 'id' | 'isDefault' | 'name' | 'occupiedCellsCount' | 'orderNumber' | 'totalCellsCount'>
  )>>> }
);

export type GetWarehousesListQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
}>;


export type GetWarehousesListQuery = (
  { __typename?: 'Query' }
  & { getWarehousesList?: Maybe<(
    { __typename?: 'WarehousesList' }
    & Pick<WarehousesList, 'count'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'Warehouse' }
      & Pick<Warehouse, 'id' | 'code' | 'address' | 'address2' | 'company' | 'email' | 'phone' | 'zipCode' | 'city' | 'state' | 'name' | 'managerName'>
      & { country?: Maybe<(
        { __typename?: 'DeliveryCountry' }
        & FragmentDeliveryCountryFragment
      )>, availableOperation?: Maybe<Array<Maybe<(
        { __typename?: 'WarehouseOperation' }
        & Pick<WarehouseOperation, 'operation'>
      )>>> }
    )>>> }
  )> }
);

export type TrackParcelQueryVariables = Exact<{
  carrier_code?: Maybe<Scalars['String']>;
  barcode?: Maybe<Scalars['String']>;
}>;


export type TrackParcelQuery = (
  { __typename?: 'Query' }
  & { trackParcel?: Maybe<(
    { __typename?: 'trackParcelResponse' }
    & Pick<TrackParcelResponse, 'carrier_code' | 'track_number' | 'message' | 'error' | 'statusDescription' | 'city' | 'countryISO' | 'currentStep' | 'senderCity' | 'senderCountryISO' | 'senderStateOrProvinceCode' | 'receiverCity' | 'receiverCountryISO' | 'receiverStateOrProvinceCode'>
    & { history?: Maybe<Array<Maybe<(
      { __typename?: 'trackParcelHistory' }
      & Pick<TrackParcelHistory, 'eventName' | 'timestamp'>
    )>>> }
  )> }
);

export type ValidationAddressQueryVariables = Exact<{
  input?: Maybe<ValidationAddress>;
}>;


export type ValidationAddressQuery = (
  { __typename?: 'Query' }
  & { validationAddress?: Maybe<(
    { __typename?: 'ValidationAddressResponse' }
    & { address?: Maybe<(
      { __typename?: 'ValidationField' }
      & Pick<ValidationField, 'inputValue' | 'returnValue'>
    )>, city?: Maybe<(
      { __typename?: 'ValidationField' }
      & Pick<ValidationField, 'inputValue' | 'returnValue'>
    )>, state?: Maybe<(
      { __typename?: 'ValidationField' }
      & Pick<ValidationField, 'inputValue' | 'returnValue'>
    )>, postalCode?: Maybe<(
      { __typename?: 'ValidationField' }
      & Pick<ValidationField, 'inputValue' | 'returnValue'>
    )> }
  )> }
);

export const FragmentDeliveryCountryFragmentDoc = gql`
    fragment FragmentDeliveryCountry on DeliveryCountry {
  id
  order
  name
  daysToDelivery
  zoneId
  nameEng
  iso
  phoneCode
  phoneMin
  phoneMax
  customsInfo
}
    `;
export const ParcelContactFragmentFragmentDoc = gql`
    fragment ParcelContactFragment on ParcelContact {
  id
  name
  company
  country {
    ...FragmentDeliveryCountry
  }
  address
  address2
  address3
  zipCode
  city
  state
  phone
  email
  innKppCode
  remark
  eoriCode
}
    ${FragmentDeliveryCountryFragmentDoc}`;
export const ConsolidationFragmentFragmentDoc = gql`
    fragment ConsolidationFragment on Consolidation {
  id
  serialNumber
  trackNumber
  paymentStatus {
    id
    name
    explain
  }
  deliveryStatus {
    id
    name
    explain
  }
  parcels {
    id
    barcode
    trackNumber
    carrierCode
    accepted
    packages {
      units {
        id
        nameRU
        code
        quantity
        price
        country {
          id
        }
        netWeight
        nameEN
        tradeMark
        state
      }
    }
  }
  sender {
    ...ParcelContactFragment
  }
  receiver {
    ...ParcelContactFragment
  }
  insurance
  shipmentServiceName
  waybillPath
  cargoPlaces
  totalGrossWeight
  totalNetWeight
  totalVolumetricWeight
  totalCostOfEnteties
  totalCost
  date
  senderAccount
  date
  fileTokenInvoiceXlsx
  packages {
    id
    width
    weight
    length
    height
  }
  barcode
}
    ${ParcelContactFragmentFragmentDoc}`;
export const ManagerFragmentFragmentDoc = gql`
    fragment managerFragment on Manager {
  id
  name
  email
  role
}
    `;
export const FragmentUserPasportFragmentDoc = gql`
    fragment FragmentUserPasport on UserPasport {
  id
  firstName
  lastName
  middleName
  birthDay
  seriesAndNumberOfPassport
  dateOfIssueOfTheDocument
  inn
  scanPath
  userId
  isDataVerified
}
    `;
export const AddEventToWarehouseShipmentDocument = gql`
    mutation addEventToWarehouseShipment($warehouseShipmentId: Int!, $operation: WarehouseOperationForUser) {
  addEventToWarehouseShipment(
    warehouseShipmentId: $warehouseShipmentId
    operation: $operation
  ) {
    id
  }
}
    `;
export type AddEventToWarehouseShipmentMutationFn = Apollo.MutationFunction<AddEventToWarehouseShipmentMutation, AddEventToWarehouseShipmentMutationVariables>;

/**
 * __useAddEventToWarehouseShipmentMutation__
 *
 * To run a mutation, you first call `useAddEventToWarehouseShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEventToWarehouseShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEventToWarehouseShipmentMutation, { data, loading, error }] = useAddEventToWarehouseShipmentMutation({
 *   variables: {
 *      warehouseShipmentId: // value for 'warehouseShipmentId'
 *      operation: // value for 'operation'
 *   },
 * });
 */
export function useAddEventToWarehouseShipmentMutation(baseOptions?: Apollo.MutationHookOptions<AddEventToWarehouseShipmentMutation, AddEventToWarehouseShipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEventToWarehouseShipmentMutation, AddEventToWarehouseShipmentMutationVariables>(AddEventToWarehouseShipmentDocument, options);
      }
export type AddEventToWarehouseShipmentMutationHookResult = ReturnType<typeof useAddEventToWarehouseShipmentMutation>;
export type AddEventToWarehouseShipmentMutationResult = Apollo.MutationResult<AddEventToWarehouseShipmentMutation>;
export type AddEventToWarehouseShipmentMutationOptions = Apollo.BaseMutationOptions<AddEventToWarehouseShipmentMutation, AddEventToWarehouseShipmentMutationVariables>;
export const AddTariffDocument = gql`
    mutation addTariff($input: TariffInput!) {
  addTariff(input: $input) {
    id
    zoneId
    weight
    price
  }
}
    `;
export type AddTariffMutationFn = Apollo.MutationFunction<AddTariffMutation, AddTariffMutationVariables>;

/**
 * __useAddTariffMutation__
 *
 * To run a mutation, you first call `useAddTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTariffMutation, { data, loading, error }] = useAddTariffMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTariffMutation(baseOptions?: Apollo.MutationHookOptions<AddTariffMutation, AddTariffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTariffMutation, AddTariffMutationVariables>(AddTariffDocument, options);
      }
export type AddTariffMutationHookResult = ReturnType<typeof useAddTariffMutation>;
export type AddTariffMutationResult = Apollo.MutationResult<AddTariffMutation>;
export type AddTariffMutationOptions = Apollo.BaseMutationOptions<AddTariffMutation, AddTariffMutationVariables>;
export const ArrivalOfTheParcelForAdminDocument = gql`
    mutation arrivalOfTheParcelForAdmin($id: Int!) {
  arrivalOfTheParcelForAdmin(id: $id) {
    id
  }
}
    `;
export type ArrivalOfTheParcelForAdminMutationFn = Apollo.MutationFunction<ArrivalOfTheParcelForAdminMutation, ArrivalOfTheParcelForAdminMutationVariables>;

/**
 * __useArrivalOfTheParcelForAdminMutation__
 *
 * To run a mutation, you first call `useArrivalOfTheParcelForAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArrivalOfTheParcelForAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [arrivalOfTheParcelForAdminMutation, { data, loading, error }] = useArrivalOfTheParcelForAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArrivalOfTheParcelForAdminMutation(baseOptions?: Apollo.MutationHookOptions<ArrivalOfTheParcelForAdminMutation, ArrivalOfTheParcelForAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArrivalOfTheParcelForAdminMutation, ArrivalOfTheParcelForAdminMutationVariables>(ArrivalOfTheParcelForAdminDocument, options);
      }
export type ArrivalOfTheParcelForAdminMutationHookResult = ReturnType<typeof useArrivalOfTheParcelForAdminMutation>;
export type ArrivalOfTheParcelForAdminMutationResult = Apollo.MutationResult<ArrivalOfTheParcelForAdminMutation>;
export type ArrivalOfTheParcelForAdminMutationOptions = Apollo.BaseMutationOptions<ArrivalOfTheParcelForAdminMutation, ArrivalOfTheParcelForAdminMutationVariables>;
export const CancelB2BOrderDocument = gql`
    mutation CancelB2BOrder($orderId: Int!) {
  cancelB2BOrder(orderId: $orderId)
}
    `;
export type CancelB2BOrderMutationFn = Apollo.MutationFunction<CancelB2BOrderMutation, CancelB2BOrderMutationVariables>;

/**
 * __useCancelB2BOrderMutation__
 *
 * To run a mutation, you first call `useCancelB2BOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelB2BOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelB2BOrderMutation, { data, loading, error }] = useCancelB2BOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCancelB2BOrderMutation(baseOptions?: Apollo.MutationHookOptions<CancelB2BOrderMutation, CancelB2BOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelB2BOrderMutation, CancelB2BOrderMutationVariables>(CancelB2BOrderDocument, options);
      }
export type CancelB2BOrderMutationHookResult = ReturnType<typeof useCancelB2BOrderMutation>;
export type CancelB2BOrderMutationResult = Apollo.MutationResult<CancelB2BOrderMutation>;
export type CancelB2BOrderMutationOptions = Apollo.BaseMutationOptions<CancelB2BOrderMutation, CancelB2BOrderMutationVariables>;
export const CancelConsolidationDocument = gql`
    mutation cancelConsolidation($id: ID!) {
  cancelConsolidation(id: $id)
}
    `;
export type CancelConsolidationMutationFn = Apollo.MutationFunction<CancelConsolidationMutation, CancelConsolidationMutationVariables>;

/**
 * __useCancelConsolidationMutation__
 *
 * To run a mutation, you first call `useCancelConsolidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelConsolidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelConsolidationMutation, { data, loading, error }] = useCancelConsolidationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelConsolidationMutation(baseOptions?: Apollo.MutationHookOptions<CancelConsolidationMutation, CancelConsolidationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelConsolidationMutation, CancelConsolidationMutationVariables>(CancelConsolidationDocument, options);
      }
export type CancelConsolidationMutationHookResult = ReturnType<typeof useCancelConsolidationMutation>;
export type CancelConsolidationMutationResult = Apollo.MutationResult<CancelConsolidationMutation>;
export type CancelConsolidationMutationOptions = Apollo.BaseMutationOptions<CancelConsolidationMutation, CancelConsolidationMutationVariables>;
export const CancelUndeclaredShipmentDocument = gql`
    mutation CancelUndeclaredShipment($cancelUndeclaredShipmentId: Int!) {
  cancelUndeclaredShipment(id: $cancelUndeclaredShipmentId) {
    id
  }
}
    `;
export type CancelUndeclaredShipmentMutationFn = Apollo.MutationFunction<CancelUndeclaredShipmentMutation, CancelUndeclaredShipmentMutationVariables>;

/**
 * __useCancelUndeclaredShipmentMutation__
 *
 * To run a mutation, you first call `useCancelUndeclaredShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelUndeclaredShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelUndeclaredShipmentMutation, { data, loading, error }] = useCancelUndeclaredShipmentMutation({
 *   variables: {
 *      cancelUndeclaredShipmentId: // value for 'cancelUndeclaredShipmentId'
 *   },
 * });
 */
export function useCancelUndeclaredShipmentMutation(baseOptions?: Apollo.MutationHookOptions<CancelUndeclaredShipmentMutation, CancelUndeclaredShipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelUndeclaredShipmentMutation, CancelUndeclaredShipmentMutationVariables>(CancelUndeclaredShipmentDocument, options);
      }
export type CancelUndeclaredShipmentMutationHookResult = ReturnType<typeof useCancelUndeclaredShipmentMutation>;
export type CancelUndeclaredShipmentMutationResult = Apollo.MutationResult<CancelUndeclaredShipmentMutation>;
export type CancelUndeclaredShipmentMutationOptions = Apollo.BaseMutationOptions<CancelUndeclaredShipmentMutation, CancelUndeclaredShipmentMutationVariables>;
export const CancelWarehouseShipmentDocument = gql`
    mutation CancelWarehouseShipment($cancelWarehouseShipmentId: Int!) {
  cancelWarehouseShipment(id: $cancelWarehouseShipmentId) {
    id
  }
}
    `;
export type CancelWarehouseShipmentMutationFn = Apollo.MutationFunction<CancelWarehouseShipmentMutation, CancelWarehouseShipmentMutationVariables>;

/**
 * __useCancelWarehouseShipmentMutation__
 *
 * To run a mutation, you first call `useCancelWarehouseShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelWarehouseShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelWarehouseShipmentMutation, { data, loading, error }] = useCancelWarehouseShipmentMutation({
 *   variables: {
 *      cancelWarehouseShipmentId: // value for 'cancelWarehouseShipmentId'
 *   },
 * });
 */
export function useCancelWarehouseShipmentMutation(baseOptions?: Apollo.MutationHookOptions<CancelWarehouseShipmentMutation, CancelWarehouseShipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelWarehouseShipmentMutation, CancelWarehouseShipmentMutationVariables>(CancelWarehouseShipmentDocument, options);
      }
export type CancelWarehouseShipmentMutationHookResult = ReturnType<typeof useCancelWarehouseShipmentMutation>;
export type CancelWarehouseShipmentMutationResult = Apollo.MutationResult<CancelWarehouseShipmentMutation>;
export type CancelWarehouseShipmentMutationOptions = Apollo.BaseMutationOptions<CancelWarehouseShipmentMutation, CancelWarehouseShipmentMutationVariables>;
export const CancelWarehouseShipmentOrderDocument = gql`
    mutation cancelWarehouseShipmentOrder($id: ID!) {
  cancelWarehouseShipmentOrder(id: $id) {
    id
  }
}
    `;
export type CancelWarehouseShipmentOrderMutationFn = Apollo.MutationFunction<CancelWarehouseShipmentOrderMutation, CancelWarehouseShipmentOrderMutationVariables>;

/**
 * __useCancelWarehouseShipmentOrderMutation__
 *
 * To run a mutation, you first call `useCancelWarehouseShipmentOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelWarehouseShipmentOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelWarehouseShipmentOrderMutation, { data, loading, error }] = useCancelWarehouseShipmentOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelWarehouseShipmentOrderMutation(baseOptions?: Apollo.MutationHookOptions<CancelWarehouseShipmentOrderMutation, CancelWarehouseShipmentOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelWarehouseShipmentOrderMutation, CancelWarehouseShipmentOrderMutationVariables>(CancelWarehouseShipmentOrderDocument, options);
      }
export type CancelWarehouseShipmentOrderMutationHookResult = ReturnType<typeof useCancelWarehouseShipmentOrderMutation>;
export type CancelWarehouseShipmentOrderMutationResult = Apollo.MutationResult<CancelWarehouseShipmentOrderMutation>;
export type CancelWarehouseShipmentOrderMutationOptions = Apollo.BaseMutationOptions<CancelWarehouseShipmentOrderMutation, CancelWarehouseShipmentOrderMutationVariables>;
export const ChangeProductAvailabilityDocument = gql`
    mutation ChangeProductAvailability($input: ChangeProductAvailabilityInput!) {
  changeProductAvailability(input: $input)
}
    `;
export type ChangeProductAvailabilityMutationFn = Apollo.MutationFunction<ChangeProductAvailabilityMutation, ChangeProductAvailabilityMutationVariables>;

/**
 * __useChangeProductAvailabilityMutation__
 *
 * To run a mutation, you first call `useChangeProductAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeProductAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeProductAvailabilityMutation, { data, loading, error }] = useChangeProductAvailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeProductAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<ChangeProductAvailabilityMutation, ChangeProductAvailabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeProductAvailabilityMutation, ChangeProductAvailabilityMutationVariables>(ChangeProductAvailabilityDocument, options);
      }
export type ChangeProductAvailabilityMutationHookResult = ReturnType<typeof useChangeProductAvailabilityMutation>;
export type ChangeProductAvailabilityMutationResult = Apollo.MutationResult<ChangeProductAvailabilityMutation>;
export type ChangeProductAvailabilityMutationOptions = Apollo.BaseMutationOptions<ChangeProductAvailabilityMutation, ChangeProductAvailabilityMutationVariables>;
export const CompleteB2BOrderDocument = gql`
    mutation CompleteB2BOrder($orderId: Int!) {
  completeB2BOrder(orderId: $orderId)
}
    `;
export type CompleteB2BOrderMutationFn = Apollo.MutationFunction<CompleteB2BOrderMutation, CompleteB2BOrderMutationVariables>;

/**
 * __useCompleteB2BOrderMutation__
 *
 * To run a mutation, you first call `useCompleteB2BOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteB2BOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeB2BOrderMutation, { data, loading, error }] = useCompleteB2BOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCompleteB2BOrderMutation(baseOptions?: Apollo.MutationHookOptions<CompleteB2BOrderMutation, CompleteB2BOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteB2BOrderMutation, CompleteB2BOrderMutationVariables>(CompleteB2BOrderDocument, options);
      }
export type CompleteB2BOrderMutationHookResult = ReturnType<typeof useCompleteB2BOrderMutation>;
export type CompleteB2BOrderMutationResult = Apollo.MutationResult<CompleteB2BOrderMutation>;
export type CompleteB2BOrderMutationOptions = Apollo.BaseMutationOptions<CompleteB2BOrderMutation, CompleteB2BOrderMutationVariables>;
export const CreateContactDocument = gql`
    mutation createContact($input: ContactInput!) {
  createContact(input: $input) {
    id
    email
    remark
    name
    company
    country {
      ...FragmentDeliveryCountry
    }
    address
    address2
    address3
    zipCode
    city
    state
    phone
    eoriCode
    contactName
    innKppCode
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;
export type CreateContactMutationFn = Apollo.MutationFunction<CreateContactMutation, CreateContactMutationVariables>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, options);
      }
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<CreateContactMutation, CreateContactMutationVariables>;
export const CreateManagerDocument = gql`
    mutation createManager($input: ManagerInput) {
  createManager(input: $input) {
    ...managerFragment
  }
}
    ${ManagerFragmentFragmentDoc}`;
export type CreateManagerMutationFn = Apollo.MutationFunction<CreateManagerMutation, CreateManagerMutationVariables>;

/**
 * __useCreateManagerMutation__
 *
 * To run a mutation, you first call `useCreateManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManagerMutation, { data, loading, error }] = useCreateManagerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateManagerMutation(baseOptions?: Apollo.MutationHookOptions<CreateManagerMutation, CreateManagerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManagerMutation, CreateManagerMutationVariables>(CreateManagerDocument, options);
      }
export type CreateManagerMutationHookResult = ReturnType<typeof useCreateManagerMutation>;
export type CreateManagerMutationResult = Apollo.MutationResult<CreateManagerMutation>;
export type CreateManagerMutationOptions = Apollo.BaseMutationOptions<CreateManagerMutation, CreateManagerMutationVariables>;
export const CreateOrEditB2BServiceDocument = gql`
    mutation CreateOrEditB2BService($title: String, $description: String, $costInUsd: Float, $createOrEditB2BServiceId: Int) {
  createOrEditB2BService(
    title: $title
    description: $description
    costInUSD: $costInUsd
    id: $createOrEditB2BServiceId
  )
}
    `;
export type CreateOrEditB2BServiceMutationFn = Apollo.MutationFunction<CreateOrEditB2BServiceMutation, CreateOrEditB2BServiceMutationVariables>;

/**
 * __useCreateOrEditB2BServiceMutation__
 *
 * To run a mutation, you first call `useCreateOrEditB2BServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrEditB2BServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrEditB2BServiceMutation, { data, loading, error }] = useCreateOrEditB2BServiceMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      costInUsd: // value for 'costInUsd'
 *      createOrEditB2BServiceId: // value for 'createOrEditB2BServiceId'
 *   },
 * });
 */
export function useCreateOrEditB2BServiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrEditB2BServiceMutation, CreateOrEditB2BServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrEditB2BServiceMutation, CreateOrEditB2BServiceMutationVariables>(CreateOrEditB2BServiceDocument, options);
      }
export type CreateOrEditB2BServiceMutationHookResult = ReturnType<typeof useCreateOrEditB2BServiceMutation>;
export type CreateOrEditB2BServiceMutationResult = Apollo.MutationResult<CreateOrEditB2BServiceMutation>;
export type CreateOrEditB2BServiceMutationOptions = Apollo.BaseMutationOptions<CreateOrEditB2BServiceMutation, CreateOrEditB2BServiceMutationVariables>;
export const CreateOrEditB2BShipmentPointDocument = gql`
    mutation CreateOrEditB2BShipmentPoint($name: String, $description: String, $createOrEditB2BShipmentPointId: Int) {
  createOrEditB2BShipmentPoint(
    name: $name
    description: $description
    id: $createOrEditB2BShipmentPointId
  )
}
    `;
export type CreateOrEditB2BShipmentPointMutationFn = Apollo.MutationFunction<CreateOrEditB2BShipmentPointMutation, CreateOrEditB2BShipmentPointMutationVariables>;

/**
 * __useCreateOrEditB2BShipmentPointMutation__
 *
 * To run a mutation, you first call `useCreateOrEditB2BShipmentPointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrEditB2BShipmentPointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrEditB2BShipmentPointMutation, { data, loading, error }] = useCreateOrEditB2BShipmentPointMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      createOrEditB2BShipmentPointId: // value for 'createOrEditB2BShipmentPointId'
 *   },
 * });
 */
export function useCreateOrEditB2BShipmentPointMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrEditB2BShipmentPointMutation, CreateOrEditB2BShipmentPointMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrEditB2BShipmentPointMutation, CreateOrEditB2BShipmentPointMutationVariables>(CreateOrEditB2BShipmentPointDocument, options);
      }
export type CreateOrEditB2BShipmentPointMutationHookResult = ReturnType<typeof useCreateOrEditB2BShipmentPointMutation>;
export type CreateOrEditB2BShipmentPointMutationResult = Apollo.MutationResult<CreateOrEditB2BShipmentPointMutation>;
export type CreateOrEditB2BShipmentPointMutationOptions = Apollo.BaseMutationOptions<CreateOrEditB2BShipmentPointMutation, CreateOrEditB2BShipmentPointMutationVariables>;
export const CreateReportByFulfillmentDocument = gql`
    mutation createReportByFulfillment($input: ReportFulfillment) {
  createReportByFulfillment(input: $input) {
    filename
  }
}
    `;
export type CreateReportByFulfillmentMutationFn = Apollo.MutationFunction<CreateReportByFulfillmentMutation, CreateReportByFulfillmentMutationVariables>;

/**
 * __useCreateReportByFulfillmentMutation__
 *
 * To run a mutation, you first call `useCreateReportByFulfillmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportByFulfillmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportByFulfillmentMutation, { data, loading, error }] = useCreateReportByFulfillmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReportByFulfillmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateReportByFulfillmentMutation, CreateReportByFulfillmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReportByFulfillmentMutation, CreateReportByFulfillmentMutationVariables>(CreateReportByFulfillmentDocument, options);
      }
export type CreateReportByFulfillmentMutationHookResult = ReturnType<typeof useCreateReportByFulfillmentMutation>;
export type CreateReportByFulfillmentMutationResult = Apollo.MutationResult<CreateReportByFulfillmentMutation>;
export type CreateReportByFulfillmentMutationOptions = Apollo.BaseMutationOptions<CreateReportByFulfillmentMutation, CreateReportByFulfillmentMutationVariables>;
export const CreateReportByProductsDocument = gql`
    mutation createReportByProducts($input: ReportProductInput) {
  createReportByProducts(input: $input) {
    filename
  }
}
    `;
export type CreateReportByProductsMutationFn = Apollo.MutationFunction<CreateReportByProductsMutation, CreateReportByProductsMutationVariables>;

/**
 * __useCreateReportByProductsMutation__
 *
 * To run a mutation, you first call `useCreateReportByProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportByProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportByProductsMutation, { data, loading, error }] = useCreateReportByProductsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReportByProductsMutation(baseOptions?: Apollo.MutationHookOptions<CreateReportByProductsMutation, CreateReportByProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReportByProductsMutation, CreateReportByProductsMutationVariables>(CreateReportByProductsDocument, options);
      }
export type CreateReportByProductsMutationHookResult = ReturnType<typeof useCreateReportByProductsMutation>;
export type CreateReportByProductsMutationResult = Apollo.MutationResult<CreateReportByProductsMutation>;
export type CreateReportByProductsMutationOptions = Apollo.BaseMutationOptions<CreateReportByProductsMutation, CreateReportByProductsMutationVariables>;
export const CreateReportByTransactionsDocument = gql`
    mutation createReportByTransactions($input: ReportRangeInput) {
  createReportByTransactions(input: $input) {
    filename
  }
}
    `;
export type CreateReportByTransactionsMutationFn = Apollo.MutationFunction<CreateReportByTransactionsMutation, CreateReportByTransactionsMutationVariables>;

/**
 * __useCreateReportByTransactionsMutation__
 *
 * To run a mutation, you first call `useCreateReportByTransactionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportByTransactionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportByTransactionsMutation, { data, loading, error }] = useCreateReportByTransactionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReportByTransactionsMutation(baseOptions?: Apollo.MutationHookOptions<CreateReportByTransactionsMutation, CreateReportByTransactionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReportByTransactionsMutation, CreateReportByTransactionsMutationVariables>(CreateReportByTransactionsDocument, options);
      }
export type CreateReportByTransactionsMutationHookResult = ReturnType<typeof useCreateReportByTransactionsMutation>;
export type CreateReportByTransactionsMutationResult = Apollo.MutationResult<CreateReportByTransactionsMutation>;
export type CreateReportByTransactionsMutationOptions = Apollo.BaseMutationOptions<CreateReportByTransactionsMutation, CreateReportByTransactionsMutationVariables>;
export const CreateRequestToCallCourierDocument = gql`
    mutation createRequestToCallCourier($input: CourierRequestInput) {
  createRequestToCallCourier(input: $input) {
    id
    pickupDate
    readyByTime
    closeTime
    contactName
    contactPhone
    packageLocation
    pieces {
      weight
      width
      height
      length
    }
    confirmationNumber
    errors
  }
}
    `;
export type CreateRequestToCallCourierMutationFn = Apollo.MutationFunction<CreateRequestToCallCourierMutation, CreateRequestToCallCourierMutationVariables>;

/**
 * __useCreateRequestToCallCourierMutation__
 *
 * To run a mutation, you first call `useCreateRequestToCallCourierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRequestToCallCourierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRequestToCallCourierMutation, { data, loading, error }] = useCreateRequestToCallCourierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRequestToCallCourierMutation(baseOptions?: Apollo.MutationHookOptions<CreateRequestToCallCourierMutation, CreateRequestToCallCourierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRequestToCallCourierMutation, CreateRequestToCallCourierMutationVariables>(CreateRequestToCallCourierDocument, options);
      }
export type CreateRequestToCallCourierMutationHookResult = ReturnType<typeof useCreateRequestToCallCourierMutation>;
export type CreateRequestToCallCourierMutationResult = Apollo.MutationResult<CreateRequestToCallCourierMutation>;
export type CreateRequestToCallCourierMutationOptions = Apollo.BaseMutationOptions<CreateRequestToCallCourierMutation, CreateRequestToCallCourierMutationVariables>;
export const CreateSenderAccountDocument = gql`
    mutation createSenderAccount($input: SenderAccountInput) {
  createSenderAccount(input: $input) {
    id
    accountName
    status
    accountNumber
    password
    siteId
  }
}
    `;
export type CreateSenderAccountMutationFn = Apollo.MutationFunction<CreateSenderAccountMutation, CreateSenderAccountMutationVariables>;

/**
 * __useCreateSenderAccountMutation__
 *
 * To run a mutation, you first call `useCreateSenderAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSenderAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSenderAccountMutation, { data, loading, error }] = useCreateSenderAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSenderAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateSenderAccountMutation, CreateSenderAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSenderAccountMutation, CreateSenderAccountMutationVariables>(CreateSenderAccountDocument, options);
      }
export type CreateSenderAccountMutationHookResult = ReturnType<typeof useCreateSenderAccountMutation>;
export type CreateSenderAccountMutationResult = Apollo.MutationResult<CreateSenderAccountMutation>;
export type CreateSenderAccountMutationOptions = Apollo.BaseMutationOptions<CreateSenderAccountMutation, CreateSenderAccountMutationVariables>;
export const CreateShipmentRequestDocument = gql`
    mutation createShipmentRequest($input: ShipmentInput) {
  createShipmentRequest(input: $input) {
    errors
    data {
      id
      airwayBillNumber
      status
      senderAccountId
      parcelId
      price
      createdAt
      updatedAt
    }
  }
}
    `;
export type CreateShipmentRequestMutationFn = Apollo.MutationFunction<CreateShipmentRequestMutation, CreateShipmentRequestMutationVariables>;

/**
 * __useCreateShipmentRequestMutation__
 *
 * To run a mutation, you first call `useCreateShipmentRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShipmentRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShipmentRequestMutation, { data, loading, error }] = useCreateShipmentRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateShipmentRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateShipmentRequestMutation, CreateShipmentRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShipmentRequestMutation, CreateShipmentRequestMutationVariables>(CreateShipmentRequestDocument, options);
      }
export type CreateShipmentRequestMutationHookResult = ReturnType<typeof useCreateShipmentRequestMutation>;
export type CreateShipmentRequestMutationResult = Apollo.MutationResult<CreateShipmentRequestMutation>;
export type CreateShipmentRequestMutationOptions = Apollo.BaseMutationOptions<CreateShipmentRequestMutation, CreateShipmentRequestMutationVariables>;
export const CreateWarehouseDocument = gql`
    mutation createWarehouse($input: WarehouseInput) {
  createWarehouse(input: $input) {
    id
    code
  }
}
    `;
export type CreateWarehouseMutationFn = Apollo.MutationFunction<CreateWarehouseMutation, CreateWarehouseMutationVariables>;

/**
 * __useCreateWarehouseMutation__
 *
 * To run a mutation, you first call `useCreateWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWarehouseMutation, { data, loading, error }] = useCreateWarehouseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWarehouseMutation(baseOptions?: Apollo.MutationHookOptions<CreateWarehouseMutation, CreateWarehouseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWarehouseMutation, CreateWarehouseMutationVariables>(CreateWarehouseDocument, options);
      }
export type CreateWarehouseMutationHookResult = ReturnType<typeof useCreateWarehouseMutation>;
export type CreateWarehouseMutationResult = Apollo.MutationResult<CreateWarehouseMutation>;
export type CreateWarehouseMutationOptions = Apollo.BaseMutationOptions<CreateWarehouseMutation, CreateWarehouseMutationVariables>;
export const CreateWarehouseZoneDocument = gql`
    mutation CreateWarehouseZone($name: String!) {
  createWarehouseZone(name: $name) {
    id
    name
  }
}
    `;
export type CreateWarehouseZoneMutationFn = Apollo.MutationFunction<CreateWarehouseZoneMutation, CreateWarehouseZoneMutationVariables>;

/**
 * __useCreateWarehouseZoneMutation__
 *
 * To run a mutation, you first call `useCreateWarehouseZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWarehouseZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWarehouseZoneMutation, { data, loading, error }] = useCreateWarehouseZoneMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateWarehouseZoneMutation(baseOptions?: Apollo.MutationHookOptions<CreateWarehouseZoneMutation, CreateWarehouseZoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWarehouseZoneMutation, CreateWarehouseZoneMutationVariables>(CreateWarehouseZoneDocument, options);
      }
export type CreateWarehouseZoneMutationHookResult = ReturnType<typeof useCreateWarehouseZoneMutation>;
export type CreateWarehouseZoneMutationResult = Apollo.MutationResult<CreateWarehouseZoneMutation>;
export type CreateWarehouseZoneMutationOptions = Apollo.BaseMutationOptions<CreateWarehouseZoneMutation, CreateWarehouseZoneMutationVariables>;
export const CreateWarehouseZoneCellDocument = gql`
    mutation CreateWarehouseZoneCell($data: CreateWarehouseZoneCell!) {
  createWarehouseZoneCell(data: $data) {
    id
    name
  }
}
    `;
export type CreateWarehouseZoneCellMutationFn = Apollo.MutationFunction<CreateWarehouseZoneCellMutation, CreateWarehouseZoneCellMutationVariables>;

/**
 * __useCreateWarehouseZoneCellMutation__
 *
 * To run a mutation, you first call `useCreateWarehouseZoneCellMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWarehouseZoneCellMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWarehouseZoneCellMutation, { data, loading, error }] = useCreateWarehouseZoneCellMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateWarehouseZoneCellMutation(baseOptions?: Apollo.MutationHookOptions<CreateWarehouseZoneCellMutation, CreateWarehouseZoneCellMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWarehouseZoneCellMutation, CreateWarehouseZoneCellMutationVariables>(CreateWarehouseZoneCellDocument, options);
      }
export type CreateWarehouseZoneCellMutationHookResult = ReturnType<typeof useCreateWarehouseZoneCellMutation>;
export type CreateWarehouseZoneCellMutationResult = Apollo.MutationResult<CreateWarehouseZoneCellMutation>;
export type CreateWarehouseZoneCellMutationOptions = Apollo.BaseMutationOptions<CreateWarehouseZoneCellMutation, CreateWarehouseZoneCellMutationVariables>;
export const DeleteB2BServiceDocument = gql`
    mutation DeleteB2BService($deleteB2BServiceId: Int) {
  deleteB2BService(id: $deleteB2BServiceId)
}
    `;
export type DeleteB2BServiceMutationFn = Apollo.MutationFunction<DeleteB2BServiceMutation, DeleteB2BServiceMutationVariables>;

/**
 * __useDeleteB2BServiceMutation__
 *
 * To run a mutation, you first call `useDeleteB2BServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteB2BServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteB2BServiceMutation, { data, loading, error }] = useDeleteB2BServiceMutation({
 *   variables: {
 *      deleteB2BServiceId: // value for 'deleteB2BServiceId'
 *   },
 * });
 */
export function useDeleteB2BServiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteB2BServiceMutation, DeleteB2BServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteB2BServiceMutation, DeleteB2BServiceMutationVariables>(DeleteB2BServiceDocument, options);
      }
export type DeleteB2BServiceMutationHookResult = ReturnType<typeof useDeleteB2BServiceMutation>;
export type DeleteB2BServiceMutationResult = Apollo.MutationResult<DeleteB2BServiceMutation>;
export type DeleteB2BServiceMutationOptions = Apollo.BaseMutationOptions<DeleteB2BServiceMutation, DeleteB2BServiceMutationVariables>;
export const DeleteB2BShipmentPointDocument = gql`
    mutation DeleteB2BShipmentPoint($deleteB2BShipmentPointId: Int) {
  deleteB2BShipmentPoint(id: $deleteB2BShipmentPointId)
}
    `;
export type DeleteB2BShipmentPointMutationFn = Apollo.MutationFunction<DeleteB2BShipmentPointMutation, DeleteB2BShipmentPointMutationVariables>;

/**
 * __useDeleteB2BShipmentPointMutation__
 *
 * To run a mutation, you first call `useDeleteB2BShipmentPointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteB2BShipmentPointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteB2BShipmentPointMutation, { data, loading, error }] = useDeleteB2BShipmentPointMutation({
 *   variables: {
 *      deleteB2BShipmentPointId: // value for 'deleteB2BShipmentPointId'
 *   },
 * });
 */
export function useDeleteB2BShipmentPointMutation(baseOptions?: Apollo.MutationHookOptions<DeleteB2BShipmentPointMutation, DeleteB2BShipmentPointMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteB2BShipmentPointMutation, DeleteB2BShipmentPointMutationVariables>(DeleteB2BShipmentPointDocument, options);
      }
export type DeleteB2BShipmentPointMutationHookResult = ReturnType<typeof useDeleteB2BShipmentPointMutation>;
export type DeleteB2BShipmentPointMutationResult = Apollo.MutationResult<DeleteB2BShipmentPointMutation>;
export type DeleteB2BShipmentPointMutationOptions = Apollo.BaseMutationOptions<DeleteB2BShipmentPointMutation, DeleteB2BShipmentPointMutationVariables>;
export const DeleteWarehouseZoneDocument = gql`
    mutation DeleteWarehouseZone($deleteWarehouseZoneId: ID!) {
  deleteWarehouseZone(id: $deleteWarehouseZoneId)
}
    `;
export type DeleteWarehouseZoneMutationFn = Apollo.MutationFunction<DeleteWarehouseZoneMutation, DeleteWarehouseZoneMutationVariables>;

/**
 * __useDeleteWarehouseZoneMutation__
 *
 * To run a mutation, you first call `useDeleteWarehouseZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWarehouseZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWarehouseZoneMutation, { data, loading, error }] = useDeleteWarehouseZoneMutation({
 *   variables: {
 *      deleteWarehouseZoneId: // value for 'deleteWarehouseZoneId'
 *   },
 * });
 */
export function useDeleteWarehouseZoneMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWarehouseZoneMutation, DeleteWarehouseZoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWarehouseZoneMutation, DeleteWarehouseZoneMutationVariables>(DeleteWarehouseZoneDocument, options);
      }
export type DeleteWarehouseZoneMutationHookResult = ReturnType<typeof useDeleteWarehouseZoneMutation>;
export type DeleteWarehouseZoneMutationResult = Apollo.MutationResult<DeleteWarehouseZoneMutation>;
export type DeleteWarehouseZoneMutationOptions = Apollo.BaseMutationOptions<DeleteWarehouseZoneMutation, DeleteWarehouseZoneMutationVariables>;
export const DeleteWarehouseZoneCellDocument = gql`
    mutation DeleteWarehouseZoneCell($deleteWarehouseZoneCellId: ID!) {
  deleteWarehouseZoneCell(id: $deleteWarehouseZoneCellId)
}
    `;
export type DeleteWarehouseZoneCellMutationFn = Apollo.MutationFunction<DeleteWarehouseZoneCellMutation, DeleteWarehouseZoneCellMutationVariables>;

/**
 * __useDeleteWarehouseZoneCellMutation__
 *
 * To run a mutation, you first call `useDeleteWarehouseZoneCellMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWarehouseZoneCellMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWarehouseZoneCellMutation, { data, loading, error }] = useDeleteWarehouseZoneCellMutation({
 *   variables: {
 *      deleteWarehouseZoneCellId: // value for 'deleteWarehouseZoneCellId'
 *   },
 * });
 */
export function useDeleteWarehouseZoneCellMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWarehouseZoneCellMutation, DeleteWarehouseZoneCellMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWarehouseZoneCellMutation, DeleteWarehouseZoneCellMutationVariables>(DeleteWarehouseZoneCellDocument, options);
      }
export type DeleteWarehouseZoneCellMutationHookResult = ReturnType<typeof useDeleteWarehouseZoneCellMutation>;
export type DeleteWarehouseZoneCellMutationResult = Apollo.MutationResult<DeleteWarehouseZoneCellMutation>;
export type DeleteWarehouseZoneCellMutationOptions = Apollo.BaseMutationOptions<DeleteWarehouseZoneCellMutation, DeleteWarehouseZoneCellMutationVariables>;
export const EditB2BOrderCargoPlacesDocument = gql`
    mutation EditB2BOrderCargoPlaces($orderId: Int!, $cargoData: EditB2BOrderCargoPlacesInput!, $cargoItems: [B2BCargoPlaceProductInput]!) {
  editB2BOrderCargoPlaces(
    orderId: $orderId
    cargoData: $cargoData
    cargoItems: $cargoItems
  )
}
    `;
export type EditB2BOrderCargoPlacesMutationFn = Apollo.MutationFunction<EditB2BOrderCargoPlacesMutation, EditB2BOrderCargoPlacesMutationVariables>;

/**
 * __useEditB2BOrderCargoPlacesMutation__
 *
 * To run a mutation, you first call `useEditB2BOrderCargoPlacesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditB2BOrderCargoPlacesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editB2BOrderCargoPlacesMutation, { data, loading, error }] = useEditB2BOrderCargoPlacesMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      cargoData: // value for 'cargoData'
 *      cargoItems: // value for 'cargoItems'
 *   },
 * });
 */
export function useEditB2BOrderCargoPlacesMutation(baseOptions?: Apollo.MutationHookOptions<EditB2BOrderCargoPlacesMutation, EditB2BOrderCargoPlacesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditB2BOrderCargoPlacesMutation, EditB2BOrderCargoPlacesMutationVariables>(EditB2BOrderCargoPlacesDocument, options);
      }
export type EditB2BOrderCargoPlacesMutationHookResult = ReturnType<typeof useEditB2BOrderCargoPlacesMutation>;
export type EditB2BOrderCargoPlacesMutationResult = Apollo.MutationResult<EditB2BOrderCargoPlacesMutation>;
export type EditB2BOrderCargoPlacesMutationOptions = Apollo.BaseMutationOptions<EditB2BOrderCargoPlacesMutation, EditB2BOrderCargoPlacesMutationVariables>;
export const RemoveContactDocument = gql`
    mutation removeContact($id: ID!) {
  removeContact(id: $id) {
    status
  }
}
    `;
export type RemoveContactMutationFn = Apollo.MutationFunction<RemoveContactMutation, RemoveContactMutationVariables>;

/**
 * __useRemoveContactMutation__
 *
 * To run a mutation, you first call `useRemoveContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContactMutation, { data, loading, error }] = useRemoveContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveContactMutation(baseOptions?: Apollo.MutationHookOptions<RemoveContactMutation, RemoveContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveContactMutation, RemoveContactMutationVariables>(RemoveContactDocument, options);
      }
export type RemoveContactMutationHookResult = ReturnType<typeof useRemoveContactMutation>;
export type RemoveContactMutationResult = Apollo.MutationResult<RemoveContactMutation>;
export type RemoveContactMutationOptions = Apollo.BaseMutationOptions<RemoveContactMutation, RemoveContactMutationVariables>;
export const RemoveManagerDocument = gql`
    mutation removeManager($id: Int!) {
  removeManager(id: $id) {
    ...managerFragment
  }
}
    ${ManagerFragmentFragmentDoc}`;
export type RemoveManagerMutationFn = Apollo.MutationFunction<RemoveManagerMutation, RemoveManagerMutationVariables>;

/**
 * __useRemoveManagerMutation__
 *
 * To run a mutation, you first call `useRemoveManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeManagerMutation, { data, loading, error }] = useRemoveManagerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveManagerMutation(baseOptions?: Apollo.MutationHookOptions<RemoveManagerMutation, RemoveManagerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveManagerMutation, RemoveManagerMutationVariables>(RemoveManagerDocument, options);
      }
export type RemoveManagerMutationHookResult = ReturnType<typeof useRemoveManagerMutation>;
export type RemoveManagerMutationResult = Apollo.MutationResult<RemoveManagerMutation>;
export type RemoveManagerMutationOptions = Apollo.BaseMutationOptions<RemoveManagerMutation, RemoveManagerMutationVariables>;
export const RemoveSenderAccountDocument = gql`
    mutation removeSenderAccount($id: Int!) {
  removeSenderAccount(id: $id)
}
    `;
export type RemoveSenderAccountMutationFn = Apollo.MutationFunction<RemoveSenderAccountMutation, RemoveSenderAccountMutationVariables>;

/**
 * __useRemoveSenderAccountMutation__
 *
 * To run a mutation, you first call `useRemoveSenderAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSenderAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSenderAccountMutation, { data, loading, error }] = useRemoveSenderAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSenderAccountMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSenderAccountMutation, RemoveSenderAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSenderAccountMutation, RemoveSenderAccountMutationVariables>(RemoveSenderAccountDocument, options);
      }
export type RemoveSenderAccountMutationHookResult = ReturnType<typeof useRemoveSenderAccountMutation>;
export type RemoveSenderAccountMutationResult = Apollo.MutationResult<RemoveSenderAccountMutation>;
export type RemoveSenderAccountMutationOptions = Apollo.BaseMutationOptions<RemoveSenderAccountMutation, RemoveSenderAccountMutationVariables>;
export const RemoveTariffDocument = gql`
    mutation removeTariff($weight: Float!) {
  removeTariff(weight: $weight)
}
    `;
export type RemoveTariffMutationFn = Apollo.MutationFunction<RemoveTariffMutation, RemoveTariffMutationVariables>;

/**
 * __useRemoveTariffMutation__
 *
 * To run a mutation, you first call `useRemoveTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTariffMutation, { data, loading, error }] = useRemoveTariffMutation({
 *   variables: {
 *      weight: // value for 'weight'
 *   },
 * });
 */
export function useRemoveTariffMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTariffMutation, RemoveTariffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTariffMutation, RemoveTariffMutationVariables>(RemoveTariffDocument, options);
      }
export type RemoveTariffMutationHookResult = ReturnType<typeof useRemoveTariffMutation>;
export type RemoveTariffMutationResult = Apollo.MutationResult<RemoveTariffMutation>;
export type RemoveTariffMutationOptions = Apollo.BaseMutationOptions<RemoveTariffMutation, RemoveTariffMutationVariables>;
export const RemoveWarehouseDocument = gql`
    mutation removeWarehouse($id: Int!) {
  removeWarehouse(id: $id) {
    id
    code
  }
}
    `;
export type RemoveWarehouseMutationFn = Apollo.MutationFunction<RemoveWarehouseMutation, RemoveWarehouseMutationVariables>;

/**
 * __useRemoveWarehouseMutation__
 *
 * To run a mutation, you first call `useRemoveWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWarehouseMutation, { data, loading, error }] = useRemoveWarehouseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveWarehouseMutation(baseOptions?: Apollo.MutationHookOptions<RemoveWarehouseMutation, RemoveWarehouseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveWarehouseMutation, RemoveWarehouseMutationVariables>(RemoveWarehouseDocument, options);
      }
export type RemoveWarehouseMutationHookResult = ReturnType<typeof useRemoveWarehouseMutation>;
export type RemoveWarehouseMutationResult = Apollo.MutationResult<RemoveWarehouseMutation>;
export type RemoveWarehouseMutationOptions = Apollo.BaseMutationOptions<RemoveWarehouseMutation, RemoveWarehouseMutationVariables>;
export const SaveOrSendConsolidationDocument = gql`
    mutation saveOrSendConsolidation($parcelsIds: [Int], $consolidationId: ID, $accountId: ID, $shipmentData: shipmentDataSend!, $doSend: Boolean) {
  saveOrSendConsolidation(
    parcelsIds: $parcelsIds
    consolidationId: $consolidationId
    accountId: $accountId
    shipmentData: $shipmentData
    doSend: $doSend
  ) {
    id
  }
}
    `;
export type SaveOrSendConsolidationMutationFn = Apollo.MutationFunction<SaveOrSendConsolidationMutation, SaveOrSendConsolidationMutationVariables>;

/**
 * __useSaveOrSendConsolidationMutation__
 *
 * To run a mutation, you first call `useSaveOrSendConsolidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveOrSendConsolidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveOrSendConsolidationMutation, { data, loading, error }] = useSaveOrSendConsolidationMutation({
 *   variables: {
 *      parcelsIds: // value for 'parcelsIds'
 *      consolidationId: // value for 'consolidationId'
 *      accountId: // value for 'accountId'
 *      shipmentData: // value for 'shipmentData'
 *      doSend: // value for 'doSend'
 *   },
 * });
 */
export function useSaveOrSendConsolidationMutation(baseOptions?: Apollo.MutationHookOptions<SaveOrSendConsolidationMutation, SaveOrSendConsolidationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveOrSendConsolidationMutation, SaveOrSendConsolidationMutationVariables>(SaveOrSendConsolidationDocument, options);
      }
export type SaveOrSendConsolidationMutationHookResult = ReturnType<typeof useSaveOrSendConsolidationMutation>;
export type SaveOrSendConsolidationMutationResult = Apollo.MutationResult<SaveOrSendConsolidationMutation>;
export type SaveOrSendConsolidationMutationOptions = Apollo.BaseMutationOptions<SaveOrSendConsolidationMutation, SaveOrSendConsolidationMutationVariables>;
export const SendNotificationDocument = gql`
    mutation SendNotification($topic: String, $text: String) {
  sendNotification(topic: $topic, text: $text)
}
    `;
export type SendNotificationMutationFn = Apollo.MutationFunction<SendNotificationMutation, SendNotificationMutationVariables>;

/**
 * __useSendNotificationMutation__
 *
 * To run a mutation, you first call `useSendNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendNotificationMutation, { data, loading, error }] = useSendNotificationMutation({
 *   variables: {
 *      topic: // value for 'topic'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useSendNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SendNotificationMutation, SendNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendNotificationMutation, SendNotificationMutationVariables>(SendNotificationDocument, options);
      }
export type SendNotificationMutationHookResult = ReturnType<typeof useSendNotificationMutation>;
export type SendNotificationMutationResult = Apollo.MutationResult<SendNotificationMutation>;
export type SendNotificationMutationOptions = Apollo.BaseMutationOptions<SendNotificationMutation, SendNotificationMutationVariables>;
export const SendWarehouseShipmentOrderDocument = gql`
    mutation sendWarehouseShipmentOrder($data: SendWarehouseShipmentOrderInput!) {
  sendWarehouseShipmentOrder(data: $data) {
    id
    clientUser {
      id
    }
    receiver {
      id
    }
    sender {
      id
    }
    trackNumber
    carrierCode
    carrierService
    declaredAmount
    actualAmount
    additionalInsurance
    insuranceAmount
    signature
    dimensions {
      id
      warehouseShipmentId
      length
      width
      height
      weight
    }
    deliveryStatus {
      id
      name
      explain
    }
    pathNameFile
    senderMark
    vatKey
    vatValue
    products {
      price
      quantity
    }
  }
}
    `;
export type SendWarehouseShipmentOrderMutationFn = Apollo.MutationFunction<SendWarehouseShipmentOrderMutation, SendWarehouseShipmentOrderMutationVariables>;

/**
 * __useSendWarehouseShipmentOrderMutation__
 *
 * To run a mutation, you first call `useSendWarehouseShipmentOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendWarehouseShipmentOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendWarehouseShipmentOrderMutation, { data, loading, error }] = useSendWarehouseShipmentOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendWarehouseShipmentOrderMutation(baseOptions?: Apollo.MutationHookOptions<SendWarehouseShipmentOrderMutation, SendWarehouseShipmentOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendWarehouseShipmentOrderMutation, SendWarehouseShipmentOrderMutationVariables>(SendWarehouseShipmentOrderDocument, options);
      }
export type SendWarehouseShipmentOrderMutationHookResult = ReturnType<typeof useSendWarehouseShipmentOrderMutation>;
export type SendWarehouseShipmentOrderMutationResult = Apollo.MutationResult<SendWarehouseShipmentOrderMutation>;
export type SendWarehouseShipmentOrderMutationOptions = Apollo.BaseMutationOptions<SendWarehouseShipmentOrderMutation, SendWarehouseShipmentOrderMutationVariables>;
export const SendWarehouseShipmentOrderWihtoutBuyLabelDocument = gql`
    mutation SendWarehouseShipmentOrderWihtoutBuyLabel($data: SendWarehouseShipmentOrderWithoutBuyLabelInput!) {
  sendWarehouseShipmentOrderWihtoutBuyLabel(data: $data) {
    id
    clientUser {
      id
    }
    receiver {
      id
    }
    sender {
      id
    }
    trackNumber
    carrierCode
    carrierService
    declaredAmount
    actualAmount
    additionalInsurance
    insuranceAmount
    signature
    dimensions {
      id
      warehouseShipmentId
      length
      width
      height
      weight
    }
    deliveryStatus {
      id
      name
      explain
    }
    pathNameFile
    senderMark
    vatKey
    vatValue
    products {
      price
      quantity
    }
  }
}
    `;
export type SendWarehouseShipmentOrderWihtoutBuyLabelMutationFn = Apollo.MutationFunction<SendWarehouseShipmentOrderWihtoutBuyLabelMutation, SendWarehouseShipmentOrderWihtoutBuyLabelMutationVariables>;

/**
 * __useSendWarehouseShipmentOrderWihtoutBuyLabelMutation__
 *
 * To run a mutation, you first call `useSendWarehouseShipmentOrderWihtoutBuyLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendWarehouseShipmentOrderWihtoutBuyLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendWarehouseShipmentOrderWihtoutBuyLabelMutation, { data, loading, error }] = useSendWarehouseShipmentOrderWihtoutBuyLabelMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendWarehouseShipmentOrderWihtoutBuyLabelMutation(baseOptions?: Apollo.MutationHookOptions<SendWarehouseShipmentOrderWihtoutBuyLabelMutation, SendWarehouseShipmentOrderWihtoutBuyLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendWarehouseShipmentOrderWihtoutBuyLabelMutation, SendWarehouseShipmentOrderWihtoutBuyLabelMutationVariables>(SendWarehouseShipmentOrderWihtoutBuyLabelDocument, options);
      }
export type SendWarehouseShipmentOrderWihtoutBuyLabelMutationHookResult = ReturnType<typeof useSendWarehouseShipmentOrderWihtoutBuyLabelMutation>;
export type SendWarehouseShipmentOrderWihtoutBuyLabelMutationResult = Apollo.MutationResult<SendWarehouseShipmentOrderWihtoutBuyLabelMutation>;
export type SendWarehouseShipmentOrderWihtoutBuyLabelMutationOptions = Apollo.BaseMutationOptions<SendWarehouseShipmentOrderWihtoutBuyLabelMutation, SendWarehouseShipmentOrderWihtoutBuyLabelMutationVariables>;
export const SetB2BOrderCargoPlacesDocument = gql`
    mutation SetB2BOrderCargoPlaces($orderId: Int!, $cargosCount: Int!) {
  setB2BOrderCargoPlaces(orderId: $orderId, cargosCount: $cargosCount)
}
    `;
export type SetB2BOrderCargoPlacesMutationFn = Apollo.MutationFunction<SetB2BOrderCargoPlacesMutation, SetB2BOrderCargoPlacesMutationVariables>;

/**
 * __useSetB2BOrderCargoPlacesMutation__
 *
 * To run a mutation, you first call `useSetB2BOrderCargoPlacesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetB2BOrderCargoPlacesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setB2BOrderCargoPlacesMutation, { data, loading, error }] = useSetB2BOrderCargoPlacesMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      cargosCount: // value for 'cargosCount'
 *   },
 * });
 */
export function useSetB2BOrderCargoPlacesMutation(baseOptions?: Apollo.MutationHookOptions<SetB2BOrderCargoPlacesMutation, SetB2BOrderCargoPlacesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetB2BOrderCargoPlacesMutation, SetB2BOrderCargoPlacesMutationVariables>(SetB2BOrderCargoPlacesDocument, options);
      }
export type SetB2BOrderCargoPlacesMutationHookResult = ReturnType<typeof useSetB2BOrderCargoPlacesMutation>;
export type SetB2BOrderCargoPlacesMutationResult = Apollo.MutationResult<SetB2BOrderCargoPlacesMutation>;
export type SetB2BOrderCargoPlacesMutationOptions = Apollo.BaseMutationOptions<SetB2BOrderCargoPlacesMutation, SetB2BOrderCargoPlacesMutationVariables>;
export const SetB2BOrderServicesAndShipmentDocument = gql`
    mutation SetB2BOrderServicesAndShipment($orderId: Int!, $data: setB2BOrderServicesAndShipmentInput!) {
  setB2BOrderServicesAndShipment(orderId: $orderId, data: $data)
}
    `;
export type SetB2BOrderServicesAndShipmentMutationFn = Apollo.MutationFunction<SetB2BOrderServicesAndShipmentMutation, SetB2BOrderServicesAndShipmentMutationVariables>;

/**
 * __useSetB2BOrderServicesAndShipmentMutation__
 *
 * To run a mutation, you first call `useSetB2BOrderServicesAndShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetB2BOrderServicesAndShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setB2BOrderServicesAndShipmentMutation, { data, loading, error }] = useSetB2BOrderServicesAndShipmentMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSetB2BOrderServicesAndShipmentMutation(baseOptions?: Apollo.MutationHookOptions<SetB2BOrderServicesAndShipmentMutation, SetB2BOrderServicesAndShipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetB2BOrderServicesAndShipmentMutation, SetB2BOrderServicesAndShipmentMutationVariables>(SetB2BOrderServicesAndShipmentDocument, options);
      }
export type SetB2BOrderServicesAndShipmentMutationHookResult = ReturnType<typeof useSetB2BOrderServicesAndShipmentMutation>;
export type SetB2BOrderServicesAndShipmentMutationResult = Apollo.MutationResult<SetB2BOrderServicesAndShipmentMutation>;
export type SetB2BOrderServicesAndShipmentMutationOptions = Apollo.BaseMutationOptions<SetB2BOrderServicesAndShipmentMutation, SetB2BOrderServicesAndShipmentMutationVariables>;
export const SetProductActualDimensionsDocument = gql`
    mutation setProductActualDimensions($id: Int!, $weight: Float!, $width: Float!, $length: Float!, $height: Float!) {
  setProductActualDimensions(
    id: $id
    weight: $weight
    width: $width
    length: $length
    height: $height
  ) {
    id
    name
    sku
  }
}
    `;
export type SetProductActualDimensionsMutationFn = Apollo.MutationFunction<SetProductActualDimensionsMutation, SetProductActualDimensionsMutationVariables>;

/**
 * __useSetProductActualDimensionsMutation__
 *
 * To run a mutation, you first call `useSetProductActualDimensionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProductActualDimensionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProductActualDimensionsMutation, { data, loading, error }] = useSetProductActualDimensionsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      weight: // value for 'weight'
 *      width: // value for 'width'
 *      length: // value for 'length'
 *      height: // value for 'height'
 *   },
 * });
 */
export function useSetProductActualDimensionsMutation(baseOptions?: Apollo.MutationHookOptions<SetProductActualDimensionsMutation, SetProductActualDimensionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetProductActualDimensionsMutation, SetProductActualDimensionsMutationVariables>(SetProductActualDimensionsDocument, options);
      }
export type SetProductActualDimensionsMutationHookResult = ReturnType<typeof useSetProductActualDimensionsMutation>;
export type SetProductActualDimensionsMutationResult = Apollo.MutationResult<SetProductActualDimensionsMutation>;
export type SetProductActualDimensionsMutationOptions = Apollo.BaseMutationOptions<SetProductActualDimensionsMutation, SetProductActualDimensionsMutationVariables>;
export const UserDiscountManagementDocument = gql`
    mutation UserDiscountManagement($userId: Int!, $discount: DiscountInput) {
  userDiscountManagement(userId: $userId, discount: $discount) {
    id
    userId
    creditLimit
    orderHandlingDiscountPercent
    warehouseStorageDiscountPercent
    forwardingDiscountPercent
    returnHandlingDiscountPercent
    inboundDiscountPercent
    undeclaredInboundDiscountPercent
  }
}
    `;
export type UserDiscountManagementMutationFn = Apollo.MutationFunction<UserDiscountManagementMutation, UserDiscountManagementMutationVariables>;

/**
 * __useUserDiscountManagementMutation__
 *
 * To run a mutation, you first call `useUserDiscountManagementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDiscountManagementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDiscountManagementMutation, { data, loading, error }] = useUserDiscountManagementMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      discount: // value for 'discount'
 *   },
 * });
 */
export function useUserDiscountManagementMutation(baseOptions?: Apollo.MutationHookOptions<UserDiscountManagementMutation, UserDiscountManagementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserDiscountManagementMutation, UserDiscountManagementMutationVariables>(UserDiscountManagementDocument, options);
      }
export type UserDiscountManagementMutationHookResult = ReturnType<typeof useUserDiscountManagementMutation>;
export type UserDiscountManagementMutationResult = Apollo.MutationResult<UserDiscountManagementMutation>;
export type UserDiscountManagementMutationOptions = Apollo.BaseMutationOptions<UserDiscountManagementMutation, UserDiscountManagementMutationVariables>;
export const UpdateConfigurationDocument = gql`
    mutation updateConfiguration($input: [ConfigInput]!) {
  updateConfiguration(input: $input)
}
    `;
export type UpdateConfigurationMutationFn = Apollo.MutationFunction<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>;

/**
 * __useUpdateConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConfigurationMutation, { data, loading, error }] = useUpdateConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>(UpdateConfigurationDocument, options);
      }
export type UpdateConfigurationMutationHookResult = ReturnType<typeof useUpdateConfigurationMutation>;
export type UpdateConfigurationMutationResult = Apollo.MutationResult<UpdateConfigurationMutation>;
export type UpdateConfigurationMutationOptions = Apollo.BaseMutationOptions<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>;
export const UpdateContactDocument = gql`
    mutation updateContact($id: ID!, $input: ContactInput!) {
  updateContact(id: $id, input: $input) {
    id
    email
    remark
    name
    company
    country {
      ...FragmentDeliveryCountry
    }
    address
    address2
    address3
    zipCode
    city
    state
    phone
    eoriCode
    contactName
    innKppCode
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;
export type UpdateContactMutationFn = Apollo.MutationFunction<UpdateContactMutation, UpdateContactMutationVariables>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, options);
      }
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<UpdateContactMutation, UpdateContactMutationVariables>;
export const UpdateCreditLimitForUserDocument = gql`
    mutation updateCreditLimitForUser($userId: Int!, $value: Int!) {
  updateCreditLimitForUser(userId: $userId, value: $value) {
    id
  }
}
    `;
export type UpdateCreditLimitForUserMutationFn = Apollo.MutationFunction<UpdateCreditLimitForUserMutation, UpdateCreditLimitForUserMutationVariables>;

/**
 * __useUpdateCreditLimitForUserMutation__
 *
 * To run a mutation, you first call `useUpdateCreditLimitForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreditLimitForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreditLimitForUserMutation, { data, loading, error }] = useUpdateCreditLimitForUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateCreditLimitForUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCreditLimitForUserMutation, UpdateCreditLimitForUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCreditLimitForUserMutation, UpdateCreditLimitForUserMutationVariables>(UpdateCreditLimitForUserDocument, options);
      }
export type UpdateCreditLimitForUserMutationHookResult = ReturnType<typeof useUpdateCreditLimitForUserMutation>;
export type UpdateCreditLimitForUserMutationResult = Apollo.MutationResult<UpdateCreditLimitForUserMutation>;
export type UpdateCreditLimitForUserMutationOptions = Apollo.BaseMutationOptions<UpdateCreditLimitForUserMutation, UpdateCreditLimitForUserMutationVariables>;
export const UpdateManagerDocument = gql`
    mutation updateManager($input: ManagerInput, $id: Int!) {
  updateManager(input: $input, id: $id) {
    ...managerFragment
    warehouse {
      id
      code
    }
  }
}
    ${ManagerFragmentFragmentDoc}`;
export type UpdateManagerMutationFn = Apollo.MutationFunction<UpdateManagerMutation, UpdateManagerMutationVariables>;

/**
 * __useUpdateManagerMutation__
 *
 * To run a mutation, you first call `useUpdateManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManagerMutation, { data, loading, error }] = useUpdateManagerMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateManagerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateManagerMutation, UpdateManagerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateManagerMutation, UpdateManagerMutationVariables>(UpdateManagerDocument, options);
      }
export type UpdateManagerMutationHookResult = ReturnType<typeof useUpdateManagerMutation>;
export type UpdateManagerMutationResult = Apollo.MutationResult<UpdateManagerMutation>;
export type UpdateManagerMutationOptions = Apollo.BaseMutationOptions<UpdateManagerMutation, UpdateManagerMutationVariables>;
export const UpdatePassportDataDocument = gql`
    mutation updatePassportData($input: UserPassportUpdateDataInput!, $file: Upload) {
  updatePassportData(input: $input, file: $file) {
    ...FragmentUserPasport
  }
}
    ${FragmentUserPasportFragmentDoc}`;
export type UpdatePassportDataMutationFn = Apollo.MutationFunction<UpdatePassportDataMutation, UpdatePassportDataMutationVariables>;

/**
 * __useUpdatePassportDataMutation__
 *
 * To run a mutation, you first call `useUpdatePassportDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePassportDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePassportDataMutation, { data, loading, error }] = useUpdatePassportDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUpdatePassportDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePassportDataMutation, UpdatePassportDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePassportDataMutation, UpdatePassportDataMutationVariables>(UpdatePassportDataDocument, options);
      }
export type UpdatePassportDataMutationHookResult = ReturnType<typeof useUpdatePassportDataMutation>;
export type UpdatePassportDataMutationResult = Apollo.MutationResult<UpdatePassportDataMutation>;
export type UpdatePassportDataMutationOptions = Apollo.BaseMutationOptions<UpdatePassportDataMutation, UpdatePassportDataMutationVariables>;
export const UpdateSenderAccountDocument = gql`
    mutation updateSenderAccount($input: SenderAccountInput) {
  updateSenderAccount(input: $input) {
    id
    accountName
    status
    accountNumber
    password
    siteId
  }
}
    `;
export type UpdateSenderAccountMutationFn = Apollo.MutationFunction<UpdateSenderAccountMutation, UpdateSenderAccountMutationVariables>;

/**
 * __useUpdateSenderAccountMutation__
 *
 * To run a mutation, you first call `useUpdateSenderAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSenderAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSenderAccountMutation, { data, loading, error }] = useUpdateSenderAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSenderAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSenderAccountMutation, UpdateSenderAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSenderAccountMutation, UpdateSenderAccountMutationVariables>(UpdateSenderAccountDocument, options);
      }
export type UpdateSenderAccountMutationHookResult = ReturnType<typeof useUpdateSenderAccountMutation>;
export type UpdateSenderAccountMutationResult = Apollo.MutationResult<UpdateSenderAccountMutation>;
export type UpdateSenderAccountMutationOptions = Apollo.BaseMutationOptions<UpdateSenderAccountMutation, UpdateSenderAccountMutationVariables>;
export const UpdateTariffDocument = gql`
    mutation updateTariff($input: TariffInput!) {
  updateTariff(input: $input) {
    id
    zoneId
    weight
    price
  }
}
    `;
export type UpdateTariffMutationFn = Apollo.MutationFunction<UpdateTariffMutation, UpdateTariffMutationVariables>;

/**
 * __useUpdateTariffMutation__
 *
 * To run a mutation, you first call `useUpdateTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTariffMutation, { data, loading, error }] = useUpdateTariffMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTariffMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTariffMutation, UpdateTariffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTariffMutation, UpdateTariffMutationVariables>(UpdateTariffDocument, options);
      }
export type UpdateTariffMutationHookResult = ReturnType<typeof useUpdateTariffMutation>;
export type UpdateTariffMutationResult = Apollo.MutationResult<UpdateTariffMutation>;
export type UpdateTariffMutationOptions = Apollo.BaseMutationOptions<UpdateTariffMutation, UpdateTariffMutationVariables>;
export const UpdateTrackNumberConsolidationMutationDocument = gql`
    mutation updateTrackNumberConsolidationMutation($id: ID!, $trackNumber: String!, $carrierCode: String) {
  updateTrackNumberConsolidation(
    id: $id
    trackNumber: $trackNumber
    carrierCode: $carrierCode
  ) {
    id
    trackNumber
    serialNumber
    sender {
      id
      name
      company
      country {
        ...FragmentDeliveryCountry
      }
      address
      address2
      address3
      zipCode
      city
      state
      phone
      email
      innKppCode
      remark
      eoriCode
    }
    receiver {
      id
      name
      company
      country {
        ...FragmentDeliveryCountry
      }
      address
      address2
      address3
      zipCode
      city
      state
      phone
      email
      innKppCode
      remark
      eoriCode
    }
    parcels {
      id
      user {
        id
      }
      sender {
        id
        name
        company
        country {
          ...FragmentDeliveryCountry
        }
        address
        address2
        address3
        zipCode
        city
        state
        phone
        email
        innKppCode
        remark
      }
      receiver {
        id
        name
        company
        country {
          ...FragmentDeliveryCountry
        }
        address
        address2
        address3
        zipCode
        city
        state
        phone
        email
        innKppCode
        remark
        eoriCode
      }
      packages {
        id
        weightKg
        lengthCm
        widthCm
        heightCm
        units {
          id
          nameRU
          nameEN
          tradeMark
          code
          quantity
          price
          country {
            ...FragmentDeliveryCountry
          }
          netWeight
          state
        }
      }
      typeDelivery {
        id
        name
        serviceName
      }
      fileTokenInvoiceXlsx
      senderNotes
      senderMark
      additionalInsurance
      declaredAmount
      declaredWeight
      actualAmount
      actualWeight
      trackNumber
      carrierCode
      deliveryStatusId
      paymentStatusId
      currency
      sentToWarehouseId
      exportReason
      paymentOfTaxes
      vatKey
      vatID
      isConsolidationPiece
      consolidationTrackNumber {
        service
        consolidationTrackNumber
      }
    }
    deliveryStatus {
      id
      name
      explain
    }
    paymentStatus {
      id
      name
      explain
    }
    insurance
    date
    senderAccount
    totalCost
    shipmentServiceName
    waybillPath
    cargoPlaces
    totalGrossWeight
    totalNetWeight
    totalVolumetricWeight
    totalCostOfEnteties
    fileTokenInvoiceXlsx
    barcode
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;
export type UpdateTrackNumberConsolidationMutationMutationFn = Apollo.MutationFunction<UpdateTrackNumberConsolidationMutationMutation, UpdateTrackNumberConsolidationMutationMutationVariables>;

/**
 * __useUpdateTrackNumberConsolidationMutationMutation__
 *
 * To run a mutation, you first call `useUpdateTrackNumberConsolidationMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrackNumberConsolidationMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrackNumberConsolidationMutationMutation, { data, loading, error }] = useUpdateTrackNumberConsolidationMutationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      trackNumber: // value for 'trackNumber'
 *      carrierCode: // value for 'carrierCode'
 *   },
 * });
 */
export function useUpdateTrackNumberConsolidationMutationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTrackNumberConsolidationMutationMutation, UpdateTrackNumberConsolidationMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTrackNumberConsolidationMutationMutation, UpdateTrackNumberConsolidationMutationMutationVariables>(UpdateTrackNumberConsolidationMutationDocument, options);
      }
export type UpdateTrackNumberConsolidationMutationMutationHookResult = ReturnType<typeof useUpdateTrackNumberConsolidationMutationMutation>;
export type UpdateTrackNumberConsolidationMutationMutationResult = Apollo.MutationResult<UpdateTrackNumberConsolidationMutationMutation>;
export type UpdateTrackNumberConsolidationMutationMutationOptions = Apollo.BaseMutationOptions<UpdateTrackNumberConsolidationMutationMutation, UpdateTrackNumberConsolidationMutationMutationVariables>;
export const UpdateWarehouseDocument = gql`
    mutation updateWarehouse($id: Int!, $input: WarehouseInput) {
  updateWarehouse(id: $id, input: $input) {
    id
    code
    address
    company
    phone
  }
}
    `;
export type UpdateWarehouseMutationFn = Apollo.MutationFunction<UpdateWarehouseMutation, UpdateWarehouseMutationVariables>;

/**
 * __useUpdateWarehouseMutation__
 *
 * To run a mutation, you first call `useUpdateWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWarehouseMutation, { data, loading, error }] = useUpdateWarehouseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWarehouseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWarehouseMutation, UpdateWarehouseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWarehouseMutation, UpdateWarehouseMutationVariables>(UpdateWarehouseDocument, options);
      }
export type UpdateWarehouseMutationHookResult = ReturnType<typeof useUpdateWarehouseMutation>;
export type UpdateWarehouseMutationResult = Apollo.MutationResult<UpdateWarehouseMutation>;
export type UpdateWarehouseMutationOptions = Apollo.BaseMutationOptions<UpdateWarehouseMutation, UpdateWarehouseMutationVariables>;
export const UpdateWarehouseZoneDocument = gql`
    mutation UpdateWarehouseZone($updateWarehouseZoneId: ID!, $data: UpdateWarehouseZone!) {
  updateWarehouseZone(id: $updateWarehouseZoneId, data: $data) {
    id
    name
  }
}
    `;
export type UpdateWarehouseZoneMutationFn = Apollo.MutationFunction<UpdateWarehouseZoneMutation, UpdateWarehouseZoneMutationVariables>;

/**
 * __useUpdateWarehouseZoneMutation__
 *
 * To run a mutation, you first call `useUpdateWarehouseZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWarehouseZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWarehouseZoneMutation, { data, loading, error }] = useUpdateWarehouseZoneMutation({
 *   variables: {
 *      updateWarehouseZoneId: // value for 'updateWarehouseZoneId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateWarehouseZoneMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWarehouseZoneMutation, UpdateWarehouseZoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWarehouseZoneMutation, UpdateWarehouseZoneMutationVariables>(UpdateWarehouseZoneDocument, options);
      }
export type UpdateWarehouseZoneMutationHookResult = ReturnType<typeof useUpdateWarehouseZoneMutation>;
export type UpdateWarehouseZoneMutationResult = Apollo.MutationResult<UpdateWarehouseZoneMutation>;
export type UpdateWarehouseZoneMutationOptions = Apollo.BaseMutationOptions<UpdateWarehouseZoneMutation, UpdateWarehouseZoneMutationVariables>;
export const UpdateWarehouseZoneCellDocument = gql`
    mutation UpdateWarehouseZoneCell($updateWarehouseZoneCellId: ID!, $data: UpdateWarehouseZoneCell!) {
  updateWarehouseZoneCell(id: $updateWarehouseZoneCellId, data: $data) {
    id
    name
  }
}
    `;
export type UpdateWarehouseZoneCellMutationFn = Apollo.MutationFunction<UpdateWarehouseZoneCellMutation, UpdateWarehouseZoneCellMutationVariables>;

/**
 * __useUpdateWarehouseZoneCellMutation__
 *
 * To run a mutation, you first call `useUpdateWarehouseZoneCellMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWarehouseZoneCellMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWarehouseZoneCellMutation, { data, loading, error }] = useUpdateWarehouseZoneCellMutation({
 *   variables: {
 *      updateWarehouseZoneCellId: // value for 'updateWarehouseZoneCellId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateWarehouseZoneCellMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWarehouseZoneCellMutation, UpdateWarehouseZoneCellMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWarehouseZoneCellMutation, UpdateWarehouseZoneCellMutationVariables>(UpdateWarehouseZoneCellDocument, options);
      }
export type UpdateWarehouseZoneCellMutationHookResult = ReturnType<typeof useUpdateWarehouseZoneCellMutation>;
export type UpdateWarehouseZoneCellMutationResult = Apollo.MutationResult<UpdateWarehouseZoneCellMutation>;
export type UpdateWarehouseZoneCellMutationOptions = Apollo.BaseMutationOptions<UpdateWarehouseZoneCellMutation, UpdateWarehouseZoneCellMutationVariables>;
export const UploadWarehouseZoneCellsDocument = gql`
    mutation UploadWarehouseZoneCells($file: Upload!) {
  uploadWarehouseZoneCells(file: $file) {
    all
    loaded
    messages
    status
  }
}
    `;
export type UploadWarehouseZoneCellsMutationFn = Apollo.MutationFunction<UploadWarehouseZoneCellsMutation, UploadWarehouseZoneCellsMutationVariables>;

/**
 * __useUploadWarehouseZoneCellsMutation__
 *
 * To run a mutation, you first call `useUploadWarehouseZoneCellsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadWarehouseZoneCellsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadWarehouseZoneCellsMutation, { data, loading, error }] = useUploadWarehouseZoneCellsMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadWarehouseZoneCellsMutation(baseOptions?: Apollo.MutationHookOptions<UploadWarehouseZoneCellsMutation, UploadWarehouseZoneCellsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadWarehouseZoneCellsMutation, UploadWarehouseZoneCellsMutationVariables>(UploadWarehouseZoneCellsDocument, options);
      }
export type UploadWarehouseZoneCellsMutationHookResult = ReturnType<typeof useUploadWarehouseZoneCellsMutation>;
export type UploadWarehouseZoneCellsMutationResult = Apollo.MutationResult<UploadWarehouseZoneCellsMutation>;
export type UploadWarehouseZoneCellsMutationOptions = Apollo.BaseMutationOptions<UploadWarehouseZoneCellsMutation, UploadWarehouseZoneCellsMutationVariables>;
export const VerifyLegalDataDocument = gql`
    mutation verifyLegalData($entityLegalDataId: Int!) {
  verifyLegalData(entityLegalDataId: $entityLegalDataId) {
    id
  }
}
    `;
export type VerifyLegalDataMutationFn = Apollo.MutationFunction<VerifyLegalDataMutation, VerifyLegalDataMutationVariables>;

/**
 * __useVerifyLegalDataMutation__
 *
 * To run a mutation, you first call `useVerifyLegalDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyLegalDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyLegalDataMutation, { data, loading, error }] = useVerifyLegalDataMutation({
 *   variables: {
 *      entityLegalDataId: // value for 'entityLegalDataId'
 *   },
 * });
 */
export function useVerifyLegalDataMutation(baseOptions?: Apollo.MutationHookOptions<VerifyLegalDataMutation, VerifyLegalDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyLegalDataMutation, VerifyLegalDataMutationVariables>(VerifyLegalDataDocument, options);
      }
export type VerifyLegalDataMutationHookResult = ReturnType<typeof useVerifyLegalDataMutation>;
export type VerifyLegalDataMutationResult = Apollo.MutationResult<VerifyLegalDataMutation>;
export type VerifyLegalDataMutationOptions = Apollo.BaseMutationOptions<VerifyLegalDataMutation, VerifyLegalDataMutationVariables>;
export const VerifyPassportDataDocument = gql`
    mutation verifyPassportData($passportId: Int!) {
  verifyPassportData(passportId: $passportId) {
    ...FragmentUserPasport
  }
}
    ${FragmentUserPasportFragmentDoc}`;
export type VerifyPassportDataMutationFn = Apollo.MutationFunction<VerifyPassportDataMutation, VerifyPassportDataMutationVariables>;

/**
 * __useVerifyPassportDataMutation__
 *
 * To run a mutation, you first call `useVerifyPassportDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyPassportDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyPassportDataMutation, { data, loading, error }] = useVerifyPassportDataMutation({
 *   variables: {
 *      passportId: // value for 'passportId'
 *   },
 * });
 */
export function useVerifyPassportDataMutation(baseOptions?: Apollo.MutationHookOptions<VerifyPassportDataMutation, VerifyPassportDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyPassportDataMutation, VerifyPassportDataMutationVariables>(VerifyPassportDataDocument, options);
      }
export type VerifyPassportDataMutationHookResult = ReturnType<typeof useVerifyPassportDataMutation>;
export type VerifyPassportDataMutationResult = Apollo.MutationResult<VerifyPassportDataMutation>;
export type VerifyPassportDataMutationOptions = Apollo.BaseMutationOptions<VerifyPassportDataMutation, VerifyPassportDataMutationVariables>;
export const GetAvailableWarehouseProductByIdDocument = gql`
    query GetAvailableWarehouseProductById($warehouseId: ID!, $productId: ID!) {
  getAvailableWarehouseProductById(
    warehouseId: $warehouseId
    productId: $productId
  ) {
    inTransitQuantity
    reservedQuantity
    availableQuantity
  }
}
    `;

/**
 * __useGetAvailableWarehouseProductByIdQuery__
 *
 * To run a query within a React component, call `useGetAvailableWarehouseProductByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableWarehouseProductByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableWarehouseProductByIdQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetAvailableWarehouseProductByIdQuery(baseOptions: Apollo.QueryHookOptions<GetAvailableWarehouseProductByIdQuery, GetAvailableWarehouseProductByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailableWarehouseProductByIdQuery, GetAvailableWarehouseProductByIdQueryVariables>(GetAvailableWarehouseProductByIdDocument, options);
      }
export function useGetAvailableWarehouseProductByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableWarehouseProductByIdQuery, GetAvailableWarehouseProductByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailableWarehouseProductByIdQuery, GetAvailableWarehouseProductByIdQueryVariables>(GetAvailableWarehouseProductByIdDocument, options);
        }
export type GetAvailableWarehouseProductByIdQueryHookResult = ReturnType<typeof useGetAvailableWarehouseProductByIdQuery>;
export type GetAvailableWarehouseProductByIdLazyQueryHookResult = ReturnType<typeof useGetAvailableWarehouseProductByIdLazyQuery>;
export type GetAvailableWarehouseProductByIdQueryResult = Apollo.QueryResult<GetAvailableWarehouseProductByIdQuery, GetAvailableWarehouseProductByIdQueryVariables>;
export const GetAvailableWarehouseProductsDocument = gql`
    query GetAvailableWarehouseProducts($warehouseId: ID, $search: String) {
  getAvailableWarehouseProducts(warehouseId: $warehouseId, search: $search) {
    inTransitQuantity
    reservedQuantity
    availableQuantity
    product {
      id
      name
      sku
      detailsDescriptionRU
      detailsDescriptionEN
      state
      trademark
      declaredValue
      country {
        ...FragmentDeliveryCountry
      }
      netWeight
      code
      userId
      fnsku
      upc
      actualDimensions {
        id
        warehouseShipmentId
        weight
        length
        width
        height
      }
      declaredDimensions {
        id
        warehouseShipmentId
        weight
        length
        width
        height
      }
    }
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;

/**
 * __useGetAvailableWarehouseProductsQuery__
 *
 * To run a query within a React component, call `useGetAvailableWarehouseProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableWarehouseProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableWarehouseProductsQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetAvailableWarehouseProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetAvailableWarehouseProductsQuery, GetAvailableWarehouseProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailableWarehouseProductsQuery, GetAvailableWarehouseProductsQueryVariables>(GetAvailableWarehouseProductsDocument, options);
      }
export function useGetAvailableWarehouseProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableWarehouseProductsQuery, GetAvailableWarehouseProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailableWarehouseProductsQuery, GetAvailableWarehouseProductsQueryVariables>(GetAvailableWarehouseProductsDocument, options);
        }
export type GetAvailableWarehouseProductsQueryHookResult = ReturnType<typeof useGetAvailableWarehouseProductsQuery>;
export type GetAvailableWarehouseProductsLazyQueryHookResult = ReturnType<typeof useGetAvailableWarehouseProductsLazyQuery>;
export type GetAvailableWarehouseProductsQueryResult = Apollo.QueryResult<GetAvailableWarehouseProductsQuery, GetAvailableWarehouseProductsQueryVariables>;
export const GetB2BOrderByIdDocument = gql`
    query GetB2BOrderById($getB2BOrderByIdId: Int!) {
  getB2BOrderById(id: $getB2BOrderByIdId) {
    id
    client {
      id
      name
    }
    wrapType
    wrapWay
    shipmentInfo {
      driverInfo {
        carModel
        carNumber
        fullName
        mobileNumber
      }
      scheduledDate
      scheduledTime
      shipmentLabelFileUrl
      typeMethod
    }
    barcodeId
    externalOrdeId
    shipmentPointTitle
    warehouseName
    comment
    technicalTaskFileUrl
    products {
      productId
      expectedQuantity
      acceptedQuantity
      relatedProduct {
        barcodeId
        name
        sku
      }
    }
    cargoPlaces {
      id
      type
      externalBarcodeUrl
      uniqSkuCount
      productQty
      weight
      length
      width
      height
      productsItems {
        id
        cargoId
        productId
        quantity
      }
    }
    servicesWithCost {
      costInUSD
      description
      id
      title
      qty
    }
    totalCost
    warehouseStatus
    createdAt
  }
}
    `;

/**
 * __useGetB2BOrderByIdQuery__
 *
 * To run a query within a React component, call `useGetB2BOrderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetB2BOrderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetB2BOrderByIdQuery({
 *   variables: {
 *      getB2BOrderByIdId: // value for 'getB2BOrderByIdId'
 *   },
 * });
 */
export function useGetB2BOrderByIdQuery(baseOptions: Apollo.QueryHookOptions<GetB2BOrderByIdQuery, GetB2BOrderByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetB2BOrderByIdQuery, GetB2BOrderByIdQueryVariables>(GetB2BOrderByIdDocument, options);
      }
export function useGetB2BOrderByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetB2BOrderByIdQuery, GetB2BOrderByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetB2BOrderByIdQuery, GetB2BOrderByIdQueryVariables>(GetB2BOrderByIdDocument, options);
        }
export type GetB2BOrderByIdQueryHookResult = ReturnType<typeof useGetB2BOrderByIdQuery>;
export type GetB2BOrderByIdLazyQueryHookResult = ReturnType<typeof useGetB2BOrderByIdLazyQuery>;
export type GetB2BOrderByIdQueryResult = Apollo.QueryResult<GetB2BOrderByIdQuery, GetB2BOrderByIdQueryVariables>;
export const GetB2BOrderListDocument = gql`
    query GetB2BOrderList($filter: B2BOrderListFilter!) {
  getB2BOrderList(filter: $filter) {
    rows {
      id
      client {
        name
        id
      }
      barcodeId
      externalOrdeId
      paymentStatus {
        explain
        id
        name
      }
      shipmentPointTitle
      productsQty
      warehouseName
      totalCost
      createdAt
      shipmentDate
      warehouseStatus
    }
    count
  }
}
    `;

/**
 * __useGetB2BOrderListQuery__
 *
 * To run a query within a React component, call `useGetB2BOrderListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetB2BOrderListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetB2BOrderListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetB2BOrderListQuery(baseOptions: Apollo.QueryHookOptions<GetB2BOrderListQuery, GetB2BOrderListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetB2BOrderListQuery, GetB2BOrderListQueryVariables>(GetB2BOrderListDocument, options);
      }
export function useGetB2BOrderListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetB2BOrderListQuery, GetB2BOrderListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetB2BOrderListQuery, GetB2BOrderListQueryVariables>(GetB2BOrderListDocument, options);
        }
export type GetB2BOrderListQueryHookResult = ReturnType<typeof useGetB2BOrderListQuery>;
export type GetB2BOrderListLazyQueryHookResult = ReturnType<typeof useGetB2BOrderListLazyQuery>;
export type GetB2BOrderListQueryResult = Apollo.QueryResult<GetB2BOrderListQuery, GetB2BOrderListQueryVariables>;
export const GetB2BShipmentPointsDocument = gql`
    query GetB2BShipmentPoints($limit: Int, $offset: Int) {
  getB2BShipmentPoints(limit: $limit, offset: $offset) {
    rows {
      description
      id
      name
    }
    count
  }
}
    `;

/**
 * __useGetB2BShipmentPointsQuery__
 *
 * To run a query within a React component, call `useGetB2BShipmentPointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetB2BShipmentPointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetB2BShipmentPointsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetB2BShipmentPointsQuery(baseOptions?: Apollo.QueryHookOptions<GetB2BShipmentPointsQuery, GetB2BShipmentPointsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetB2BShipmentPointsQuery, GetB2BShipmentPointsQueryVariables>(GetB2BShipmentPointsDocument, options);
      }
export function useGetB2BShipmentPointsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetB2BShipmentPointsQuery, GetB2BShipmentPointsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetB2BShipmentPointsQuery, GetB2BShipmentPointsQueryVariables>(GetB2BShipmentPointsDocument, options);
        }
export type GetB2BShipmentPointsQueryHookResult = ReturnType<typeof useGetB2BShipmentPointsQuery>;
export type GetB2BShipmentPointsLazyQueryHookResult = ReturnType<typeof useGetB2BShipmentPointsLazyQuery>;
export type GetB2BShipmentPointsQueryResult = Apollo.QueryResult<GetB2BShipmentPointsQuery, GetB2BShipmentPointsQueryVariables>;
export const GetB2BShipmentServicesDocument = gql`
    query GetB2BShipmentServices($limit: Int, $offset: Int) {
  getB2BShipmentServices(limit: $limit, offset: $offset) {
    rows {
      costInUSD
      description
      id
      title
    }
    count
  }
}
    `;

/**
 * __useGetB2BShipmentServicesQuery__
 *
 * To run a query within a React component, call `useGetB2BShipmentServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetB2BShipmentServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetB2BShipmentServicesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetB2BShipmentServicesQuery(baseOptions?: Apollo.QueryHookOptions<GetB2BShipmentServicesQuery, GetB2BShipmentServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetB2BShipmentServicesQuery, GetB2BShipmentServicesQueryVariables>(GetB2BShipmentServicesDocument, options);
      }
export function useGetB2BShipmentServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetB2BShipmentServicesQuery, GetB2BShipmentServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetB2BShipmentServicesQuery, GetB2BShipmentServicesQueryVariables>(GetB2BShipmentServicesDocument, options);
        }
export type GetB2BShipmentServicesQueryHookResult = ReturnType<typeof useGetB2BShipmentServicesQuery>;
export type GetB2BShipmentServicesLazyQueryHookResult = ReturnType<typeof useGetB2BShipmentServicesLazyQuery>;
export type GetB2BShipmentServicesQueryResult = Apollo.QueryResult<GetB2BShipmentServicesQuery, GetB2BShipmentServicesQueryVariables>;
export const GetCitiesByCountryIsoDocument = gql`
    query getCitiesByCountryIso($iso: String!) {
  getCitiesByCountryIso(iso: $iso) {
    name
  }
}
    `;

/**
 * __useGetCitiesByCountryIsoQuery__
 *
 * To run a query within a React component, call `useGetCitiesByCountryIsoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCitiesByCountryIsoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCitiesByCountryIsoQuery({
 *   variables: {
 *      iso: // value for 'iso'
 *   },
 * });
 */
export function useGetCitiesByCountryIsoQuery(baseOptions: Apollo.QueryHookOptions<GetCitiesByCountryIsoQuery, GetCitiesByCountryIsoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCitiesByCountryIsoQuery, GetCitiesByCountryIsoQueryVariables>(GetCitiesByCountryIsoDocument, options);
      }
export function useGetCitiesByCountryIsoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCitiesByCountryIsoQuery, GetCitiesByCountryIsoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCitiesByCountryIsoQuery, GetCitiesByCountryIsoQueryVariables>(GetCitiesByCountryIsoDocument, options);
        }
export type GetCitiesByCountryIsoQueryHookResult = ReturnType<typeof useGetCitiesByCountryIsoQuery>;
export type GetCitiesByCountryIsoLazyQueryHookResult = ReturnType<typeof useGetCitiesByCountryIsoLazyQuery>;
export type GetCitiesByCountryIsoQueryResult = Apollo.QueryResult<GetCitiesByCountryIsoQuery, GetCitiesByCountryIsoQueryVariables>;
export const GetConfigurationListDocument = gql`
    query getConfigurationList {
  getConfigurationList
}
    `;

/**
 * __useGetConfigurationListQuery__
 *
 * To run a query within a React component, call `useGetConfigurationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfigurationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfigurationListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetConfigurationListQuery(baseOptions?: Apollo.QueryHookOptions<GetConfigurationListQuery, GetConfigurationListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConfigurationListQuery, GetConfigurationListQueryVariables>(GetConfigurationListDocument, options);
      }
export function useGetConfigurationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConfigurationListQuery, GetConfigurationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConfigurationListQuery, GetConfigurationListQueryVariables>(GetConfigurationListDocument, options);
        }
export type GetConfigurationListQueryHookResult = ReturnType<typeof useGetConfigurationListQuery>;
export type GetConfigurationListLazyQueryHookResult = ReturnType<typeof useGetConfigurationListLazyQuery>;
export type GetConfigurationListQueryResult = Apollo.QueryResult<GetConfigurationListQuery, GetConfigurationListQueryVariables>;
export const GetConsolidationByIdDocument = gql`
    query getConsolidationById($id: ID!) {
  getConsolidationById(id: $id) {
    ...ConsolidationFragment
  }
}
    ${ConsolidationFragmentFragmentDoc}`;

/**
 * __useGetConsolidationByIdQuery__
 *
 * To run a query within a React component, call `useGetConsolidationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsolidationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsolidationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetConsolidationByIdQuery(baseOptions: Apollo.QueryHookOptions<GetConsolidationByIdQuery, GetConsolidationByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConsolidationByIdQuery, GetConsolidationByIdQueryVariables>(GetConsolidationByIdDocument, options);
      }
export function useGetConsolidationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConsolidationByIdQuery, GetConsolidationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConsolidationByIdQuery, GetConsolidationByIdQueryVariables>(GetConsolidationByIdDocument, options);
        }
export type GetConsolidationByIdQueryHookResult = ReturnType<typeof useGetConsolidationByIdQuery>;
export type GetConsolidationByIdLazyQueryHookResult = ReturnType<typeof useGetConsolidationByIdLazyQuery>;
export type GetConsolidationByIdQueryResult = Apollo.QueryResult<GetConsolidationByIdQuery, GetConsolidationByIdQueryVariables>;
export const GetConsolidationsDeliveryVariantsDocument = gql`
    query getConsolidationsDeliveryVariants($data: shipmentDataRate!) {
  getConsolidationsDeliveryVariants(data: $data) {
    weight
    volume_weight
    weight_to_pay
    variants {
      name
      summary_amount
      typeDelivery
      error
      accountId
    }
  }
}
    `;

/**
 * __useGetConsolidationsDeliveryVariantsQuery__
 *
 * To run a query within a React component, call `useGetConsolidationsDeliveryVariantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsolidationsDeliveryVariantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsolidationsDeliveryVariantsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetConsolidationsDeliveryVariantsQuery(baseOptions: Apollo.QueryHookOptions<GetConsolidationsDeliveryVariantsQuery, GetConsolidationsDeliveryVariantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConsolidationsDeliveryVariantsQuery, GetConsolidationsDeliveryVariantsQueryVariables>(GetConsolidationsDeliveryVariantsDocument, options);
      }
export function useGetConsolidationsDeliveryVariantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConsolidationsDeliveryVariantsQuery, GetConsolidationsDeliveryVariantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConsolidationsDeliveryVariantsQuery, GetConsolidationsDeliveryVariantsQueryVariables>(GetConsolidationsDeliveryVariantsDocument, options);
        }
export type GetConsolidationsDeliveryVariantsQueryHookResult = ReturnType<typeof useGetConsolidationsDeliveryVariantsQuery>;
export type GetConsolidationsDeliveryVariantsLazyQueryHookResult = ReturnType<typeof useGetConsolidationsDeliveryVariantsLazyQuery>;
export type GetConsolidationsDeliveryVariantsQueryResult = Apollo.QueryResult<GetConsolidationsDeliveryVariantsQuery, GetConsolidationsDeliveryVariantsQueryVariables>;
export const GetConsolidationsListDocument = gql`
    query getConsolidationsList {
  getConsolidationsList {
    consolidations {
      ...ConsolidationFragment
    }
    count
  }
}
    ${ConsolidationFragmentFragmentDoc}`;

/**
 * __useGetConsolidationsListQuery__
 *
 * To run a query within a React component, call `useGetConsolidationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsolidationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsolidationsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetConsolidationsListQuery(baseOptions?: Apollo.QueryHookOptions<GetConsolidationsListQuery, GetConsolidationsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConsolidationsListQuery, GetConsolidationsListQueryVariables>(GetConsolidationsListDocument, options);
      }
export function useGetConsolidationsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConsolidationsListQuery, GetConsolidationsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConsolidationsListQuery, GetConsolidationsListQueryVariables>(GetConsolidationsListDocument, options);
        }
export type GetConsolidationsListQueryHookResult = ReturnType<typeof useGetConsolidationsListQuery>;
export type GetConsolidationsListLazyQueryHookResult = ReturnType<typeof useGetConsolidationsListLazyQuery>;
export type GetConsolidationsListQueryResult = Apollo.QueryResult<GetConsolidationsListQuery, GetConsolidationsListQueryVariables>;
export const GetContactDocument = gql`
    query getContact($id: ID!) {
  contact(id: $id) {
    id
    name
    company
    country {
      ...FragmentDeliveryCountry
    }
    address
    address2
    address3
    zipCode
    city
    city
    state
    phone
    email
    eoriCode
    contactName
    innKppCode
    remark
    type
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;

/**
 * __useGetContactQuery__
 *
 * To run a query within a React component, call `useGetContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContactQuery(baseOptions: Apollo.QueryHookOptions<GetContactQuery, GetContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactQuery, GetContactQueryVariables>(GetContactDocument, options);
      }
export function useGetContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactQuery, GetContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactQuery, GetContactQueryVariables>(GetContactDocument, options);
        }
export type GetContactQueryHookResult = ReturnType<typeof useGetContactQuery>;
export type GetContactLazyQueryHookResult = ReturnType<typeof useGetContactLazyQuery>;
export type GetContactQueryResult = Apollo.QueryResult<GetContactQuery, GetContactQueryVariables>;
export const GetContactsDocument = gql`
    query getContacts {
  contacts {
    id
    name
    company
    country {
      ...FragmentDeliveryCountry
    }
    address
    address2
    address3
    zipCode
    city
    city
    state
    phone
    email
    innKppCode
    remark
    eoriCode
    contactName
    type
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;

/**
 * __useGetContactsQuery__
 *
 * To run a query within a React component, call `useGetContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContactsQuery(baseOptions?: Apollo.QueryHookOptions<GetContactsQuery, GetContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactsQuery, GetContactsQueryVariables>(GetContactsDocument, options);
      }
export function useGetContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactsQuery, GetContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactsQuery, GetContactsQueryVariables>(GetContactsDocument, options);
        }
export type GetContactsQueryHookResult = ReturnType<typeof useGetContactsQuery>;
export type GetContactsLazyQueryHookResult = ReturnType<typeof useGetContactsLazyQuery>;
export type GetContactsQueryResult = Apollo.QueryResult<GetContactsQuery, GetContactsQueryVariables>;
export const GetCourierListDocument = gql`
    query getCourierList($limit: Int, $offset: Int) {
  getCourierList(limit: $limit, offset: $offset) {
    count
    rows {
      id
      pickupDate
      readyByTime
      closeTime
      contactName
      contactPhone
      packageLocation
      pieces {
        weight
        width
        height
        length
      }
      confirmationNumber
      errors
    }
  }
}
    `;

/**
 * __useGetCourierListQuery__
 *
 * To run a query within a React component, call `useGetCourierListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourierListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourierListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetCourierListQuery(baseOptions?: Apollo.QueryHookOptions<GetCourierListQuery, GetCourierListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCourierListQuery, GetCourierListQueryVariables>(GetCourierListDocument, options);
      }
export function useGetCourierListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCourierListQuery, GetCourierListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCourierListQuery, GetCourierListQueryVariables>(GetCourierListDocument, options);
        }
export type GetCourierListQueryHookResult = ReturnType<typeof useGetCourierListQuery>;
export type GetCourierListLazyQueryHookResult = ReturnType<typeof useGetCourierListLazyQuery>;
export type GetCourierListQueryResult = Apollo.QueryResult<GetCourierListQuery, GetCourierListQueryVariables>;
export const GetCurrentUserDocument = gql`
    query getCurrentUser {
  currentUser {
    id
    email
    name
    company
    country {
      ...FragmentDeliveryCountry
    }
    address
    address2
    address3
    zipCode
    city
    state
    phone
    status
    createdAt
    updatedAt
    passportData {
      ...FragmentUserPasport
    }
    entityLegalData {
      id
      company
      inn
      kpp
      MSRN
      generalManager
      legalAddress
      nameOfTheBank
      BIK
      paymentAccount
      correspondentAccount
      fileName
      userId
      isDataVerified
    }
    role
    warehouse {
      id
      code
    }
    whFeePersonalDiscount
  }
}
    ${FragmentDeliveryCountryFragmentDoc}
${FragmentUserPasportFragmentDoc}`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetDhlShipmentListDocument = gql`
    query getDHLShipmentList($limit: Int, $offset: Int) {
  getDHLShipmentList(limit: $limit, offset: $offset) {
    count
    rows {
      id
      airwayBillNumber
      status
      senderAccountId
      parcelId
      price
      createdAt
      updatedAt
      waybill
    }
  }
}
    `;

/**
 * __useGetDhlShipmentListQuery__
 *
 * To run a query within a React component, call `useGetDhlShipmentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDhlShipmentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDhlShipmentListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetDhlShipmentListQuery(baseOptions?: Apollo.QueryHookOptions<GetDhlShipmentListQuery, GetDhlShipmentListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDhlShipmentListQuery, GetDhlShipmentListQueryVariables>(GetDhlShipmentListDocument, options);
      }
export function useGetDhlShipmentListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDhlShipmentListQuery, GetDhlShipmentListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDhlShipmentListQuery, GetDhlShipmentListQueryVariables>(GetDhlShipmentListDocument, options);
        }
export type GetDhlShipmentListQueryHookResult = ReturnType<typeof useGetDhlShipmentListQuery>;
export type GetDhlShipmentListLazyQueryHookResult = ReturnType<typeof useGetDhlShipmentListLazyQuery>;
export type GetDhlShipmentListQueryResult = Apollo.QueryResult<GetDhlShipmentListQuery, GetDhlShipmentListQueryVariables>;
export const GetDeliveryCountriesDocument = gql`
    query getDeliveryCountries {
  deliveryCountries {
    id
    order
    name
    daysToDelivery
    zoneId
    nameEng
    iso
    customsInfo
  }
}
    `;

/**
 * __useGetDeliveryCountriesQuery__
 *
 * To run a query within a React component, call `useGetDeliveryCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDeliveryCountriesQuery(baseOptions?: Apollo.QueryHookOptions<GetDeliveryCountriesQuery, GetDeliveryCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeliveryCountriesQuery, GetDeliveryCountriesQueryVariables>(GetDeliveryCountriesDocument, options);
      }
export function useGetDeliveryCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeliveryCountriesQuery, GetDeliveryCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeliveryCountriesQuery, GetDeliveryCountriesQueryVariables>(GetDeliveryCountriesDocument, options);
        }
export type GetDeliveryCountriesQueryHookResult = ReturnType<typeof useGetDeliveryCountriesQuery>;
export type GetDeliveryCountriesLazyQueryHookResult = ReturnType<typeof useGetDeliveryCountriesLazyQuery>;
export type GetDeliveryCountriesQueryResult = Apollo.QueryResult<GetDeliveryCountriesQuery, GetDeliveryCountriesQueryVariables>;
export const GetListOfDeliveryOptionsDocument = gql`
    query getListOfDeliveryOptions($input: CalculationInput) {
  getListOfDeliveryOptions(input: $input) {
    weight
    volume_weight
    weight_to_pay
    special_tax
    insurance
    data {
      name
      success
      days_to_delivery
      summary_amount
      remoteArea
      typeDelivery
      fuelSurcharge
      dollarRate
      errors
      rateId
      shipmentId
      priceCurrency
    }
  }
}
    `;

/**
 * __useGetListOfDeliveryOptionsQuery__
 *
 * To run a query within a React component, call `useGetListOfDeliveryOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOfDeliveryOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOfDeliveryOptionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetListOfDeliveryOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetListOfDeliveryOptionsQuery, GetListOfDeliveryOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListOfDeliveryOptionsQuery, GetListOfDeliveryOptionsQueryVariables>(GetListOfDeliveryOptionsDocument, options);
      }
export function useGetListOfDeliveryOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListOfDeliveryOptionsQuery, GetListOfDeliveryOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListOfDeliveryOptionsQuery, GetListOfDeliveryOptionsQueryVariables>(GetListOfDeliveryOptionsDocument, options);
        }
export type GetListOfDeliveryOptionsQueryHookResult = ReturnType<typeof useGetListOfDeliveryOptionsQuery>;
export type GetListOfDeliveryOptionsLazyQueryHookResult = ReturnType<typeof useGetListOfDeliveryOptionsLazyQuery>;
export type GetListOfDeliveryOptionsQueryResult = Apollo.QueryResult<GetListOfDeliveryOptionsQuery, GetListOfDeliveryOptionsQueryVariables>;
export const GetListOfReturnedShipmentsDocument = gql`
    query getListOfReturnedShipments($filter: WarehouseShipmentFilter) {
  getListOfReturnedShipments(filter: $filter) {
    count
    rows {
      id
      createdAt
      warehouse {
        id
        address
        code
      }
      trackNumber
      carrierCode
      user {
        name
        address
        city
        country {
          name
        }
      }
      deliveryStatus {
        id
        name
        explain
      }
      events {
        operation
        manager {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetListOfReturnedShipmentsQuery__
 *
 * To run a query within a React component, call `useGetListOfReturnedShipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOfReturnedShipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOfReturnedShipmentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetListOfReturnedShipmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetListOfReturnedShipmentsQuery, GetListOfReturnedShipmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListOfReturnedShipmentsQuery, GetListOfReturnedShipmentsQueryVariables>(GetListOfReturnedShipmentsDocument, options);
      }
export function useGetListOfReturnedShipmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListOfReturnedShipmentsQuery, GetListOfReturnedShipmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListOfReturnedShipmentsQuery, GetListOfReturnedShipmentsQueryVariables>(GetListOfReturnedShipmentsDocument, options);
        }
export type GetListOfReturnedShipmentsQueryHookResult = ReturnType<typeof useGetListOfReturnedShipmentsQuery>;
export type GetListOfReturnedShipmentsLazyQueryHookResult = ReturnType<typeof useGetListOfReturnedShipmentsLazyQuery>;
export type GetListOfReturnedShipmentsQueryResult = Apollo.QueryResult<GetListOfReturnedShipmentsQuery, GetListOfReturnedShipmentsQueryVariables>;
export const GetManagersDocument = gql`
    query getManagers($limit: Int, $offset: Int) {
  getManagers(limit: $limit, offset: $offset) {
    count
    rows {
      ...managerFragment
      warehouse {
        id
        code
      }
    }
  }
}
    ${ManagerFragmentFragmentDoc}`;

/**
 * __useGetManagersQuery__
 *
 * To run a query within a React component, call `useGetManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetManagersQuery(baseOptions?: Apollo.QueryHookOptions<GetManagersQuery, GetManagersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManagersQuery, GetManagersQueryVariables>(GetManagersDocument, options);
      }
export function useGetManagersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManagersQuery, GetManagersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManagersQuery, GetManagersQueryVariables>(GetManagersDocument, options);
        }
export type GetManagersQueryHookResult = ReturnType<typeof useGetManagersQuery>;
export type GetManagersLazyQueryHookResult = ReturnType<typeof useGetManagersLazyQuery>;
export type GetManagersQueryResult = Apollo.QueryResult<GetManagersQuery, GetManagersQueryVariables>;
export const GetMyProductByIdDocument = gql`
    query getMyProductById($productId: Int!) {
  getMyProductById(productId: $productId) {
    id
    name
    sku
    detailsDescriptionRU
    detailsDescriptionEN
    state
    trademark
    declaredValue
    country {
      id
      nameEng
    }
    netWeight
    code
    userId
    fnsku
    upc
    actualDimensions {
      id
      warehouseShipmentId
      weight
      length
      width
      height
    }
    declaredDimensions {
      id
      warehouseShipmentId
      weight
      length
      width
      height
    }
    productAvailabilityQuantity {
      inTransitQuantity
      reservedQuantity
      availableQuantity
    }
    createdAt
    barcodeId
    monthlyWarehouseStorageFee
    isCustomBarcodeId
    currency
  }
}
    `;

/**
 * __useGetMyProductByIdQuery__
 *
 * To run a query within a React component, call `useGetMyProductByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyProductByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyProductByIdQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetMyProductByIdQuery(baseOptions: Apollo.QueryHookOptions<GetMyProductByIdQuery, GetMyProductByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyProductByIdQuery, GetMyProductByIdQueryVariables>(GetMyProductByIdDocument, options);
      }
export function useGetMyProductByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyProductByIdQuery, GetMyProductByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyProductByIdQuery, GetMyProductByIdQueryVariables>(GetMyProductByIdDocument, options);
        }
export type GetMyProductByIdQueryHookResult = ReturnType<typeof useGetMyProductByIdQuery>;
export type GetMyProductByIdLazyQueryHookResult = ReturnType<typeof useGetMyProductByIdLazyQuery>;
export type GetMyProductByIdQueryResult = Apollo.QueryResult<GetMyProductByIdQuery, GetMyProductByIdQueryVariables>;
export const GetMyProductListDocument = gql`
    query GetMyProductList($warehouseId: ID, $limit: Int, $offset: Int, $search: String, $sort: ProductSort) {
  getMyProductList(
    warehouseId: $warehouseId
    limit: $limit
    offset: $offset
    search: $search
    sort: $sort
  ) {
    count
    rows {
      id
      name
      sku
      detailsDescriptionRU
      detailsDescriptionEN
      state
      trademark
      declaredValue
      country {
        ...FragmentDeliveryCountry
      }
      netWeight
      code
      userId
      fnsku
      upc
      actualDimensions {
        id
        warehouseShipmentId
        weight
        length
        width
        height
      }
      declaredDimensions {
        id
        warehouseShipmentId
        weight
        length
        width
        height
      }
      barcodeId
      productAvailabilityQuantity {
        inTransitQuantity
        reservedQuantity
        availableQuantity
      }
      createdAt
      isCustomBarcodeId
      currency
    }
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;

/**
 * __useGetMyProductListQuery__
 *
 * To run a query within a React component, call `useGetMyProductListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyProductListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyProductListQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetMyProductListQuery(baseOptions?: Apollo.QueryHookOptions<GetMyProductListQuery, GetMyProductListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyProductListQuery, GetMyProductListQueryVariables>(GetMyProductListDocument, options);
      }
export function useGetMyProductListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyProductListQuery, GetMyProductListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyProductListQuery, GetMyProductListQueryVariables>(GetMyProductListDocument, options);
        }
export type GetMyProductListQueryHookResult = ReturnType<typeof useGetMyProductListQuery>;
export type GetMyProductListLazyQueryHookResult = ReturnType<typeof useGetMyProductListLazyQuery>;
export type GetMyProductListQueryResult = Apollo.QueryResult<GetMyProductListQuery, GetMyProductListQueryVariables>;
export const GetNotificationListDocument = gql`
    query GetNotificationList($limit: Int, $offset: Int) {
  getNotificationList(limit: $limit, offset: $offset) {
    rows {
      data
      isRead
      text
      sendAt
      topic
    }
    count
  }
}
    `;

/**
 * __useGetNotificationListQuery__
 *
 * To run a query within a React component, call `useGetNotificationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetNotificationListQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationListQuery, GetNotificationListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationListQuery, GetNotificationListQueryVariables>(GetNotificationListDocument, options);
      }
export function useGetNotificationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationListQuery, GetNotificationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationListQuery, GetNotificationListQueryVariables>(GetNotificationListDocument, options);
        }
export type GetNotificationListQueryHookResult = ReturnType<typeof useGetNotificationListQuery>;
export type GetNotificationListLazyQueryHookResult = ReturnType<typeof useGetNotificationListLazyQuery>;
export type GetNotificationListQueryResult = Apollo.QueryResult<GetNotificationListQuery, GetNotificationListQueryVariables>;
export const ParcelDocument = gql`
    query parcel($id: ID!) {
  parcel(id: $id) {
    id
    user {
      id
      name
    }
    sender {
      id
      name
      company
      country {
        ...FragmentDeliveryCountry
      }
      address
      address2
      address3
      zipCode
      city
      state
      phone
      email
      innKppCode
      remark
    }
    receiver {
      id
      name
      company
      country {
        ...FragmentDeliveryCountry
      }
      address
      address2
      address3
      zipCode
      city
      state
      phone
      email
      innKppCode
      remark
      eoriCode
    }
    packages {
      id
      weightKg
      lengthCm
      widthCm
      heightCm
      units {
        id
        nameRU
        nameEN
        tradeMark
        code
        quantity
        price
        country {
          ...FragmentDeliveryCountry
        }
        netWeight
        state
      }
    }
    typeDelivery {
      id
      name
      serviceName
    }
    fileTokenInvoiceXlsx
    senderNotes
    senderMark
    additionalInsurance
    declaredAmount
    declaredWeight
    actualAmount
    actualWeight
    trackNumber
    carrierCode
    deliveryStatusId
    paymentStatusId
    currency
    sentToWarehouseId
    exportReason
    paymentOfTaxes
    vatKey
    vatID
    isConsolidationPiece
    consolidationTrackNumber {
      service
      consolidationTrackNumber
    }
    barcode
    pathNameFile
    orderCostCurrency
    createdAt
    warehouseId
    warehouse {
      id
      code
      address
      address2
      company
      phone
      country {
        ...FragmentDeliveryCountry
      }
      managerName
      city
      zipCode
      state
      email
    }
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;

/**
 * __useParcelQuery__
 *
 * To run a query within a React component, call `useParcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useParcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParcelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useParcelQuery(baseOptions: Apollo.QueryHookOptions<ParcelQuery, ParcelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ParcelQuery, ParcelQueryVariables>(ParcelDocument, options);
      }
export function useParcelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParcelQuery, ParcelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ParcelQuery, ParcelQueryVariables>(ParcelDocument, options);
        }
export type ParcelQueryHookResult = ReturnType<typeof useParcelQuery>;
export type ParcelLazyQueryHookResult = ReturnType<typeof useParcelLazyQuery>;
export type ParcelQueryResult = Apollo.QueryResult<ParcelQuery, ParcelQueryVariables>;
export const ParcelsDocument = gql`
    query parcels($offset: Int, $limit: Int, $search: String, $date: Int, $paymentStatusId: Int, $deliveryStatusId: Int, $isUserAccount: Boolean, $convert_to_usd: Boolean) {
  parcels(
    offset: $offset
    limit: $limit
    search: $search
    date: $date
    paymentStatusId: $paymentStatusId
    deliveryStatusId: $deliveryStatusId
    isUserAccount: $isUserAccount
    convert_to_usd: $convert_to_usd
  ) {
    count
    rows {
      user {
        id
        senderAccount
      }
      id
      currency
      sender {
        id
        name
        company
        country {
          ...FragmentDeliveryCountry
        }
        address
        address2
        address3
        zipCode
        city
        state
        phone
        email
        innKppCode
        remark
        eoriCode
      }
      receiver {
        id
        name
        company
        country {
          ...FragmentDeliveryCountry
        }
        address
        address2
        address3
        zipCode
        city
        state
        phone
        email
        innKppCode
        remark
        eoriCode
      }
      packages {
        id
        weightKg
        lengthCm
        widthCm
        heightCm
        units {
          id
          nameRU
          nameEN
          tradeMark
          code
          quantity
          price
          country {
            ...FragmentDeliveryCountry
          }
          netWeight
        }
      }
      sentToWarehouseId
      typeDelivery {
        id
        name
        serviceName
      }
      fileTokenInvoiceXlsx
      senderNotes
      senderMark
      exportReason
      paymentOfTaxes
      vatID
      additionalInsurance
      declaredWeight
      actualWeight
      declaredAmount
      actualAmount
      trackNumber
      carrierCode
      deliveryStatusId
      paymentStatusId
      pathNameFile
      isConsolidationPiece
      barcode
      orderCostCurrency
      createdAt
    }
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;

/**
 * __useParcelsQuery__
 *
 * To run a query within a React component, call `useParcelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useParcelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParcelsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      date: // value for 'date'
 *      paymentStatusId: // value for 'paymentStatusId'
 *      deliveryStatusId: // value for 'deliveryStatusId'
 *      isUserAccount: // value for 'isUserAccount'
 *      convert_to_usd: // value for 'convert_to_usd'
 *   },
 * });
 */
export function useParcelsQuery(baseOptions?: Apollo.QueryHookOptions<ParcelsQuery, ParcelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ParcelsQuery, ParcelsQueryVariables>(ParcelsDocument, options);
      }
export function useParcelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParcelsQuery, ParcelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ParcelsQuery, ParcelsQueryVariables>(ParcelsDocument, options);
        }
export type ParcelsQueryHookResult = ReturnType<typeof useParcelsQuery>;
export type ParcelsLazyQueryHookResult = ReturnType<typeof useParcelsLazyQuery>;
export type ParcelsQueryResult = Apollo.QueryResult<ParcelsQuery, ParcelsQueryVariables>;
export const GetParcelsByTypeDocument = gql`
    query GetParcelsByType($offset: Int, $limit: Int, $search: String, $date: Int, $type: ParcelType, $deliveryStatusId: Int, $paymentStatusId: Int) {
  getParcelsByType(
    offset: $offset
    limit: $limit
    search: $search
    date: $date
    type: $type
    deliveryStatusId: $deliveryStatusId
    paymentStatusId: $paymentStatusId
  ) {
    count
    rows {
      id
      user {
        id
        senderAccount
      }
      sender {
        id
        name
        company
        country {
          ...FragmentDeliveryCountry
        }
        address
        address2
        address3
        zipCode
        city
        state
        phone
        email
        innKppCode
        remark
        eoriCode
      }
      receiver {
        id
        name
        company
        country {
          ...FragmentDeliveryCountry
        }
        address
        address2
        address3
        zipCode
        city
        state
        phone
        email
        innKppCode
        remark
        eoriCode
      }
      packages {
        id
        weightKg
        lengthCm
        widthCm
        heightCm
        units {
          id
          nameRU
          nameEN
          tradeMark
          code
          quantity
          price
          country {
            ...FragmentDeliveryCountry
          }
          netWeight
          state
        }
      }
      typeDelivery {
        id
        name
        serviceName
      }
      fileTokenInvoiceXlsx
      senderNotes
      senderMark
      additionalInsurance
      declaredAmount
      declaredWeight
      actualAmount
      actualWeight
      trackNumber
      carrierCode
      deliveryStatusId
      paymentStatusId
      pathNameFile
      isConsolidationPiece
      barcode
      orderCostCurrency
      createdAt
      warehouseId
      warehouse {
        id
        code
        address
        address2
        company
        phone
        country {
          ...FragmentDeliveryCountry
        }
        managerName
        city
        zipCode
        state
        email
      }
    }
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;

/**
 * __useGetParcelsByTypeQuery__
 *
 * To run a query within a React component, call `useGetParcelsByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParcelsByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParcelsByTypeQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      date: // value for 'date'
 *      type: // value for 'type'
 *      deliveryStatusId: // value for 'deliveryStatusId'
 *      paymentStatusId: // value for 'paymentStatusId'
 *   },
 * });
 */
export function useGetParcelsByTypeQuery(baseOptions?: Apollo.QueryHookOptions<GetParcelsByTypeQuery, GetParcelsByTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParcelsByTypeQuery, GetParcelsByTypeQueryVariables>(GetParcelsByTypeDocument, options);
      }
export function useGetParcelsByTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParcelsByTypeQuery, GetParcelsByTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParcelsByTypeQuery, GetParcelsByTypeQueryVariables>(GetParcelsByTypeDocument, options);
        }
export type GetParcelsByTypeQueryHookResult = ReturnType<typeof useGetParcelsByTypeQuery>;
export type GetParcelsByTypeLazyQueryHookResult = ReturnType<typeof useGetParcelsByTypeLazyQuery>;
export type GetParcelsByTypeQueryResult = Apollo.QueryResult<GetParcelsByTypeQuery, GetParcelsByTypeQueryVariables>;
export const GetPaymentHistoryListByUserIdDocument = gql`
    query getPaymentHistoryListByUserId($limit: Int, $offset: Int, $userId: Int) {
  getPaymentHistoryListByUserId(limit: $limit, offset: $offset, userId: $userId) {
    count
    rows {
      id
      userId
      transactionId
      parcelId
      amount
      currency
      confirmationUrl
      paymentType
      status
      createdAt
      updatedAt
      cancellationReason
      cdekParcelId
      reason
      balance
      parcel {
        id
      }
      warehouseShipment {
        id
        barcode
      }
      warehouseShipmentOrder {
        id
        barcodeId
      }
      returnShipment {
        barcodeId
        id
      }
      undeclaredShipment {
        id
      }
      b2bOrder {
        barcodeId
        id
      }
      paymentStatus
    }
    name
    email
    address
    address2
    address3
    phone
    balance
    state
    country {
      ...FragmentDeliveryCountry
    }
    zipCode
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;

/**
 * __useGetPaymentHistoryListByUserIdQuery__
 *
 * To run a query within a React component, call `useGetPaymentHistoryListByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentHistoryListByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentHistoryListByUserIdQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetPaymentHistoryListByUserIdQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentHistoryListByUserIdQuery, GetPaymentHistoryListByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentHistoryListByUserIdQuery, GetPaymentHistoryListByUserIdQueryVariables>(GetPaymentHistoryListByUserIdDocument, options);
      }
export function useGetPaymentHistoryListByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentHistoryListByUserIdQuery, GetPaymentHistoryListByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentHistoryListByUserIdQuery, GetPaymentHistoryListByUserIdQueryVariables>(GetPaymentHistoryListByUserIdDocument, options);
        }
export type GetPaymentHistoryListByUserIdQueryHookResult = ReturnType<typeof useGetPaymentHistoryListByUserIdQuery>;
export type GetPaymentHistoryListByUserIdLazyQueryHookResult = ReturnType<typeof useGetPaymentHistoryListByUserIdLazyQuery>;
export type GetPaymentHistoryListByUserIdQueryResult = Apollo.QueryResult<GetPaymentHistoryListByUserIdQuery, GetPaymentHistoryListByUserIdQueryVariables>;
export const GetQuoteDocument = gql`
    query getQuote($input: QuoteInput) {
  getQuote(input: $input) {
    errors
    rows {
      OriginServiceArea {
        FacilityCode
        ServiceAreaCode
      }
      DestinationServiceArea {
        FacilityCode
        ServiceAreaCode
      }
      GlobalProductCode
      LocalProductCode
      ProductShortName
      LocalProductName
      NetworkTypeCode
      POfferedCustAgreement
      TransInd
      PickupDate
      PickupCutoffTime
      BookingTime
      ExchangeRate
      WeightCharge
      WeightChargeTax
      TotalTransitDays
      PickupPostalLocAddDays
      DeliveryPostalLocAddDays
      DeliveryDate {
        DeliveryType
        DlvyDateTime
        DeliveryDateTimeOffset
      }
      DeliveryTime
      DimensionalWeight
      WeightUnit
      PickupDayOfWeekNum
      DestinationDayOfWeekNum
      QuotedWeight
      QuotedWeightUOM
      PricingDate
      ShippingCharge
      TotalTaxAmount
      QtdSInAdCur {
        CurrencyCode
        CurrencyRoleTypeCode
        WeightCharge
        TotalAmount
        TotalTaxAmount
        WeightChargeTax
      }
      PickupWindowEarliestTime
      PickupWindowLatestTime
      BookingCutoffOffset
    }
  }
}
    `;

/**
 * __useGetQuoteQuery__
 *
 * To run a query within a React component, call `useGetQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuoteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetQuoteQuery(baseOptions?: Apollo.QueryHookOptions<GetQuoteQuery, GetQuoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuoteQuery, GetQuoteQueryVariables>(GetQuoteDocument, options);
      }
export function useGetQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuoteQuery, GetQuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuoteQuery, GetQuoteQueryVariables>(GetQuoteDocument, options);
        }
export type GetQuoteQueryHookResult = ReturnType<typeof useGetQuoteQuery>;
export type GetQuoteLazyQueryHookResult = ReturnType<typeof useGetQuoteLazyQuery>;
export type GetQuoteQueryResult = Apollo.QueryResult<GetQuoteQuery, GetQuoteQueryVariables>;
export const GetReportsDocument = gql`
    query getReports($limit: Int, $offset: Int) {
  getReports(limit: $limit, offset: $offset) {
    rows {
      id
      userId
      type
      from
      to
      createdAt
      fileToken
      productBarcode
      warehouseCode
    }
    count
  }
}
    `;

/**
 * __useGetReportsQuery__
 *
 * To run a query within a React component, call `useGetReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetReportsQuery(baseOptions?: Apollo.QueryHookOptions<GetReportsQuery, GetReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportsQuery, GetReportsQueryVariables>(GetReportsDocument, options);
      }
export function useGetReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportsQuery, GetReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportsQuery, GetReportsQueryVariables>(GetReportsDocument, options);
        }
export type GetReportsQueryHookResult = ReturnType<typeof useGetReportsQuery>;
export type GetReportsLazyQueryHookResult = ReturnType<typeof useGetReportsLazyQuery>;
export type GetReportsQueryResult = Apollo.QueryResult<GetReportsQuery, GetReportsQueryVariables>;
export const GetReturnShipmentInfoDocument = gql`
    query getReturnShipmentInfo($id: Int!) {
  getReturnShipmentInfo(id: $id) {
    id
    barcodeId
    warehouse
    cost
    trackNumber
    carrierCode
    warehouseStatus
    createdAt
    type
    attachments {
      id
      filePath
    }
    products {
      productId
      productBarcode
      productSku
      productName
      acceptedQuantity
      expectedQuantity
    }
    arrivalDate
    shipmentOrderBarcodeId
    shipmentOrderId
    user {
      id
      name
    }
  }
}
    `;

/**
 * __useGetReturnShipmentInfoQuery__
 *
 * To run a query within a React component, call `useGetReturnShipmentInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReturnShipmentInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReturnShipmentInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReturnShipmentInfoQuery(baseOptions: Apollo.QueryHookOptions<GetReturnShipmentInfoQuery, GetReturnShipmentInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReturnShipmentInfoQuery, GetReturnShipmentInfoQueryVariables>(GetReturnShipmentInfoDocument, options);
      }
export function useGetReturnShipmentInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReturnShipmentInfoQuery, GetReturnShipmentInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReturnShipmentInfoQuery, GetReturnShipmentInfoQueryVariables>(GetReturnShipmentInfoDocument, options);
        }
export type GetReturnShipmentInfoQueryHookResult = ReturnType<typeof useGetReturnShipmentInfoQuery>;
export type GetReturnShipmentInfoLazyQueryHookResult = ReturnType<typeof useGetReturnShipmentInfoLazyQuery>;
export type GetReturnShipmentInfoQueryResult = Apollo.QueryResult<GetReturnShipmentInfoQuery, GetReturnShipmentInfoQueryVariables>;
export const GetReturnShipmentsListDocument = gql`
    query getReturnShipmentsList($filter: ReturnShipmentFilter, $search: String) {
  getReturnShipmentsList(filter: $filter, search: $search) {
    count
    rows {
      id
      barcodeId
      shipmentOrderId
      shipmentOrderBarcodeId
      warehouse
      cost
      trackNumber
      carrierCode
      warehouseStatus
      acceptedQuantityItem
      createdAt
    }
  }
}
    `;

/**
 * __useGetReturnShipmentsListQuery__
 *
 * To run a query within a React component, call `useGetReturnShipmentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReturnShipmentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReturnShipmentsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetReturnShipmentsListQuery(baseOptions?: Apollo.QueryHookOptions<GetReturnShipmentsListQuery, GetReturnShipmentsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReturnShipmentsListQuery, GetReturnShipmentsListQueryVariables>(GetReturnShipmentsListDocument, options);
      }
export function useGetReturnShipmentsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReturnShipmentsListQuery, GetReturnShipmentsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReturnShipmentsListQuery, GetReturnShipmentsListQueryVariables>(GetReturnShipmentsListDocument, options);
        }
export type GetReturnShipmentsListQueryHookResult = ReturnType<typeof useGetReturnShipmentsListQuery>;
export type GetReturnShipmentsListLazyQueryHookResult = ReturnType<typeof useGetReturnShipmentsListLazyQuery>;
export type GetReturnShipmentsListQueryResult = Apollo.QueryResult<GetReturnShipmentsListQuery, GetReturnShipmentsListQueryVariables>;
export const GetSenderAccountByIdDocument = gql`
    query getSenderAccountById($id: Int!) {
  getSenderAccountById(id: $id) {
    id
    accountName
    status
    accountNumber
    password
    siteId
    TaxIdentificationNumber
    attentionName
    clientIdFEDEX
    clientSecretFEDEX
    userNameUPS
    userPasswordUPS
    accessLicenseNumberUPS
    requestorName
    requestorPhone
    requestorAddress
    requestorCity
    requestorCountryCode
    requestorPostalCode
    serviceName
    isTestMode
    user {
      id
      email
      name
      company
      country {
        ...FragmentDeliveryCountry
      }
      address
      address2
      address3
      zipCode
      city
      state
      phone
      eoriCode
      status
      createdAt
      updatedAt
      balance
      discount
    }
    easypostTestApiKey
    easypostProdApiKey
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;

/**
 * __useGetSenderAccountByIdQuery__
 *
 * To run a query within a React component, call `useGetSenderAccountByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSenderAccountByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSenderAccountByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSenderAccountByIdQuery(baseOptions: Apollo.QueryHookOptions<GetSenderAccountByIdQuery, GetSenderAccountByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSenderAccountByIdQuery, GetSenderAccountByIdQueryVariables>(GetSenderAccountByIdDocument, options);
      }
export function useGetSenderAccountByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSenderAccountByIdQuery, GetSenderAccountByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSenderAccountByIdQuery, GetSenderAccountByIdQueryVariables>(GetSenderAccountByIdDocument, options);
        }
export type GetSenderAccountByIdQueryHookResult = ReturnType<typeof useGetSenderAccountByIdQuery>;
export type GetSenderAccountByIdLazyQueryHookResult = ReturnType<typeof useGetSenderAccountByIdLazyQuery>;
export type GetSenderAccountByIdQueryResult = Apollo.QueryResult<GetSenderAccountByIdQuery, GetSenderAccountByIdQueryVariables>;
export const GetSenderAccountListDocument = gql`
    query getSenderAccountList($limit: Int, $offset: Int) {
  getSenderAccountList(limit: $limit, offset: $offset) {
    count
    rows {
      id
      accountName
      status
      accountNumber
      password
      siteId
      TaxIdentificationNumber
      attentionName
      clientIdFEDEX
      clientSecretFEDEX
      userNameUPS
      userPasswordUPS
      accessLicenseNumberUPS
      requestorName
      requestorPhone
      requestorAddress
      requestorCity
      requestorCountryCode
      requestorPostalCode
      serviceName
      isTestMode
      user {
        id
        email
        name
        company
        country {
          ...FragmentDeliveryCountry
        }
        address
        address2
        address3
        zipCode
        city
        state
        phone
        eoriCode
        status
        createdAt
        updatedAt
        balance
        discount
      }
    }
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;

/**
 * __useGetSenderAccountListQuery__
 *
 * To run a query within a React component, call `useGetSenderAccountListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSenderAccountListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSenderAccountListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetSenderAccountListQuery(baseOptions?: Apollo.QueryHookOptions<GetSenderAccountListQuery, GetSenderAccountListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSenderAccountListQuery, GetSenderAccountListQueryVariables>(GetSenderAccountListDocument, options);
      }
export function useGetSenderAccountListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSenderAccountListQuery, GetSenderAccountListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSenderAccountListQuery, GetSenderAccountListQueryVariables>(GetSenderAccountListDocument, options);
        }
export type GetSenderAccountListQueryHookResult = ReturnType<typeof useGetSenderAccountListQuery>;
export type GetSenderAccountListLazyQueryHookResult = ReturnType<typeof useGetSenderAccountListLazyQuery>;
export type GetSenderAccountListQueryResult = Apollo.QueryResult<GetSenderAccountListQuery, GetSenderAccountListQueryVariables>;
export const GetShipmentBarcodeListDocument = gql`
    query getShipmentBarcodeList($id: ID!) {
  getShipmentBarcodeList(id: $id)
}
    `;

/**
 * __useGetShipmentBarcodeListQuery__
 *
 * To run a query within a React component, call `useGetShipmentBarcodeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShipmentBarcodeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShipmentBarcodeListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetShipmentBarcodeListQuery(baseOptions: Apollo.QueryHookOptions<GetShipmentBarcodeListQuery, GetShipmentBarcodeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShipmentBarcodeListQuery, GetShipmentBarcodeListQueryVariables>(GetShipmentBarcodeListDocument, options);
      }
export function useGetShipmentBarcodeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShipmentBarcodeListQuery, GetShipmentBarcodeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShipmentBarcodeListQuery, GetShipmentBarcodeListQueryVariables>(GetShipmentBarcodeListDocument, options);
        }
export type GetShipmentBarcodeListQueryHookResult = ReturnType<typeof useGetShipmentBarcodeListQuery>;
export type GetShipmentBarcodeListLazyQueryHookResult = ReturnType<typeof useGetShipmentBarcodeListLazyQuery>;
export type GetShipmentBarcodeListQueryResult = Apollo.QueryResult<GetShipmentBarcodeListQuery, GetShipmentBarcodeListQueryVariables>;
export const GetStatusesListDocument = gql`
    query getStatusesList {
  getStatusesList {
    DeliveryStatusList {
      id
      name
      explain
    }
    PaymentStatusList {
      id
      name
      explain
    }
  }
}
    `;

/**
 * __useGetStatusesListQuery__
 *
 * To run a query within a React component, call `useGetStatusesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatusesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatusesListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStatusesListQuery(baseOptions?: Apollo.QueryHookOptions<GetStatusesListQuery, GetStatusesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatusesListQuery, GetStatusesListQueryVariables>(GetStatusesListDocument, options);
      }
export function useGetStatusesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatusesListQuery, GetStatusesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatusesListQuery, GetStatusesListQueryVariables>(GetStatusesListDocument, options);
        }
export type GetStatusesListQueryHookResult = ReturnType<typeof useGetStatusesListQuery>;
export type GetStatusesListLazyQueryHookResult = ReturnType<typeof useGetStatusesListLazyQuery>;
export type GetStatusesListQueryResult = Apollo.QueryResult<GetStatusesListQuery, GetStatusesListQueryVariables>;
export const GetTariffsListDocument = gql`
    query getTariffsList($limit: Int, $offset: Int) {
  getTariffsList(limit: $limit, offset: $offset) {
    count
    rows {
      id
      zoneId
      weight
      price
    }
  }
}
    `;

/**
 * __useGetTariffsListQuery__
 *
 * To run a query within a React component, call `useGetTariffsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTariffsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTariffsListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetTariffsListQuery(baseOptions?: Apollo.QueryHookOptions<GetTariffsListQuery, GetTariffsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTariffsListQuery, GetTariffsListQueryVariables>(GetTariffsListDocument, options);
      }
export function useGetTariffsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTariffsListQuery, GetTariffsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTariffsListQuery, GetTariffsListQueryVariables>(GetTariffsListDocument, options);
        }
export type GetTariffsListQueryHookResult = ReturnType<typeof useGetTariffsListQuery>;
export type GetTariffsListLazyQueryHookResult = ReturnType<typeof useGetTariffsListLazyQuery>;
export type GetTariffsListQueryResult = Apollo.QueryResult<GetTariffsListQuery, GetTariffsListQueryVariables>;
export const GetTheExchangeRateDocument = gql`
    query getTheExchangeRate {
  getTheExchangeRate {
    exchangeRate {
      code
      nominal
      value
    }
    lastUpdate
  }
}
    `;

/**
 * __useGetTheExchangeRateQuery__
 *
 * To run a query within a React component, call `useGetTheExchangeRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTheExchangeRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTheExchangeRateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTheExchangeRateQuery(baseOptions?: Apollo.QueryHookOptions<GetTheExchangeRateQuery, GetTheExchangeRateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTheExchangeRateQuery, GetTheExchangeRateQueryVariables>(GetTheExchangeRateDocument, options);
      }
export function useGetTheExchangeRateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTheExchangeRateQuery, GetTheExchangeRateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTheExchangeRateQuery, GetTheExchangeRateQueryVariables>(GetTheExchangeRateDocument, options);
        }
export type GetTheExchangeRateQueryHookResult = ReturnType<typeof useGetTheExchangeRateQuery>;
export type GetTheExchangeRateLazyQueryHookResult = ReturnType<typeof useGetTheExchangeRateLazyQuery>;
export type GetTheExchangeRateQueryResult = Apollo.QueryResult<GetTheExchangeRateQuery, GetTheExchangeRateQueryVariables>;
export const GetTransactionTypesListDocument = gql`
    query GetTransactionTypesList($operation: AVAILABILITY_OPERATION) {
  getTransactionTypesList(operation: $operation) {
    id
    paymentType
  }
}
    `;

/**
 * __useGetTransactionTypesListQuery__
 *
 * To run a query within a React component, call `useGetTransactionTypesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionTypesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionTypesListQuery({
 *   variables: {
 *      operation: // value for 'operation'
 *   },
 * });
 */
export function useGetTransactionTypesListQuery(baseOptions?: Apollo.QueryHookOptions<GetTransactionTypesListQuery, GetTransactionTypesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransactionTypesListQuery, GetTransactionTypesListQueryVariables>(GetTransactionTypesListDocument, options);
      }
export function useGetTransactionTypesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionTypesListQuery, GetTransactionTypesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransactionTypesListQuery, GetTransactionTypesListQueryVariables>(GetTransactionTypesListDocument, options);
        }
export type GetTransactionTypesListQueryHookResult = ReturnType<typeof useGetTransactionTypesListQuery>;
export type GetTransactionTypesListLazyQueryHookResult = ReturnType<typeof useGetTransactionTypesListLazyQuery>;
export type GetTransactionTypesListQueryResult = Apollo.QueryResult<GetTransactionTypesListQuery, GetTransactionTypesListQueryVariables>;
export const GetUndeclaredShipmentInfoDocument = gql`
    query GetUndeclaredShipmentInfo($getUndeclaredShipmentInfoId: Int!) {
  getUndeclaredShipmentInfo(id: $getUndeclaredShipmentInfoId) {
    id
    createdAt
    updatedAt
    warehouse
    trackNumber
    carrierCode
    cost
    note
    warehouseStatus
    user {
      id
      name
    }
    attachments {
      filePath
      id
    }
    items {
      product {
        barcodeId
        sku
        name
      }
      productId
      quantity
    }
  }
}
    `;

/**
 * __useGetUndeclaredShipmentInfoQuery__
 *
 * To run a query within a React component, call `useGetUndeclaredShipmentInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUndeclaredShipmentInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUndeclaredShipmentInfoQuery({
 *   variables: {
 *      getUndeclaredShipmentInfoId: // value for 'getUndeclaredShipmentInfoId'
 *   },
 * });
 */
export function useGetUndeclaredShipmentInfoQuery(baseOptions: Apollo.QueryHookOptions<GetUndeclaredShipmentInfoQuery, GetUndeclaredShipmentInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUndeclaredShipmentInfoQuery, GetUndeclaredShipmentInfoQueryVariables>(GetUndeclaredShipmentInfoDocument, options);
      }
export function useGetUndeclaredShipmentInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUndeclaredShipmentInfoQuery, GetUndeclaredShipmentInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUndeclaredShipmentInfoQuery, GetUndeclaredShipmentInfoQueryVariables>(GetUndeclaredShipmentInfoDocument, options);
        }
export type GetUndeclaredShipmentInfoQueryHookResult = ReturnType<typeof useGetUndeclaredShipmentInfoQuery>;
export type GetUndeclaredShipmentInfoLazyQueryHookResult = ReturnType<typeof useGetUndeclaredShipmentInfoLazyQuery>;
export type GetUndeclaredShipmentInfoQueryResult = Apollo.QueryResult<GetUndeclaredShipmentInfoQuery, GetUndeclaredShipmentInfoQueryVariables>;
export const GetUndeclaredShipmentsListDocument = gql`
    query GetUndeclaredShipmentsList($filter: UndeclaredShipmentFilter, $search: String) {
  getUndeclaredShipmentsList(filter: $filter, search: $search) {
    count
    rows {
      carrierCode
      cost
      createdAt
      id
      note
      quantityItems
      trackNumber
      warehouse
      warehouseStatus
      user {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetUndeclaredShipmentsListQuery__
 *
 * To run a query within a React component, call `useGetUndeclaredShipmentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUndeclaredShipmentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUndeclaredShipmentsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetUndeclaredShipmentsListQuery(baseOptions?: Apollo.QueryHookOptions<GetUndeclaredShipmentsListQuery, GetUndeclaredShipmentsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUndeclaredShipmentsListQuery, GetUndeclaredShipmentsListQueryVariables>(GetUndeclaredShipmentsListDocument, options);
      }
export function useGetUndeclaredShipmentsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUndeclaredShipmentsListQuery, GetUndeclaredShipmentsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUndeclaredShipmentsListQuery, GetUndeclaredShipmentsListQueryVariables>(GetUndeclaredShipmentsListDocument, options);
        }
export type GetUndeclaredShipmentsListQueryHookResult = ReturnType<typeof useGetUndeclaredShipmentsListQuery>;
export type GetUndeclaredShipmentsListLazyQueryHookResult = ReturnType<typeof useGetUndeclaredShipmentsListLazyQuery>;
export type GetUndeclaredShipmentsListQueryResult = Apollo.QueryResult<GetUndeclaredShipmentsListQuery, GetUndeclaredShipmentsListQueryVariables>;
export const GetUserDocument = gql`
    query getUser($id: Int!) {
  getUser(id: $id) {
    id
    email
    name
    company
    country {
      ...FragmentDeliveryCountry
    }
    address
    address2
    address3
    zipCode
    city
    state
    phone
    eoriCode
    status
    createdAt
    updatedAt
    discount
    balance
    passportData {
      ...FragmentUserPasport
    }
    entityLegalData {
      id
      company
      inn
      kpp
      MSRN
      generalManager
      legalAddress
      nameOfTheBank
      BIK
      paymentAccount
      correspondentAccount
      fileName
      userId
      isDataVerified
    }
    creditLimit
    whFeePersonalDiscount
  }
}
    ${FragmentDeliveryCountryFragmentDoc}
${FragmentUserPasportFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUserDiscountDocument = gql`
    query GetUserDiscount($userId: Int) {
  getUserDiscount(userId: $userId) {
    id
    userId
    creditLimit
    orderHandlingDiscountPercent
    warehouseStorageDiscountPercent
    forwardingDiscountPercent
    returnHandlingDiscountPercent
    inboundDiscountPercent
    undeclaredInboundDiscountPercent
  }
}
    `;

/**
 * __useGetUserDiscountQuery__
 *
 * To run a query within a React component, call `useGetUserDiscountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDiscountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDiscountQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserDiscountQuery(baseOptions?: Apollo.QueryHookOptions<GetUserDiscountQuery, GetUserDiscountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDiscountQuery, GetUserDiscountQueryVariables>(GetUserDiscountDocument, options);
      }
export function useGetUserDiscountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDiscountQuery, GetUserDiscountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDiscountQuery, GetUserDiscountQueryVariables>(GetUserDiscountDocument, options);
        }
export type GetUserDiscountQueryHookResult = ReturnType<typeof useGetUserDiscountQuery>;
export type GetUserDiscountLazyQueryHookResult = ReturnType<typeof useGetUserDiscountLazyQuery>;
export type GetUserDiscountQueryResult = Apollo.QueryResult<GetUserDiscountQuery, GetUserDiscountQueryVariables>;
export const UsersDocument = gql`
    query Users($offset: Int, $limit: Int, $search: String, $order: UserOrder) {
  users(offset: $offset, limit: $limit, search: $search, order: $order) {
    count
    rows {
      id
      email
      name
      company
      country {
        ...FragmentDeliveryCountry
      }
      address
      address2
      address3
      zipCode
      city
      state
      phone
      eoriCode
      status
      createdAt
      updatedAt
      discount
      balance
      passportData {
        ...FragmentUserPasport
      }
      entityLegalData {
        isDataVerified
        company
        id
        isDataVerified
      }
      creditLimit
      whFeePersonalDiscount
    }
  }
}
    ${FragmentDeliveryCountryFragmentDoc}
${FragmentUserPasportFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const GetWarehouseProductLogDocument = gql`
    query GetWarehouseProductLog($limit: Int!, $offset: Int!, $barcodeId: String) {
  getWarehouseProductLog(limit: $limit, offset: $offset, barcodeId: $barcodeId) {
    rows {
      id
      productId
      productBarcodeId
      fromCellId
      fromCellName
      toCellId
      toCellName
      operation
      quantity
      shipmentId
      comment
      createdAt
    }
    count
  }
}
    `;

/**
 * __useGetWarehouseProductLogQuery__
 *
 * To run a query within a React component, call `useGetWarehouseProductLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseProductLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseProductLogQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      barcodeId: // value for 'barcodeId'
 *   },
 * });
 */
export function useGetWarehouseProductLogQuery(baseOptions: Apollo.QueryHookOptions<GetWarehouseProductLogQuery, GetWarehouseProductLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWarehouseProductLogQuery, GetWarehouseProductLogQueryVariables>(GetWarehouseProductLogDocument, options);
      }
export function useGetWarehouseProductLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWarehouseProductLogQuery, GetWarehouseProductLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWarehouseProductLogQuery, GetWarehouseProductLogQueryVariables>(GetWarehouseProductLogDocument, options);
        }
export type GetWarehouseProductLogQueryHookResult = ReturnType<typeof useGetWarehouseProductLogQuery>;
export type GetWarehouseProductLogLazyQueryHookResult = ReturnType<typeof useGetWarehouseProductLogLazyQuery>;
export type GetWarehouseProductLogQueryResult = Apollo.QueryResult<GetWarehouseProductLogQuery, GetWarehouseProductLogQueryVariables>;
export const GetWarehouseProductLogByProductIdDocument = gql`
    query GetWarehouseProductLogByProductId($warehouseId: ID!, $productId: ID!, $limit: Int!, $offset: Int!) {
  getWarehouseProductLogByProductId(
    warehouseId: $warehouseId
    productId: $productId
    limit: $limit
    offset: $offset
  ) {
    rows {
      id
      productId
      productBarcodeId
      operation
      quantity
      shipmentId
      comment
      createdAt
    }
    count
  }
}
    `;

/**
 * __useGetWarehouseProductLogByProductIdQuery__
 *
 * To run a query within a React component, call `useGetWarehouseProductLogByProductIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseProductLogByProductIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseProductLogByProductIdQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      productId: // value for 'productId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetWarehouseProductLogByProductIdQuery(baseOptions: Apollo.QueryHookOptions<GetWarehouseProductLogByProductIdQuery, GetWarehouseProductLogByProductIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWarehouseProductLogByProductIdQuery, GetWarehouseProductLogByProductIdQueryVariables>(GetWarehouseProductLogByProductIdDocument, options);
      }
export function useGetWarehouseProductLogByProductIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWarehouseProductLogByProductIdQuery, GetWarehouseProductLogByProductIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWarehouseProductLogByProductIdQuery, GetWarehouseProductLogByProductIdQueryVariables>(GetWarehouseProductLogByProductIdDocument, options);
        }
export type GetWarehouseProductLogByProductIdQueryHookResult = ReturnType<typeof useGetWarehouseProductLogByProductIdQuery>;
export type GetWarehouseProductLogByProductIdLazyQueryHookResult = ReturnType<typeof useGetWarehouseProductLogByProductIdLazyQuery>;
export type GetWarehouseProductLogByProductIdQueryResult = Apollo.QueryResult<GetWarehouseProductLogByProductIdQuery, GetWarehouseProductLogByProductIdQueryVariables>;
export const GetWarehouseShipmentInfoDocument = gql`
    query getWarehouseShipmentInfo($id: Int!) {
  getWarehouseShipmentInfo(id: $id) {
    id
    events {
      id
      operation
      date
      warehouse {
        code
      }
    }
    from {
      id
      email
      name
      company
      country {
        ...FragmentDeliveryCountry
      }
      address
      address2
      address3
      zipCode
      city
      state
      phone
    }
    createdAt
    warehouse {
      id
      code
      address
      address2
      company
      phone
      country {
        ...FragmentDeliveryCountry
      }
      managerName
      city
      zipCode
      state
      email
    }
    trackNumber
    carrierCode
    deliveryStatus {
      id
      name
      explain
    }
    user {
      id
      email
      name
      company
      country {
        id
      }
      address
      address2
      address3
      zipCode
      city
      state
      phone
      eoriCode
      status
      createdAt
      updatedAt
    }
    packages {
      id
      warehouseShipmentId
      weight
      length
      width
      height
    }
    products {
      id
      quantity
      arrivalQuantity
      product {
        id
        name
        sku
        netWeight
        declaredValue
        barcodeId
      }
    }
    places
    expectedQuantity
    acceptedQuantity
    barcode
    parcels {
      id
      barcode
      accepted
    }
    warehouseStatus
    note
    pieces {
      accepted
      customIdentifier
      id
      warehouseShipmentId
    }
    cost
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;

/**
 * __useGetWarehouseShipmentInfoQuery__
 *
 * To run a query within a React component, call `useGetWarehouseShipmentInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseShipmentInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseShipmentInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWarehouseShipmentInfoQuery(baseOptions: Apollo.QueryHookOptions<GetWarehouseShipmentInfoQuery, GetWarehouseShipmentInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWarehouseShipmentInfoQuery, GetWarehouseShipmentInfoQueryVariables>(GetWarehouseShipmentInfoDocument, options);
      }
export function useGetWarehouseShipmentInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWarehouseShipmentInfoQuery, GetWarehouseShipmentInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWarehouseShipmentInfoQuery, GetWarehouseShipmentInfoQueryVariables>(GetWarehouseShipmentInfoDocument, options);
        }
export type GetWarehouseShipmentInfoQueryHookResult = ReturnType<typeof useGetWarehouseShipmentInfoQuery>;
export type GetWarehouseShipmentInfoLazyQueryHookResult = ReturnType<typeof useGetWarehouseShipmentInfoLazyQuery>;
export type GetWarehouseShipmentInfoQueryResult = Apollo.QueryResult<GetWarehouseShipmentInfoQuery, GetWarehouseShipmentInfoQueryVariables>;
export const GetWarehouseShipmentsListDocument = gql`
    query getWarehouseShipmentsList($filter: WarehouseShipmentFilter, $search: String) {
  getWarehouseShipmentsList(filter: $filter, search: $search) {
    count
    rows {
      id
      events {
        id
        date
        operation
      }
      createdAt
      warehouse {
        id
        code
        address
        company
        phone
      }
      trackNumber
      carrierCode
      deliveryStatus {
        id
        name
        explain
      }
      user {
        id
        name
      }
      places
      expectedQuantity
      acceptedQuantity
      barcode
      packages {
        id
      }
      warehouseStatus
      note
    }
  }
}
    `;

/**
 * __useGetWarehouseShipmentsListQuery__
 *
 * To run a query within a React component, call `useGetWarehouseShipmentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseShipmentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseShipmentsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetWarehouseShipmentsListQuery(baseOptions?: Apollo.QueryHookOptions<GetWarehouseShipmentsListQuery, GetWarehouseShipmentsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWarehouseShipmentsListQuery, GetWarehouseShipmentsListQueryVariables>(GetWarehouseShipmentsListDocument, options);
      }
export function useGetWarehouseShipmentsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWarehouseShipmentsListQuery, GetWarehouseShipmentsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWarehouseShipmentsListQuery, GetWarehouseShipmentsListQueryVariables>(GetWarehouseShipmentsListDocument, options);
        }
export type GetWarehouseShipmentsListQueryHookResult = ReturnType<typeof useGetWarehouseShipmentsListQuery>;
export type GetWarehouseShipmentsListLazyQueryHookResult = ReturnType<typeof useGetWarehouseShipmentsListLazyQuery>;
export type GetWarehouseShipmentsListQueryResult = Apollo.QueryResult<GetWarehouseShipmentsListQuery, GetWarehouseShipmentsListQueryVariables>;
export const GetWarehouseShipmentOrderAdditionalOptionsDocument = gql`
    query GetWarehouseShipmentOrderAdditionalOptions {
  getWarehouseShipmentOrderAdditionalOptions {
    wrappers
    giftMessageCost
    giftWrapperCost
  }
}
    `;

/**
 * __useGetWarehouseShipmentOrderAdditionalOptionsQuery__
 *
 * To run a query within a React component, call `useGetWarehouseShipmentOrderAdditionalOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseShipmentOrderAdditionalOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseShipmentOrderAdditionalOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWarehouseShipmentOrderAdditionalOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetWarehouseShipmentOrderAdditionalOptionsQuery, GetWarehouseShipmentOrderAdditionalOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWarehouseShipmentOrderAdditionalOptionsQuery, GetWarehouseShipmentOrderAdditionalOptionsQueryVariables>(GetWarehouseShipmentOrderAdditionalOptionsDocument, options);
      }
export function useGetWarehouseShipmentOrderAdditionalOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWarehouseShipmentOrderAdditionalOptionsQuery, GetWarehouseShipmentOrderAdditionalOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWarehouseShipmentOrderAdditionalOptionsQuery, GetWarehouseShipmentOrderAdditionalOptionsQueryVariables>(GetWarehouseShipmentOrderAdditionalOptionsDocument, options);
        }
export type GetWarehouseShipmentOrderAdditionalOptionsQueryHookResult = ReturnType<typeof useGetWarehouseShipmentOrderAdditionalOptionsQuery>;
export type GetWarehouseShipmentOrderAdditionalOptionsLazyQueryHookResult = ReturnType<typeof useGetWarehouseShipmentOrderAdditionalOptionsLazyQuery>;
export type GetWarehouseShipmentOrderAdditionalOptionsQueryResult = Apollo.QueryResult<GetWarehouseShipmentOrderAdditionalOptionsQuery, GetWarehouseShipmentOrderAdditionalOptionsQueryVariables>;
export const GetWarehouseShipmentOrderByIdDocument = gql`
    query getWarehouseShipmentOrderById($id: ID) {
  getWarehouseShipmentOrderById(id: $id) {
    id
    receiver {
      id
      name
      company
      country {
        ...FragmentDeliveryCountry
      }
      address
      address2
      address3
      zipCode
      city
      state
      phone
      email
      innKppCode
      remark
      eoriCode
    }
    sender {
      id
      name
      company
      country {
        ...FragmentDeliveryCountry
      }
      address
      address2
      zipCode
      city
      state
      phone
      email
      code
      managerName
    }
    clientUser {
      id
      name
      company
      country {
        ...FragmentDeliveryCountry
      }
      address
      address2
      address3
      zipCode
      city
      state
      phone
      email
      eoriCode
    }
    trackNumber
    carrierCode
    carrierService
    declaredAmount
    actualAmount
    additionalInsurance
    insuranceAmount
    signature
    wrap
    message
    senderMark
    vatKey
    vatValue
    barcodeId
    dimensions {
      id
      warehouseShipmentId
      weight
      length
      width
      height
    }
    deliveryStatus {
      id
      name
      explain
    }
    paymentStatus {
      id
      name
      explain
    }
    products {
      price
      quantity
      relatedProduct {
        id
        name
        barcodeId
        sku
        detailsDescriptionEN
        code
        netWeight
        country {
          id
        }
        productAvailabilityQuantity {
          availableQuantity
          inTransitQuantity
          reservedQuantity
        }
      }
    }
    orderCostCurrency
    createdAt
    currency
    giftWrap
    type
    pathNameFile
    easyPostShipmentId
    easyPostRateId
    preliminaryCost
    typeDelivery {
      id
    }
    warehouseStatus
    comment
    declaredDimensions {
      id
      weight
      length
      width
      height
    }
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;

/**
 * __useGetWarehouseShipmentOrderByIdQuery__
 *
 * To run a query within a React component, call `useGetWarehouseShipmentOrderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseShipmentOrderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseShipmentOrderByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWarehouseShipmentOrderByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetWarehouseShipmentOrderByIdQuery, GetWarehouseShipmentOrderByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWarehouseShipmentOrderByIdQuery, GetWarehouseShipmentOrderByIdQueryVariables>(GetWarehouseShipmentOrderByIdDocument, options);
      }
export function useGetWarehouseShipmentOrderByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWarehouseShipmentOrderByIdQuery, GetWarehouseShipmentOrderByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWarehouseShipmentOrderByIdQuery, GetWarehouseShipmentOrderByIdQueryVariables>(GetWarehouseShipmentOrderByIdDocument, options);
        }
export type GetWarehouseShipmentOrderByIdQueryHookResult = ReturnType<typeof useGetWarehouseShipmentOrderByIdQuery>;
export type GetWarehouseShipmentOrderByIdLazyQueryHookResult = ReturnType<typeof useGetWarehouseShipmentOrderByIdLazyQuery>;
export type GetWarehouseShipmentOrderByIdQueryResult = Apollo.QueryResult<GetWarehouseShipmentOrderByIdQuery, GetWarehouseShipmentOrderByIdQueryVariables>;
export const GetWarehouseShipmentOrderDeliveryOptionsListDocument = gql`
    query getWarehouseShipmentOrderDeliveryOptionsList($data: WarehouseShipmentOrderDeliveryOptionsInput) {
  getWarehouseShipmentOrderDeliveryOptionsList(data: $data) {
    variants {
      name
      days_to_delivery
      summary_amount
      typeDelivery
      info {
        warehouse_delivery_cost
        warehouse_proccesing_cost
        last_mile_label_cost
      }
      rateId
      shipmentId
      lastMileCost
      priceCurrency
    }
    monthly_pay_amount
  }
}
    `;

/**
 * __useGetWarehouseShipmentOrderDeliveryOptionsListQuery__
 *
 * To run a query within a React component, call `useGetWarehouseShipmentOrderDeliveryOptionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseShipmentOrderDeliveryOptionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseShipmentOrderDeliveryOptionsListQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetWarehouseShipmentOrderDeliveryOptionsListQuery(baseOptions?: Apollo.QueryHookOptions<GetWarehouseShipmentOrderDeliveryOptionsListQuery, GetWarehouseShipmentOrderDeliveryOptionsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWarehouseShipmentOrderDeliveryOptionsListQuery, GetWarehouseShipmentOrderDeliveryOptionsListQueryVariables>(GetWarehouseShipmentOrderDeliveryOptionsListDocument, options);
      }
export function useGetWarehouseShipmentOrderDeliveryOptionsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWarehouseShipmentOrderDeliveryOptionsListQuery, GetWarehouseShipmentOrderDeliveryOptionsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWarehouseShipmentOrderDeliveryOptionsListQuery, GetWarehouseShipmentOrderDeliveryOptionsListQueryVariables>(GetWarehouseShipmentOrderDeliveryOptionsListDocument, options);
        }
export type GetWarehouseShipmentOrderDeliveryOptionsListQueryHookResult = ReturnType<typeof useGetWarehouseShipmentOrderDeliveryOptionsListQuery>;
export type GetWarehouseShipmentOrderDeliveryOptionsListLazyQueryHookResult = ReturnType<typeof useGetWarehouseShipmentOrderDeliveryOptionsListLazyQuery>;
export type GetWarehouseShipmentOrderDeliveryOptionsListQueryResult = Apollo.QueryResult<GetWarehouseShipmentOrderDeliveryOptionsListQuery, GetWarehouseShipmentOrderDeliveryOptionsListQueryVariables>;
export const GetWarehouseShipmentOrdersDocument = gql`
    query getWarehouseShipmentOrders($data: WarehouseShipmentOrdersOptions) {
  getWarehouseShipmentOrders(data: $data) {
    count
    rows {
      id
      receiver {
        id
        name
        city
        country {
          ...FragmentDeliveryCountry
        }
      }
      trackNumber
      carrierCode
      carrierService
      declaredAmount
      actualAmount
      additionalInsurance
      insuranceAmount
      signature
      wrap
      message
      senderMark
      pathNameFile
      vatKey
      vatValue
      deliveryStatus {
        id
        name
        explain
      }
      paymentStatus {
        id
        name
        explain
      }
      products {
        price
        quantity
        relatedProduct {
          id
          name
          barcodeId
          sku
        }
      }
      warehouseStatus
      barcodeId
      orderCostCurrency
      createdAt
      clientUser {
        id
        name
      }
      comment
      giftWrap
    }
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;

/**
 * __useGetWarehouseShipmentOrdersQuery__
 *
 * To run a query within a React component, call `useGetWarehouseShipmentOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseShipmentOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseShipmentOrdersQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetWarehouseShipmentOrdersQuery(baseOptions?: Apollo.QueryHookOptions<GetWarehouseShipmentOrdersQuery, GetWarehouseShipmentOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWarehouseShipmentOrdersQuery, GetWarehouseShipmentOrdersQueryVariables>(GetWarehouseShipmentOrdersDocument, options);
      }
export function useGetWarehouseShipmentOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWarehouseShipmentOrdersQuery, GetWarehouseShipmentOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWarehouseShipmentOrdersQuery, GetWarehouseShipmentOrdersQueryVariables>(GetWarehouseShipmentOrdersDocument, options);
        }
export type GetWarehouseShipmentOrdersQueryHookResult = ReturnType<typeof useGetWarehouseShipmentOrdersQuery>;
export type GetWarehouseShipmentOrdersLazyQueryHookResult = ReturnType<typeof useGetWarehouseShipmentOrdersLazyQuery>;
export type GetWarehouseShipmentOrdersQueryResult = Apollo.QueryResult<GetWarehouseShipmentOrdersQuery, GetWarehouseShipmentOrdersQueryVariables>;
export const GetWarehouseZoneByIdDocument = gql`
    query GetWarehouseZoneById($getWarehouseZoneByIdId: ID!) {
  getWarehouseZoneById(id: $getWarehouseZoneByIdId) {
    freeCellsCount
    id
    isDefault
    name
    occupiedCellsCount
    orderNumber
    totalCellsCount
  }
}
    `;

/**
 * __useGetWarehouseZoneByIdQuery__
 *
 * To run a query within a React component, call `useGetWarehouseZoneByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseZoneByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseZoneByIdQuery({
 *   variables: {
 *      getWarehouseZoneByIdId: // value for 'getWarehouseZoneByIdId'
 *   },
 * });
 */
export function useGetWarehouseZoneByIdQuery(baseOptions: Apollo.QueryHookOptions<GetWarehouseZoneByIdQuery, GetWarehouseZoneByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWarehouseZoneByIdQuery, GetWarehouseZoneByIdQueryVariables>(GetWarehouseZoneByIdDocument, options);
      }
export function useGetWarehouseZoneByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWarehouseZoneByIdQuery, GetWarehouseZoneByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWarehouseZoneByIdQuery, GetWarehouseZoneByIdQueryVariables>(GetWarehouseZoneByIdDocument, options);
        }
export type GetWarehouseZoneByIdQueryHookResult = ReturnType<typeof useGetWarehouseZoneByIdQuery>;
export type GetWarehouseZoneByIdLazyQueryHookResult = ReturnType<typeof useGetWarehouseZoneByIdLazyQuery>;
export type GetWarehouseZoneByIdQueryResult = Apollo.QueryResult<GetWarehouseZoneByIdQuery, GetWarehouseZoneByIdQueryVariables>;
export const GetWarehouseZoneCellByIdDocument = gql`
    query GetWarehouseZoneCellById($getWarehouseZoneCellByIdId: ID!) {
  getWarehouseZoneCellById(id: $getWarehouseZoneCellByIdId) {
    id
    isDefault
    name
    orderNumber
    totalProductsCount
    warehouseZoneId
  }
}
    `;

/**
 * __useGetWarehouseZoneCellByIdQuery__
 *
 * To run a query within a React component, call `useGetWarehouseZoneCellByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseZoneCellByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseZoneCellByIdQuery({
 *   variables: {
 *      getWarehouseZoneCellByIdId: // value for 'getWarehouseZoneCellByIdId'
 *   },
 * });
 */
export function useGetWarehouseZoneCellByIdQuery(baseOptions: Apollo.QueryHookOptions<GetWarehouseZoneCellByIdQuery, GetWarehouseZoneCellByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWarehouseZoneCellByIdQuery, GetWarehouseZoneCellByIdQueryVariables>(GetWarehouseZoneCellByIdDocument, options);
      }
export function useGetWarehouseZoneCellByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWarehouseZoneCellByIdQuery, GetWarehouseZoneCellByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWarehouseZoneCellByIdQuery, GetWarehouseZoneCellByIdQueryVariables>(GetWarehouseZoneCellByIdDocument, options);
        }
export type GetWarehouseZoneCellByIdQueryHookResult = ReturnType<typeof useGetWarehouseZoneCellByIdQuery>;
export type GetWarehouseZoneCellByIdLazyQueryHookResult = ReturnType<typeof useGetWarehouseZoneCellByIdLazyQuery>;
export type GetWarehouseZoneCellByIdQueryResult = Apollo.QueryResult<GetWarehouseZoneCellByIdQuery, GetWarehouseZoneCellByIdQueryVariables>;
export const GetWarehouseZoneCellProductDocument = gql`
    query GetWarehouseZoneCellProduct($options: getWarehouseZoneCellProduct!) {
  getWarehouseZoneCellProduct(options: $options) {
    cellName
    product {
      id
      name
      sku
      productAvailabilityQuantity {
        availableQuantity
        inTransitQuantity
        reservedQuantity
      }
    }
    quantity
  }
}
    `;

/**
 * __useGetWarehouseZoneCellProductQuery__
 *
 * To run a query within a React component, call `useGetWarehouseZoneCellProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseZoneCellProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseZoneCellProductQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetWarehouseZoneCellProductQuery(baseOptions: Apollo.QueryHookOptions<GetWarehouseZoneCellProductQuery, GetWarehouseZoneCellProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWarehouseZoneCellProductQuery, GetWarehouseZoneCellProductQueryVariables>(GetWarehouseZoneCellProductDocument, options);
      }
export function useGetWarehouseZoneCellProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWarehouseZoneCellProductQuery, GetWarehouseZoneCellProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWarehouseZoneCellProductQuery, GetWarehouseZoneCellProductQueryVariables>(GetWarehouseZoneCellProductDocument, options);
        }
export type GetWarehouseZoneCellProductQueryHookResult = ReturnType<typeof useGetWarehouseZoneCellProductQuery>;
export type GetWarehouseZoneCellProductLazyQueryHookResult = ReturnType<typeof useGetWarehouseZoneCellProductLazyQuery>;
export type GetWarehouseZoneCellProductQueryResult = Apollo.QueryResult<GetWarehouseZoneCellProductQuery, GetWarehouseZoneCellProductQueryVariables>;
export const GetWarehouseZoneCellsDocument = gql`
    query GetWarehouseZoneCells($search: String, $limit: Int, $offset: Int) {
  getWarehouseZoneCells(search: $search, limit: $limit, offset: $offset) {
    count
    rows {
      barcode
      id
      isDefault
      name
      orderNumber
      totalProductsCount
      warehouseZoneId
    }
  }
}
    `;

/**
 * __useGetWarehouseZoneCellsQuery__
 *
 * To run a query within a React component, call `useGetWarehouseZoneCellsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseZoneCellsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseZoneCellsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetWarehouseZoneCellsQuery(baseOptions?: Apollo.QueryHookOptions<GetWarehouseZoneCellsQuery, GetWarehouseZoneCellsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWarehouseZoneCellsQuery, GetWarehouseZoneCellsQueryVariables>(GetWarehouseZoneCellsDocument, options);
      }
export function useGetWarehouseZoneCellsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWarehouseZoneCellsQuery, GetWarehouseZoneCellsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWarehouseZoneCellsQuery, GetWarehouseZoneCellsQueryVariables>(GetWarehouseZoneCellsDocument, options);
        }
export type GetWarehouseZoneCellsQueryHookResult = ReturnType<typeof useGetWarehouseZoneCellsQuery>;
export type GetWarehouseZoneCellsLazyQueryHookResult = ReturnType<typeof useGetWarehouseZoneCellsLazyQuery>;
export type GetWarehouseZoneCellsQueryResult = Apollo.QueryResult<GetWarehouseZoneCellsQuery, GetWarehouseZoneCellsQueryVariables>;
export const GetWarehouseZoneCellsResiduesDocument = gql`
    query GetWarehouseZoneCellsResidues($options: GetWarehouseZoneCellResidues!) {
  getWarehouseZoneCellsResidues(options: $options) {
    count
    rows {
      cellName
      product {
        id
        name
        sku
        createdAt
        barcodeId
      }
      quantity
    }
  }
}
    `;

/**
 * __useGetWarehouseZoneCellsResiduesQuery__
 *
 * To run a query within a React component, call `useGetWarehouseZoneCellsResiduesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseZoneCellsResiduesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseZoneCellsResiduesQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetWarehouseZoneCellsResiduesQuery(baseOptions: Apollo.QueryHookOptions<GetWarehouseZoneCellsResiduesQuery, GetWarehouseZoneCellsResiduesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWarehouseZoneCellsResiduesQuery, GetWarehouseZoneCellsResiduesQueryVariables>(GetWarehouseZoneCellsResiduesDocument, options);
      }
export function useGetWarehouseZoneCellsResiduesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWarehouseZoneCellsResiduesQuery, GetWarehouseZoneCellsResiduesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWarehouseZoneCellsResiduesQuery, GetWarehouseZoneCellsResiduesQueryVariables>(GetWarehouseZoneCellsResiduesDocument, options);
        }
export type GetWarehouseZoneCellsResiduesQueryHookResult = ReturnType<typeof useGetWarehouseZoneCellsResiduesQuery>;
export type GetWarehouseZoneCellsResiduesLazyQueryHookResult = ReturnType<typeof useGetWarehouseZoneCellsResiduesLazyQuery>;
export type GetWarehouseZoneCellsResiduesQueryResult = Apollo.QueryResult<GetWarehouseZoneCellsResiduesQuery, GetWarehouseZoneCellsResiduesQueryVariables>;
export const GetWarehouseZonesDocument = gql`
    query GetWarehouseZones {
  getWarehouseZones {
    freeCellsCount
    id
    isDefault
    name
    occupiedCellsCount
    orderNumber
    totalCellsCount
  }
}
    `;

/**
 * __useGetWarehouseZonesQuery__
 *
 * To run a query within a React component, call `useGetWarehouseZonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseZonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseZonesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWarehouseZonesQuery(baseOptions?: Apollo.QueryHookOptions<GetWarehouseZonesQuery, GetWarehouseZonesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWarehouseZonesQuery, GetWarehouseZonesQueryVariables>(GetWarehouseZonesDocument, options);
      }
export function useGetWarehouseZonesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWarehouseZonesQuery, GetWarehouseZonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWarehouseZonesQuery, GetWarehouseZonesQueryVariables>(GetWarehouseZonesDocument, options);
        }
export type GetWarehouseZonesQueryHookResult = ReturnType<typeof useGetWarehouseZonesQuery>;
export type GetWarehouseZonesLazyQueryHookResult = ReturnType<typeof useGetWarehouseZonesLazyQuery>;
export type GetWarehouseZonesQueryResult = Apollo.QueryResult<GetWarehouseZonesQuery, GetWarehouseZonesQueryVariables>;
export const GetWarehousesListDocument = gql`
    query getWarehousesList($limit: Int, $offset: Int, $code: String) {
  getWarehousesList(limit: $limit, offset: $offset, code: $code) {
    count
    rows {
      id
      code
      address
      address2
      company
      email
      phone
      zipCode
      city
      state
      country {
        ...FragmentDeliveryCountry
      }
      name
      managerName
      availableOperation {
        operation
      }
    }
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;

/**
 * __useGetWarehousesListQuery__
 *
 * To run a query within a React component, call `useGetWarehousesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehousesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehousesListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetWarehousesListQuery(baseOptions?: Apollo.QueryHookOptions<GetWarehousesListQuery, GetWarehousesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWarehousesListQuery, GetWarehousesListQueryVariables>(GetWarehousesListDocument, options);
      }
export function useGetWarehousesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWarehousesListQuery, GetWarehousesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWarehousesListQuery, GetWarehousesListQueryVariables>(GetWarehousesListDocument, options);
        }
export type GetWarehousesListQueryHookResult = ReturnType<typeof useGetWarehousesListQuery>;
export type GetWarehousesListLazyQueryHookResult = ReturnType<typeof useGetWarehousesListLazyQuery>;
export type GetWarehousesListQueryResult = Apollo.QueryResult<GetWarehousesListQuery, GetWarehousesListQueryVariables>;
export const TrackParcelDocument = gql`
    query trackParcel($carrier_code: String, $barcode: String) {
  trackParcel(carrier_code: $carrier_code, barcode: $barcode) {
    carrier_code
    track_number
    message
    error
    statusDescription
    city
    countryISO
    history {
      eventName
      timestamp
    }
    currentStep
    senderCity
    senderCountryISO
    senderStateOrProvinceCode
    receiverCity
    receiverCountryISO
    receiverStateOrProvinceCode
  }
}
    `;

/**
 * __useTrackParcelQuery__
 *
 * To run a query within a React component, call `useTrackParcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackParcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackParcelQuery({
 *   variables: {
 *      carrier_code: // value for 'carrier_code'
 *      barcode: // value for 'barcode'
 *   },
 * });
 */
export function useTrackParcelQuery(baseOptions?: Apollo.QueryHookOptions<TrackParcelQuery, TrackParcelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrackParcelQuery, TrackParcelQueryVariables>(TrackParcelDocument, options);
      }
export function useTrackParcelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrackParcelQuery, TrackParcelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrackParcelQuery, TrackParcelQueryVariables>(TrackParcelDocument, options);
        }
export type TrackParcelQueryHookResult = ReturnType<typeof useTrackParcelQuery>;
export type TrackParcelLazyQueryHookResult = ReturnType<typeof useTrackParcelLazyQuery>;
export type TrackParcelQueryResult = Apollo.QueryResult<TrackParcelQuery, TrackParcelQueryVariables>;
export const ValidationAddressDocument = gql`
    query validationAddress($input: ValidationAddress) {
  validationAddress(input: $input) {
    address {
      inputValue
      returnValue
    }
    city {
      inputValue
      returnValue
    }
    state {
      inputValue
      returnValue
    }
    postalCode {
      inputValue
      returnValue
    }
  }
}
    `;

/**
 * __useValidationAddressQuery__
 *
 * To run a query within a React component, call `useValidationAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidationAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidationAddressQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidationAddressQuery(baseOptions?: Apollo.QueryHookOptions<ValidationAddressQuery, ValidationAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidationAddressQuery, ValidationAddressQueryVariables>(ValidationAddressDocument, options);
      }
export function useValidationAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidationAddressQuery, ValidationAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidationAddressQuery, ValidationAddressQueryVariables>(ValidationAddressDocument, options);
        }
export type ValidationAddressQueryHookResult = ReturnType<typeof useValidationAddressQuery>;
export type ValidationAddressLazyQueryHookResult = ReturnType<typeof useValidationAddressLazyQuery>;
export type ValidationAddressQueryResult = Apollo.QueryResult<ValidationAddressQuery, ValidationAddressQueryVariables>;